/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getExternalLink = /* GraphQL */ `
  query GetExternalLink($id: ID!) {
    getExternalLink(id: $id) {
      id
      groupId
      projectId
      title
      url
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listExternalLinks = /* GraphQL */ `
  query ListExternalLinks(
    $filter: ModelExternalLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExternalLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupId
        projectId
        title
        url
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncExternalLinks = /* GraphQL */ `
  query SyncExternalLinks(
    $filter: ModelExternalLinkFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncExternalLinks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        groupId
        projectId
        title
        url
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const externalLinksByProjectId = /* GraphQL */ `
  query ExternalLinksByProjectId(
    $projectId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelExternalLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    externalLinksByProjectId(
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        projectId
        title
        url
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getContact = /* GraphQL */ `
  query GetContact($id: ID!) {
    getContact(id: $id) {
      id
      type
      firstName
      lastName
      companyName
      jobTitle
      phone
      email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts(
    $id: ID
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listContacts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncContacts = /* GraphQL */ `
  query SyncContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncContacts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const searchContacts = /* GraphQL */ `
  query SearchContacts(
    $filter: SearchableContactFilterInput
    $sort: [SearchableContactSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableContactAggregationInput]
  ) {
    searchContacts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      status
      type
      firstName
      lastName
      companyName
      jobTitle
      phone
      email
      metadata
      projects
      favoriteProjects
      inspections {
        items {
          id
          groupId
          clientId
          projectId
          phaseId
          photoSequence
          status
          userId
          inspectionReviewerId
          inspectionDate
          reviewedDate
          deliveredDate
          nextInspectionDate
          arrivalTime
          notes
          coverLetter
          scopeHierarchyItems
          scope
          number
          code
          weather
          milestoneBundleIds
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          inspectionCheckInContactId
          owner
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $id: ID
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        status
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        metadata
        projects
        favoriteProjects
        inspections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        status
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        metadata
        projects
        favoriteProjects
        inspections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const usersByType = /* GraphQL */ `
  query UsersByType(
    $type: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByType(
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        metadata
        projects
        favoriteProjects
        inspections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: [SearchableUserSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableUserAggregationInput]
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        status
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        metadata
        projects
        favoriteProjects
        inspections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getClient = /* GraphQL */ `
  query GetClient($id: ID!) {
    getClient(id: $id) {
      id
      name
      code
      status
      address {
        street
        street2
        city
        state
        zip
      }
      primaryContact {
        id
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      reportDisclaimer
      contacts {
        items {
          id
          clientId
          contactId
          label
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      projects {
        items {
          id
          groupId
          name
          status
          clientId
          reportTemplateId
          reportDisclaimer
          distributionList
          hierarchyItemExclusions
          exclusions
          notes
          code
          milestoneTemplateId
          externalId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          projectEftId
          projectReviewerId
          projectAdminId
          projectPrimaryContactId
          owner
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      clientPrimaryContactId
      owner
    }
  }
`;
export const listClients = /* GraphQL */ `
  query ListClients(
    $id: ID
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listClients(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        code
        status
        address {
          street
          street2
          city
          state
          zip
        }
        primaryContact {
          id
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        reportDisclaimer
        contacts {
          nextToken
          startedAt
        }
        projects {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientPrimaryContactId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncClients = /* GraphQL */ `
  query SyncClients(
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClients(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        code
        status
        address {
          street
          street2
          city
          state
          zip
        }
        primaryContact {
          id
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        reportDisclaimer
        contacts {
          nextToken
          startedAt
        }
        projects {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientPrimaryContactId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const searchClients = /* GraphQL */ `
  query SearchClients(
    $filter: SearchableClientFilterInput
    $sort: [SearchableClientSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableClientAggregationInput]
  ) {
    searchClients(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        code
        status
        address {
          street
          street2
          city
          state
          zip
        }
        primaryContact {
          id
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        reportDisclaimer
        contacts {
          nextToken
          startedAt
        }
        projects {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientPrimaryContactId
        owner
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getClientContact = /* GraphQL */ `
  query GetClientContact($id: ID!) {
    getClientContact(id: $id) {
      id
      clientId
      contactId
      contact {
        id
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      label
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listClientContacts = /* GraphQL */ `
  query ListClientContacts(
    $filter: ModelClientContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientId
        contactId
        contact {
          id
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        label
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncClientContacts = /* GraphQL */ `
  query SyncClientContacts(
    $filter: ModelClientContactFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClientContacts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        clientId
        contactId
        contact {
          id
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        label
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const clientContactsByClientId = /* GraphQL */ `
  query ClientContactsByClientId(
    $clientId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelClientContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clientContactsByClientId(
      clientId: $clientId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        contactId
        contact {
          id
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        label
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      groupId
      name
      status
      clientId
      client {
        id
        name
        code
        status
        address {
          street
          street2
          city
          state
          zip
        }
        primaryContact {
          id
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        reportDisclaimer
        contacts {
          nextToken
          startedAt
        }
        projects {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientPrimaryContactId
        owner
      }
      eft {
        id
        status
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        metadata
        projects
        favoriteProjects
        inspections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      reviewer {
        id
        status
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        metadata
        projects
        favoriteProjects
        inspections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      admin {
        id
        status
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        metadata
        projects
        favoriteProjects
        inspections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      address {
        street
        street2
        city
        state
        zip
      }
      primaryContact {
        id
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      reportTemplateId
      reportDisclaimer
      inspections {
        items {
          id
          groupId
          clientId
          projectId
          phaseId
          photoSequence
          status
          userId
          inspectionReviewerId
          inspectionDate
          reviewedDate
          deliveredDate
          nextInspectionDate
          arrivalTime
          notes
          coverLetter
          scopeHierarchyItems
          scope
          number
          code
          weather
          milestoneBundleIds
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          inspectionCheckInContactId
          owner
        }
        nextToken
        startedAt
      }
      contacts {
        items {
          id
          groupId
          projectId
          contactId
          label
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      distributionList
      externalLinks {
        items {
          id
          groupId
          projectId
          title
          url
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      tags {
        items {
          id
          groupId
          type
          projectId
          text
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      findings {
        items {
          id
          groupId
          type
          projectId
          phaseId
          inspectionId
          hierarchyItemId
          observationId
          status
          date
          closedDate
          code
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          findingClosingObservationId
        }
        nextToken
        startedAt
      }
      phases {
        items {
          id
          groupId
          projectId
          name
          status
          milestoneTemplateId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      hierarchyItemExclusions
      exclusions
      notes
      code
      milestoneTemplate {
        id
        name
        status
        hierarchyBundleIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      milestoneTemplateId
      externalId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      projectEftId
      projectReviewerId
      projectAdminId
      projectPrimaryContactId
      owner
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $id: ID
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProjects(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        groupId
        name
        status
        clientId
        client {
          id
          name
          code
          status
          reportDisclaimer
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientPrimaryContactId
          owner
        }
        eft {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        reviewer {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        admin {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        address {
          street
          street2
          city
          state
          zip
        }
        primaryContact {
          id
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        reportTemplateId
        reportDisclaimer
        inspections {
          nextToken
          startedAt
        }
        contacts {
          nextToken
          startedAt
        }
        distributionList
        externalLinks {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        findings {
          nextToken
          startedAt
        }
        phases {
          nextToken
          startedAt
        }
        hierarchyItemExclusions
        exclusions
        notes
        code
        milestoneTemplate {
          id
          name
          status
          hierarchyBundleIds
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        milestoneTemplateId
        externalId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        projectEftId
        projectReviewerId
        projectAdminId
        projectPrimaryContactId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncProjects = /* GraphQL */ `
  query SyncProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProjects(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        groupId
        name
        status
        clientId
        client {
          id
          name
          code
          status
          reportDisclaimer
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientPrimaryContactId
          owner
        }
        eft {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        reviewer {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        admin {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        address {
          street
          street2
          city
          state
          zip
        }
        primaryContact {
          id
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        reportTemplateId
        reportDisclaimer
        inspections {
          nextToken
          startedAt
        }
        contacts {
          nextToken
          startedAt
        }
        distributionList
        externalLinks {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        findings {
          nextToken
          startedAt
        }
        phases {
          nextToken
          startedAt
        }
        hierarchyItemExclusions
        exclusions
        notes
        code
        milestoneTemplate {
          id
          name
          status
          hierarchyBundleIds
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        milestoneTemplateId
        externalId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        projectEftId
        projectReviewerId
        projectAdminId
        projectPrimaryContactId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const projectsByClientId = /* GraphQL */ `
  query ProjectsByClientId(
    $clientId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectsByClientId(
      clientId: $clientId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        name
        status
        clientId
        client {
          id
          name
          code
          status
          reportDisclaimer
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientPrimaryContactId
          owner
        }
        eft {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        reviewer {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        admin {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        address {
          street
          street2
          city
          state
          zip
        }
        primaryContact {
          id
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        reportTemplateId
        reportDisclaimer
        inspections {
          nextToken
          startedAt
        }
        contacts {
          nextToken
          startedAt
        }
        distributionList
        externalLinks {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        findings {
          nextToken
          startedAt
        }
        phases {
          nextToken
          startedAt
        }
        hierarchyItemExclusions
        exclusions
        notes
        code
        milestoneTemplate {
          id
          name
          status
          hierarchyBundleIds
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        milestoneTemplateId
        externalId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        projectEftId
        projectReviewerId
        projectAdminId
        projectPrimaryContactId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const searchProjects = /* GraphQL */ `
  query SearchProjects(
    $filter: SearchableProjectFilterInput
    $sort: [SearchableProjectSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableProjectAggregationInput]
  ) {
    searchProjects(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        groupId
        name
        status
        clientId
        client {
          id
          name
          code
          status
          reportDisclaimer
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientPrimaryContactId
          owner
        }
        eft {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        reviewer {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        admin {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        address {
          street
          street2
          city
          state
          zip
        }
        primaryContact {
          id
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        reportTemplateId
        reportDisclaimer
        inspections {
          nextToken
          startedAt
        }
        contacts {
          nextToken
          startedAt
        }
        distributionList
        externalLinks {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        findings {
          nextToken
          startedAt
        }
        phases {
          nextToken
          startedAt
        }
        hierarchyItemExclusions
        exclusions
        notes
        code
        milestoneTemplate {
          id
          name
          status
          hierarchyBundleIds
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        milestoneTemplateId
        externalId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        projectEftId
        projectReviewerId
        projectAdminId
        projectPrimaryContactId
        owner
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getPhase = /* GraphQL */ `
  query GetPhase($id: ID!) {
    getPhase(id: $id) {
      id
      groupId
      projectId
      project {
        id
        groupId
        name
        status
        clientId
        client {
          id
          name
          code
          status
          reportDisclaimer
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientPrimaryContactId
          owner
        }
        eft {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        reviewer {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        admin {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        address {
          street
          street2
          city
          state
          zip
        }
        primaryContact {
          id
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        reportTemplateId
        reportDisclaimer
        inspections {
          nextToken
          startedAt
        }
        contacts {
          nextToken
          startedAt
        }
        distributionList
        externalLinks {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        findings {
          nextToken
          startedAt
        }
        phases {
          nextToken
          startedAt
        }
        hierarchyItemExclusions
        exclusions
        notes
        code
        milestoneTemplate {
          id
          name
          status
          hierarchyBundleIds
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        milestoneTemplateId
        externalId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        projectEftId
        projectReviewerId
        projectAdminId
        projectPrimaryContactId
        owner
      }
      name
      status
      units {
        id
        name
      }
      milestoneTemplate {
        id
        name
        status
        hierarchyBundleIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      milestoneTemplateId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listPhases = /* GraphQL */ `
  query ListPhases(
    $filter: ModelPhaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhases(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupId
        projectId
        project {
          id
          groupId
          name
          status
          clientId
          reportTemplateId
          reportDisclaimer
          distributionList
          hierarchyItemExclusions
          exclusions
          notes
          code
          milestoneTemplateId
          externalId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          projectEftId
          projectReviewerId
          projectAdminId
          projectPrimaryContactId
          owner
        }
        name
        status
        units {
          id
          name
        }
        milestoneTemplate {
          id
          name
          status
          hierarchyBundleIds
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        milestoneTemplateId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPhases = /* GraphQL */ `
  query SyncPhases(
    $filter: ModelPhaseFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPhases(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        groupId
        projectId
        project {
          id
          groupId
          name
          status
          clientId
          reportTemplateId
          reportDisclaimer
          distributionList
          hierarchyItemExclusions
          exclusions
          notes
          code
          milestoneTemplateId
          externalId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          projectEftId
          projectReviewerId
          projectAdminId
          projectPrimaryContactId
          owner
        }
        name
        status
        units {
          id
          name
        }
        milestoneTemplate {
          id
          name
          status
          hierarchyBundleIds
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        milestoneTemplateId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const phasesByProjectId = /* GraphQL */ `
  query PhasesByProjectId(
    $projectId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPhaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    phasesByProjectId(
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        projectId
        project {
          id
          groupId
          name
          status
          clientId
          reportTemplateId
          reportDisclaimer
          distributionList
          hierarchyItemExclusions
          exclusions
          notes
          code
          milestoneTemplateId
          externalId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          projectEftId
          projectReviewerId
          projectAdminId
          projectPrimaryContactId
          owner
        }
        name
        status
        units {
          id
          name
        }
        milestoneTemplate {
          id
          name
          status
          hierarchyBundleIds
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        milestoneTemplateId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const searchPhases = /* GraphQL */ `
  query SearchPhases(
    $filter: SearchablePhaseFilterInput
    $sort: [SearchablePhaseSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablePhaseAggregationInput]
  ) {
    searchPhases(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        groupId
        projectId
        project {
          id
          groupId
          name
          status
          clientId
          reportTemplateId
          reportDisclaimer
          distributionList
          hierarchyItemExclusions
          exclusions
          notes
          code
          milestoneTemplateId
          externalId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          projectEftId
          projectReviewerId
          projectAdminId
          projectPrimaryContactId
          owner
        }
        name
        status
        units {
          id
          name
        }
        milestoneTemplate {
          id
          name
          status
          hierarchyBundleIds
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        milestoneTemplateId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getProjectContact = /* GraphQL */ `
  query GetProjectContact($id: ID!) {
    getProjectContact(id: $id) {
      id
      groupId
      projectId
      contactId
      contact {
        id
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      label
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listProjectContacts = /* GraphQL */ `
  query ListProjectContacts(
    $filter: ModelProjectContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjectContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupId
        projectId
        contactId
        contact {
          id
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        label
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncProjectContacts = /* GraphQL */ `
  query SyncProjectContacts(
    $filter: ModelProjectContactFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProjectContacts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        groupId
        projectId
        contactId
        contact {
          id
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        label
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const projectContactsByProjectId = /* GraphQL */ `
  query ProjectContactsByProjectId(
    $projectId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProjectContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectContactsByProjectId(
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        projectId
        contactId
        contact {
          id
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        label
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getFavoriteProject = /* GraphQL */ `
  query GetFavoriteProject($id: ID!) {
    getFavoriteProject(id: $id) {
      id
      projectId
      project {
        id
        groupId
        name
        status
        clientId
        client {
          id
          name
          code
          status
          reportDisclaimer
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientPrimaryContactId
          owner
        }
        eft {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        reviewer {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        admin {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        address {
          street
          street2
          city
          state
          zip
        }
        primaryContact {
          id
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        reportTemplateId
        reportDisclaimer
        inspections {
          nextToken
          startedAt
        }
        contacts {
          nextToken
          startedAt
        }
        distributionList
        externalLinks {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        findings {
          nextToken
          startedAt
        }
        phases {
          nextToken
          startedAt
        }
        hierarchyItemExclusions
        exclusions
        notes
        code
        milestoneTemplate {
          id
          name
          status
          hierarchyBundleIds
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        milestoneTemplateId
        externalId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        projectEftId
        projectReviewerId
        projectAdminId
        projectPrimaryContactId
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listFavoriteProjects = /* GraphQL */ `
  query ListFavoriteProjects(
    $filter: ModelFavoriteProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFavoriteProjects(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        projectId
        project {
          id
          groupId
          name
          status
          clientId
          reportTemplateId
          reportDisclaimer
          distributionList
          hierarchyItemExclusions
          exclusions
          notes
          code
          milestoneTemplateId
          externalId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          projectEftId
          projectReviewerId
          projectAdminId
          projectPrimaryContactId
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFavoriteProjects = /* GraphQL */ `
  query SyncFavoriteProjects(
    $filter: ModelFavoriteProjectFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFavoriteProjects(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        projectId
        project {
          id
          groupId
          name
          status
          clientId
          reportTemplateId
          reportDisclaimer
          distributionList
          hierarchyItemExclusions
          exclusions
          notes
          code
          milestoneTemplateId
          externalId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          projectEftId
          projectReviewerId
          projectAdminId
          projectPrimaryContactId
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getInspection = /* GraphQL */ `
  query GetInspection($id: ID!) {
    getInspection(id: $id) {
      id
      groupId
      clientId
      projectId
      project {
        id
        groupId
        name
        status
        clientId
        client {
          id
          name
          code
          status
          reportDisclaimer
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientPrimaryContactId
          owner
        }
        eft {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        reviewer {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        admin {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        address {
          street
          street2
          city
          state
          zip
        }
        primaryContact {
          id
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        reportTemplateId
        reportDisclaimer
        inspections {
          nextToken
          startedAt
        }
        contacts {
          nextToken
          startedAt
        }
        distributionList
        externalLinks {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        findings {
          nextToken
          startedAt
        }
        phases {
          nextToken
          startedAt
        }
        hierarchyItemExclusions
        exclusions
        notes
        code
        milestoneTemplate {
          id
          name
          status
          hierarchyBundleIds
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        milestoneTemplateId
        externalId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        projectEftId
        projectReviewerId
        projectAdminId
        projectPrimaryContactId
        owner
      }
      phase {
        id
        groupId
        projectId
        project {
          id
          groupId
          name
          status
          clientId
          reportTemplateId
          reportDisclaimer
          distributionList
          hierarchyItemExclusions
          exclusions
          notes
          code
          milestoneTemplateId
          externalId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          projectEftId
          projectReviewerId
          projectAdminId
          projectPrimaryContactId
          owner
        }
        name
        status
        units {
          id
          name
        }
        milestoneTemplate {
          id
          name
          status
          hierarchyBundleIds
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        milestoneTemplateId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      phaseId
      photoSequence
      status
      userId
      user {
        id
        status
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        metadata
        projects
        favoriteProjects
        inspections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      reviewer {
        id
        status
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        metadata
        projects
        favoriteProjects
        inspections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      inspectionReviewerId
      inspectionDate
      reviewedDate
      deliveredDate
      nextInspectionDate
      arrivalTime
      checkInContact {
        id
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      notes
      coverLetter
      comments {
        items {
          id
          groupId
          projectId
          inspectionId
          commentId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      observations {
        items {
          id
          groupId
          type
          projectId
          inspectionId
          hierarchyItemId
          inspectionPointId
          text
          suggestion
          findingId
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          observationPrimaryLocationId
          owner
        }
        nextToken
        startedAt
      }
      findings {
        items {
          id
          groupId
          type
          projectId
          phaseId
          inspectionId
          hierarchyItemId
          observationId
          status
          date
          closedDate
          code
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          findingClosingObservationId
        }
        nextToken
        startedAt
      }
      hierarchyItems {
        items {
          id
          inspectionId
          hierarchyItemId
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      photos {
        items {
          id
          groupId
          projectId
          inspectionId
          photoId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      scopeHierarchyItems
      scope
      number
      code
      report {
        bucket
        region
        key
      }
      weather
      milestoneBundleIds
      milestoneTrackers {
        milestoneId
        all
        units
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      inspectionCheckInContactId
      owner
    }
  }
`;
export const listInspections = /* GraphQL */ `
  query ListInspections(
    $id: ID
    $filter: ModelInspectionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInspections(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        groupId
        clientId
        projectId
        project {
          id
          groupId
          name
          status
          clientId
          reportTemplateId
          reportDisclaimer
          distributionList
          hierarchyItemExclusions
          exclusions
          notes
          code
          milestoneTemplateId
          externalId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          projectEftId
          projectReviewerId
          projectAdminId
          projectPrimaryContactId
          owner
        }
        phase {
          id
          groupId
          projectId
          name
          status
          milestoneTemplateId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        phaseId
        photoSequence
        status
        userId
        user {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        reviewer {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        inspectionReviewerId
        inspectionDate
        reviewedDate
        deliveredDate
        nextInspectionDate
        arrivalTime
        checkInContact {
          id
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        notes
        coverLetter
        comments {
          nextToken
          startedAt
        }
        observations {
          nextToken
          startedAt
        }
        findings {
          nextToken
          startedAt
        }
        hierarchyItems {
          nextToken
          startedAt
        }
        photos {
          nextToken
          startedAt
        }
        scopeHierarchyItems
        scope
        number
        code
        report {
          bucket
          region
          key
        }
        weather
        milestoneBundleIds
        milestoneTrackers {
          milestoneId
          all
          units
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        inspectionCheckInContactId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncInspections = /* GraphQL */ `
  query SyncInspections(
    $filter: ModelInspectionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInspections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        groupId
        clientId
        projectId
        project {
          id
          groupId
          name
          status
          clientId
          reportTemplateId
          reportDisclaimer
          distributionList
          hierarchyItemExclusions
          exclusions
          notes
          code
          milestoneTemplateId
          externalId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          projectEftId
          projectReviewerId
          projectAdminId
          projectPrimaryContactId
          owner
        }
        phase {
          id
          groupId
          projectId
          name
          status
          milestoneTemplateId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        phaseId
        photoSequence
        status
        userId
        user {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        reviewer {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        inspectionReviewerId
        inspectionDate
        reviewedDate
        deliveredDate
        nextInspectionDate
        arrivalTime
        checkInContact {
          id
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        notes
        coverLetter
        comments {
          nextToken
          startedAt
        }
        observations {
          nextToken
          startedAt
        }
        findings {
          nextToken
          startedAt
        }
        hierarchyItems {
          nextToken
          startedAt
        }
        photos {
          nextToken
          startedAt
        }
        scopeHierarchyItems
        scope
        number
        code
        report {
          bucket
          region
          key
        }
        weather
        milestoneBundleIds
        milestoneTrackers {
          milestoneId
          all
          units
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        inspectionCheckInContactId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const inspectionsByProjectId = /* GraphQL */ `
  query InspectionsByProjectId(
    $projectId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInspectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inspectionsByProjectId(
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        clientId
        projectId
        project {
          id
          groupId
          name
          status
          clientId
          reportTemplateId
          reportDisclaimer
          distributionList
          hierarchyItemExclusions
          exclusions
          notes
          code
          milestoneTemplateId
          externalId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          projectEftId
          projectReviewerId
          projectAdminId
          projectPrimaryContactId
          owner
        }
        phase {
          id
          groupId
          projectId
          name
          status
          milestoneTemplateId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        phaseId
        photoSequence
        status
        userId
        user {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        reviewer {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        inspectionReviewerId
        inspectionDate
        reviewedDate
        deliveredDate
        nextInspectionDate
        arrivalTime
        checkInContact {
          id
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        notes
        coverLetter
        comments {
          nextToken
          startedAt
        }
        observations {
          nextToken
          startedAt
        }
        findings {
          nextToken
          startedAt
        }
        hierarchyItems {
          nextToken
          startedAt
        }
        photos {
          nextToken
          startedAt
        }
        scopeHierarchyItems
        scope
        number
        code
        report {
          bucket
          region
          key
        }
        weather
        milestoneBundleIds
        milestoneTrackers {
          milestoneId
          all
          units
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        inspectionCheckInContactId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const inspectionsByUserId = /* GraphQL */ `
  query InspectionsByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInspectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inspectionsByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        clientId
        projectId
        project {
          id
          groupId
          name
          status
          clientId
          reportTemplateId
          reportDisclaimer
          distributionList
          hierarchyItemExclusions
          exclusions
          notes
          code
          milestoneTemplateId
          externalId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          projectEftId
          projectReviewerId
          projectAdminId
          projectPrimaryContactId
          owner
        }
        phase {
          id
          groupId
          projectId
          name
          status
          milestoneTemplateId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        phaseId
        photoSequence
        status
        userId
        user {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        reviewer {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        inspectionReviewerId
        inspectionDate
        reviewedDate
        deliveredDate
        nextInspectionDate
        arrivalTime
        checkInContact {
          id
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        notes
        coverLetter
        comments {
          nextToken
          startedAt
        }
        observations {
          nextToken
          startedAt
        }
        findings {
          nextToken
          startedAt
        }
        hierarchyItems {
          nextToken
          startedAt
        }
        photos {
          nextToken
          startedAt
        }
        scopeHierarchyItems
        scope
        number
        code
        report {
          bucket
          region
          key
        }
        weather
        milestoneBundleIds
        milestoneTrackers {
          milestoneId
          all
          units
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        inspectionCheckInContactId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const searchInspections = /* GraphQL */ `
  query SearchInspections(
    $filter: SearchableInspectionFilterInput
    $sort: [SearchableInspectionSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableInspectionAggregationInput]
  ) {
    searchInspections(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        groupId
        clientId
        projectId
        project {
          id
          groupId
          name
          status
          clientId
          reportTemplateId
          reportDisclaimer
          distributionList
          hierarchyItemExclusions
          exclusions
          notes
          code
          milestoneTemplateId
          externalId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          projectEftId
          projectReviewerId
          projectAdminId
          projectPrimaryContactId
          owner
        }
        phase {
          id
          groupId
          projectId
          name
          status
          milestoneTemplateId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        phaseId
        photoSequence
        status
        userId
        user {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        reviewer {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        inspectionReviewerId
        inspectionDate
        reviewedDate
        deliveredDate
        nextInspectionDate
        arrivalTime
        checkInContact {
          id
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        notes
        coverLetter
        comments {
          nextToken
          startedAt
        }
        observations {
          nextToken
          startedAt
        }
        findings {
          nextToken
          startedAt
        }
        hierarchyItems {
          nextToken
          startedAt
        }
        photos {
          nextToken
          startedAt
        }
        scopeHierarchyItems
        scope
        number
        code
        report {
          bucket
          region
          key
        }
        weather
        milestoneBundleIds
        milestoneTrackers {
          milestoneId
          all
          units
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        inspectionCheckInContactId
        owner
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getHierarchy = /* GraphQL */ `
  query GetHierarchy($id: ID!) {
    getHierarchy(id: $id) {
      id
      name
      items {
        items {
          id
          name
          code
          hierarchyId
          parentId
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listHierarchies = /* GraphQL */ `
  query ListHierarchies(
    $filter: ModelHierarchyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHierarchies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        items {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHierarchies = /* GraphQL */ `
  query SyncHierarchies(
    $filter: ModelHierarchyFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHierarchies(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        items {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getHierarchyItem = /* GraphQL */ `
  query GetHierarchyItem($id: ID!) {
    getHierarchyItem(id: $id) {
      id
      name
      code
      hierarchyId
      parentId
      hidden
      inspectionPoints {
        items {
          id
          groupId
          hierarchyItemId
          type
          projectId
          text
          code
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listHierarchyItems = /* GraphQL */ `
  query ListHierarchyItems(
    $filter: ModelHierarchyItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHierarchyItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        code
        hierarchyId
        parentId
        hidden
        inspectionPoints {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHierarchyItems = /* GraphQL */ `
  query SyncHierarchyItems(
    $filter: ModelHierarchyItemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHierarchyItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        code
        hierarchyId
        parentId
        hidden
        inspectionPoints {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const hierarchyItemsByHierarchyId = /* GraphQL */ `
  query HierarchyItemsByHierarchyId(
    $hierarchyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelHierarchyItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    hierarchyItemsByHierarchyId(
      hierarchyId: $hierarchyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        code
        hierarchyId
        parentId
        hidden
        inspectionPoints {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const hierarchyItemsByParentId = /* GraphQL */ `
  query HierarchyItemsByParentId(
    $parentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelHierarchyItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    hierarchyItemsByParentId(
      parentId: $parentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        code
        hierarchyId
        parentId
        hidden
        inspectionPoints {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getInspectionHierarchyItem = /* GraphQL */ `
  query GetInspectionHierarchyItem($id: ID!) {
    getInspectionHierarchyItem(id: $id) {
      id
      inspectionId
      hierarchyItemId
      hierarchyItem {
        id
        name
        code
        hierarchyId
        parentId
        hidden
        inspectionPoints {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      notes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listInspectionHierarchyItems = /* GraphQL */ `
  query ListInspectionHierarchyItems(
    $filter: ModelInspectionHierarchyItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInspectionHierarchyItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        inspectionId
        hierarchyItemId
        hierarchyItem {
          id
          name
          code
          hierarchyId
          parentId
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        notes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncInspectionHierarchyItems = /* GraphQL */ `
  query SyncInspectionHierarchyItems(
    $filter: ModelInspectionHierarchyItemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInspectionHierarchyItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        inspectionId
        hierarchyItemId
        hierarchyItem {
          id
          name
          code
          hierarchyId
          parentId
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        notes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const inspectionHierarchyItemsByInspectionId = /* GraphQL */ `
  query InspectionHierarchyItemsByInspectionId(
    $inspectionId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInspectionHierarchyItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inspectionHierarchyItemsByInspectionId(
      inspectionId: $inspectionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        inspectionId
        hierarchyItemId
        hierarchyItem {
          id
          name
          code
          hierarchyId
          parentId
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        notes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getInspectionPoint = /* GraphQL */ `
  query GetInspectionPoint($id: ID!) {
    getInspectionPoint(id: $id) {
      id
      groupId
      hierarchyItemId
      type
      projectId
      text
      code
      hidden
      observations {
        items {
          id
          groupId
          type
          projectId
          inspectionId
          hierarchyItemId
          inspectionPointId
          text
          suggestion
          findingId
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          observationPrimaryLocationId
          owner
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listInspectionPoints = /* GraphQL */ `
  query ListInspectionPoints(
    $id: ID
    $filter: ModelInspectionPointFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInspectionPoints(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        groupId
        hierarchyItemId
        type
        projectId
        text
        code
        hidden
        observations {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncInspectionPoints = /* GraphQL */ `
  query SyncInspectionPoints(
    $filter: ModelInspectionPointFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInspectionPoints(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        groupId
        hierarchyItemId
        type
        projectId
        text
        code
        hidden
        observations {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const inspectionPointsByHierarchyItemId = /* GraphQL */ `
  query InspectionPointsByHierarchyItemId(
    $hierarchyItemId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInspectionPointFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inspectionPointsByHierarchyItemId(
      hierarchyItemId: $hierarchyItemId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        hierarchyItemId
        type
        projectId
        text
        code
        hidden
        observations {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const searchInspectionPoints = /* GraphQL */ `
  query SearchInspectionPoints(
    $filter: SearchableInspectionPointFilterInput
    $sort: [SearchableInspectionPointSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableInspectionPointAggregationInput]
  ) {
    searchInspectionPoints(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        groupId
        hierarchyItemId
        type
        projectId
        text
        code
        hidden
        observations {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      groupId
      projectId
      userId
      user {
        id
        status
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        metadata
        projects
        favoriteProjects
        inspections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      date
      text
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupId
        projectId
        userId
        user {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        date
        text
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncComments = /* GraphQL */ `
  query SyncComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncComments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        groupId
        projectId
        userId
        user {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        date
        text
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getInspectionComment = /* GraphQL */ `
  query GetInspectionComment($id: ID!) {
    getInspectionComment(id: $id) {
      id
      groupId
      projectId
      inspectionId
      commentId
      comment {
        id
        groupId
        projectId
        userId
        user {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        date
        text
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listInspectionComments = /* GraphQL */ `
  query ListInspectionComments(
    $filter: ModelInspectionCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInspectionComments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        projectId
        inspectionId
        commentId
        comment {
          id
          groupId
          projectId
          userId
          date
          text
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncInspectionComments = /* GraphQL */ `
  query SyncInspectionComments(
    $filter: ModelInspectionCommentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInspectionComments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        groupId
        projectId
        inspectionId
        commentId
        comment {
          id
          groupId
          projectId
          userId
          date
          text
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const commentsByInspectionId = /* GraphQL */ `
  query CommentsByInspectionId(
    $inspectionId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInspectionCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commentsByInspectionId(
      inspectionId: $inspectionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        projectId
        inspectionId
        commentId
        comment {
          id
          groupId
          projectId
          userId
          date
          text
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getObservation = /* GraphQL */ `
  query GetObservation($id: ID!) {
    getObservation(id: $id) {
      id
      groupId
      type
      projectId
      inspectionId
      hierarchyItemId
      hierarchyItem {
        id
        name
        code
        hierarchyId
        parentId
        hidden
        inspectionPoints {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      inspectionPointId
      inspectionPoint {
        id
        groupId
        hierarchyItemId
        type
        projectId
        text
        code
        hidden
        observations {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      text
      suggestion
      findingId
      primaryLocation {
        id
        groupId
        projectId
        observationId
        primaryPhoto {
          id
          groupId
          projectId
          inspectionId
          json
          text
          reportable
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        photoSequence
        tags {
          nextToken
          startedAt
        }
        photos {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        locationPrimaryPhotoId
        owner
      }
      locations {
        items {
          id
          groupId
          projectId
          observationId
          photoSequence
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          locationPrimaryPhotoId
          owner
        }
        nextToken
        startedAt
      }
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      observationPrimaryLocationId
      owner
    }
  }
`;
export const listObservations = /* GraphQL */ `
  query ListObservations(
    $filter: ModelObservationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listObservations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupId
        type
        projectId
        inspectionId
        hierarchyItemId
        hierarchyItem {
          id
          name
          code
          hierarchyId
          parentId
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        inspectionPointId
        inspectionPoint {
          id
          groupId
          hierarchyItemId
          type
          projectId
          text
          code
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        text
        suggestion
        findingId
        primaryLocation {
          id
          groupId
          projectId
          observationId
          photoSequence
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          locationPrimaryPhotoId
          owner
        }
        locations {
          nextToken
          startedAt
        }
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        observationPrimaryLocationId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncObservations = /* GraphQL */ `
  query SyncObservations(
    $filter: ModelObservationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncObservations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        groupId
        type
        projectId
        inspectionId
        hierarchyItemId
        hierarchyItem {
          id
          name
          code
          hierarchyId
          parentId
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        inspectionPointId
        inspectionPoint {
          id
          groupId
          hierarchyItemId
          type
          projectId
          text
          code
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        text
        suggestion
        findingId
        primaryLocation {
          id
          groupId
          projectId
          observationId
          photoSequence
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          locationPrimaryPhotoId
          owner
        }
        locations {
          nextToken
          startedAt
        }
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        observationPrimaryLocationId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const observationsByInspectionId = /* GraphQL */ `
  query ObservationsByInspectionId(
    $inspectionId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelObservationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    observationsByInspectionId(
      inspectionId: $inspectionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        type
        projectId
        inspectionId
        hierarchyItemId
        hierarchyItem {
          id
          name
          code
          hierarchyId
          parentId
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        inspectionPointId
        inspectionPoint {
          id
          groupId
          hierarchyItemId
          type
          projectId
          text
          code
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        text
        suggestion
        findingId
        primaryLocation {
          id
          groupId
          projectId
          observationId
          photoSequence
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          locationPrimaryPhotoId
          owner
        }
        locations {
          nextToken
          startedAt
        }
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        observationPrimaryLocationId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const observationsByInspectionIdAndStatus = /* GraphQL */ `
  query ObservationsByInspectionIdAndStatus(
    $inspectionId: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelObservationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    observationsByInspectionIdAndStatus(
      inspectionId: $inspectionId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        type
        projectId
        inspectionId
        hierarchyItemId
        hierarchyItem {
          id
          name
          code
          hierarchyId
          parentId
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        inspectionPointId
        inspectionPoint {
          id
          groupId
          hierarchyItemId
          type
          projectId
          text
          code
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        text
        suggestion
        findingId
        primaryLocation {
          id
          groupId
          projectId
          observationId
          photoSequence
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          locationPrimaryPhotoId
          owner
        }
        locations {
          nextToken
          startedAt
        }
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        observationPrimaryLocationId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const observationsByInspectionPointId = /* GraphQL */ `
  query ObservationsByInspectionPointId(
    $inspectionPointId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelObservationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    observationsByInspectionPointId(
      inspectionPointId: $inspectionPointId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        type
        projectId
        inspectionId
        hierarchyItemId
        hierarchyItem {
          id
          name
          code
          hierarchyId
          parentId
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        inspectionPointId
        inspectionPoint {
          id
          groupId
          hierarchyItemId
          type
          projectId
          text
          code
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        text
        suggestion
        findingId
        primaryLocation {
          id
          groupId
          projectId
          observationId
          photoSequence
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          locationPrimaryPhotoId
          owner
        }
        locations {
          nextToken
          startedAt
        }
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        observationPrimaryLocationId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getLocation = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      groupId
      projectId
      observationId
      primaryPhoto {
        id
        groupId
        projectId
        inspectionId
        json
        text
        file {
          bucket
          region
          key
        }
        reportable
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      photoSequence
      tags {
        items {
          id
          groupId
          projectId
          locationId
          tagId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      photos {
        items {
          id
          groupId
          projectId
          locationId
          photoId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      locationPrimaryPhotoId
      owner
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupId
        projectId
        observationId
        primaryPhoto {
          id
          groupId
          projectId
          inspectionId
          json
          text
          reportable
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        photoSequence
        tags {
          nextToken
          startedAt
        }
        photos {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        locationPrimaryPhotoId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncLocations = /* GraphQL */ `
  query SyncLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLocations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        groupId
        projectId
        observationId
        primaryPhoto {
          id
          groupId
          projectId
          inspectionId
          json
          text
          reportable
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        photoSequence
        tags {
          nextToken
          startedAt
        }
        photos {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        locationPrimaryPhotoId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const locationsByObservationId = /* GraphQL */ `
  query LocationsByObservationId(
    $observationId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    locationsByObservationId(
      observationId: $observationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        projectId
        observationId
        primaryPhoto {
          id
          groupId
          projectId
          inspectionId
          json
          text
          reportable
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        photoSequence
        tags {
          nextToken
          startedAt
        }
        photos {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        locationPrimaryPhotoId
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getTag = /* GraphQL */ `
  query GetTag($id: ID!) {
    getTag(id: $id) {
      id
      groupId
      type
      projectId
      text
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listTags = /* GraphQL */ `
  query ListTags(
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupId
        type
        projectId
        text
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTags = /* GraphQL */ `
  query SyncTags(
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTags(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        groupId
        type
        projectId
        text
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const tagsByProjectId = /* GraphQL */ `
  query TagsByProjectId(
    $projectId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tagsByProjectId(
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        type
        projectId
        text
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getLocationTag = /* GraphQL */ `
  query GetLocationTag($id: ID!) {
    getLocationTag(id: $id) {
      id
      groupId
      projectId
      locationId
      tagId
      tag {
        id
        groupId
        type
        projectId
        text
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listLocationTags = /* GraphQL */ `
  query ListLocationTags(
    $filter: ModelLocationTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocationTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupId
        projectId
        locationId
        tagId
        tag {
          id
          groupId
          type
          projectId
          text
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncLocationTags = /* GraphQL */ `
  query SyncLocationTags(
    $filter: ModelLocationTagFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLocationTags(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        groupId
        projectId
        locationId
        tagId
        tag {
          id
          groupId
          type
          projectId
          text
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const locationTagsByLocationId = /* GraphQL */ `
  query LocationTagsByLocationId(
    $locationId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLocationTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    locationTagsByLocationId(
      locationId: $locationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        projectId
        locationId
        tagId
        tag {
          id
          groupId
          type
          projectId
          text
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getPhoto = /* GraphQL */ `
  query GetPhoto($id: ID!) {
    getPhoto(id: $id) {
      id
      groupId
      projectId
      inspectionId
      json
      text
      file {
        bucket
        region
        key
      }
      reportable
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listPhotos = /* GraphQL */ `
  query ListPhotos(
    $filter: ModelPhotoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhotos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupId
        projectId
        inspectionId
        json
        text
        file {
          bucket
          region
          key
        }
        reportable
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPhotos = /* GraphQL */ `
  query SyncPhotos(
    $filter: ModelPhotoFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPhotos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        groupId
        projectId
        inspectionId
        json
        text
        file {
          bucket
          region
          key
        }
        reportable
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getInspectionPhoto = /* GraphQL */ `
  query GetInspectionPhoto($id: ID!) {
    getInspectionPhoto(id: $id) {
      id
      groupId
      projectId
      inspectionId
      photoId
      photo {
        id
        groupId
        projectId
        inspectionId
        json
        text
        file {
          bucket
          region
          key
        }
        reportable
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listInspectionPhotos = /* GraphQL */ `
  query ListInspectionPhotos(
    $filter: ModelInspectionPhotoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInspectionPhotos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        projectId
        inspectionId
        photoId
        photo {
          id
          groupId
          projectId
          inspectionId
          json
          text
          reportable
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncInspectionPhotos = /* GraphQL */ `
  query SyncInspectionPhotos(
    $filter: ModelInspectionPhotoFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInspectionPhotos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        groupId
        projectId
        inspectionId
        photoId
        photo {
          id
          groupId
          projectId
          inspectionId
          json
          text
          reportable
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const photosByInspectionId = /* GraphQL */ `
  query PhotosByInspectionId(
    $inspectionId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInspectionPhotoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    photosByInspectionId(
      inspectionId: $inspectionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        projectId
        inspectionId
        photoId
        photo {
          id
          groupId
          projectId
          inspectionId
          json
          text
          reportable
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getLocationPhoto = /* GraphQL */ `
  query GetLocationPhoto($id: ID!) {
    getLocationPhoto(id: $id) {
      id
      groupId
      projectId
      locationId
      photoId
      photo {
        id
        groupId
        projectId
        inspectionId
        json
        text
        file {
          bucket
          region
          key
        }
        reportable
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listLocationPhotos = /* GraphQL */ `
  query ListLocationPhotos(
    $filter: ModelLocationPhotoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocationPhotos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupId
        projectId
        locationId
        photoId
        photo {
          id
          groupId
          projectId
          inspectionId
          json
          text
          reportable
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncLocationPhotos = /* GraphQL */ `
  query SyncLocationPhotos(
    $filter: ModelLocationPhotoFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLocationPhotos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        groupId
        projectId
        locationId
        photoId
        photo {
          id
          groupId
          projectId
          inspectionId
          json
          text
          reportable
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const photosByLocationId = /* GraphQL */ `
  query PhotosByLocationId(
    $locationId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLocationPhotoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    photosByLocationId(
      locationId: $locationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        projectId
        locationId
        photoId
        photo {
          id
          groupId
          projectId
          inspectionId
          json
          text
          reportable
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getFinding = /* GraphQL */ `
  query GetFinding($id: ID!) {
    getFinding(id: $id) {
      id
      groupId
      type
      projectId
      phaseId
      inspectionId
      hierarchyItemId
      hierarchyItem {
        id
        name
        code
        hierarchyId
        parentId
        hidden
        inspectionPoints {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      observationId
      observation {
        id
        groupId
        type
        projectId
        inspectionId
        hierarchyItemId
        hierarchyItem {
          id
          name
          code
          hierarchyId
          parentId
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        inspectionPointId
        inspectionPoint {
          id
          groupId
          hierarchyItemId
          type
          projectId
          text
          code
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        text
        suggestion
        findingId
        primaryLocation {
          id
          groupId
          projectId
          observationId
          photoSequence
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          locationPrimaryPhotoId
          owner
        }
        locations {
          nextToken
          startedAt
        }
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        observationPrimaryLocationId
        owner
      }
      closingObservation {
        id
        groupId
        type
        projectId
        inspectionId
        hierarchyItemId
        hierarchyItem {
          id
          name
          code
          hierarchyId
          parentId
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        inspectionPointId
        inspectionPoint {
          id
          groupId
          hierarchyItemId
          type
          projectId
          text
          code
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        text
        suggestion
        findingId
        primaryLocation {
          id
          groupId
          projectId
          observationId
          photoSequence
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          locationPrimaryPhotoId
          owner
        }
        locations {
          nextToken
          startedAt
        }
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        observationPrimaryLocationId
        owner
      }
      status
      date
      closedDate
      code
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      findingClosingObservationId
    }
  }
`;
export const listFindings = /* GraphQL */ `
  query ListFindings(
    $filter: ModelFindingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFindings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupId
        type
        projectId
        phaseId
        inspectionId
        hierarchyItemId
        hierarchyItem {
          id
          name
          code
          hierarchyId
          parentId
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        observationId
        observation {
          id
          groupId
          type
          projectId
          inspectionId
          hierarchyItemId
          inspectionPointId
          text
          suggestion
          findingId
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          observationPrimaryLocationId
          owner
        }
        closingObservation {
          id
          groupId
          type
          projectId
          inspectionId
          hierarchyItemId
          inspectionPointId
          text
          suggestion
          findingId
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          observationPrimaryLocationId
          owner
        }
        status
        date
        closedDate
        code
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        findingClosingObservationId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFindings = /* GraphQL */ `
  query SyncFindings(
    $filter: ModelFindingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFindings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        groupId
        type
        projectId
        phaseId
        inspectionId
        hierarchyItemId
        hierarchyItem {
          id
          name
          code
          hierarchyId
          parentId
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        observationId
        observation {
          id
          groupId
          type
          projectId
          inspectionId
          hierarchyItemId
          inspectionPointId
          text
          suggestion
          findingId
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          observationPrimaryLocationId
          owner
        }
        closingObservation {
          id
          groupId
          type
          projectId
          inspectionId
          hierarchyItemId
          inspectionPointId
          text
          suggestion
          findingId
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          observationPrimaryLocationId
          owner
        }
        status
        date
        closedDate
        code
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        findingClosingObservationId
      }
      nextToken
      startedAt
    }
  }
`;
export const findingsByProjectId = /* GraphQL */ `
  query FindingsByProjectId(
    $projectId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelFindingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findingsByProjectId(
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        type
        projectId
        phaseId
        inspectionId
        hierarchyItemId
        hierarchyItem {
          id
          name
          code
          hierarchyId
          parentId
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        observationId
        observation {
          id
          groupId
          type
          projectId
          inspectionId
          hierarchyItemId
          inspectionPointId
          text
          suggestion
          findingId
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          observationPrimaryLocationId
          owner
        }
        closingObservation {
          id
          groupId
          type
          projectId
          inspectionId
          hierarchyItemId
          inspectionPointId
          text
          suggestion
          findingId
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          observationPrimaryLocationId
          owner
        }
        status
        date
        closedDate
        code
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        findingClosingObservationId
      }
      nextToken
      startedAt
    }
  }
`;
export const findingsByProjectIdAndStatus = /* GraphQL */ `
  query FindingsByProjectIdAndStatus(
    $projectId: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFindingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findingsByProjectIdAndStatus(
      projectId: $projectId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        type
        projectId
        phaseId
        inspectionId
        hierarchyItemId
        hierarchyItem {
          id
          name
          code
          hierarchyId
          parentId
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        observationId
        observation {
          id
          groupId
          type
          projectId
          inspectionId
          hierarchyItemId
          inspectionPointId
          text
          suggestion
          findingId
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          observationPrimaryLocationId
          owner
        }
        closingObservation {
          id
          groupId
          type
          projectId
          inspectionId
          hierarchyItemId
          inspectionPointId
          text
          suggestion
          findingId
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          observationPrimaryLocationId
          owner
        }
        status
        date
        closedDate
        code
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        findingClosingObservationId
      }
      nextToken
      startedAt
    }
  }
`;
export const findingsByInspectionId = /* GraphQL */ `
  query FindingsByInspectionId(
    $inspectionId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelFindingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    findingsByInspectionId(
      inspectionId: $inspectionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        type
        projectId
        phaseId
        inspectionId
        hierarchyItemId
        hierarchyItem {
          id
          name
          code
          hierarchyId
          parentId
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        observationId
        observation {
          id
          groupId
          type
          projectId
          inspectionId
          hierarchyItemId
          inspectionPointId
          text
          suggestion
          findingId
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          observationPrimaryLocationId
          owner
        }
        closingObservation {
          id
          groupId
          type
          projectId
          inspectionId
          hierarchyItemId
          inspectionPointId
          text
          suggestion
          findingId
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          observationPrimaryLocationId
          owner
        }
        status
        date
        closedDate
        code
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        findingClosingObservationId
      }
      nextToken
      startedAt
    }
  }
`;
export const getHierarchyBundle = /* GraphQL */ `
  query GetHierarchyBundle($id: ID!) {
    getHierarchyBundle(id: $id) {
      id
      name
      type
      status
      hierarchyItemIds
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listHierarchyBundles = /* GraphQL */ `
  query ListHierarchyBundles(
    $id: ID
    $filter: ModelHierarchyBundleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listHierarchyBundles(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        type
        status
        hierarchyItemIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHierarchyBundles = /* GraphQL */ `
  query SyncHierarchyBundles(
    $filter: ModelHierarchyBundleFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHierarchyBundles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        type
        status
        hierarchyItemIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const hierarchyBundlesByType = /* GraphQL */ `
  query HierarchyBundlesByType(
    $type: String!
    $sortDirection: ModelSortDirection
    $filter: ModelHierarchyBundleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    hierarchyBundlesByType(
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        status
        hierarchyItemIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const hierarchyBundlesByTypeAndStatus = /* GraphQL */ `
  query HierarchyBundlesByTypeAndStatus(
    $type: String!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelHierarchyBundleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    hierarchyBundlesByTypeAndStatus(
      type: $type
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        status
        hierarchyItemIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const searchHierarchyBundles = /* GraphQL */ `
  query SearchHierarchyBundles(
    $filter: SearchableHierarchyBundleFilterInput
    $sort: [SearchableHierarchyBundleSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableHierarchyBundleAggregationInput]
  ) {
    searchHierarchyBundles(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        type
        status
        hierarchyItemIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getMilestoneTemplate = /* GraphQL */ `
  query GetMilestoneTemplate($id: ID!) {
    getMilestoneTemplate(id: $id) {
      id
      name
      status
      hierarchyBundleIds
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listMilestoneTemplates = /* GraphQL */ `
  query ListMilestoneTemplates(
    $id: ID
    $filter: ModelMilestoneTemplateFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMilestoneTemplates(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        status
        hierarchyBundleIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMilestoneTemplates = /* GraphQL */ `
  query SyncMilestoneTemplates(
    $filter: ModelMilestoneTemplateFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMilestoneTemplates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        status
        hierarchyBundleIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const searchMilestoneTemplates = /* GraphQL */ `
  query SearchMilestoneTemplates(
    $filter: SearchableMilestoneTemplateFilterInput
    $sort: [SearchableMilestoneTemplateSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableMilestoneTemplateAggregationInput]
  ) {
    searchMilestoneTemplates(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        status
        hierarchyBundleIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getActivityStream = /* GraphQL */ `
  query GetActivityStream($id: ID!) {
    getActivityStream(id: $id) {
      id
      modelType
      instanceId
      userId
      user {
        id
        status
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        metadata
        projects
        favoriteProjects
        inspections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      date
      type
      text
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listActivityStreams = /* GraphQL */ `
  query ListActivityStreams(
    $id: ID
    $filter: ModelActivityStreamFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listActivityStreams(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        modelType
        instanceId
        userId
        user {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        date
        type
        text
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncActivityStreams = /* GraphQL */ `
  query SyncActivityStreams(
    $filter: ModelActivityStreamFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncActivityStreams(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        modelType
        instanceId
        userId
        user {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        date
        type
        text
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const searchActivityStreams = /* GraphQL */ `
  query SearchActivityStreams(
    $filter: SearchableActivityStreamFilterInput
    $sort: [SearchableActivityStreamSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableActivityStreamAggregationInput]
  ) {
    searchActivityStreams(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        modelType
        instanceId
        userId
        user {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        date
        type
        text
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
