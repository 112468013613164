// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const ContactType = {
  "CONTACT": "CONTACT"
};

const UserType = {
  "ADMIN": "ADMIN",
  "EFT": "EFT",
  "REVIEWER": "REVIEWER"
};

const UserStatus = {
  "ACTIVE": "ACTIVE",
  "INACTIVE": "INACTIVE"
};

const ClientStatus = {
  "ACTIVE": "ACTIVE",
  "INACTIVE": "INACTIVE"
};

const ProjectStatus = {
  "ACTIVE": "ACTIVE",
  "INACTIVE": "INACTIVE"
};

const PhaseStatus = {
  "ACTIVE": "ACTIVE",
  "INACTIVE": "INACTIVE"
};

const InspectionStatus = {
  "ACTIVE": "ACTIVE",
  "ACTIVEW": "ACTIVEW",
  "SUBMITTING": "SUBMITTING",
  "SUBMITTED": "SUBMITTED",
  "REVIEWING": "REVIEWING",
  "DECLINED": "DECLINED",
  "APPROVED": "APPROVED",
  "SENT": "SENT",
  "REVISING": "REVISING"
};

const InspectionPointType = {
  "APPROVED": "APPROVED",
  "SUBMITTED": "SUBMITTED"
};

const ObservationType = {
  "CONFORMING": "CONFORMING",
  "FINDING": "FINDING",
  "RESOLUTION": "RESOLUTION"
};

const ObservationStatus = {
  "NEW": "NEW",
  "ACCEPTED": "ACCEPTED",
  "DECLINED": "DECLINED",
  "HIDDEN": "HIDDEN"
};

const TagType = {
  "LOCATION": "LOCATION"
};

const FindingStatus = {
  "NEW": "NEW",
  "OPEN": "OPEN",
  "CLOSED": "CLOSED"
};

const FindingType = {
  "IOC": "IOC",
  "NCI": "NCI"
};

const HierarchyBundleStatus = {
  "ACTIVE": "ACTIVE",
  "INACTIVE": "INACTIVE"
};

const HierarchyBundleType = {
  "MILESTONE": "MILESTONE"
};

const MilestoneTemplateStatus = {
  "ACTIVE": "ACTIVE",
  "INACTIVE": "INACTIVE"
};

const UpdateType = {
  "STATUS": "STATUS"
};

const { ExternalLink, Contact, User, Client, ClientContact, Project, Phase, ProjectContact, FavoriteProject, Inspection, Hierarchy, HierarchyItem, InspectionHierarchyItem, InspectionPoint, Comment, InspectionComment, Observation, Location, Tag, LocationTag, Photo, InspectionPhoto, LocationPhoto, Finding, HierarchyBundle, MilestoneTemplate, ActivityStream, Address, S3Object, Unit, MilestoneTracker } = initSchema(schema);

export {
  ExternalLink,
  Contact,
  User,
  Client,
  ClientContact,
  Project,
  Phase,
  ProjectContact,
  FavoriteProject,
  Inspection,
  Hierarchy,
  HierarchyItem,
  InspectionHierarchyItem,
  InspectionPoint,
  Comment,
  InspectionComment,
  Observation,
  Location,
  Tag,
  LocationTag,
  Photo,
  InspectionPhoto,
  LocationPhoto,
  Finding,
  HierarchyBundle,
  MilestoneTemplate,
  ActivityStream,
  ContactType,
  UserType,
  UserStatus,
  ClientStatus,
  ProjectStatus,
  PhaseStatus,
  InspectionStatus,
  InspectionPointType,
  ObservationType,
  ObservationStatus,
  TagType,
  FindingStatus,
  FindingType,
  HierarchyBundleStatus,
  HierarchyBundleType,
  MilestoneTemplateStatus,
  UpdateType,
  Address,
  S3Object,
  Unit,
  MilestoneTracker
};