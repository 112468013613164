import { createContext } from 'react';
import { UpdateInspectionInput, UpdateObservationInput } from '../API';

export type ObservationContextType = {
    inspection?: UpdateInspectionInput,
    editable: boolean,
    observation?: UpdateObservationInput,
    update: (changes: any) => any
}
export const ObservationContext = createContext<ObservationContextType>({
    editable: false,
    update: () => {}
})
