import { createContext } from 'react';
import { UpdateInspectionInput } from '../API';

export type InspectionContextType = {
    inspection?: UpdateInspectionInput,
    editable: boolean
    update: (changes: any) => any
}
export const InspectionContext = createContext<InspectionContextType>({
    editable: false,
    update: () => {}
})
