/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createExternalLink = /* GraphQL */ `
  mutation CreateExternalLink(
    $input: CreateExternalLinkInput!
    $condition: ModelExternalLinkConditionInput
  ) {
    createExternalLink(input: $input, condition: $condition) {
      id
      groupId
      projectId
      title
      url
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateExternalLink = /* GraphQL */ `
  mutation UpdateExternalLink(
    $input: UpdateExternalLinkInput!
    $condition: ModelExternalLinkConditionInput
  ) {
    updateExternalLink(input: $input, condition: $condition) {
      id
      groupId
      projectId
      title
      url
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteExternalLink = /* GraphQL */ `
  mutation DeleteExternalLink(
    $input: DeleteExternalLinkInput!
    $condition: ModelExternalLinkConditionInput
  ) {
    deleteExternalLink(input: $input, condition: $condition) {
      id
      groupId
      projectId
      title
      url
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createContact = /* GraphQL */ `
  mutation CreateContact(
    $input: CreateContactInput!
    $condition: ModelContactConditionInput
  ) {
    createContact(input: $input, condition: $condition) {
      id
      type
      firstName
      lastName
      companyName
      jobTitle
      phone
      email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateContact = /* GraphQL */ `
  mutation UpdateContact(
    $input: UpdateContactInput!
    $condition: ModelContactConditionInput
  ) {
    updateContact(input: $input, condition: $condition) {
      id
      type
      firstName
      lastName
      companyName
      jobTitle
      phone
      email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteContact = /* GraphQL */ `
  mutation DeleteContact(
    $input: DeleteContactInput!
    $condition: ModelContactConditionInput
  ) {
    deleteContact(input: $input, condition: $condition) {
      id
      type
      firstName
      lastName
      companyName
      jobTitle
      phone
      email
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      status
      type
      firstName
      lastName
      companyName
      jobTitle
      phone
      email
      metadata
      projects
      favoriteProjects
      inspections {
        items {
          id
          groupId
          clientId
          projectId
          phaseId
          photoSequence
          status
          userId
          inspectionReviewerId
          inspectionDate
          reviewedDate
          deliveredDate
          nextInspectionDate
          arrivalTime
          notes
          coverLetter
          scopeHierarchyItems
          scope
          number
          code
          weather
          milestoneBundleIds
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          inspectionCheckInContactId
          owner
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      status
      type
      firstName
      lastName
      companyName
      jobTitle
      phone
      email
      metadata
      projects
      favoriteProjects
      inspections {
        items {
          id
          groupId
          clientId
          projectId
          phaseId
          photoSequence
          status
          userId
          inspectionReviewerId
          inspectionDate
          reviewedDate
          deliveredDate
          nextInspectionDate
          arrivalTime
          notes
          coverLetter
          scopeHierarchyItems
          scope
          number
          code
          weather
          milestoneBundleIds
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          inspectionCheckInContactId
          owner
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      status
      type
      firstName
      lastName
      companyName
      jobTitle
      phone
      email
      metadata
      projects
      favoriteProjects
      inspections {
        items {
          id
          groupId
          clientId
          projectId
          phaseId
          photoSequence
          status
          userId
          inspectionReviewerId
          inspectionDate
          reviewedDate
          deliveredDate
          nextInspectionDate
          arrivalTime
          notes
          coverLetter
          scopeHierarchyItems
          scope
          number
          code
          weather
          milestoneBundleIds
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          inspectionCheckInContactId
          owner
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createClient = /* GraphQL */ `
  mutation CreateClient(
    $input: CreateClientInput!
    $condition: ModelClientConditionInput
  ) {
    createClient(input: $input, condition: $condition) {
      id
      name
      code
      status
      address {
        street
        street2
        city
        state
        zip
      }
      primaryContact {
        id
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      reportDisclaimer
      contacts {
        items {
          id
          clientId
          contactId
          label
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      projects {
        items {
          id
          groupId
          name
          status
          clientId
          reportTemplateId
          reportDisclaimer
          distributionList
          hierarchyItemExclusions
          exclusions
          notes
          code
          milestoneTemplateId
          externalId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          projectEftId
          projectReviewerId
          projectAdminId
          projectPrimaryContactId
          owner
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      clientPrimaryContactId
      owner
    }
  }
`;
export const updateClient = /* GraphQL */ `
  mutation UpdateClient(
    $input: UpdateClientInput!
    $condition: ModelClientConditionInput
  ) {
    updateClient(input: $input, condition: $condition) {
      id
      name
      code
      status
      address {
        street
        street2
        city
        state
        zip
      }
      primaryContact {
        id
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      reportDisclaimer
      contacts {
        items {
          id
          clientId
          contactId
          label
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      projects {
        items {
          id
          groupId
          name
          status
          clientId
          reportTemplateId
          reportDisclaimer
          distributionList
          hierarchyItemExclusions
          exclusions
          notes
          code
          milestoneTemplateId
          externalId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          projectEftId
          projectReviewerId
          projectAdminId
          projectPrimaryContactId
          owner
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      clientPrimaryContactId
      owner
    }
  }
`;
export const deleteClient = /* GraphQL */ `
  mutation DeleteClient(
    $input: DeleteClientInput!
    $condition: ModelClientConditionInput
  ) {
    deleteClient(input: $input, condition: $condition) {
      id
      name
      code
      status
      address {
        street
        street2
        city
        state
        zip
      }
      primaryContact {
        id
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      reportDisclaimer
      contacts {
        items {
          id
          clientId
          contactId
          label
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      projects {
        items {
          id
          groupId
          name
          status
          clientId
          reportTemplateId
          reportDisclaimer
          distributionList
          hierarchyItemExclusions
          exclusions
          notes
          code
          milestoneTemplateId
          externalId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          projectEftId
          projectReviewerId
          projectAdminId
          projectPrimaryContactId
          owner
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      clientPrimaryContactId
      owner
    }
  }
`;
export const createClientContact = /* GraphQL */ `
  mutation CreateClientContact(
    $input: CreateClientContactInput!
    $condition: ModelClientContactConditionInput
  ) {
    createClientContact(input: $input, condition: $condition) {
      id
      clientId
      contactId
      contact {
        id
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      label
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateClientContact = /* GraphQL */ `
  mutation UpdateClientContact(
    $input: UpdateClientContactInput!
    $condition: ModelClientContactConditionInput
  ) {
    updateClientContact(input: $input, condition: $condition) {
      id
      clientId
      contactId
      contact {
        id
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      label
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteClientContact = /* GraphQL */ `
  mutation DeleteClientContact(
    $input: DeleteClientContactInput!
    $condition: ModelClientContactConditionInput
  ) {
    deleteClientContact(input: $input, condition: $condition) {
      id
      clientId
      contactId
      contact {
        id
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      label
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
      groupId
      name
      status
      clientId
      client {
        id
        name
        code
        status
        address {
          street
          street2
          city
          state
          zip
        }
        primaryContact {
          id
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        reportDisclaimer
        contacts {
          nextToken
          startedAt
        }
        projects {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientPrimaryContactId
        owner
      }
      eft {
        id
        status
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        metadata
        projects
        favoriteProjects
        inspections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      reviewer {
        id
        status
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        metadata
        projects
        favoriteProjects
        inspections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      admin {
        id
        status
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        metadata
        projects
        favoriteProjects
        inspections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      address {
        street
        street2
        city
        state
        zip
      }
      primaryContact {
        id
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      reportTemplateId
      reportDisclaimer
      inspections {
        items {
          id
          groupId
          clientId
          projectId
          phaseId
          photoSequence
          status
          userId
          inspectionReviewerId
          inspectionDate
          reviewedDate
          deliveredDate
          nextInspectionDate
          arrivalTime
          notes
          coverLetter
          scopeHierarchyItems
          scope
          number
          code
          weather
          milestoneBundleIds
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          inspectionCheckInContactId
          owner
        }
        nextToken
        startedAt
      }
      contacts {
        items {
          id
          groupId
          projectId
          contactId
          label
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      distributionList
      externalLinks {
        items {
          id
          groupId
          projectId
          title
          url
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      tags {
        items {
          id
          groupId
          type
          projectId
          text
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      findings {
        items {
          id
          groupId
          type
          projectId
          phaseId
          inspectionId
          hierarchyItemId
          observationId
          status
          date
          closedDate
          code
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          findingClosingObservationId
        }
        nextToken
        startedAt
      }
      phases {
        items {
          id
          groupId
          projectId
          name
          status
          milestoneTemplateId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      hierarchyItemExclusions
      exclusions
      notes
      code
      milestoneTemplate {
        id
        name
        status
        hierarchyBundleIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      milestoneTemplateId
      externalId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      projectEftId
      projectReviewerId
      projectAdminId
      projectPrimaryContactId
      owner
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      groupId
      name
      status
      clientId
      client {
        id
        name
        code
        status
        address {
          street
          street2
          city
          state
          zip
        }
        primaryContact {
          id
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        reportDisclaimer
        contacts {
          nextToken
          startedAt
        }
        projects {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientPrimaryContactId
        owner
      }
      eft {
        id
        status
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        metadata
        projects
        favoriteProjects
        inspections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      reviewer {
        id
        status
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        metadata
        projects
        favoriteProjects
        inspections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      admin {
        id
        status
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        metadata
        projects
        favoriteProjects
        inspections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      address {
        street
        street2
        city
        state
        zip
      }
      primaryContact {
        id
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      reportTemplateId
      reportDisclaimer
      inspections {
        items {
          id
          groupId
          clientId
          projectId
          phaseId
          photoSequence
          status
          userId
          inspectionReviewerId
          inspectionDate
          reviewedDate
          deliveredDate
          nextInspectionDate
          arrivalTime
          notes
          coverLetter
          scopeHierarchyItems
          scope
          number
          code
          weather
          milestoneBundleIds
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          inspectionCheckInContactId
          owner
        }
        nextToken
        startedAt
      }
      contacts {
        items {
          id
          groupId
          projectId
          contactId
          label
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      distributionList
      externalLinks {
        items {
          id
          groupId
          projectId
          title
          url
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      tags {
        items {
          id
          groupId
          type
          projectId
          text
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      findings {
        items {
          id
          groupId
          type
          projectId
          phaseId
          inspectionId
          hierarchyItemId
          observationId
          status
          date
          closedDate
          code
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          findingClosingObservationId
        }
        nextToken
        startedAt
      }
      phases {
        items {
          id
          groupId
          projectId
          name
          status
          milestoneTemplateId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      hierarchyItemExclusions
      exclusions
      notes
      code
      milestoneTemplate {
        id
        name
        status
        hierarchyBundleIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      milestoneTemplateId
      externalId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      projectEftId
      projectReviewerId
      projectAdminId
      projectPrimaryContactId
      owner
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
      groupId
      name
      status
      clientId
      client {
        id
        name
        code
        status
        address {
          street
          street2
          city
          state
          zip
        }
        primaryContact {
          id
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        reportDisclaimer
        contacts {
          nextToken
          startedAt
        }
        projects {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        clientPrimaryContactId
        owner
      }
      eft {
        id
        status
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        metadata
        projects
        favoriteProjects
        inspections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      reviewer {
        id
        status
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        metadata
        projects
        favoriteProjects
        inspections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      admin {
        id
        status
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        metadata
        projects
        favoriteProjects
        inspections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      address {
        street
        street2
        city
        state
        zip
      }
      primaryContact {
        id
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      reportTemplateId
      reportDisclaimer
      inspections {
        items {
          id
          groupId
          clientId
          projectId
          phaseId
          photoSequence
          status
          userId
          inspectionReviewerId
          inspectionDate
          reviewedDate
          deliveredDate
          nextInspectionDate
          arrivalTime
          notes
          coverLetter
          scopeHierarchyItems
          scope
          number
          code
          weather
          milestoneBundleIds
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          inspectionCheckInContactId
          owner
        }
        nextToken
        startedAt
      }
      contacts {
        items {
          id
          groupId
          projectId
          contactId
          label
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      distributionList
      externalLinks {
        items {
          id
          groupId
          projectId
          title
          url
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      tags {
        items {
          id
          groupId
          type
          projectId
          text
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      findings {
        items {
          id
          groupId
          type
          projectId
          phaseId
          inspectionId
          hierarchyItemId
          observationId
          status
          date
          closedDate
          code
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          findingClosingObservationId
        }
        nextToken
        startedAt
      }
      phases {
        items {
          id
          groupId
          projectId
          name
          status
          milestoneTemplateId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      hierarchyItemExclusions
      exclusions
      notes
      code
      milestoneTemplate {
        id
        name
        status
        hierarchyBundleIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      milestoneTemplateId
      externalId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      projectEftId
      projectReviewerId
      projectAdminId
      projectPrimaryContactId
      owner
    }
  }
`;
export const createPhase = /* GraphQL */ `
  mutation CreatePhase(
    $input: CreatePhaseInput!
    $condition: ModelPhaseConditionInput
  ) {
    createPhase(input: $input, condition: $condition) {
      id
      groupId
      projectId
      project {
        id
        groupId
        name
        status
        clientId
        client {
          id
          name
          code
          status
          reportDisclaimer
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientPrimaryContactId
          owner
        }
        eft {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        reviewer {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        admin {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        address {
          street
          street2
          city
          state
          zip
        }
        primaryContact {
          id
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        reportTemplateId
        reportDisclaimer
        inspections {
          nextToken
          startedAt
        }
        contacts {
          nextToken
          startedAt
        }
        distributionList
        externalLinks {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        findings {
          nextToken
          startedAt
        }
        phases {
          nextToken
          startedAt
        }
        hierarchyItemExclusions
        exclusions
        notes
        code
        milestoneTemplate {
          id
          name
          status
          hierarchyBundleIds
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        milestoneTemplateId
        externalId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        projectEftId
        projectReviewerId
        projectAdminId
        projectPrimaryContactId
        owner
      }
      name
      status
      units {
        id
        name
      }
      milestoneTemplate {
        id
        name
        status
        hierarchyBundleIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      milestoneTemplateId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updatePhase = /* GraphQL */ `
  mutation UpdatePhase(
    $input: UpdatePhaseInput!
    $condition: ModelPhaseConditionInput
  ) {
    updatePhase(input: $input, condition: $condition) {
      id
      groupId
      projectId
      project {
        id
        groupId
        name
        status
        clientId
        client {
          id
          name
          code
          status
          reportDisclaimer
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientPrimaryContactId
          owner
        }
        eft {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        reviewer {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        admin {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        address {
          street
          street2
          city
          state
          zip
        }
        primaryContact {
          id
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        reportTemplateId
        reportDisclaimer
        inspections {
          nextToken
          startedAt
        }
        contacts {
          nextToken
          startedAt
        }
        distributionList
        externalLinks {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        findings {
          nextToken
          startedAt
        }
        phases {
          nextToken
          startedAt
        }
        hierarchyItemExclusions
        exclusions
        notes
        code
        milestoneTemplate {
          id
          name
          status
          hierarchyBundleIds
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        milestoneTemplateId
        externalId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        projectEftId
        projectReviewerId
        projectAdminId
        projectPrimaryContactId
        owner
      }
      name
      status
      units {
        id
        name
      }
      milestoneTemplate {
        id
        name
        status
        hierarchyBundleIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      milestoneTemplateId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deletePhase = /* GraphQL */ `
  mutation DeletePhase(
    $input: DeletePhaseInput!
    $condition: ModelPhaseConditionInput
  ) {
    deletePhase(input: $input, condition: $condition) {
      id
      groupId
      projectId
      project {
        id
        groupId
        name
        status
        clientId
        client {
          id
          name
          code
          status
          reportDisclaimer
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientPrimaryContactId
          owner
        }
        eft {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        reviewer {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        admin {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        address {
          street
          street2
          city
          state
          zip
        }
        primaryContact {
          id
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        reportTemplateId
        reportDisclaimer
        inspections {
          nextToken
          startedAt
        }
        contacts {
          nextToken
          startedAt
        }
        distributionList
        externalLinks {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        findings {
          nextToken
          startedAt
        }
        phases {
          nextToken
          startedAt
        }
        hierarchyItemExclusions
        exclusions
        notes
        code
        milestoneTemplate {
          id
          name
          status
          hierarchyBundleIds
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        milestoneTemplateId
        externalId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        projectEftId
        projectReviewerId
        projectAdminId
        projectPrimaryContactId
        owner
      }
      name
      status
      units {
        id
        name
      }
      milestoneTemplate {
        id
        name
        status
        hierarchyBundleIds
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      milestoneTemplateId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createProjectContact = /* GraphQL */ `
  mutation CreateProjectContact(
    $input: CreateProjectContactInput!
    $condition: ModelProjectContactConditionInput
  ) {
    createProjectContact(input: $input, condition: $condition) {
      id
      groupId
      projectId
      contactId
      contact {
        id
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      label
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateProjectContact = /* GraphQL */ `
  mutation UpdateProjectContact(
    $input: UpdateProjectContactInput!
    $condition: ModelProjectContactConditionInput
  ) {
    updateProjectContact(input: $input, condition: $condition) {
      id
      groupId
      projectId
      contactId
      contact {
        id
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      label
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteProjectContact = /* GraphQL */ `
  mutation DeleteProjectContact(
    $input: DeleteProjectContactInput!
    $condition: ModelProjectContactConditionInput
  ) {
    deleteProjectContact(input: $input, condition: $condition) {
      id
      groupId
      projectId
      contactId
      contact {
        id
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      label
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createFavoriteProject = /* GraphQL */ `
  mutation CreateFavoriteProject(
    $input: CreateFavoriteProjectInput!
    $condition: ModelFavoriteProjectConditionInput
  ) {
    createFavoriteProject(input: $input, condition: $condition) {
      id
      projectId
      project {
        id
        groupId
        name
        status
        clientId
        client {
          id
          name
          code
          status
          reportDisclaimer
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientPrimaryContactId
          owner
        }
        eft {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        reviewer {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        admin {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        address {
          street
          street2
          city
          state
          zip
        }
        primaryContact {
          id
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        reportTemplateId
        reportDisclaimer
        inspections {
          nextToken
          startedAt
        }
        contacts {
          nextToken
          startedAt
        }
        distributionList
        externalLinks {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        findings {
          nextToken
          startedAt
        }
        phases {
          nextToken
          startedAt
        }
        hierarchyItemExclusions
        exclusions
        notes
        code
        milestoneTemplate {
          id
          name
          status
          hierarchyBundleIds
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        milestoneTemplateId
        externalId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        projectEftId
        projectReviewerId
        projectAdminId
        projectPrimaryContactId
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateFavoriteProject = /* GraphQL */ `
  mutation UpdateFavoriteProject(
    $input: UpdateFavoriteProjectInput!
    $condition: ModelFavoriteProjectConditionInput
  ) {
    updateFavoriteProject(input: $input, condition: $condition) {
      id
      projectId
      project {
        id
        groupId
        name
        status
        clientId
        client {
          id
          name
          code
          status
          reportDisclaimer
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientPrimaryContactId
          owner
        }
        eft {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        reviewer {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        admin {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        address {
          street
          street2
          city
          state
          zip
        }
        primaryContact {
          id
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        reportTemplateId
        reportDisclaimer
        inspections {
          nextToken
          startedAt
        }
        contacts {
          nextToken
          startedAt
        }
        distributionList
        externalLinks {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        findings {
          nextToken
          startedAt
        }
        phases {
          nextToken
          startedAt
        }
        hierarchyItemExclusions
        exclusions
        notes
        code
        milestoneTemplate {
          id
          name
          status
          hierarchyBundleIds
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        milestoneTemplateId
        externalId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        projectEftId
        projectReviewerId
        projectAdminId
        projectPrimaryContactId
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteFavoriteProject = /* GraphQL */ `
  mutation DeleteFavoriteProject(
    $input: DeleteFavoriteProjectInput!
    $condition: ModelFavoriteProjectConditionInput
  ) {
    deleteFavoriteProject(input: $input, condition: $condition) {
      id
      projectId
      project {
        id
        groupId
        name
        status
        clientId
        client {
          id
          name
          code
          status
          reportDisclaimer
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientPrimaryContactId
          owner
        }
        eft {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        reviewer {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        admin {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        address {
          street
          street2
          city
          state
          zip
        }
        primaryContact {
          id
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        reportTemplateId
        reportDisclaimer
        inspections {
          nextToken
          startedAt
        }
        contacts {
          nextToken
          startedAt
        }
        distributionList
        externalLinks {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        findings {
          nextToken
          startedAt
        }
        phases {
          nextToken
          startedAt
        }
        hierarchyItemExclusions
        exclusions
        notes
        code
        milestoneTemplate {
          id
          name
          status
          hierarchyBundleIds
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        milestoneTemplateId
        externalId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        projectEftId
        projectReviewerId
        projectAdminId
        projectPrimaryContactId
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createInspection = /* GraphQL */ `
  mutation CreateInspection(
    $input: CreateInspectionInput!
    $condition: ModelInspectionConditionInput
  ) {
    createInspection(input: $input, condition: $condition) {
      id
      groupId
      clientId
      projectId
      project {
        id
        groupId
        name
        status
        clientId
        client {
          id
          name
          code
          status
          reportDisclaimer
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientPrimaryContactId
          owner
        }
        eft {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        reviewer {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        admin {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        address {
          street
          street2
          city
          state
          zip
        }
        primaryContact {
          id
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        reportTemplateId
        reportDisclaimer
        inspections {
          nextToken
          startedAt
        }
        contacts {
          nextToken
          startedAt
        }
        distributionList
        externalLinks {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        findings {
          nextToken
          startedAt
        }
        phases {
          nextToken
          startedAt
        }
        hierarchyItemExclusions
        exclusions
        notes
        code
        milestoneTemplate {
          id
          name
          status
          hierarchyBundleIds
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        milestoneTemplateId
        externalId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        projectEftId
        projectReviewerId
        projectAdminId
        projectPrimaryContactId
        owner
      }
      phase {
        id
        groupId
        projectId
        project {
          id
          groupId
          name
          status
          clientId
          reportTemplateId
          reportDisclaimer
          distributionList
          hierarchyItemExclusions
          exclusions
          notes
          code
          milestoneTemplateId
          externalId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          projectEftId
          projectReviewerId
          projectAdminId
          projectPrimaryContactId
          owner
        }
        name
        status
        units {
          id
          name
        }
        milestoneTemplate {
          id
          name
          status
          hierarchyBundleIds
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        milestoneTemplateId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      phaseId
      photoSequence
      status
      userId
      user {
        id
        status
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        metadata
        projects
        favoriteProjects
        inspections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      reviewer {
        id
        status
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        metadata
        projects
        favoriteProjects
        inspections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      inspectionReviewerId
      inspectionDate
      reviewedDate
      deliveredDate
      nextInspectionDate
      arrivalTime
      checkInContact {
        id
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      notes
      coverLetter
      comments {
        items {
          id
          groupId
          projectId
          inspectionId
          commentId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      observations {
        items {
          id
          groupId
          type
          projectId
          inspectionId
          hierarchyItemId
          inspectionPointId
          text
          suggestion
          findingId
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          observationPrimaryLocationId
          owner
        }
        nextToken
        startedAt
      }
      findings {
        items {
          id
          groupId
          type
          projectId
          phaseId
          inspectionId
          hierarchyItemId
          observationId
          status
          date
          closedDate
          code
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          findingClosingObservationId
        }
        nextToken
        startedAt
      }
      hierarchyItems {
        items {
          id
          inspectionId
          hierarchyItemId
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      photos {
        items {
          id
          groupId
          projectId
          inspectionId
          photoId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      scopeHierarchyItems
      scope
      number
      code
      report {
        bucket
        region
        key
      }
      weather
      milestoneBundleIds
      milestoneTrackers {
        milestoneId
        all
        units
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      inspectionCheckInContactId
      owner
    }
  }
`;
export const updateInspection = /* GraphQL */ `
  mutation UpdateInspection(
    $input: UpdateInspectionInput!
    $condition: ModelInspectionConditionInput
  ) {
    updateInspection(input: $input, condition: $condition) {
      id
      groupId
      clientId
      projectId
      project {
        id
        groupId
        name
        status
        clientId
        client {
          id
          name
          code
          status
          reportDisclaimer
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientPrimaryContactId
          owner
        }
        eft {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        reviewer {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        admin {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        address {
          street
          street2
          city
          state
          zip
        }
        primaryContact {
          id
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        reportTemplateId
        reportDisclaimer
        inspections {
          nextToken
          startedAt
        }
        contacts {
          nextToken
          startedAt
        }
        distributionList
        externalLinks {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        findings {
          nextToken
          startedAt
        }
        phases {
          nextToken
          startedAt
        }
        hierarchyItemExclusions
        exclusions
        notes
        code
        milestoneTemplate {
          id
          name
          status
          hierarchyBundleIds
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        milestoneTemplateId
        externalId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        projectEftId
        projectReviewerId
        projectAdminId
        projectPrimaryContactId
        owner
      }
      phase {
        id
        groupId
        projectId
        project {
          id
          groupId
          name
          status
          clientId
          reportTemplateId
          reportDisclaimer
          distributionList
          hierarchyItemExclusions
          exclusions
          notes
          code
          milestoneTemplateId
          externalId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          projectEftId
          projectReviewerId
          projectAdminId
          projectPrimaryContactId
          owner
        }
        name
        status
        units {
          id
          name
        }
        milestoneTemplate {
          id
          name
          status
          hierarchyBundleIds
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        milestoneTemplateId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      phaseId
      photoSequence
      status
      userId
      user {
        id
        status
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        metadata
        projects
        favoriteProjects
        inspections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      reviewer {
        id
        status
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        metadata
        projects
        favoriteProjects
        inspections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      inspectionReviewerId
      inspectionDate
      reviewedDate
      deliveredDate
      nextInspectionDate
      arrivalTime
      checkInContact {
        id
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      notes
      coverLetter
      comments {
        items {
          id
          groupId
          projectId
          inspectionId
          commentId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      observations {
        items {
          id
          groupId
          type
          projectId
          inspectionId
          hierarchyItemId
          inspectionPointId
          text
          suggestion
          findingId
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          observationPrimaryLocationId
          owner
        }
        nextToken
        startedAt
      }
      findings {
        items {
          id
          groupId
          type
          projectId
          phaseId
          inspectionId
          hierarchyItemId
          observationId
          status
          date
          closedDate
          code
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          findingClosingObservationId
        }
        nextToken
        startedAt
      }
      hierarchyItems {
        items {
          id
          inspectionId
          hierarchyItemId
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      photos {
        items {
          id
          groupId
          projectId
          inspectionId
          photoId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      scopeHierarchyItems
      scope
      number
      code
      report {
        bucket
        region
        key
      }
      weather
      milestoneBundleIds
      milestoneTrackers {
        milestoneId
        all
        units
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      inspectionCheckInContactId
      owner
    }
  }
`;
export const deleteInspection = /* GraphQL */ `
  mutation DeleteInspection(
    $input: DeleteInspectionInput!
    $condition: ModelInspectionConditionInput
  ) {
    deleteInspection(input: $input, condition: $condition) {
      id
      groupId
      clientId
      projectId
      project {
        id
        groupId
        name
        status
        clientId
        client {
          id
          name
          code
          status
          reportDisclaimer
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          clientPrimaryContactId
          owner
        }
        eft {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        reviewer {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        admin {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        address {
          street
          street2
          city
          state
          zip
        }
        primaryContact {
          id
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        reportTemplateId
        reportDisclaimer
        inspections {
          nextToken
          startedAt
        }
        contacts {
          nextToken
          startedAt
        }
        distributionList
        externalLinks {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        findings {
          nextToken
          startedAt
        }
        phases {
          nextToken
          startedAt
        }
        hierarchyItemExclusions
        exclusions
        notes
        code
        milestoneTemplate {
          id
          name
          status
          hierarchyBundleIds
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        milestoneTemplateId
        externalId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        projectEftId
        projectReviewerId
        projectAdminId
        projectPrimaryContactId
        owner
      }
      phase {
        id
        groupId
        projectId
        project {
          id
          groupId
          name
          status
          clientId
          reportTemplateId
          reportDisclaimer
          distributionList
          hierarchyItemExclusions
          exclusions
          notes
          code
          milestoneTemplateId
          externalId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          projectEftId
          projectReviewerId
          projectAdminId
          projectPrimaryContactId
          owner
        }
        name
        status
        units {
          id
          name
        }
        milestoneTemplate {
          id
          name
          status
          hierarchyBundleIds
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        milestoneTemplateId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      phaseId
      photoSequence
      status
      userId
      user {
        id
        status
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        metadata
        projects
        favoriteProjects
        inspections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      reviewer {
        id
        status
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        metadata
        projects
        favoriteProjects
        inspections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      inspectionReviewerId
      inspectionDate
      reviewedDate
      deliveredDate
      nextInspectionDate
      arrivalTime
      checkInContact {
        id
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      notes
      coverLetter
      comments {
        items {
          id
          groupId
          projectId
          inspectionId
          commentId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      observations {
        items {
          id
          groupId
          type
          projectId
          inspectionId
          hierarchyItemId
          inspectionPointId
          text
          suggestion
          findingId
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          observationPrimaryLocationId
          owner
        }
        nextToken
        startedAt
      }
      findings {
        items {
          id
          groupId
          type
          projectId
          phaseId
          inspectionId
          hierarchyItemId
          observationId
          status
          date
          closedDate
          code
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          findingClosingObservationId
        }
        nextToken
        startedAt
      }
      hierarchyItems {
        items {
          id
          inspectionId
          hierarchyItemId
          notes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      photos {
        items {
          id
          groupId
          projectId
          inspectionId
          photoId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      scopeHierarchyItems
      scope
      number
      code
      report {
        bucket
        region
        key
      }
      weather
      milestoneBundleIds
      milestoneTrackers {
        milestoneId
        all
        units
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      inspectionCheckInContactId
      owner
    }
  }
`;
export const createHierarchy = /* GraphQL */ `
  mutation CreateHierarchy(
    $input: CreateHierarchyInput!
    $condition: ModelHierarchyConditionInput
  ) {
    createHierarchy(input: $input, condition: $condition) {
      id
      name
      items {
        items {
          id
          name
          code
          hierarchyId
          parentId
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateHierarchy = /* GraphQL */ `
  mutation UpdateHierarchy(
    $input: UpdateHierarchyInput!
    $condition: ModelHierarchyConditionInput
  ) {
    updateHierarchy(input: $input, condition: $condition) {
      id
      name
      items {
        items {
          id
          name
          code
          hierarchyId
          parentId
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteHierarchy = /* GraphQL */ `
  mutation DeleteHierarchy(
    $input: DeleteHierarchyInput!
    $condition: ModelHierarchyConditionInput
  ) {
    deleteHierarchy(input: $input, condition: $condition) {
      id
      name
      items {
        items {
          id
          name
          code
          hierarchyId
          parentId
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createHierarchyItem = /* GraphQL */ `
  mutation CreateHierarchyItem(
    $input: CreateHierarchyItemInput!
    $condition: ModelHierarchyItemConditionInput
  ) {
    createHierarchyItem(input: $input, condition: $condition) {
      id
      name
      code
      hierarchyId
      parentId
      hidden
      inspectionPoints {
        items {
          id
          groupId
          hierarchyItemId
          type
          projectId
          text
          code
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateHierarchyItem = /* GraphQL */ `
  mutation UpdateHierarchyItem(
    $input: UpdateHierarchyItemInput!
    $condition: ModelHierarchyItemConditionInput
  ) {
    updateHierarchyItem(input: $input, condition: $condition) {
      id
      name
      code
      hierarchyId
      parentId
      hidden
      inspectionPoints {
        items {
          id
          groupId
          hierarchyItemId
          type
          projectId
          text
          code
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteHierarchyItem = /* GraphQL */ `
  mutation DeleteHierarchyItem(
    $input: DeleteHierarchyItemInput!
    $condition: ModelHierarchyItemConditionInput
  ) {
    deleteHierarchyItem(input: $input, condition: $condition) {
      id
      name
      code
      hierarchyId
      parentId
      hidden
      inspectionPoints {
        items {
          id
          groupId
          hierarchyItemId
          type
          projectId
          text
          code
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createInspectionHierarchyItem = /* GraphQL */ `
  mutation CreateInspectionHierarchyItem(
    $input: CreateInspectionHierarchyItemInput!
    $condition: ModelInspectionHierarchyItemConditionInput
  ) {
    createInspectionHierarchyItem(input: $input, condition: $condition) {
      id
      inspectionId
      hierarchyItemId
      hierarchyItem {
        id
        name
        code
        hierarchyId
        parentId
        hidden
        inspectionPoints {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      notes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateInspectionHierarchyItem = /* GraphQL */ `
  mutation UpdateInspectionHierarchyItem(
    $input: UpdateInspectionHierarchyItemInput!
    $condition: ModelInspectionHierarchyItemConditionInput
  ) {
    updateInspectionHierarchyItem(input: $input, condition: $condition) {
      id
      inspectionId
      hierarchyItemId
      hierarchyItem {
        id
        name
        code
        hierarchyId
        parentId
        hidden
        inspectionPoints {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      notes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteInspectionHierarchyItem = /* GraphQL */ `
  mutation DeleteInspectionHierarchyItem(
    $input: DeleteInspectionHierarchyItemInput!
    $condition: ModelInspectionHierarchyItemConditionInput
  ) {
    deleteInspectionHierarchyItem(input: $input, condition: $condition) {
      id
      inspectionId
      hierarchyItemId
      hierarchyItem {
        id
        name
        code
        hierarchyId
        parentId
        hidden
        inspectionPoints {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      notes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createInspectionPoint = /* GraphQL */ `
  mutation CreateInspectionPoint(
    $input: CreateInspectionPointInput!
    $condition: ModelInspectionPointConditionInput
  ) {
    createInspectionPoint(input: $input, condition: $condition) {
      id
      groupId
      hierarchyItemId
      type
      projectId
      text
      code
      hidden
      observations {
        items {
          id
          groupId
          type
          projectId
          inspectionId
          hierarchyItemId
          inspectionPointId
          text
          suggestion
          findingId
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          observationPrimaryLocationId
          owner
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateInspectionPoint = /* GraphQL */ `
  mutation UpdateInspectionPoint(
    $input: UpdateInspectionPointInput!
    $condition: ModelInspectionPointConditionInput
  ) {
    updateInspectionPoint(input: $input, condition: $condition) {
      id
      groupId
      hierarchyItemId
      type
      projectId
      text
      code
      hidden
      observations {
        items {
          id
          groupId
          type
          projectId
          inspectionId
          hierarchyItemId
          inspectionPointId
          text
          suggestion
          findingId
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          observationPrimaryLocationId
          owner
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteInspectionPoint = /* GraphQL */ `
  mutation DeleteInspectionPoint(
    $input: DeleteInspectionPointInput!
    $condition: ModelInspectionPointConditionInput
  ) {
    deleteInspectionPoint(input: $input, condition: $condition) {
      id
      groupId
      hierarchyItemId
      type
      projectId
      text
      code
      hidden
      observations {
        items {
          id
          groupId
          type
          projectId
          inspectionId
          hierarchyItemId
          inspectionPointId
          text
          suggestion
          findingId
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          observationPrimaryLocationId
          owner
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      groupId
      projectId
      userId
      user {
        id
        status
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        metadata
        projects
        favoriteProjects
        inspections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      date
      text
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      groupId
      projectId
      userId
      user {
        id
        status
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        metadata
        projects
        favoriteProjects
        inspections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      date
      text
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      groupId
      projectId
      userId
      user {
        id
        status
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        metadata
        projects
        favoriteProjects
        inspections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      date
      text
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createInspectionComment = /* GraphQL */ `
  mutation CreateInspectionComment(
    $input: CreateInspectionCommentInput!
    $condition: ModelInspectionCommentConditionInput
  ) {
    createInspectionComment(input: $input, condition: $condition) {
      id
      groupId
      projectId
      inspectionId
      commentId
      comment {
        id
        groupId
        projectId
        userId
        user {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        date
        text
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateInspectionComment = /* GraphQL */ `
  mutation UpdateInspectionComment(
    $input: UpdateInspectionCommentInput!
    $condition: ModelInspectionCommentConditionInput
  ) {
    updateInspectionComment(input: $input, condition: $condition) {
      id
      groupId
      projectId
      inspectionId
      commentId
      comment {
        id
        groupId
        projectId
        userId
        user {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        date
        text
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteInspectionComment = /* GraphQL */ `
  mutation DeleteInspectionComment(
    $input: DeleteInspectionCommentInput!
    $condition: ModelInspectionCommentConditionInput
  ) {
    deleteInspectionComment(input: $input, condition: $condition) {
      id
      groupId
      projectId
      inspectionId
      commentId
      comment {
        id
        groupId
        projectId
        userId
        user {
          id
          status
          type
          firstName
          lastName
          companyName
          jobTitle
          phone
          email
          metadata
          projects
          favoriteProjects
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        date
        text
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createObservation = /* GraphQL */ `
  mutation CreateObservation(
    $input: CreateObservationInput!
    $condition: ModelObservationConditionInput
  ) {
    createObservation(input: $input, condition: $condition) {
      id
      groupId
      type
      projectId
      inspectionId
      hierarchyItemId
      hierarchyItem {
        id
        name
        code
        hierarchyId
        parentId
        hidden
        inspectionPoints {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      inspectionPointId
      inspectionPoint {
        id
        groupId
        hierarchyItemId
        type
        projectId
        text
        code
        hidden
        observations {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      text
      suggestion
      findingId
      primaryLocation {
        id
        groupId
        projectId
        observationId
        primaryPhoto {
          id
          groupId
          projectId
          inspectionId
          json
          text
          reportable
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        photoSequence
        tags {
          nextToken
          startedAt
        }
        photos {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        locationPrimaryPhotoId
        owner
      }
      locations {
        items {
          id
          groupId
          projectId
          observationId
          photoSequence
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          locationPrimaryPhotoId
          owner
        }
        nextToken
        startedAt
      }
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      observationPrimaryLocationId
      owner
    }
  }
`;
export const updateObservation = /* GraphQL */ `
  mutation UpdateObservation(
    $input: UpdateObservationInput!
    $condition: ModelObservationConditionInput
  ) {
    updateObservation(input: $input, condition: $condition) {
      id
      groupId
      type
      projectId
      inspectionId
      hierarchyItemId
      hierarchyItem {
        id
        name
        code
        hierarchyId
        parentId
        hidden
        inspectionPoints {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      inspectionPointId
      inspectionPoint {
        id
        groupId
        hierarchyItemId
        type
        projectId
        text
        code
        hidden
        observations {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      text
      suggestion
      findingId
      primaryLocation {
        id
        groupId
        projectId
        observationId
        primaryPhoto {
          id
          groupId
          projectId
          inspectionId
          json
          text
          reportable
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        photoSequence
        tags {
          nextToken
          startedAt
        }
        photos {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        locationPrimaryPhotoId
        owner
      }
      locations {
        items {
          id
          groupId
          projectId
          observationId
          photoSequence
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          locationPrimaryPhotoId
          owner
        }
        nextToken
        startedAt
      }
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      observationPrimaryLocationId
      owner
    }
  }
`;
export const deleteObservation = /* GraphQL */ `
  mutation DeleteObservation(
    $input: DeleteObservationInput!
    $condition: ModelObservationConditionInput
  ) {
    deleteObservation(input: $input, condition: $condition) {
      id
      groupId
      type
      projectId
      inspectionId
      hierarchyItemId
      hierarchyItem {
        id
        name
        code
        hierarchyId
        parentId
        hidden
        inspectionPoints {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      inspectionPointId
      inspectionPoint {
        id
        groupId
        hierarchyItemId
        type
        projectId
        text
        code
        hidden
        observations {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      text
      suggestion
      findingId
      primaryLocation {
        id
        groupId
        projectId
        observationId
        primaryPhoto {
          id
          groupId
          projectId
          inspectionId
          json
          text
          reportable
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        photoSequence
        tags {
          nextToken
          startedAt
        }
        photos {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        locationPrimaryPhotoId
        owner
      }
      locations {
        items {
          id
          groupId
          projectId
          observationId
          photoSequence
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          locationPrimaryPhotoId
          owner
        }
        nextToken
        startedAt
      }
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      observationPrimaryLocationId
      owner
    }
  }
`;
export const createLocation = /* GraphQL */ `
  mutation CreateLocation(
    $input: CreateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    createLocation(input: $input, condition: $condition) {
      id
      groupId
      projectId
      observationId
      primaryPhoto {
        id
        groupId
        projectId
        inspectionId
        json
        text
        file {
          bucket
          region
          key
        }
        reportable
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      photoSequence
      tags {
        items {
          id
          groupId
          projectId
          locationId
          tagId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      photos {
        items {
          id
          groupId
          projectId
          locationId
          photoId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      locationPrimaryPhotoId
      owner
    }
  }
`;
export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation(
    $input: UpdateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    updateLocation(input: $input, condition: $condition) {
      id
      groupId
      projectId
      observationId
      primaryPhoto {
        id
        groupId
        projectId
        inspectionId
        json
        text
        file {
          bucket
          region
          key
        }
        reportable
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      photoSequence
      tags {
        items {
          id
          groupId
          projectId
          locationId
          tagId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      photos {
        items {
          id
          groupId
          projectId
          locationId
          photoId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      locationPrimaryPhotoId
      owner
    }
  }
`;
export const deleteLocation = /* GraphQL */ `
  mutation DeleteLocation(
    $input: DeleteLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    deleteLocation(input: $input, condition: $condition) {
      id
      groupId
      projectId
      observationId
      primaryPhoto {
        id
        groupId
        projectId
        inspectionId
        json
        text
        file {
          bucket
          region
          key
        }
        reportable
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      photoSequence
      tags {
        items {
          id
          groupId
          projectId
          locationId
          tagId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      photos {
        items {
          id
          groupId
          projectId
          locationId
          photoId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      locationPrimaryPhotoId
      owner
    }
  }
`;
export const createTag = /* GraphQL */ `
  mutation CreateTag(
    $input: CreateTagInput!
    $condition: ModelTagConditionInput
  ) {
    createTag(input: $input, condition: $condition) {
      id
      groupId
      type
      projectId
      text
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateTag = /* GraphQL */ `
  mutation UpdateTag(
    $input: UpdateTagInput!
    $condition: ModelTagConditionInput
  ) {
    updateTag(input: $input, condition: $condition) {
      id
      groupId
      type
      projectId
      text
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteTag = /* GraphQL */ `
  mutation DeleteTag(
    $input: DeleteTagInput!
    $condition: ModelTagConditionInput
  ) {
    deleteTag(input: $input, condition: $condition) {
      id
      groupId
      type
      projectId
      text
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createLocationTag = /* GraphQL */ `
  mutation CreateLocationTag(
    $input: CreateLocationTagInput!
    $condition: ModelLocationTagConditionInput
  ) {
    createLocationTag(input: $input, condition: $condition) {
      id
      groupId
      projectId
      locationId
      tagId
      tag {
        id
        groupId
        type
        projectId
        text
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateLocationTag = /* GraphQL */ `
  mutation UpdateLocationTag(
    $input: UpdateLocationTagInput!
    $condition: ModelLocationTagConditionInput
  ) {
    updateLocationTag(input: $input, condition: $condition) {
      id
      groupId
      projectId
      locationId
      tagId
      tag {
        id
        groupId
        type
        projectId
        text
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteLocationTag = /* GraphQL */ `
  mutation DeleteLocationTag(
    $input: DeleteLocationTagInput!
    $condition: ModelLocationTagConditionInput
  ) {
    deleteLocationTag(input: $input, condition: $condition) {
      id
      groupId
      projectId
      locationId
      tagId
      tag {
        id
        groupId
        type
        projectId
        text
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createPhoto = /* GraphQL */ `
  mutation CreatePhoto(
    $input: CreatePhotoInput!
    $condition: ModelPhotoConditionInput
  ) {
    createPhoto(input: $input, condition: $condition) {
      id
      groupId
      projectId
      inspectionId
      json
      text
      file {
        bucket
        region
        key
      }
      reportable
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updatePhoto = /* GraphQL */ `
  mutation UpdatePhoto(
    $input: UpdatePhotoInput!
    $condition: ModelPhotoConditionInput
  ) {
    updatePhoto(input: $input, condition: $condition) {
      id
      groupId
      projectId
      inspectionId
      json
      text
      file {
        bucket
        region
        key
      }
      reportable
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deletePhoto = /* GraphQL */ `
  mutation DeletePhoto(
    $input: DeletePhotoInput!
    $condition: ModelPhotoConditionInput
  ) {
    deletePhoto(input: $input, condition: $condition) {
      id
      groupId
      projectId
      inspectionId
      json
      text
      file {
        bucket
        region
        key
      }
      reportable
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createInspectionPhoto = /* GraphQL */ `
  mutation CreateInspectionPhoto(
    $input: CreateInspectionPhotoInput!
    $condition: ModelInspectionPhotoConditionInput
  ) {
    createInspectionPhoto(input: $input, condition: $condition) {
      id
      groupId
      projectId
      inspectionId
      photoId
      photo {
        id
        groupId
        projectId
        inspectionId
        json
        text
        file {
          bucket
          region
          key
        }
        reportable
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateInspectionPhoto = /* GraphQL */ `
  mutation UpdateInspectionPhoto(
    $input: UpdateInspectionPhotoInput!
    $condition: ModelInspectionPhotoConditionInput
  ) {
    updateInspectionPhoto(input: $input, condition: $condition) {
      id
      groupId
      projectId
      inspectionId
      photoId
      photo {
        id
        groupId
        projectId
        inspectionId
        json
        text
        file {
          bucket
          region
          key
        }
        reportable
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteInspectionPhoto = /* GraphQL */ `
  mutation DeleteInspectionPhoto(
    $input: DeleteInspectionPhotoInput!
    $condition: ModelInspectionPhotoConditionInput
  ) {
    deleteInspectionPhoto(input: $input, condition: $condition) {
      id
      groupId
      projectId
      inspectionId
      photoId
      photo {
        id
        groupId
        projectId
        inspectionId
        json
        text
        file {
          bucket
          region
          key
        }
        reportable
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createLocationPhoto = /* GraphQL */ `
  mutation CreateLocationPhoto(
    $input: CreateLocationPhotoInput!
    $condition: ModelLocationPhotoConditionInput
  ) {
    createLocationPhoto(input: $input, condition: $condition) {
      id
      groupId
      projectId
      locationId
      photoId
      photo {
        id
        groupId
        projectId
        inspectionId
        json
        text
        file {
          bucket
          region
          key
        }
        reportable
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateLocationPhoto = /* GraphQL */ `
  mutation UpdateLocationPhoto(
    $input: UpdateLocationPhotoInput!
    $condition: ModelLocationPhotoConditionInput
  ) {
    updateLocationPhoto(input: $input, condition: $condition) {
      id
      groupId
      projectId
      locationId
      photoId
      photo {
        id
        groupId
        projectId
        inspectionId
        json
        text
        file {
          bucket
          region
          key
        }
        reportable
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteLocationPhoto = /* GraphQL */ `
  mutation DeleteLocationPhoto(
    $input: DeleteLocationPhotoInput!
    $condition: ModelLocationPhotoConditionInput
  ) {
    deleteLocationPhoto(input: $input, condition: $condition) {
      id
      groupId
      projectId
      locationId
      photoId
      photo {
        id
        groupId
        projectId
        inspectionId
        json
        text
        file {
          bucket
          region
          key
        }
        reportable
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createFinding = /* GraphQL */ `
  mutation CreateFinding(
    $input: CreateFindingInput!
    $condition: ModelFindingConditionInput
  ) {
    createFinding(input: $input, condition: $condition) {
      id
      groupId
      type
      projectId
      phaseId
      inspectionId
      hierarchyItemId
      hierarchyItem {
        id
        name
        code
        hierarchyId
        parentId
        hidden
        inspectionPoints {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      observationId
      observation {
        id
        groupId
        type
        projectId
        inspectionId
        hierarchyItemId
        hierarchyItem {
          id
          name
          code
          hierarchyId
          parentId
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        inspectionPointId
        inspectionPoint {
          id
          groupId
          hierarchyItemId
          type
          projectId
          text
          code
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        text
        suggestion
        findingId
        primaryLocation {
          id
          groupId
          projectId
          observationId
          photoSequence
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          locationPrimaryPhotoId
          owner
        }
        locations {
          nextToken
          startedAt
        }
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        observationPrimaryLocationId
        owner
      }
      closingObservation {
        id
        groupId
        type
        projectId
        inspectionId
        hierarchyItemId
        hierarchyItem {
          id
          name
          code
          hierarchyId
          parentId
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        inspectionPointId
        inspectionPoint {
          id
          groupId
          hierarchyItemId
          type
          projectId
          text
          code
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        text
        suggestion
        findingId
        primaryLocation {
          id
          groupId
          projectId
          observationId
          photoSequence
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          locationPrimaryPhotoId
          owner
        }
        locations {
          nextToken
          startedAt
        }
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        observationPrimaryLocationId
        owner
      }
      status
      date
      closedDate
      code
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      findingClosingObservationId
    }
  }
`;
export const updateFinding = /* GraphQL */ `
  mutation UpdateFinding(
    $input: UpdateFindingInput!
    $condition: ModelFindingConditionInput
  ) {
    updateFinding(input: $input, condition: $condition) {
      id
      groupId
      type
      projectId
      phaseId
      inspectionId
      hierarchyItemId
      hierarchyItem {
        id
        name
        code
        hierarchyId
        parentId
        hidden
        inspectionPoints {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      observationId
      observation {
        id
        groupId
        type
        projectId
        inspectionId
        hierarchyItemId
        hierarchyItem {
          id
          name
          code
          hierarchyId
          parentId
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        inspectionPointId
        inspectionPoint {
          id
          groupId
          hierarchyItemId
          type
          projectId
          text
          code
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        text
        suggestion
        findingId
        primaryLocation {
          id
          groupId
          projectId
          observationId
          photoSequence
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          locationPrimaryPhotoId
          owner
        }
        locations {
          nextToken
          startedAt
        }
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        observationPrimaryLocationId
        owner
      }
      closingObservation {
        id
        groupId
        type
        projectId
        inspectionId
        hierarchyItemId
        hierarchyItem {
          id
          name
          code
          hierarchyId
          parentId
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        inspectionPointId
        inspectionPoint {
          id
          groupId
          hierarchyItemId
          type
          projectId
          text
          code
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        text
        suggestion
        findingId
        primaryLocation {
          id
          groupId
          projectId
          observationId
          photoSequence
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          locationPrimaryPhotoId
          owner
        }
        locations {
          nextToken
          startedAt
        }
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        observationPrimaryLocationId
        owner
      }
      status
      date
      closedDate
      code
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      findingClosingObservationId
    }
  }
`;
export const deleteFinding = /* GraphQL */ `
  mutation DeleteFinding(
    $input: DeleteFindingInput!
    $condition: ModelFindingConditionInput
  ) {
    deleteFinding(input: $input, condition: $condition) {
      id
      groupId
      type
      projectId
      phaseId
      inspectionId
      hierarchyItemId
      hierarchyItem {
        id
        name
        code
        hierarchyId
        parentId
        hidden
        inspectionPoints {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      observationId
      observation {
        id
        groupId
        type
        projectId
        inspectionId
        hierarchyItemId
        hierarchyItem {
          id
          name
          code
          hierarchyId
          parentId
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        inspectionPointId
        inspectionPoint {
          id
          groupId
          hierarchyItemId
          type
          projectId
          text
          code
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        text
        suggestion
        findingId
        primaryLocation {
          id
          groupId
          projectId
          observationId
          photoSequence
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          locationPrimaryPhotoId
          owner
        }
        locations {
          nextToken
          startedAt
        }
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        observationPrimaryLocationId
        owner
      }
      closingObservation {
        id
        groupId
        type
        projectId
        inspectionId
        hierarchyItemId
        hierarchyItem {
          id
          name
          code
          hierarchyId
          parentId
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        inspectionPointId
        inspectionPoint {
          id
          groupId
          hierarchyItemId
          type
          projectId
          text
          code
          hidden
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        text
        suggestion
        findingId
        primaryLocation {
          id
          groupId
          projectId
          observationId
          photoSequence
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          locationPrimaryPhotoId
          owner
        }
        locations {
          nextToken
          startedAt
        }
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        observationPrimaryLocationId
        owner
      }
      status
      date
      closedDate
      code
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      findingClosingObservationId
    }
  }
`;
export const createHierarchyBundle = /* GraphQL */ `
  mutation CreateHierarchyBundle(
    $input: CreateHierarchyBundleInput!
    $condition: ModelHierarchyBundleConditionInput
  ) {
    createHierarchyBundle(input: $input, condition: $condition) {
      id
      name
      type
      status
      hierarchyItemIds
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateHierarchyBundle = /* GraphQL */ `
  mutation UpdateHierarchyBundle(
    $input: UpdateHierarchyBundleInput!
    $condition: ModelHierarchyBundleConditionInput
  ) {
    updateHierarchyBundle(input: $input, condition: $condition) {
      id
      name
      type
      status
      hierarchyItemIds
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteHierarchyBundle = /* GraphQL */ `
  mutation DeleteHierarchyBundle(
    $input: DeleteHierarchyBundleInput!
    $condition: ModelHierarchyBundleConditionInput
  ) {
    deleteHierarchyBundle(input: $input, condition: $condition) {
      id
      name
      type
      status
      hierarchyItemIds
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createMilestoneTemplate = /* GraphQL */ `
  mutation CreateMilestoneTemplate(
    $input: CreateMilestoneTemplateInput!
    $condition: ModelMilestoneTemplateConditionInput
  ) {
    createMilestoneTemplate(input: $input, condition: $condition) {
      id
      name
      status
      hierarchyBundleIds
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateMilestoneTemplate = /* GraphQL */ `
  mutation UpdateMilestoneTemplate(
    $input: UpdateMilestoneTemplateInput!
    $condition: ModelMilestoneTemplateConditionInput
  ) {
    updateMilestoneTemplate(input: $input, condition: $condition) {
      id
      name
      status
      hierarchyBundleIds
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteMilestoneTemplate = /* GraphQL */ `
  mutation DeleteMilestoneTemplate(
    $input: DeleteMilestoneTemplateInput!
    $condition: ModelMilestoneTemplateConditionInput
  ) {
    deleteMilestoneTemplate(input: $input, condition: $condition) {
      id
      name
      status
      hierarchyBundleIds
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const createActivityStream = /* GraphQL */ `
  mutation CreateActivityStream(
    $input: CreateActivityStreamInput!
    $condition: ModelActivityStreamConditionInput
  ) {
    createActivityStream(input: $input, condition: $condition) {
      id
      modelType
      instanceId
      userId
      user {
        id
        status
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        metadata
        projects
        favoriteProjects
        inspections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      date
      type
      text
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const updateActivityStream = /* GraphQL */ `
  mutation UpdateActivityStream(
    $input: UpdateActivityStreamInput!
    $condition: ModelActivityStreamConditionInput
  ) {
    updateActivityStream(input: $input, condition: $condition) {
      id
      modelType
      instanceId
      userId
      user {
        id
        status
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        metadata
        projects
        favoriteProjects
        inspections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      date
      type
      text
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const deleteActivityStream = /* GraphQL */ `
  mutation DeleteActivityStream(
    $input: DeleteActivityStreamInput!
    $condition: ModelActivityStreamConditionInput
  ) {
    deleteActivityStream(input: $input, condition: $condition) {
      id
      modelType
      instanceId
      userId
      user {
        id
        status
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        metadata
        projects
        favoriteProjects
        inspections {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      date
      type
      text
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
