/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type Location = {
  __typename: "Location",
  id: string,
  groupId?: string | null,
  projectId?: string | null,
  observationId: string,
  primaryPhoto?: Photo | null,
  photoSequence?: Array< string | null > | null,
  tags?: ModelLocationTagConnection | null,
  photos?: ModelLocationPhotoConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  locationPrimaryPhotoId?: string | null,
  owner?: string | null,
};

export type Photo = {
  __typename: "Photo",
  id: string,
  groupId?: string | null,
  projectId?: string | null,
  inspectionId: string,
  json?: string | null,
  text?: string | null,
  file?: S3Object | null,
  reportable?: boolean | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  owner?: string | null,
};

export type S3Object = {
  __typename: "S3Object",
  bucket: string,
  region: string,
  key: string,
};

export type ModelLocationTagConnection = {
  __typename: "ModelLocationTagConnection",
  items:  Array<LocationTag | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type LocationTag = {
  __typename: "LocationTag",
  id: string,
  groupId?: string | null,
  projectId?: string | null,
  locationId: string,
  tagId: string,
  tag?: Tag | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  owner?: string | null,
};

export type Tag = {
  __typename: "Tag",
  id: string,
  groupId?: string | null,
  type: TagType,
  projectId: string,
  text: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  owner?: string | null,
};

export enum TagType {
  LOCATION = "LOCATION",
}


export type ModelLocationPhotoConnection = {
  __typename: "ModelLocationPhotoConnection",
  items:  Array<LocationPhoto | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type LocationPhoto = {
  __typename: "LocationPhoto",
  id: string,
  groupId?: string | null,
  projectId?: string | null,
  locationId: string,
  photoId: string,
  photo?: Photo | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  owner?: string | null,
};

export type SearchableContactFilterInput = {
  id?: SearchableIDFilterInput | null,
  type?: SearchableStringFilterInput | null,
  firstName?: SearchableStringFilterInput | null,
  lastName?: SearchableStringFilterInput | null,
  companyName?: SearchableStringFilterInput | null,
  jobTitle?: SearchableStringFilterInput | null,
  phone?: SearchableStringFilterInput | null,
  email?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  _version?: SearchableIntFilterInput | null,
  _deleted?: SearchableBooleanFilterInput | null,
  _lastChangedAt?: SearchableIntFilterInput | null,
  and?: Array< SearchableContactFilterInput | null > | null,
  or?: Array< SearchableContactFilterInput | null > | null,
  not?: SearchableContactFilterInput | null,
};

export type SearchableIDFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableStringFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableIntFilterInput = {
  ne?: number | null,
  gt?: number | null,
  lt?: number | null,
  gte?: number | null,
  lte?: number | null,
  eq?: number | null,
  range?: Array< number | null > | null,
};

export type SearchableBooleanFilterInput = {
  eq?: boolean | null,
  ne?: boolean | null,
};

export type SearchableContactSortInput = {
  field?: SearchableContactSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableContactSortableFields {
  id = "id",
  type = "type",
  firstName = "firstName",
  lastName = "lastName",
  companyName = "companyName",
  jobTitle = "jobTitle",
  phone = "phone",
  email = "email",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export enum SearchableSortDirection {
  asc = "asc",
  desc = "desc",
}


export type SearchableContactConnection = {
  __typename: "SearchableContactConnection",
  items:  Array<Contact | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type Contact = {
  __typename: "Contact",
  id: string,
  type: string,
  firstName: string,
  lastName: string,
  companyName?: string | null,
  jobTitle?: string | null,
  phone?: string | null,
  email?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  owner?: string | null,
};

export type SearchableAggregateResult = {
  __typename: "SearchableAggregateResult",
  name: string,
  result?: SearchableAggregateGenericResult | null,
};

export type SearchableAggregateGenericResult = SearchableAggregateScalarResult | SearchableAggregateBucketResult


export type SearchableAggregateScalarResult = {
  __typename: "SearchableAggregateScalarResult",
  value: number,
};

export type SearchableAggregateBucketResult = {
  __typename: "SearchableAggregateBucketResult",
  buckets?:  Array<SearchableAggregateBucketResultItem | null > | null,
};

export type SearchableAggregateBucketResultItem = {
  __typename: "SearchableAggregateBucketResultItem",
  key: string,
  doc_count: number,
};

export type SearchableInspectionPointFilterInput = {
  id?: SearchableIDFilterInput | null,
  groupId?: SearchableStringFilterInput | null,
  hierarchyItemId?: SearchableIDFilterInput | null,
  projectId?: SearchableIDFilterInput | null,
  text?: SearchableStringFilterInput | null,
  code?: SearchableStringFilterInput | null,
  hidden?: SearchableBooleanFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  _version?: SearchableIntFilterInput | null,
  _deleted?: SearchableBooleanFilterInput | null,
  _lastChangedAt?: SearchableIntFilterInput | null,
  type?: SearchableStringFilterInput | null,
  and?: Array< SearchableInspectionPointFilterInput | null > | null,
  or?: Array< SearchableInspectionPointFilterInput | null > | null,
  not?: SearchableInspectionPointFilterInput | null,
};

export type SearchableInspectionPointSortInput = {
  field?: SearchableInspectionPointSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableInspectionPointSortableFields {
  id = "id",
  groupId = "groupId",
  hierarchyItemId = "hierarchyItemId",
  projectId = "projectId",
  text = "text",
  code = "code",
  hidden = "hidden",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type SearchableInspectionPointConnection = {
  __typename: "SearchableInspectionPointConnection",
  items:  Array<InspectionPoint | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type InspectionPoint = {
  __typename: "InspectionPoint",
  id: string,
  groupId?: string | null,
  hierarchyItemId: string,
  type: InspectionPointType,
  projectId?: string | null,
  text: string,
  code?: string | null,
  hidden?: boolean | null,
  observations?: ModelObservationConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  owner?: string | null,
};

export enum InspectionPointType {
  APPROVED = "APPROVED",
  SUBMITTED = "SUBMITTED",
}


export type ModelObservationConnection = {
  __typename: "ModelObservationConnection",
  items:  Array<Observation | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Observation = {
  __typename: "Observation",
  id: string,
  groupId?: string | null,
  type?: ObservationType | null,
  projectId?: string | null,
  inspectionId: string,
  hierarchyItemId: string,
  hierarchyItem?: HierarchyItem | null,
  inspectionPointId: string,
  inspectionPoint?: InspectionPoint | null,
  text?: string | null,
  suggestion?: string | null,
  findingId?: string | null,
  primaryLocation?: Location | null,
  locations?: ModelLocationConnection | null,
  status?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  observationPrimaryLocationId?: string | null,
  owner?: string | null,
};

export enum ObservationType {
  CONFORMING = "CONFORMING",
  FINDING = "FINDING",
  RESOLUTION = "RESOLUTION",
}


export type HierarchyItem = {
  __typename: "HierarchyItem",
  id: string,
  name: string,
  code?: string | null,
  hierarchyId: string,
  parentId?: string | null,
  hidden?: boolean | null,
  inspectionPoints?: ModelInspectionPointConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  owner?: string | null,
};

export type ModelInspectionPointConnection = {
  __typename: "ModelInspectionPointConnection",
  items:  Array<InspectionPoint | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelLocationConnection = {
  __typename: "ModelLocationConnection",
  items:  Array<Location | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelFindingFilterInput = {
  id?: ModelIDInput | null,
  groupId?: ModelStringInput | null,
  type?: ModelFindingTypeInput | null,
  projectId?: ModelIDInput | null,
  phaseId?: ModelIDInput | null,
  inspectionId?: ModelIDInput | null,
  hierarchyItemId?: ModelIDInput | null,
  observationId?: ModelIDInput | null,
  status?: ModelFindingStatusInput | null,
  date?: ModelStringInput | null,
  closedDate?: ModelStringInput | null,
  code?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelFindingFilterInput | null > | null,
  or?: Array< ModelFindingFilterInput | null > | null,
  not?: ModelFindingFilterInput | null,
  findingClosingObservationId?: ModelIDInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelFindingTypeInput = {
  eq?: FindingType | null,
  ne?: FindingType | null,
};

export enum FindingType {
  IOC = "IOC",
  NCI = "NCI",
}


export type ModelFindingStatusInput = {
  eq?: FindingStatus | null,
  ne?: FindingStatus | null,
};

export enum FindingStatus {
  NEW = "NEW",
  OPEN = "OPEN",
  CLOSED = "CLOSED",
}


export type ModelFindingConnection = {
  __typename: "ModelFindingConnection",
  items:  Array<Finding | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Finding = {
  __typename: "Finding",
  id: string,
  groupId?: string | null,
  type: FindingType,
  projectId: string,
  phaseId?: string | null,
  inspectionId: string,
  hierarchyItemId: string,
  hierarchyItem?: HierarchyItem | null,
  observationId: string,
  observation?: Observation | null,
  closingObservation?: Observation | null,
  status: FindingStatus,
  date?: string | null,
  closedDate?: string | null,
  code?: string | null,
  owner?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  findingClosingObservationId?: string | null,
};

export type SearchableInspectionConnection = {
  __typename: "SearchableInspectionConnection",
  items:  Array<Inspection | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type Inspection = {
  __typename: "Inspection",
  id: string,
  groupId?: string | null,
  clientId?: string | null,
  projectId: string,
  project?: Project | null,
  phase?: Phase | null,
  phaseId?: string | null,
  photoSequence?: Array< string | null > | null,
  status: string,
  userId: string,
  user?: User | null,
  reviewer?: User | null,
  inspectionReviewerId?: string | null,
  inspectionDate?: string | null,
  reviewedDate?: string | null,
  deliveredDate?: string | null,
  nextInspectionDate?: string | null,
  arrivalTime?: string | null,
  checkInContact?: Contact | null,
  notes?: string | null,
  coverLetter?: string | null,
  comments?: ModelInspectionCommentConnection | null,
  observations?: ModelObservationConnection | null,
  findings?: ModelFindingConnection | null,
  hierarchyItems?: ModelInspectionHierarchyItemConnection | null,
  photos?: ModelInspectionPhotoConnection | null,
  scopeHierarchyItems?: Array< string | null > | null,
  scope?: string | null,
  number?: string | null,
  code?: string | null,
  report?: S3Object | null,
  weather?: string | null,
  milestoneBundleIds?: string | null,
  milestoneTrackers?:  Array<MilestoneTracker | null > | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  inspectionCheckInContactId?: string | null,
  owner?: string | null,
};

export type Project = {
  __typename: "Project",
  id: string,
  groupId?: string | null,
  name: string,
  status: string,
  clientId: string,
  client?: Client | null,
  eft?: User | null,
  reviewer?: User | null,
  admin?: User | null,
  address: Address,
  primaryContact?: Contact | null,
  reportTemplateId?: string | null,
  reportDisclaimer?: string | null,
  inspections?: ModelInspectionConnection | null,
  contacts?: ModelProjectContactConnection | null,
  distributionList?: string | null,
  externalLinks?: ModelExternalLinkConnection | null,
  tags?: ModelTagConnection | null,
  findings?: ModelFindingConnection | null,
  phases?: ModelPhaseConnection | null,
  hierarchyItemExclusions?: Array< string | null > | null,
  exclusions?: string | null,
  notes?: string | null,
  code?: string | null,
  milestoneTemplate?: MilestoneTemplate | null,
  milestoneTemplateId?: string | null,
  externalId?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  projectEftId?: string | null,
  projectReviewerId?: string | null,
  projectAdminId?: string | null,
  projectPrimaryContactId?: string | null,
  owner?: string | null,
};

export type Client = {
  __typename: "Client",
  id: string,
  name: string,
  code: string,
  status: string,
  address: Address,
  primaryContact?: Contact | null,
  reportDisclaimer?: string | null,
  contacts?: ModelClientContactConnection | null,
  projects?: ModelProjectConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  clientPrimaryContactId?: string | null,
  owner?: string | null,
};

export type Address = {
  __typename: "Address",
  street: string,
  street2?: string | null,
  city: string,
  state: string,
  zip: string,
};

export type ModelClientContactConnection = {
  __typename: "ModelClientContactConnection",
  items:  Array<ClientContact | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ClientContact = {
  __typename: "ClientContact",
  id: string,
  clientId: string,
  contactId: string,
  contact?: Contact | null,
  label?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  owner?: string | null,
};

export type ModelProjectConnection = {
  __typename: "ModelProjectConnection",
  items:  Array<Project | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type User = {
  __typename: "User",
  id: string,
  status: string,
  type: string,
  firstName: string,
  lastName: string,
  companyName?: string | null,
  jobTitle?: string | null,
  phone?: string | null,
  email?: string | null,
  metadata?: string | null,
  projects?: Array< string | null > | null,
  favoriteProjects?: string | null,
  inspections?: ModelInspectionConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  owner?: string | null,
};

export type ModelInspectionConnection = {
  __typename: "ModelInspectionConnection",
  items:  Array<Inspection | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelProjectContactConnection = {
  __typename: "ModelProjectContactConnection",
  items:  Array<ProjectContact | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ProjectContact = {
  __typename: "ProjectContact",
  id: string,
  groupId?: string | null,
  projectId: string,
  contactId: string,
  contact?: Contact | null,
  label?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  owner?: string | null,
};

export type ModelExternalLinkConnection = {
  __typename: "ModelExternalLinkConnection",
  items:  Array<ExternalLink | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ExternalLink = {
  __typename: "ExternalLink",
  id: string,
  groupId?: string | null,
  projectId: string,
  title: string,
  url: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  owner?: string | null,
};

export type ModelTagConnection = {
  __typename: "ModelTagConnection",
  items:  Array<Tag | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelPhaseConnection = {
  __typename: "ModelPhaseConnection",
  items:  Array<Phase | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Phase = {
  __typename: "Phase",
  id: string,
  groupId?: string | null,
  projectId: string,
  project: Project,
  name: string,
  status: string,
  units?:  Array<Unit | null > | null,
  milestoneTemplate?: MilestoneTemplate | null,
  milestoneTemplateId?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  owner?: string | null,
};

export type Unit = {
  __typename: "Unit",
  id: string,
  name: string,
};

export type MilestoneTemplate = {
  __typename: "MilestoneTemplate",
  id: string,
  name: string,
  status: string,
  hierarchyBundleIds?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  owner?: string | null,
};

export type ModelInspectionCommentConnection = {
  __typename: "ModelInspectionCommentConnection",
  items:  Array<InspectionComment | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type InspectionComment = {
  __typename: "InspectionComment",
  id: string,
  groupId?: string | null,
  projectId?: string | null,
  inspectionId: string,
  commentId: string,
  comment?: Comment | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  owner?: string | null,
};

export type Comment = {
  __typename: "Comment",
  id: string,
  groupId?: string | null,
  projectId?: string | null,
  userId: string,
  user?: User | null,
  date: string,
  text: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  owner?: string | null,
};

export type ModelInspectionHierarchyItemConnection = {
  __typename: "ModelInspectionHierarchyItemConnection",
  items:  Array<InspectionHierarchyItem | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type InspectionHierarchyItem = {
  __typename: "InspectionHierarchyItem",
  id: string,
  inspectionId: string,
  hierarchyItemId: string,
  hierarchyItem?: HierarchyItem | null,
  notes?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  owner?: string | null,
};

export type ModelInspectionPhotoConnection = {
  __typename: "ModelInspectionPhotoConnection",
  items:  Array<InspectionPhoto | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type InspectionPhoto = {
  __typename: "InspectionPhoto",
  id: string,
  groupId?: string | null,
  projectId?: string | null,
  inspectionId: string,
  photoId: string,
  photo?: Photo | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  owner?: string | null,
};

export type MilestoneTracker = {
  __typename: "MilestoneTracker",
  milestoneId: string,
  all?: boolean | null,
  units?: Array< string | null > | null,
};

export type CreateExternalLinkInput = {
  id?: string | null,
  groupId?: string | null,
  projectId: string,
  title: string,
  url: string,
  _version?: number | null,
};

export type ModelExternalLinkConditionInput = {
  groupId?: ModelStringInput | null,
  projectId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  url?: ModelStringInput | null,
  and?: Array< ModelExternalLinkConditionInput | null > | null,
  or?: Array< ModelExternalLinkConditionInput | null > | null,
  not?: ModelExternalLinkConditionInput | null,
};

export type UpdateExternalLinkInput = {
  id: string,
  groupId?: string | null,
  projectId?: string | null,
  title?: string | null,
  url?: string | null,
  _version?: number | null,
};

export type DeleteExternalLinkInput = {
  id: string,
  _version?: number | null,
};

export type CreateContactInput = {
  id?: string | null,
  type: string,
  firstName: string,
  lastName: string,
  companyName?: string | null,
  jobTitle?: string | null,
  phone?: string | null,
  email?: string | null,
  _version?: number | null,
};

export type ModelContactConditionInput = {
  type?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  companyName?: ModelStringInput | null,
  jobTitle?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  email?: ModelStringInput | null,
  and?: Array< ModelContactConditionInput | null > | null,
  or?: Array< ModelContactConditionInput | null > | null,
  not?: ModelContactConditionInput | null,
};

export type UpdateContactInput = {
  id: string,
  type?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  companyName?: string | null,
  jobTitle?: string | null,
  phone?: string | null,
  email?: string | null,
  _version?: number | null,
};

export type DeleteContactInput = {
  id: string,
  _version?: number | null,
};

export type CreateUserInput = {
  id?: string | null,
  status: string,
  type: string,
  firstName: string,
  lastName: string,
  companyName?: string | null,
  jobTitle?: string | null,
  phone?: string | null,
  email?: string | null,
  metadata?: string | null,
  projects?: Array< string | null > | null,
  favoriteProjects?: string | null,
  _version?: number | null,
};

export type ModelUserConditionInput = {
  status?: ModelStringInput | null,
  type?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  companyName?: ModelStringInput | null,
  jobTitle?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  email?: ModelStringInput | null,
  metadata?: ModelStringInput | null,
  projects?: ModelStringInput | null,
  favoriteProjects?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type UpdateUserInput = {
  id: string,
  status?: string | null,
  type?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  companyName?: string | null,
  jobTitle?: string | null,
  phone?: string | null,
  email?: string | null,
  metadata?: string | null,
  projects?: Array< string | null > | null,
  favoriteProjects?: string | null,
  _version?: number | null,
};

export type DeleteUserInput = {
  id: string,
  _version?: number | null,
};

export type CreateClientInput = {
  id?: string | null,
  name: string,
  code: string,
  status: string,
  address: AddressInput,
  reportDisclaimer?: string | null,
  _version?: number | null,
  clientPrimaryContactId?: string | null,
};

export type AddressInput = {
  street: string,
  street2?: string | null,
  city: string,
  state: string,
  zip: string,
};

export type ModelClientConditionInput = {
  name?: ModelStringInput | null,
  code?: ModelStringInput | null,
  status?: ModelStringInput | null,
  reportDisclaimer?: ModelStringInput | null,
  and?: Array< ModelClientConditionInput | null > | null,
  or?: Array< ModelClientConditionInput | null > | null,
  not?: ModelClientConditionInput | null,
  clientPrimaryContactId?: ModelIDInput | null,
};

export type UpdateClientInput = {
  id: string,
  name?: string | null,
  code?: string | null,
  status?: string | null,
  address?: AddressInput | null,
  reportDisclaimer?: string | null,
  _version?: number | null,
  clientPrimaryContactId?: string | null,
};

export type DeleteClientInput = {
  id: string,
  _version?: number | null,
};

export type CreateClientContactInput = {
  id?: string | null,
  clientId: string,
  contactId: string,
  label?: string | null,
  _version?: number | null,
};

export type ModelClientContactConditionInput = {
  clientId?: ModelIDInput | null,
  contactId?: ModelIDInput | null,
  label?: ModelStringInput | null,
  and?: Array< ModelClientContactConditionInput | null > | null,
  or?: Array< ModelClientContactConditionInput | null > | null,
  not?: ModelClientContactConditionInput | null,
};

export type UpdateClientContactInput = {
  id: string,
  clientId?: string | null,
  contactId?: string | null,
  label?: string | null,
  _version?: number | null,
};

export type DeleteClientContactInput = {
  id: string,
  _version?: number | null,
};

export type CreateProjectInput = {
  id?: string | null,
  groupId?: string | null,
  name: string,
  status: string,
  clientId: string,
  address: AddressInput,
  reportTemplateId?: string | null,
  reportDisclaimer?: string | null,
  distributionList?: string | null,
  hierarchyItemExclusions?: Array< string | null > | null,
  exclusions?: string | null,
  notes?: string | null,
  code?: string | null,
  milestoneTemplateId?: string | null,
  externalId?: string | null,
  _version?: number | null,
  projectEftId?: string | null,
  projectReviewerId?: string | null,
  projectAdminId?: string | null,
  projectPrimaryContactId?: string | null,
};

export type ModelProjectConditionInput = {
  groupId?: ModelStringInput | null,
  name?: ModelStringInput | null,
  status?: ModelStringInput | null,
  clientId?: ModelIDInput | null,
  reportTemplateId?: ModelStringInput | null,
  reportDisclaimer?: ModelStringInput | null,
  distributionList?: ModelStringInput | null,
  hierarchyItemExclusions?: ModelStringInput | null,
  exclusions?: ModelStringInput | null,
  notes?: ModelStringInput | null,
  code?: ModelStringInput | null,
  milestoneTemplateId?: ModelIDInput | null,
  externalId?: ModelStringInput | null,
  and?: Array< ModelProjectConditionInput | null > | null,
  or?: Array< ModelProjectConditionInput | null > | null,
  not?: ModelProjectConditionInput | null,
  projectEftId?: ModelIDInput | null,
  projectReviewerId?: ModelIDInput | null,
  projectAdminId?: ModelIDInput | null,
  projectPrimaryContactId?: ModelIDInput | null,
};

export type UpdateProjectInput = {
  id: string,
  groupId?: string | null,
  name?: string | null,
  status?: string | null,
  clientId?: string | null,
  address?: AddressInput | null,
  reportTemplateId?: string | null,
  reportDisclaimer?: string | null,
  distributionList?: string | null,
  hierarchyItemExclusions?: Array< string | null > | null,
  exclusions?: string | null,
  notes?: string | null,
  code?: string | null,
  milestoneTemplateId?: string | null,
  externalId?: string | null,
  _version?: number | null,
  projectEftId?: string | null,
  projectReviewerId?: string | null,
  projectAdminId?: string | null,
  projectPrimaryContactId?: string | null,
};

export type DeleteProjectInput = {
  id: string,
  _version?: number | null,
};

export type CreatePhaseInput = {
  id?: string | null,
  groupId?: string | null,
  projectId: string,
  name: string,
  status: string,
  units?: Array< UnitInput | null > | null,
  milestoneTemplateId?: string | null,
  _version?: number | null,
};

export type UnitInput = {
  id?: string | null,
  name: string,
};

export type ModelPhaseConditionInput = {
  groupId?: ModelStringInput | null,
  projectId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  status?: ModelStringInput | null,
  milestoneTemplateId?: ModelIDInput | null,
  and?: Array< ModelPhaseConditionInput | null > | null,
  or?: Array< ModelPhaseConditionInput | null > | null,
  not?: ModelPhaseConditionInput | null,
};

export type UpdatePhaseInput = {
  id: string,
  groupId?: string | null,
  projectId?: string | null,
  name?: string | null,
  status?: string | null,
  units?: Array< UnitInput | null > | null,
  milestoneTemplateId?: string | null,
  _version?: number | null,
};

export type DeletePhaseInput = {
  id: string,
  _version?: number | null,
};

export type CreateProjectContactInput = {
  id?: string | null,
  groupId?: string | null,
  projectId: string,
  contactId: string,
  label?: string | null,
  _version?: number | null,
};

export type ModelProjectContactConditionInput = {
  groupId?: ModelStringInput | null,
  projectId?: ModelIDInput | null,
  contactId?: ModelIDInput | null,
  label?: ModelStringInput | null,
  and?: Array< ModelProjectContactConditionInput | null > | null,
  or?: Array< ModelProjectContactConditionInput | null > | null,
  not?: ModelProjectContactConditionInput | null,
};

export type UpdateProjectContactInput = {
  id: string,
  groupId?: string | null,
  projectId?: string | null,
  contactId?: string | null,
  label?: string | null,
  _version?: number | null,
};

export type DeleteProjectContactInput = {
  id: string,
  _version?: number | null,
};

export type CreateFavoriteProjectInput = {
  id?: string | null,
  projectId: string,
  _version?: number | null,
};

export type ModelFavoriteProjectConditionInput = {
  projectId?: ModelIDInput | null,
  and?: Array< ModelFavoriteProjectConditionInput | null > | null,
  or?: Array< ModelFavoriteProjectConditionInput | null > | null,
  not?: ModelFavoriteProjectConditionInput | null,
};

export type FavoriteProject = {
  __typename: "FavoriteProject",
  id: string,
  projectId: string,
  project: Project,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  owner?: string | null,
};

export type UpdateFavoriteProjectInput = {
  id: string,
  projectId?: string | null,
  _version?: number | null,
};

export type DeleteFavoriteProjectInput = {
  id: string,
  _version?: number | null,
};

export type CreateInspectionInput = {
  id?: string | null,
  groupId?: string | null,
  clientId?: string | null,
  projectId: string,
  phaseId?: string | null,
  photoSequence?: Array< string | null > | null,
  status: string,
  userId: string,
  inspectionReviewerId?: string | null,
  inspectionDate?: string | null,
  reviewedDate?: string | null,
  deliveredDate?: string | null,
  nextInspectionDate?: string | null,
  arrivalTime?: string | null,
  notes?: string | null,
  coverLetter?: string | null,
  scopeHierarchyItems?: Array< string | null > | null,
  scope?: string | null,
  number?: string | null,
  code?: string | null,
  report?: S3ObjectInput | null,
  weather?: string | null,
  milestoneBundleIds?: string | null,
  milestoneTrackers?: Array< MilestoneTrackerInput | null > | null,
  _version?: number | null,
  inspectionCheckInContactId?: string | null,
};

export type S3ObjectInput = {
  bucket: string,
  region: string,
  key: string,
};

export type MilestoneTrackerInput = {
  milestoneId: string,
  all?: boolean | null,
  units?: Array< string | null > | null,
};

export type ModelInspectionConditionInput = {
  groupId?: ModelStringInput | null,
  clientId?: ModelIDInput | null,
  projectId?: ModelIDInput | null,
  phaseId?: ModelIDInput | null,
  photoSequence?: ModelIDInput | null,
  status?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  inspectionReviewerId?: ModelIDInput | null,
  inspectionDate?: ModelStringInput | null,
  reviewedDate?: ModelStringInput | null,
  deliveredDate?: ModelStringInput | null,
  nextInspectionDate?: ModelStringInput | null,
  arrivalTime?: ModelStringInput | null,
  notes?: ModelStringInput | null,
  coverLetter?: ModelStringInput | null,
  scopeHierarchyItems?: ModelStringInput | null,
  scope?: ModelStringInput | null,
  number?: ModelStringInput | null,
  code?: ModelStringInput | null,
  weather?: ModelStringInput | null,
  milestoneBundleIds?: ModelStringInput | null,
  and?: Array< ModelInspectionConditionInput | null > | null,
  or?: Array< ModelInspectionConditionInput | null > | null,
  not?: ModelInspectionConditionInput | null,
  inspectionCheckInContactId?: ModelIDInput | null,
};

export type UpdateInspectionInput = {
  id: string,
  groupId?: string | null,
  clientId?: string | null,
  projectId?: string | null,
  phaseId?: string | null,
  photoSequence?: Array< string | null > | null,
  status?: string | null,
  userId?: string | null,
  inspectionReviewerId?: string | null,
  inspectionDate?: string | null,
  reviewedDate?: string | null,
  deliveredDate?: string | null,
  nextInspectionDate?: string | null,
  arrivalTime?: string | null,
  notes?: string | null,
  coverLetter?: string | null,
  scopeHierarchyItems?: Array< string | null > | null,
  scope?: string | null,
  number?: string | null,
  code?: string | null,
  report?: S3ObjectInput | null,
  weather?: string | null,
  milestoneBundleIds?: string | null,
  milestoneTrackers?: Array< MilestoneTrackerInput | null > | null,
  _version?: number | null,
  inspectionCheckInContactId?: string | null,
};

export type DeleteInspectionInput = {
  id: string,
  _version?: number | null,
};

export type CreateHierarchyInput = {
  id?: string | null,
  name: string,
  _version?: number | null,
};

export type ModelHierarchyConditionInput = {
  name?: ModelStringInput | null,
  and?: Array< ModelHierarchyConditionInput | null > | null,
  or?: Array< ModelHierarchyConditionInput | null > | null,
  not?: ModelHierarchyConditionInput | null,
};

export type Hierarchy = {
  __typename: "Hierarchy",
  id: string,
  name: string,
  items?: ModelHierarchyItemConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  owner?: string | null,
};

export type ModelHierarchyItemConnection = {
  __typename: "ModelHierarchyItemConnection",
  items:  Array<HierarchyItem | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type UpdateHierarchyInput = {
  id: string,
  name?: string | null,
  _version?: number | null,
};

export type DeleteHierarchyInput = {
  id: string,
  _version?: number | null,
};

export type CreateHierarchyItemInput = {
  id?: string | null,
  name: string,
  code?: string | null,
  hierarchyId: string,
  parentId?: string | null,
  hidden?: boolean | null,
  _version?: number | null,
};

export type ModelHierarchyItemConditionInput = {
  name?: ModelStringInput | null,
  code?: ModelStringInput | null,
  hierarchyId?: ModelIDInput | null,
  parentId?: ModelIDInput | null,
  hidden?: ModelBooleanInput | null,
  and?: Array< ModelHierarchyItemConditionInput | null > | null,
  or?: Array< ModelHierarchyItemConditionInput | null > | null,
  not?: ModelHierarchyItemConditionInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateHierarchyItemInput = {
  id: string,
  name?: string | null,
  code?: string | null,
  hierarchyId?: string | null,
  parentId?: string | null,
  hidden?: boolean | null,
  _version?: number | null,
};

export type DeleteHierarchyItemInput = {
  id: string,
  _version?: number | null,
};

export type CreateInspectionHierarchyItemInput = {
  id?: string | null,
  inspectionId: string,
  hierarchyItemId: string,
  notes?: string | null,
  _version?: number | null,
};

export type ModelInspectionHierarchyItemConditionInput = {
  inspectionId?: ModelIDInput | null,
  hierarchyItemId?: ModelIDInput | null,
  notes?: ModelStringInput | null,
  and?: Array< ModelInspectionHierarchyItemConditionInput | null > | null,
  or?: Array< ModelInspectionHierarchyItemConditionInput | null > | null,
  not?: ModelInspectionHierarchyItemConditionInput | null,
};

export type UpdateInspectionHierarchyItemInput = {
  id: string,
  inspectionId?: string | null,
  hierarchyItemId?: string | null,
  notes?: string | null,
  _version?: number | null,
};

export type DeleteInspectionHierarchyItemInput = {
  id: string,
  _version?: number | null,
};

export type CreateInspectionPointInput = {
  id?: string | null,
  groupId?: string | null,
  hierarchyItemId: string,
  type: InspectionPointType,
  projectId?: string | null,
  text: string,
  code?: string | null,
  hidden?: boolean | null,
  _version?: number | null,
};

export type ModelInspectionPointConditionInput = {
  groupId?: ModelStringInput | null,
  hierarchyItemId?: ModelIDInput | null,
  type?: ModelInspectionPointTypeInput | null,
  projectId?: ModelIDInput | null,
  text?: ModelStringInput | null,
  code?: ModelStringInput | null,
  hidden?: ModelBooleanInput | null,
  and?: Array< ModelInspectionPointConditionInput | null > | null,
  or?: Array< ModelInspectionPointConditionInput | null > | null,
  not?: ModelInspectionPointConditionInput | null,
};

export type ModelInspectionPointTypeInput = {
  eq?: InspectionPointType | null,
  ne?: InspectionPointType | null,
};

export type UpdateInspectionPointInput = {
  id: string,
  groupId?: string | null,
  hierarchyItemId?: string | null,
  type?: InspectionPointType | null,
  projectId?: string | null,
  text?: string | null,
  code?: string | null,
  hidden?: boolean | null,
  _version?: number | null,
};

export type DeleteInspectionPointInput = {
  id: string,
  _version?: number | null,
};

export type CreateCommentInput = {
  id?: string | null,
  groupId?: string | null,
  projectId?: string | null,
  userId: string,
  date: string,
  text: string,
  _version?: number | null,
};

export type ModelCommentConditionInput = {
  groupId?: ModelStringInput | null,
  projectId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  date?: ModelStringInput | null,
  text?: ModelStringInput | null,
  and?: Array< ModelCommentConditionInput | null > | null,
  or?: Array< ModelCommentConditionInput | null > | null,
  not?: ModelCommentConditionInput | null,
};

export type UpdateCommentInput = {
  id: string,
  groupId?: string | null,
  projectId?: string | null,
  userId?: string | null,
  date?: string | null,
  text?: string | null,
  _version?: number | null,
};

export type DeleteCommentInput = {
  id: string,
  _version?: number | null,
};

export type CreateInspectionCommentInput = {
  id?: string | null,
  groupId?: string | null,
  projectId?: string | null,
  inspectionId: string,
  commentId: string,
  _version?: number | null,
};

export type ModelInspectionCommentConditionInput = {
  groupId?: ModelStringInput | null,
  projectId?: ModelIDInput | null,
  inspectionId?: ModelIDInput | null,
  commentId?: ModelIDInput | null,
  and?: Array< ModelInspectionCommentConditionInput | null > | null,
  or?: Array< ModelInspectionCommentConditionInput | null > | null,
  not?: ModelInspectionCommentConditionInput | null,
};

export type UpdateInspectionCommentInput = {
  id: string,
  groupId?: string | null,
  projectId?: string | null,
  inspectionId?: string | null,
  commentId?: string | null,
  _version?: number | null,
};

export type DeleteInspectionCommentInput = {
  id: string,
  _version?: number | null,
};

export type CreateObservationInput = {
  id?: string | null,
  groupId?: string | null,
  type?: ObservationType | null,
  projectId?: string | null,
  inspectionId: string,
  hierarchyItemId: string,
  inspectionPointId: string,
  text?: string | null,
  suggestion?: string | null,
  findingId?: string | null,
  status?: string | null,
  _version?: number | null,
  observationPrimaryLocationId?: string | null,
};

export type ModelObservationConditionInput = {
  groupId?: ModelStringInput | null,
  type?: ModelObservationTypeInput | null,
  projectId?: ModelIDInput | null,
  inspectionId?: ModelIDInput | null,
  hierarchyItemId?: ModelIDInput | null,
  inspectionPointId?: ModelIDInput | null,
  text?: ModelStringInput | null,
  suggestion?: ModelStringInput | null,
  findingId?: ModelIDInput | null,
  status?: ModelStringInput | null,
  and?: Array< ModelObservationConditionInput | null > | null,
  or?: Array< ModelObservationConditionInput | null > | null,
  not?: ModelObservationConditionInput | null,
  observationPrimaryLocationId?: ModelIDInput | null,
};

export type ModelObservationTypeInput = {
  eq?: ObservationType | null,
  ne?: ObservationType | null,
};

export type UpdateObservationInput = {
  id: string,
  groupId?: string | null,
  type?: ObservationType | null,
  projectId?: string | null,
  inspectionId?: string | null,
  hierarchyItemId?: string | null,
  inspectionPointId?: string | null,
  text?: string | null,
  suggestion?: string | null,
  findingId?: string | null,
  status?: string | null,
  _version?: number | null,
  observationPrimaryLocationId?: string | null,
};

export type DeleteObservationInput = {
  id: string,
  _version?: number | null,
};

export type CreateLocationInput = {
  id?: string | null,
  groupId?: string | null,
  projectId?: string | null,
  observationId: string,
  photoSequence?: Array< string | null > | null,
  _version?: number | null,
  locationPrimaryPhotoId?: string | null,
};

export type ModelLocationConditionInput = {
  groupId?: ModelStringInput | null,
  projectId?: ModelIDInput | null,
  observationId?: ModelIDInput | null,
  photoSequence?: ModelIDInput | null,
  and?: Array< ModelLocationConditionInput | null > | null,
  or?: Array< ModelLocationConditionInput | null > | null,
  not?: ModelLocationConditionInput | null,
  locationPrimaryPhotoId?: ModelIDInput | null,
};

export type UpdateLocationInput = {
  id: string,
  groupId?: string | null,
  projectId?: string | null,
  observationId?: string | null,
  photoSequence?: Array< string | null > | null,
  _version?: number | null,
  locationPrimaryPhotoId?: string | null,
};

export type DeleteLocationInput = {
  id: string,
  _version?: number | null,
};

export type CreateTagInput = {
  id?: string | null,
  groupId?: string | null,
  type: TagType,
  projectId: string,
  text: string,
  _version?: number | null,
};

export type ModelTagConditionInput = {
  groupId?: ModelStringInput | null,
  type?: ModelTagTypeInput | null,
  projectId?: ModelIDInput | null,
  text?: ModelStringInput | null,
  and?: Array< ModelTagConditionInput | null > | null,
  or?: Array< ModelTagConditionInput | null > | null,
  not?: ModelTagConditionInput | null,
};

export type ModelTagTypeInput = {
  eq?: TagType | null,
  ne?: TagType | null,
};

export type UpdateTagInput = {
  id: string,
  groupId?: string | null,
  type?: TagType | null,
  projectId?: string | null,
  text?: string | null,
  _version?: number | null,
};

export type DeleteTagInput = {
  id: string,
  _version?: number | null,
};

export type CreateLocationTagInput = {
  id?: string | null,
  groupId?: string | null,
  projectId?: string | null,
  locationId: string,
  tagId: string,
  _version?: number | null,
};

export type ModelLocationTagConditionInput = {
  groupId?: ModelStringInput | null,
  projectId?: ModelIDInput | null,
  locationId?: ModelIDInput | null,
  tagId?: ModelIDInput | null,
  and?: Array< ModelLocationTagConditionInput | null > | null,
  or?: Array< ModelLocationTagConditionInput | null > | null,
  not?: ModelLocationTagConditionInput | null,
};

export type UpdateLocationTagInput = {
  id: string,
  groupId?: string | null,
  projectId?: string | null,
  locationId?: string | null,
  tagId?: string | null,
  _version?: number | null,
};

export type DeleteLocationTagInput = {
  id: string,
  _version?: number | null,
};

export type CreatePhotoInput = {
  id?: string | null,
  groupId?: string | null,
  projectId?: string | null,
  inspectionId: string,
  json?: string | null,
  text?: string | null,
  file?: S3ObjectInput | null,
  reportable?: boolean | null,
  _version?: number | null,
};

export type ModelPhotoConditionInput = {
  groupId?: ModelStringInput | null,
  projectId?: ModelIDInput | null,
  inspectionId?: ModelIDInput | null,
  json?: ModelStringInput | null,
  text?: ModelStringInput | null,
  reportable?: ModelBooleanInput | null,
  and?: Array< ModelPhotoConditionInput | null > | null,
  or?: Array< ModelPhotoConditionInput | null > | null,
  not?: ModelPhotoConditionInput | null,
};

export type UpdatePhotoInput = {
  id: string,
  groupId?: string | null,
  projectId?: string | null,
  inspectionId?: string | null,
  json?: string | null,
  text?: string | null,
  file?: S3ObjectInput | null,
  reportable?: boolean | null,
  _version?: number | null,
};

export type DeletePhotoInput = {
  id: string,
  _version?: number | null,
};

export type CreateInspectionPhotoInput = {
  id?: string | null,
  groupId?: string | null,
  projectId?: string | null,
  inspectionId: string,
  photoId: string,
  _version?: number | null,
};

export type ModelInspectionPhotoConditionInput = {
  groupId?: ModelStringInput | null,
  projectId?: ModelIDInput | null,
  inspectionId?: ModelIDInput | null,
  photoId?: ModelIDInput | null,
  and?: Array< ModelInspectionPhotoConditionInput | null > | null,
  or?: Array< ModelInspectionPhotoConditionInput | null > | null,
  not?: ModelInspectionPhotoConditionInput | null,
};

export type UpdateInspectionPhotoInput = {
  id: string,
  groupId?: string | null,
  projectId?: string | null,
  inspectionId?: string | null,
  photoId?: string | null,
  _version?: number | null,
};

export type DeleteInspectionPhotoInput = {
  id: string,
  _version?: number | null,
};

export type CreateLocationPhotoInput = {
  id?: string | null,
  groupId?: string | null,
  projectId?: string | null,
  locationId: string,
  photoId: string,
  _version?: number | null,
};

export type ModelLocationPhotoConditionInput = {
  groupId?: ModelStringInput | null,
  projectId?: ModelIDInput | null,
  locationId?: ModelIDInput | null,
  photoId?: ModelIDInput | null,
  and?: Array< ModelLocationPhotoConditionInput | null > | null,
  or?: Array< ModelLocationPhotoConditionInput | null > | null,
  not?: ModelLocationPhotoConditionInput | null,
};

export type UpdateLocationPhotoInput = {
  id: string,
  groupId?: string | null,
  projectId?: string | null,
  locationId?: string | null,
  photoId?: string | null,
  _version?: number | null,
};

export type DeleteLocationPhotoInput = {
  id: string,
  _version?: number | null,
};

export type CreateFindingInput = {
  id?: string | null,
  groupId?: string | null,
  type: FindingType,
  projectId: string,
  phaseId?: string | null,
  inspectionId: string,
  hierarchyItemId: string,
  observationId: string,
  status: FindingStatus,
  date?: string | null,
  closedDate?: string | null,
  code?: string | null,
  owner?: string | null,
  _version?: number | null,
  findingClosingObservationId?: string | null,
};

export type ModelFindingConditionInput = {
  groupId?: ModelStringInput | null,
  type?: ModelFindingTypeInput | null,
  projectId?: ModelIDInput | null,
  phaseId?: ModelIDInput | null,
  inspectionId?: ModelIDInput | null,
  hierarchyItemId?: ModelIDInput | null,
  observationId?: ModelIDInput | null,
  status?: ModelFindingStatusInput | null,
  date?: ModelStringInput | null,
  closedDate?: ModelStringInput | null,
  code?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelFindingConditionInput | null > | null,
  or?: Array< ModelFindingConditionInput | null > | null,
  not?: ModelFindingConditionInput | null,
  findingClosingObservationId?: ModelIDInput | null,
};

export type UpdateFindingInput = {
  id: string,
  groupId?: string | null,
  type?: FindingType | null,
  projectId?: string | null,
  phaseId?: string | null,
  inspectionId?: string | null,
  hierarchyItemId?: string | null,
  observationId?: string | null,
  status?: FindingStatus | null,
  date?: string | null,
  closedDate?: string | null,
  code?: string | null,
  owner?: string | null,
  _version?: number | null,
  findingClosingObservationId?: string | null,
};

export type DeleteFindingInput = {
  id: string,
  _version?: number | null,
};

export type CreateHierarchyBundleInput = {
  id?: string | null,
  name: string,
  type: string,
  status: string,
  hierarchyItemIds?: string | null,
  _version?: number | null,
};

export type ModelHierarchyBundleConditionInput = {
  name?: ModelStringInput | null,
  type?: ModelStringInput | null,
  status?: ModelStringInput | null,
  hierarchyItemIds?: ModelStringInput | null,
  and?: Array< ModelHierarchyBundleConditionInput | null > | null,
  or?: Array< ModelHierarchyBundleConditionInput | null > | null,
  not?: ModelHierarchyBundleConditionInput | null,
};

export type HierarchyBundle = {
  __typename: "HierarchyBundle",
  id: string,
  name: string,
  type: string,
  status: string,
  hierarchyItemIds?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  owner?: string | null,
};

export type UpdateHierarchyBundleInput = {
  id: string,
  name?: string | null,
  type?: string | null,
  status?: string | null,
  hierarchyItemIds?: string | null,
  _version?: number | null,
};

export type DeleteHierarchyBundleInput = {
  id: string,
  _version?: number | null,
};

export type CreateMilestoneTemplateInput = {
  id?: string | null,
  name: string,
  status: string,
  hierarchyBundleIds?: string | null,
  _version?: number | null,
};

export type ModelMilestoneTemplateConditionInput = {
  name?: ModelStringInput | null,
  status?: ModelStringInput | null,
  hierarchyBundleIds?: ModelStringInput | null,
  and?: Array< ModelMilestoneTemplateConditionInput | null > | null,
  or?: Array< ModelMilestoneTemplateConditionInput | null > | null,
  not?: ModelMilestoneTemplateConditionInput | null,
};

export type UpdateMilestoneTemplateInput = {
  id: string,
  name?: string | null,
  status?: string | null,
  hierarchyBundleIds?: string | null,
  _version?: number | null,
};

export type DeleteMilestoneTemplateInput = {
  id: string,
  _version?: number | null,
};

export type CreateActivityStreamInput = {
  id?: string | null,
  modelType: string,
  instanceId: string,
  userId: string,
  date: string,
  type: string,
  text?: string | null,
  _version?: number | null,
};

export type ModelActivityStreamConditionInput = {
  modelType?: ModelStringInput | null,
  instanceId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  date?: ModelStringInput | null,
  type?: ModelStringInput | null,
  text?: ModelStringInput | null,
  and?: Array< ModelActivityStreamConditionInput | null > | null,
  or?: Array< ModelActivityStreamConditionInput | null > | null,
  not?: ModelActivityStreamConditionInput | null,
};

export type ActivityStream = {
  __typename: "ActivityStream",
  id: string,
  modelType: string,
  instanceId: string,
  userId: string,
  user?: User | null,
  date: string,
  type: string,
  text?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  owner?: string | null,
};

export type UpdateActivityStreamInput = {
  id: string,
  modelType?: string | null,
  instanceId?: string | null,
  userId?: string | null,
  date?: string | null,
  type?: string | null,
  text?: string | null,
  _version?: number | null,
};

export type DeleteActivityStreamInput = {
  id: string,
  _version?: number | null,
};

export type ModelExternalLinkFilterInput = {
  id?: ModelIDInput | null,
  groupId?: ModelStringInput | null,
  projectId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  url?: ModelStringInput | null,
  and?: Array< ModelExternalLinkFilterInput | null > | null,
  or?: Array< ModelExternalLinkFilterInput | null > | null,
  not?: ModelExternalLinkFilterInput | null,
};

export type ModelContactFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  companyName?: ModelStringInput | null,
  jobTitle?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  email?: ModelStringInput | null,
  and?: Array< ModelContactFilterInput | null > | null,
  or?: Array< ModelContactFilterInput | null > | null,
  not?: ModelContactFilterInput | null,
};

export type ModelContactConnection = {
  __typename: "ModelContactConnection",
  items:  Array<Contact | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type SearchableContactAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableContactAggregateField,
};

export enum SearchableAggregateType {
  terms = "terms",
  avg = "avg",
  min = "min",
  max = "max",
  sum = "sum",
}


export enum SearchableContactAggregateField {
  id = "id",
  type = "type",
  firstName = "firstName",
  lastName = "lastName",
  companyName = "companyName",
  jobTitle = "jobTitle",
  phone = "phone",
  email = "email",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  status?: ModelStringInput | null,
  type?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  companyName?: ModelStringInput | null,
  jobTitle?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  email?: ModelStringInput | null,
  metadata?: ModelStringInput | null,
  projects?: ModelStringInput | null,
  favoriteProjects?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type SearchableUserFilterInput = {
  id?: SearchableIDFilterInput | null,
  status?: SearchableStringFilterInput | null,
  type?: SearchableStringFilterInput | null,
  firstName?: SearchableStringFilterInput | null,
  lastName?: SearchableStringFilterInput | null,
  companyName?: SearchableStringFilterInput | null,
  jobTitle?: SearchableStringFilterInput | null,
  phone?: SearchableStringFilterInput | null,
  email?: SearchableStringFilterInput | null,
  metadata?: SearchableStringFilterInput | null,
  projects?: SearchableStringFilterInput | null,
  favoriteProjects?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  _version?: SearchableIntFilterInput | null,
  _deleted?: SearchableBooleanFilterInput | null,
  _lastChangedAt?: SearchableIntFilterInput | null,
  and?: Array< SearchableUserFilterInput | null > | null,
  or?: Array< SearchableUserFilterInput | null > | null,
  not?: SearchableUserFilterInput | null,
};

export type SearchableUserSortInput = {
  field?: SearchableUserSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableUserSortableFields {
  id = "id",
  status = "status",
  type = "type",
  firstName = "firstName",
  lastName = "lastName",
  companyName = "companyName",
  jobTitle = "jobTitle",
  phone = "phone",
  email = "email",
  metadata = "metadata",
  projects = "projects",
  favoriteProjects = "favoriteProjects",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type SearchableUserAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableUserAggregateField,
};

export enum SearchableUserAggregateField {
  id = "id",
  status = "status",
  type = "type",
  firstName = "firstName",
  lastName = "lastName",
  companyName = "companyName",
  jobTitle = "jobTitle",
  phone = "phone",
  email = "email",
  metadata = "metadata",
  projects = "projects",
  favoriteProjects = "favoriteProjects",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type SearchableUserConnection = {
  __typename: "SearchableUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelClientFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  code?: ModelStringInput | null,
  status?: ModelStringInput | null,
  reportDisclaimer?: ModelStringInput | null,
  and?: Array< ModelClientFilterInput | null > | null,
  or?: Array< ModelClientFilterInput | null > | null,
  not?: ModelClientFilterInput | null,
  clientPrimaryContactId?: ModelIDInput | null,
};

export type ModelClientConnection = {
  __typename: "ModelClientConnection",
  items:  Array<Client | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type SearchableClientFilterInput = {
  id?: SearchableIDFilterInput | null,
  name?: SearchableStringFilterInput | null,
  code?: SearchableStringFilterInput | null,
  status?: SearchableStringFilterInput | null,
  reportDisclaimer?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  _version?: SearchableIntFilterInput | null,
  _deleted?: SearchableBooleanFilterInput | null,
  _lastChangedAt?: SearchableIntFilterInput | null,
  clientPrimaryContactId?: SearchableIDFilterInput | null,
  and?: Array< SearchableClientFilterInput | null > | null,
  or?: Array< SearchableClientFilterInput | null > | null,
  not?: SearchableClientFilterInput | null,
};

export type SearchableClientSortInput = {
  field?: SearchableClientSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableClientSortableFields {
  id = "id",
  name = "name",
  code = "code",
  status = "status",
  reportDisclaimer = "reportDisclaimer",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
  clientPrimaryContactId = "clientPrimaryContactId",
}


export type SearchableClientAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableClientAggregateField,
};

export enum SearchableClientAggregateField {
  id = "id",
  name = "name",
  code = "code",
  status = "status",
  reportDisclaimer = "reportDisclaimer",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
  clientPrimaryContactId = "clientPrimaryContactId",
}


export type SearchableClientConnection = {
  __typename: "SearchableClientConnection",
  items:  Array<Client | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelClientContactFilterInput = {
  id?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  contactId?: ModelIDInput | null,
  label?: ModelStringInput | null,
  and?: Array< ModelClientContactFilterInput | null > | null,
  or?: Array< ModelClientContactFilterInput | null > | null,
  not?: ModelClientContactFilterInput | null,
};

export type ModelProjectFilterInput = {
  id?: ModelIDInput | null,
  groupId?: ModelStringInput | null,
  name?: ModelStringInput | null,
  status?: ModelStringInput | null,
  clientId?: ModelIDInput | null,
  reportTemplateId?: ModelStringInput | null,
  reportDisclaimer?: ModelStringInput | null,
  distributionList?: ModelStringInput | null,
  hierarchyItemExclusions?: ModelStringInput | null,
  exclusions?: ModelStringInput | null,
  notes?: ModelStringInput | null,
  code?: ModelStringInput | null,
  milestoneTemplateId?: ModelIDInput | null,
  externalId?: ModelStringInput | null,
  and?: Array< ModelProjectFilterInput | null > | null,
  or?: Array< ModelProjectFilterInput | null > | null,
  not?: ModelProjectFilterInput | null,
  projectEftId?: ModelIDInput | null,
  projectReviewerId?: ModelIDInput | null,
  projectAdminId?: ModelIDInput | null,
  projectPrimaryContactId?: ModelIDInput | null,
};

export type SearchableProjectFilterInput = {
  id?: SearchableIDFilterInput | null,
  groupId?: SearchableStringFilterInput | null,
  name?: SearchableStringFilterInput | null,
  status?: SearchableStringFilterInput | null,
  clientId?: SearchableIDFilterInput | null,
  reportTemplateId?: SearchableStringFilterInput | null,
  reportDisclaimer?: SearchableStringFilterInput | null,
  distributionList?: SearchableStringFilterInput | null,
  hierarchyItemExclusions?: SearchableStringFilterInput | null,
  exclusions?: SearchableStringFilterInput | null,
  notes?: SearchableStringFilterInput | null,
  code?: SearchableStringFilterInput | null,
  milestoneTemplateId?: SearchableIDFilterInput | null,
  externalId?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  _version?: SearchableIntFilterInput | null,
  _deleted?: SearchableBooleanFilterInput | null,
  _lastChangedAt?: SearchableIntFilterInput | null,
  projectEftId?: SearchableIDFilterInput | null,
  projectReviewerId?: SearchableIDFilterInput | null,
  projectAdminId?: SearchableIDFilterInput | null,
  projectPrimaryContactId?: SearchableIDFilterInput | null,
  and?: Array< SearchableProjectFilterInput | null > | null,
  or?: Array< SearchableProjectFilterInput | null > | null,
  not?: SearchableProjectFilterInput | null,
};

export type SearchableProjectSortInput = {
  field?: SearchableProjectSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableProjectSortableFields {
  id = "id",
  groupId = "groupId",
  name = "name",
  status = "status",
  clientId = "clientId",
  reportTemplateId = "reportTemplateId",
  reportDisclaimer = "reportDisclaimer",
  distributionList = "distributionList",
  hierarchyItemExclusions = "hierarchyItemExclusions",
  exclusions = "exclusions",
  notes = "notes",
  code = "code",
  milestoneTemplateId = "milestoneTemplateId",
  externalId = "externalId",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
  projectEftId = "projectEftId",
  projectReviewerId = "projectReviewerId",
  projectAdminId = "projectAdminId",
  projectPrimaryContactId = "projectPrimaryContactId",
}


export type SearchableProjectAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableProjectAggregateField,
};

export enum SearchableProjectAggregateField {
  id = "id",
  groupId = "groupId",
  name = "name",
  status = "status",
  clientId = "clientId",
  reportTemplateId = "reportTemplateId",
  reportDisclaimer = "reportDisclaimer",
  distributionList = "distributionList",
  hierarchyItemExclusions = "hierarchyItemExclusions",
  exclusions = "exclusions",
  notes = "notes",
  code = "code",
  milestoneTemplateId = "milestoneTemplateId",
  externalId = "externalId",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
  projectEftId = "projectEftId",
  projectReviewerId = "projectReviewerId",
  projectAdminId = "projectAdminId",
  projectPrimaryContactId = "projectPrimaryContactId",
}


export type SearchableProjectConnection = {
  __typename: "SearchableProjectConnection",
  items:  Array<Project | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelPhaseFilterInput = {
  id?: ModelIDInput | null,
  groupId?: ModelStringInput | null,
  projectId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  status?: ModelStringInput | null,
  milestoneTemplateId?: ModelIDInput | null,
  and?: Array< ModelPhaseFilterInput | null > | null,
  or?: Array< ModelPhaseFilterInput | null > | null,
  not?: ModelPhaseFilterInput | null,
};

export type SearchablePhaseFilterInput = {
  id?: SearchableIDFilterInput | null,
  groupId?: SearchableStringFilterInput | null,
  projectId?: SearchableIDFilterInput | null,
  name?: SearchableStringFilterInput | null,
  status?: SearchableStringFilterInput | null,
  milestoneTemplateId?: SearchableIDFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  _version?: SearchableIntFilterInput | null,
  _deleted?: SearchableBooleanFilterInput | null,
  _lastChangedAt?: SearchableIntFilterInput | null,
  and?: Array< SearchablePhaseFilterInput | null > | null,
  or?: Array< SearchablePhaseFilterInput | null > | null,
  not?: SearchablePhaseFilterInput | null,
};

export type SearchablePhaseSortInput = {
  field?: SearchablePhaseSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchablePhaseSortableFields {
  id = "id",
  groupId = "groupId",
  projectId = "projectId",
  name = "name",
  status = "status",
  milestoneTemplateId = "milestoneTemplateId",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type SearchablePhaseAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchablePhaseAggregateField,
};

export enum SearchablePhaseAggregateField {
  id = "id",
  groupId = "groupId",
  projectId = "projectId",
  name = "name",
  status = "status",
  milestoneTemplateId = "milestoneTemplateId",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type SearchablePhaseConnection = {
  __typename: "SearchablePhaseConnection",
  items:  Array<Phase | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelProjectContactFilterInput = {
  id?: ModelIDInput | null,
  groupId?: ModelStringInput | null,
  projectId?: ModelIDInput | null,
  contactId?: ModelIDInput | null,
  label?: ModelStringInput | null,
  and?: Array< ModelProjectContactFilterInput | null > | null,
  or?: Array< ModelProjectContactFilterInput | null > | null,
  not?: ModelProjectContactFilterInput | null,
};

export type ModelFavoriteProjectFilterInput = {
  id?: ModelIDInput | null,
  projectId?: ModelIDInput | null,
  and?: Array< ModelFavoriteProjectFilterInput | null > | null,
  or?: Array< ModelFavoriteProjectFilterInput | null > | null,
  not?: ModelFavoriteProjectFilterInput | null,
};

export type ModelFavoriteProjectConnection = {
  __typename: "ModelFavoriteProjectConnection",
  items:  Array<FavoriteProject | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelInspectionFilterInput = {
  id?: ModelIDInput | null,
  groupId?: ModelStringInput | null,
  clientId?: ModelIDInput | null,
  projectId?: ModelIDInput | null,
  phaseId?: ModelIDInput | null,
  photoSequence?: ModelIDInput | null,
  status?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  inspectionReviewerId?: ModelIDInput | null,
  inspectionDate?: ModelStringInput | null,
  reviewedDate?: ModelStringInput | null,
  deliveredDate?: ModelStringInput | null,
  nextInspectionDate?: ModelStringInput | null,
  arrivalTime?: ModelStringInput | null,
  notes?: ModelStringInput | null,
  coverLetter?: ModelStringInput | null,
  scopeHierarchyItems?: ModelStringInput | null,
  scope?: ModelStringInput | null,
  number?: ModelStringInput | null,
  code?: ModelStringInput | null,
  weather?: ModelStringInput | null,
  milestoneBundleIds?: ModelStringInput | null,
  and?: Array< ModelInspectionFilterInput | null > | null,
  or?: Array< ModelInspectionFilterInput | null > | null,
  not?: ModelInspectionFilterInput | null,
  inspectionCheckInContactId?: ModelIDInput | null,
};

export type SearchableInspectionFilterInput = {
  id?: SearchableIDFilterInput | null,
  groupId?: SearchableStringFilterInput | null,
  clientId?: SearchableIDFilterInput | null,
  projectId?: SearchableIDFilterInput | null,
  phaseId?: SearchableIDFilterInput | null,
  photoSequence?: SearchableIDFilterInput | null,
  status?: SearchableStringFilterInput | null,
  userId?: SearchableIDFilterInput | null,
  inspectionReviewerId?: SearchableIDFilterInput | null,
  inspectionDate?: SearchableStringFilterInput | null,
  reviewedDate?: SearchableStringFilterInput | null,
  deliveredDate?: SearchableStringFilterInput | null,
  nextInspectionDate?: SearchableStringFilterInput | null,
  arrivalTime?: SearchableStringFilterInput | null,
  notes?: SearchableStringFilterInput | null,
  coverLetter?: SearchableStringFilterInput | null,
  scopeHierarchyItems?: SearchableStringFilterInput | null,
  scope?: SearchableStringFilterInput | null,
  number?: SearchableStringFilterInput | null,
  code?: SearchableStringFilterInput | null,
  weather?: SearchableStringFilterInput | null,
  milestoneBundleIds?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  _version?: SearchableIntFilterInput | null,
  _deleted?: SearchableBooleanFilterInput | null,
  _lastChangedAt?: SearchableIntFilterInput | null,
  inspectionCheckInContactId?: SearchableIDFilterInput | null,
  and?: Array< SearchableInspectionFilterInput | null > | null,
  or?: Array< SearchableInspectionFilterInput | null > | null,
  not?: SearchableInspectionFilterInput | null,
};

export type SearchableInspectionSortInput = {
  field?: SearchableInspectionSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableInspectionSortableFields {
  id = "id",
  groupId = "groupId",
  clientId = "clientId",
  projectId = "projectId",
  phaseId = "phaseId",
  photoSequence = "photoSequence",
  status = "status",
  userId = "userId",
  inspectionReviewerId = "inspectionReviewerId",
  inspectionDate = "inspectionDate",
  reviewedDate = "reviewedDate",
  deliveredDate = "deliveredDate",
  nextInspectionDate = "nextInspectionDate",
  arrivalTime = "arrivalTime",
  notes = "notes",
  coverLetter = "coverLetter",
  scopeHierarchyItems = "scopeHierarchyItems",
  scope = "scope",
  number = "number",
  code = "code",
  weather = "weather",
  milestoneBundleIds = "milestoneBundleIds",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
  inspectionCheckInContactId = "inspectionCheckInContactId",
}


export type SearchableInspectionAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableInspectionAggregateField,
};

export enum SearchableInspectionAggregateField {
  id = "id",
  groupId = "groupId",
  clientId = "clientId",
  projectId = "projectId",
  phaseId = "phaseId",
  photoSequence = "photoSequence",
  status = "status",
  userId = "userId",
  inspectionReviewerId = "inspectionReviewerId",
  inspectionDate = "inspectionDate",
  reviewedDate = "reviewedDate",
  deliveredDate = "deliveredDate",
  nextInspectionDate = "nextInspectionDate",
  arrivalTime = "arrivalTime",
  notes = "notes",
  coverLetter = "coverLetter",
  scopeHierarchyItems = "scopeHierarchyItems",
  scope = "scope",
  number = "number",
  code = "code",
  weather = "weather",
  milestoneBundleIds = "milestoneBundleIds",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
  inspectionCheckInContactId = "inspectionCheckInContactId",
}


export type ModelHierarchyFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  and?: Array< ModelHierarchyFilterInput | null > | null,
  or?: Array< ModelHierarchyFilterInput | null > | null,
  not?: ModelHierarchyFilterInput | null,
};

export type ModelHierarchyConnection = {
  __typename: "ModelHierarchyConnection",
  items:  Array<Hierarchy | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelHierarchyItemFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  code?: ModelStringInput | null,
  hierarchyId?: ModelIDInput | null,
  parentId?: ModelIDInput | null,
  hidden?: ModelBooleanInput | null,
  and?: Array< ModelHierarchyItemFilterInput | null > | null,
  or?: Array< ModelHierarchyItemFilterInput | null > | null,
  not?: ModelHierarchyItemFilterInput | null,
};

export type ModelInspectionHierarchyItemFilterInput = {
  id?: ModelIDInput | null,
  inspectionId?: ModelIDInput | null,
  hierarchyItemId?: ModelIDInput | null,
  notes?: ModelStringInput | null,
  and?: Array< ModelInspectionHierarchyItemFilterInput | null > | null,
  or?: Array< ModelInspectionHierarchyItemFilterInput | null > | null,
  not?: ModelInspectionHierarchyItemFilterInput | null,
};

export type ModelInspectionPointFilterInput = {
  id?: ModelIDInput | null,
  groupId?: ModelStringInput | null,
  hierarchyItemId?: ModelIDInput | null,
  type?: ModelInspectionPointTypeInput | null,
  projectId?: ModelIDInput | null,
  text?: ModelStringInput | null,
  code?: ModelStringInput | null,
  hidden?: ModelBooleanInput | null,
  and?: Array< ModelInspectionPointFilterInput | null > | null,
  or?: Array< ModelInspectionPointFilterInput | null > | null,
  not?: ModelInspectionPointFilterInput | null,
};

export type SearchableInspectionPointAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableInspectionPointAggregateField,
};

export enum SearchableInspectionPointAggregateField {
  id = "id",
  groupId = "groupId",
  hierarchyItemId = "hierarchyItemId",
  type = "type",
  projectId = "projectId",
  text = "text",
  code = "code",
  hidden = "hidden",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type ModelCommentFilterInput = {
  id?: ModelIDInput | null,
  groupId?: ModelStringInput | null,
  projectId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  date?: ModelStringInput | null,
  text?: ModelStringInput | null,
  and?: Array< ModelCommentFilterInput | null > | null,
  or?: Array< ModelCommentFilterInput | null > | null,
  not?: ModelCommentFilterInput | null,
};

export type ModelCommentConnection = {
  __typename: "ModelCommentConnection",
  items:  Array<Comment | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelInspectionCommentFilterInput = {
  id?: ModelIDInput | null,
  groupId?: ModelStringInput | null,
  projectId?: ModelIDInput | null,
  inspectionId?: ModelIDInput | null,
  commentId?: ModelIDInput | null,
  and?: Array< ModelInspectionCommentFilterInput | null > | null,
  or?: Array< ModelInspectionCommentFilterInput | null > | null,
  not?: ModelInspectionCommentFilterInput | null,
};

export type ModelObservationFilterInput = {
  id?: ModelIDInput | null,
  groupId?: ModelStringInput | null,
  type?: ModelObservationTypeInput | null,
  projectId?: ModelIDInput | null,
  inspectionId?: ModelIDInput | null,
  hierarchyItemId?: ModelIDInput | null,
  inspectionPointId?: ModelIDInput | null,
  text?: ModelStringInput | null,
  suggestion?: ModelStringInput | null,
  findingId?: ModelIDInput | null,
  status?: ModelStringInput | null,
  and?: Array< ModelObservationFilterInput | null > | null,
  or?: Array< ModelObservationFilterInput | null > | null,
  not?: ModelObservationFilterInput | null,
  observationPrimaryLocationId?: ModelIDInput | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelLocationFilterInput = {
  id?: ModelIDInput | null,
  groupId?: ModelStringInput | null,
  projectId?: ModelIDInput | null,
  observationId?: ModelIDInput | null,
  photoSequence?: ModelIDInput | null,
  and?: Array< ModelLocationFilterInput | null > | null,
  or?: Array< ModelLocationFilterInput | null > | null,
  not?: ModelLocationFilterInput | null,
  locationPrimaryPhotoId?: ModelIDInput | null,
};

export type ModelTagFilterInput = {
  id?: ModelIDInput | null,
  groupId?: ModelStringInput | null,
  type?: ModelTagTypeInput | null,
  projectId?: ModelIDInput | null,
  text?: ModelStringInput | null,
  and?: Array< ModelTagFilterInput | null > | null,
  or?: Array< ModelTagFilterInput | null > | null,
  not?: ModelTagFilterInput | null,
};

export type ModelLocationTagFilterInput = {
  id?: ModelIDInput | null,
  groupId?: ModelStringInput | null,
  projectId?: ModelIDInput | null,
  locationId?: ModelIDInput | null,
  tagId?: ModelIDInput | null,
  and?: Array< ModelLocationTagFilterInput | null > | null,
  or?: Array< ModelLocationTagFilterInput | null > | null,
  not?: ModelLocationTagFilterInput | null,
};

export type ModelPhotoFilterInput = {
  id?: ModelIDInput | null,
  groupId?: ModelStringInput | null,
  projectId?: ModelIDInput | null,
  inspectionId?: ModelIDInput | null,
  json?: ModelStringInput | null,
  text?: ModelStringInput | null,
  reportable?: ModelBooleanInput | null,
  and?: Array< ModelPhotoFilterInput | null > | null,
  or?: Array< ModelPhotoFilterInput | null > | null,
  not?: ModelPhotoFilterInput | null,
};

export type ModelPhotoConnection = {
  __typename: "ModelPhotoConnection",
  items:  Array<Photo | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelInspectionPhotoFilterInput = {
  id?: ModelIDInput | null,
  groupId?: ModelStringInput | null,
  projectId?: ModelIDInput | null,
  inspectionId?: ModelIDInput | null,
  photoId?: ModelIDInput | null,
  and?: Array< ModelInspectionPhotoFilterInput | null > | null,
  or?: Array< ModelInspectionPhotoFilterInput | null > | null,
  not?: ModelInspectionPhotoFilterInput | null,
};

export type ModelLocationPhotoFilterInput = {
  id?: ModelIDInput | null,
  groupId?: ModelStringInput | null,
  projectId?: ModelIDInput | null,
  locationId?: ModelIDInput | null,
  photoId?: ModelIDInput | null,
  and?: Array< ModelLocationPhotoFilterInput | null > | null,
  or?: Array< ModelLocationPhotoFilterInput | null > | null,
  not?: ModelLocationPhotoFilterInput | null,
};

export type ModelHierarchyBundleFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  type?: ModelStringInput | null,
  status?: ModelStringInput | null,
  hierarchyItemIds?: ModelStringInput | null,
  and?: Array< ModelHierarchyBundleFilterInput | null > | null,
  or?: Array< ModelHierarchyBundleFilterInput | null > | null,
  not?: ModelHierarchyBundleFilterInput | null,
};

export type ModelHierarchyBundleConnection = {
  __typename: "ModelHierarchyBundleConnection",
  items:  Array<HierarchyBundle | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type SearchableHierarchyBundleFilterInput = {
  id?: SearchableIDFilterInput | null,
  name?: SearchableStringFilterInput | null,
  type?: SearchableStringFilterInput | null,
  status?: SearchableStringFilterInput | null,
  hierarchyItemIds?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  _version?: SearchableIntFilterInput | null,
  _deleted?: SearchableBooleanFilterInput | null,
  _lastChangedAt?: SearchableIntFilterInput | null,
  and?: Array< SearchableHierarchyBundleFilterInput | null > | null,
  or?: Array< SearchableHierarchyBundleFilterInput | null > | null,
  not?: SearchableHierarchyBundleFilterInput | null,
};

export type SearchableHierarchyBundleSortInput = {
  field?: SearchableHierarchyBundleSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableHierarchyBundleSortableFields {
  id = "id",
  name = "name",
  type = "type",
  status = "status",
  hierarchyItemIds = "hierarchyItemIds",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type SearchableHierarchyBundleAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableHierarchyBundleAggregateField,
};

export enum SearchableHierarchyBundleAggregateField {
  id = "id",
  name = "name",
  type = "type",
  status = "status",
  hierarchyItemIds = "hierarchyItemIds",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type SearchableHierarchyBundleConnection = {
  __typename: "SearchableHierarchyBundleConnection",
  items:  Array<HierarchyBundle | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelMilestoneTemplateFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  status?: ModelStringInput | null,
  hierarchyBundleIds?: ModelStringInput | null,
  and?: Array< ModelMilestoneTemplateFilterInput | null > | null,
  or?: Array< ModelMilestoneTemplateFilterInput | null > | null,
  not?: ModelMilestoneTemplateFilterInput | null,
};

export type ModelMilestoneTemplateConnection = {
  __typename: "ModelMilestoneTemplateConnection",
  items:  Array<MilestoneTemplate | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type SearchableMilestoneTemplateFilterInput = {
  id?: SearchableIDFilterInput | null,
  name?: SearchableStringFilterInput | null,
  status?: SearchableStringFilterInput | null,
  hierarchyBundleIds?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  _version?: SearchableIntFilterInput | null,
  _deleted?: SearchableBooleanFilterInput | null,
  _lastChangedAt?: SearchableIntFilterInput | null,
  and?: Array< SearchableMilestoneTemplateFilterInput | null > | null,
  or?: Array< SearchableMilestoneTemplateFilterInput | null > | null,
  not?: SearchableMilestoneTemplateFilterInput | null,
};

export type SearchableMilestoneTemplateSortInput = {
  field?: SearchableMilestoneTemplateSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableMilestoneTemplateSortableFields {
  id = "id",
  name = "name",
  status = "status",
  hierarchyBundleIds = "hierarchyBundleIds",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type SearchableMilestoneTemplateAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableMilestoneTemplateAggregateField,
};

export enum SearchableMilestoneTemplateAggregateField {
  id = "id",
  name = "name",
  status = "status",
  hierarchyBundleIds = "hierarchyBundleIds",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type SearchableMilestoneTemplateConnection = {
  __typename: "SearchableMilestoneTemplateConnection",
  items:  Array<MilestoneTemplate | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelActivityStreamFilterInput = {
  id?: ModelIDInput | null,
  modelType?: ModelStringInput | null,
  instanceId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  date?: ModelStringInput | null,
  type?: ModelStringInput | null,
  text?: ModelStringInput | null,
  and?: Array< ModelActivityStreamFilterInput | null > | null,
  or?: Array< ModelActivityStreamFilterInput | null > | null,
  not?: ModelActivityStreamFilterInput | null,
};

export type ModelActivityStreamConnection = {
  __typename: "ModelActivityStreamConnection",
  items:  Array<ActivityStream | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type SearchableActivityStreamFilterInput = {
  id?: SearchableIDFilterInput | null,
  modelType?: SearchableStringFilterInput | null,
  instanceId?: SearchableIDFilterInput | null,
  userId?: SearchableIDFilterInput | null,
  date?: SearchableStringFilterInput | null,
  type?: SearchableStringFilterInput | null,
  text?: SearchableStringFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  _version?: SearchableIntFilterInput | null,
  _deleted?: SearchableBooleanFilterInput | null,
  _lastChangedAt?: SearchableIntFilterInput | null,
  and?: Array< SearchableActivityStreamFilterInput | null > | null,
  or?: Array< SearchableActivityStreamFilterInput | null > | null,
  not?: SearchableActivityStreamFilterInput | null,
};

export type SearchableActivityStreamSortInput = {
  field?: SearchableActivityStreamSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableActivityStreamSortableFields {
  id = "id",
  modelType = "modelType",
  instanceId = "instanceId",
  userId = "userId",
  date = "date",
  type = "type",
  text = "text",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type SearchableActivityStreamAggregationInput = {
  name: string,
  type: SearchableAggregateType,
  field: SearchableActivityStreamAggregateField,
};

export enum SearchableActivityStreamAggregateField {
  id = "id",
  modelType = "modelType",
  instanceId = "instanceId",
  userId = "userId",
  date = "date",
  type = "type",
  text = "text",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  _version = "_version",
  _deleted = "_deleted",
  _lastChangedAt = "_lastChangedAt",
}


export type SearchableActivityStreamConnection = {
  __typename: "SearchableActivityStreamConnection",
  items:  Array<ActivityStream | null >,
  nextToken?: string | null,
  total?: number | null,
  aggregateItems:  Array<SearchableAggregateResult | null >,
};

export type ModelSubscriptionExternalLinkFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  projectId?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  url?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionExternalLinkFilterInput | null > | null,
  or?: Array< ModelSubscriptionExternalLinkFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionPhaseFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  projectId?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  milestoneTemplateId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionPhaseFilterInput | null > | null,
  or?: Array< ModelSubscriptionPhaseFilterInput | null > | null,
};

export type ModelSubscriptionProjectContactFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  projectId?: ModelSubscriptionIDInput | null,
  contactId?: ModelSubscriptionIDInput | null,
  label?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProjectContactFilterInput | null > | null,
  or?: Array< ModelSubscriptionProjectContactFilterInput | null > | null,
};

export type ModelSubscriptionInspectionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  clientId?: ModelSubscriptionIDInput | null,
  projectId?: ModelSubscriptionIDInput | null,
  phaseId?: ModelSubscriptionIDInput | null,
  photoSequence?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  userId?: ModelSubscriptionIDInput | null,
  inspectionReviewerId?: ModelSubscriptionIDInput | null,
  inspectionDate?: ModelSubscriptionStringInput | null,
  reviewedDate?: ModelSubscriptionStringInput | null,
  deliveredDate?: ModelSubscriptionStringInput | null,
  nextInspectionDate?: ModelSubscriptionStringInput | null,
  arrivalTime?: ModelSubscriptionStringInput | null,
  notes?: ModelSubscriptionStringInput | null,
  coverLetter?: ModelSubscriptionStringInput | null,
  scopeHierarchyItems?: ModelSubscriptionStringInput | null,
  scope?: ModelSubscriptionStringInput | null,
  number?: ModelSubscriptionStringInput | null,
  code?: ModelSubscriptionStringInput | null,
  weather?: ModelSubscriptionStringInput | null,
  milestoneBundleIds?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionInspectionFilterInput | null > | null,
  or?: Array< ModelSubscriptionInspectionFilterInput | null > | null,
};

export type ModelSubscriptionCommentFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  projectId?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  date?: ModelSubscriptionStringInput | null,
  text?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCommentFilterInput | null > | null,
  or?: Array< ModelSubscriptionCommentFilterInput | null > | null,
};

export type ModelSubscriptionInspectionCommentFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  projectId?: ModelSubscriptionIDInput | null,
  inspectionId?: ModelSubscriptionIDInput | null,
  commentId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionInspectionCommentFilterInput | null > | null,
  or?: Array< ModelSubscriptionInspectionCommentFilterInput | null > | null,
};

export type ModelSubscriptionObservationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  projectId?: ModelSubscriptionIDInput | null,
  inspectionId?: ModelSubscriptionIDInput | null,
  hierarchyItemId?: ModelSubscriptionIDInput | null,
  inspectionPointId?: ModelSubscriptionIDInput | null,
  text?: ModelSubscriptionStringInput | null,
  suggestion?: ModelSubscriptionStringInput | null,
  findingId?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionObservationFilterInput | null > | null,
  or?: Array< ModelSubscriptionObservationFilterInput | null > | null,
};

export type ModelSubscriptionLocationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  projectId?: ModelSubscriptionIDInput | null,
  observationId?: ModelSubscriptionIDInput | null,
  photoSequence?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionLocationFilterInput | null > | null,
  or?: Array< ModelSubscriptionLocationFilterInput | null > | null,
};

export type ModelSubscriptionTagFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  projectId?: ModelSubscriptionIDInput | null,
  text?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTagFilterInput | null > | null,
  or?: Array< ModelSubscriptionTagFilterInput | null > | null,
};

export type ModelSubscriptionLocationTagFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  projectId?: ModelSubscriptionIDInput | null,
  locationId?: ModelSubscriptionIDInput | null,
  tagId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionLocationTagFilterInput | null > | null,
  or?: Array< ModelSubscriptionLocationTagFilterInput | null > | null,
};

export type ModelSubscriptionPhotoFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  projectId?: ModelSubscriptionIDInput | null,
  inspectionId?: ModelSubscriptionIDInput | null,
  json?: ModelSubscriptionStringInput | null,
  text?: ModelSubscriptionStringInput | null,
  reportable?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionPhotoFilterInput | null > | null,
  or?: Array< ModelSubscriptionPhotoFilterInput | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionInspectionPhotoFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  projectId?: ModelSubscriptionIDInput | null,
  inspectionId?: ModelSubscriptionIDInput | null,
  photoId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionInspectionPhotoFilterInput | null > | null,
  or?: Array< ModelSubscriptionInspectionPhotoFilterInput | null > | null,
};

export type ModelSubscriptionLocationPhotoFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  projectId?: ModelSubscriptionIDInput | null,
  locationId?: ModelSubscriptionIDInput | null,
  photoId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionLocationPhotoFilterInput | null > | null,
  or?: Array< ModelSubscriptionLocationPhotoFilterInput | null > | null,
};

export type ModelSubscriptionFindingFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  projectId?: ModelSubscriptionIDInput | null,
  phaseId?: ModelSubscriptionIDInput | null,
  inspectionId?: ModelSubscriptionIDInput | null,
  hierarchyItemId?: ModelSubscriptionIDInput | null,
  observationId?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  date?: ModelSubscriptionStringInput | null,
  closedDate?: ModelSubscriptionStringInput | null,
  code?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionFindingFilterInput | null > | null,
  or?: Array< ModelSubscriptionFindingFilterInput | null > | null,
};

export type ModelSubscriptionContactFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  firstName?: ModelSubscriptionStringInput | null,
  lastName?: ModelSubscriptionStringInput | null,
  companyName?: ModelSubscriptionStringInput | null,
  jobTitle?: ModelSubscriptionStringInput | null,
  phone?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionContactFilterInput | null > | null,
  or?: Array< ModelSubscriptionContactFilterInput | null > | null,
};

export type ModelSubscriptionUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  firstName?: ModelSubscriptionStringInput | null,
  lastName?: ModelSubscriptionStringInput | null,
  companyName?: ModelSubscriptionStringInput | null,
  jobTitle?: ModelSubscriptionStringInput | null,
  phone?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  metadata?: ModelSubscriptionStringInput | null,
  projects?: ModelSubscriptionStringInput | null,
  favoriteProjects?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserFilterInput | null > | null,
};

export type ModelSubscriptionClientFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  code?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  reportDisclaimer?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionClientFilterInput | null > | null,
  or?: Array< ModelSubscriptionClientFilterInput | null > | null,
};

export type ModelSubscriptionClientContactFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  clientId?: ModelSubscriptionIDInput | null,
  contactId?: ModelSubscriptionIDInput | null,
  label?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionClientContactFilterInput | null > | null,
  or?: Array< ModelSubscriptionClientContactFilterInput | null > | null,
};

export type ModelSubscriptionProjectFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  groupId?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  clientId?: ModelSubscriptionIDInput | null,
  reportTemplateId?: ModelSubscriptionStringInput | null,
  reportDisclaimer?: ModelSubscriptionStringInput | null,
  distributionList?: ModelSubscriptionStringInput | null,
  hierarchyItemExclusions?: ModelSubscriptionStringInput | null,
  exclusions?: ModelSubscriptionStringInput | null,
  notes?: ModelSubscriptionStringInput | null,
  code?: ModelSubscriptionStringInput | null,
  milestoneTemplateId?: ModelSubscriptionIDInput | null,
  externalId?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProjectFilterInput | null > | null,
  or?: Array< ModelSubscriptionProjectFilterInput | null > | null,
};

export type ModelSubscriptionFavoriteProjectFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  projectId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionFavoriteProjectFilterInput | null > | null,
  or?: Array< ModelSubscriptionFavoriteProjectFilterInput | null > | null,
};

export type ModelSubscriptionHierarchyFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionHierarchyFilterInput | null > | null,
  or?: Array< ModelSubscriptionHierarchyFilterInput | null > | null,
};

export type ModelSubscriptionHierarchyItemFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  code?: ModelSubscriptionStringInput | null,
  hierarchyId?: ModelSubscriptionIDInput | null,
  parentId?: ModelSubscriptionIDInput | null,
  hidden?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionHierarchyItemFilterInput | null > | null,
  or?: Array< ModelSubscriptionHierarchyItemFilterInput | null > | null,
};

export type ModelSubscriptionInspectionHierarchyItemFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  inspectionId?: ModelSubscriptionIDInput | null,
  hierarchyItemId?: ModelSubscriptionIDInput | null,
  notes?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionInspectionHierarchyItemFilterInput | null > | null,
  or?: Array< ModelSubscriptionInspectionHierarchyItemFilterInput | null > | null,
};

export type ModelSubscriptionInspectionPointFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  groupId?: ModelSubscriptionStringInput | null,
  hierarchyItemId?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  projectId?: ModelSubscriptionIDInput | null,
  text?: ModelSubscriptionStringInput | null,
  code?: ModelSubscriptionStringInput | null,
  hidden?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionInspectionPointFilterInput | null > | null,
  or?: Array< ModelSubscriptionInspectionPointFilterInput | null > | null,
};

export type ModelSubscriptionHierarchyBundleFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  hierarchyItemIds?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionHierarchyBundleFilterInput | null > | null,
  or?: Array< ModelSubscriptionHierarchyBundleFilterInput | null > | null,
};

export type ModelSubscriptionMilestoneTemplateFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  hierarchyBundleIds?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMilestoneTemplateFilterInput | null > | null,
  or?: Array< ModelSubscriptionMilestoneTemplateFilterInput | null > | null,
};

export type ModelSubscriptionActivityStreamFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  modelType?: ModelSubscriptionStringInput | null,
  instanceId?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  date?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  text?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionActivityStreamFilterInput | null > | null,
  or?: Array< ModelSubscriptionActivityStreamFilterInput | null > | null,
};

export type CustomGetLocationQueryVariables = {
  id: string,
};

export type CustomGetLocationQuery = {
  getLocation?:  {
    __typename: "Location",
    id: string,
    projectId?: string | null,
    groupId?: string | null,
    observationId: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    photoSequence?: Array< string | null > | null,
    tags?:  {
      __typename: "ModelLocationTagConnection",
      items:  Array< {
        __typename: "LocationTag",
        id: string,
        projectId?: string | null,
        locationId: string,
        tagId: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    primaryPhoto?:  {
      __typename: "Photo",
      id: string,
      projectId?: string | null,
      inspectionId: string,
      json?: string | null,
      text?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    photos?:  {
      __typename: "ModelLocationPhotoConnection",
      items:  Array< {
        __typename: "LocationPhoto",
        id: string,
        projectId?: string | null,
        locationId: string,
        photo?:  {
          __typename: "Photo",
          _version: number,
          id: string,
          reportable?: boolean | null,
          file?:  {
            __typename: "S3Object",
            bucket: string,
            region: string,
            key: string,
          } | null,
        } | null,
        photoId: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
  } | null,
};

export type CustomSearchContactsQueryVariables = {
  filter?: SearchableContactFilterInput | null,
  sort?: Array< SearchableContactSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type CustomSearchContactsQuery = {
  searchContacts?:  {
    __typename: "SearchableContactConnection",
    items:  Array< {
      __typename: "Contact",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      _version: number,
      _deleted?: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type CustomSearchInspectionPointsQueryVariables = {
  filter?: SearchableInspectionPointFilterInput | null,
  sort?: Array< SearchableInspectionPointSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type CustomSearchInspectionPointsQuery = {
  searchInspectionPoints?:  {
    __typename: "SearchableInspectionPointConnection",
    items:  Array< {
      __typename: "InspectionPoint",
      id: string,
      hierarchyItemId: string,
      type: InspectionPointType,
      projectId?: string | null,
      text: string,
      code?: string | null,
      _version: number,
      _deleted?: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type CustomFindingsByProjectIdQueryVariables = {
  projectId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFindingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomFindingsByProjectIdQuery = {
  findingsByProjectId?:  {
    __typename: "ModelFindingConnection",
    items:  Array< {
      __typename: "Finding",
      id: string,
      type: FindingType,
      projectId: string,
      groupId?: string | null,
      phaseId?: string | null,
      inspectionId: string,
      hierarchyItemId: string,
      hierarchyItem?:  {
        __typename: "HierarchyItem",
        id: string,
        name: string,
        code?: string | null,
        hierarchyId: string,
        parentId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      observationId: string,
      observation?:  {
        __typename: "Observation",
        id: string,
        type?: ObservationType | null,
        projectId?: string | null,
        groupId?: string | null,
        inspectionId: string,
        hierarchyItemId: string,
        hierarchyItem?:  {
          __typename: "HierarchyItem",
          id: string,
          name: string,
          code?: string | null,
          hierarchyId: string,
          parentId?: string | null,
          inspectionPoints?:  {
            __typename: "ModelInspectionPointConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        inspectionPointId: string,
        inspectionPoint?:  {
          __typename: "InspectionPoint",
          id: string,
          hierarchyItemId: string,
          type: InspectionPointType,
          projectId?: string | null,
          groupId?: string | null,
          text: string,
          code?: string | null,
          hidden?: boolean | null,
          observations?:  {
            __typename: "ModelObservationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        text?: string | null,
        suggestion?: string | null,
        findingId?: string | null,
        primaryLocation?:  {
          __typename: "Location",
          id: string,
          projectId?: string | null,
          observationId: string,
          primaryPhoto?:  {
            __typename: "Photo",
            id: string,
            projectId?: string | null,
            groupId?: string | null,
            inspectionId: string,
            json?: string | null,
            text?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          photoSequence?: Array< string | null > | null,
          tags?:  {
            __typename: "ModelLocationTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          photos?:  {
            __typename: "ModelLocationPhotoConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        locations?:  {
          __typename: "ModelLocationConnection",
          items:  Array< {
            __typename: "Location",
            id: string,
            projectId?: string | null,
            groupId?: string | null,
            observationId: string,
            photoSequence?: Array< string | null > | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            primaryPhoto?:  {
              __typename: "Photo",
              id: string,
            } | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        status?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      closingObservation?:  {
        __typename: "Observation",
        id: string,
        type?: ObservationType | null,
        projectId?: string | null,
        groupId?: string | null,
        inspectionId: string,
        hierarchyItemId: string,
        hierarchyItem?:  {
          __typename: "HierarchyItem",
          id: string,
          name: string,
          code?: string | null,
          hierarchyId: string,
          parentId?: string | null,
          inspectionPoints?:  {
            __typename: "ModelInspectionPointConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        inspectionPointId: string,
        inspectionPoint?:  {
          __typename: "InspectionPoint",
          id: string,
          hierarchyItemId: string,
          type: InspectionPointType,
          projectId?: string | null,
          groupId?: string | null,
          text: string,
          code?: string | null,
          hidden?: boolean | null,
          observations?:  {
            __typename: "ModelObservationConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        text?: string | null,
        suggestion?: string | null,
        findingId?: string | null,
        primaryLocation?:  {
          __typename: "Location",
          id: string,
          projectId?: string | null,
          groupId?: string | null,
          observationId: string,
          primaryPhoto?:  {
            __typename: "Photo",
            id: string,
            projectId?: string | null,
            groupId?: string | null,
            inspectionId: string,
            json?: string | null,
            text?: string | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null,
          photoSequence?: Array< string | null > | null,
          tags?:  {
            __typename: "ModelLocationTagConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          photos?:  {
            __typename: "ModelLocationPhotoConnection",
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null,
        locations?:  {
          __typename: "ModelLocationConnection",
          items:  Array< {
            __typename: "Location",
            id: string,
            projectId?: string | null,
            groupId?: string | null,
            observationId: string,
            photoSequence?: Array< string | null > | null,
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            primaryPhoto?:  {
              __typename: "Photo",
              id: string,
            } | null,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        status?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      status: FindingStatus,
      date?: string | null,
      closedDate?: string | null,
      code?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type getDashboardDataQuery = {
  searchInspections?:  {
    __typename: "SearchableInspectionConnection",
    items:  Array< {
      __typename: "Inspection",
      id: string,
      project?:  {
        __typename: "Project",
        id: string,
        name: string,
        client?:  {
          __typename: "Client",
          name: string,
        } | null,
        reviewer?:  {
          __typename: "User",
          id: string,
        } | null,
      } | null,
      inspectionDate?: string | null,
      comments?:  {
        __typename: "ModelInspectionCommentConnection",
        items:  Array< {
          __typename: "InspectionComment",
          comment?:  {
            __typename: "Comment",
            text: string,
            createdAt: string,
            user?:  {
              __typename: "User",
              firstName: string,
              lastName: string,
            } | null,
          } | null,
        } | null >,
      } | null,
      phase?:  {
        __typename: "Phase",
        name: string,
      } | null,
      reviewer?:  {
        __typename: "User",
        id: string,
      } | null,
      status: string,
    } | null >,
  } | null,
};

export type locationsByObservationIdQueryVariables = {
  observationId: string,
};

export type locationsByObservationIdQuery = {
  locationsByObservationId?:  {
    __typename: "ModelLocationConnection",
    items:  Array< {
      __typename: "Location",
      photos?:  {
        __typename: "ModelLocationPhotoConnection",
        items:  Array< {
          __typename: "LocationPhoto",
          photo?:  {
            __typename: "Photo",
            id: string,
            json?: string | null,
            _version: number,
          } | null,
        } | null >,
      } | null,
    } | null >,
  } | null,
};

export type CreateExternalLinkMutationVariables = {
  input: CreateExternalLinkInput,
  condition?: ModelExternalLinkConditionInput | null,
};

export type CreateExternalLinkMutation = {
  createExternalLink?:  {
    __typename: "ExternalLink",
    id: string,
    groupId?: string | null,
    projectId: string,
    title: string,
    url: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type UpdateExternalLinkMutationVariables = {
  input: UpdateExternalLinkInput,
  condition?: ModelExternalLinkConditionInput | null,
};

export type UpdateExternalLinkMutation = {
  updateExternalLink?:  {
    __typename: "ExternalLink",
    id: string,
    groupId?: string | null,
    projectId: string,
    title: string,
    url: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type DeleteExternalLinkMutationVariables = {
  input: DeleteExternalLinkInput,
  condition?: ModelExternalLinkConditionInput | null,
};

export type DeleteExternalLinkMutation = {
  deleteExternalLink?:  {
    __typename: "ExternalLink",
    id: string,
    groupId?: string | null,
    projectId: string,
    title: string,
    url: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type CreateContactMutationVariables = {
  input: CreateContactInput,
  condition?: ModelContactConditionInput | null,
};

export type CreateContactMutation = {
  createContact?:  {
    __typename: "Contact",
    id: string,
    type: string,
    firstName: string,
    lastName: string,
    companyName?: string | null,
    jobTitle?: string | null,
    phone?: string | null,
    email?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type UpdateContactMutationVariables = {
  input: UpdateContactInput,
  condition?: ModelContactConditionInput | null,
};

export type UpdateContactMutation = {
  updateContact?:  {
    __typename: "Contact",
    id: string,
    type: string,
    firstName: string,
    lastName: string,
    companyName?: string | null,
    jobTitle?: string | null,
    phone?: string | null,
    email?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type DeleteContactMutationVariables = {
  input: DeleteContactInput,
  condition?: ModelContactConditionInput | null,
};

export type DeleteContactMutation = {
  deleteContact?:  {
    __typename: "Contact",
    id: string,
    type: string,
    firstName: string,
    lastName: string,
    companyName?: string | null,
    jobTitle?: string | null,
    phone?: string | null,
    email?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    status: string,
    type: string,
    firstName: string,
    lastName: string,
    companyName?: string | null,
    jobTitle?: string | null,
    phone?: string | null,
    email?: string | null,
    metadata?: string | null,
    projects?: Array< string | null > | null,
    favoriteProjects?: string | null,
    inspections?:  {
      __typename: "ModelInspectionConnection",
      items:  Array< {
        __typename: "Inspection",
        id: string,
        groupId?: string | null,
        clientId?: string | null,
        projectId: string,
        phaseId?: string | null,
        photoSequence?: Array< string | null > | null,
        status: string,
        userId: string,
        inspectionReviewerId?: string | null,
        inspectionDate?: string | null,
        reviewedDate?: string | null,
        deliveredDate?: string | null,
        nextInspectionDate?: string | null,
        arrivalTime?: string | null,
        notes?: string | null,
        coverLetter?: string | null,
        scopeHierarchyItems?: Array< string | null > | null,
        scope?: string | null,
        number?: string | null,
        code?: string | null,
        weather?: string | null,
        milestoneBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        inspectionCheckInContactId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    status: string,
    type: string,
    firstName: string,
    lastName: string,
    companyName?: string | null,
    jobTitle?: string | null,
    phone?: string | null,
    email?: string | null,
    metadata?: string | null,
    projects?: Array< string | null > | null,
    favoriteProjects?: string | null,
    inspections?:  {
      __typename: "ModelInspectionConnection",
      items:  Array< {
        __typename: "Inspection",
        id: string,
        groupId?: string | null,
        clientId?: string | null,
        projectId: string,
        phaseId?: string | null,
        photoSequence?: Array< string | null > | null,
        status: string,
        userId: string,
        inspectionReviewerId?: string | null,
        inspectionDate?: string | null,
        reviewedDate?: string | null,
        deliveredDate?: string | null,
        nextInspectionDate?: string | null,
        arrivalTime?: string | null,
        notes?: string | null,
        coverLetter?: string | null,
        scopeHierarchyItems?: Array< string | null > | null,
        scope?: string | null,
        number?: string | null,
        code?: string | null,
        weather?: string | null,
        milestoneBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        inspectionCheckInContactId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    status: string,
    type: string,
    firstName: string,
    lastName: string,
    companyName?: string | null,
    jobTitle?: string | null,
    phone?: string | null,
    email?: string | null,
    metadata?: string | null,
    projects?: Array< string | null > | null,
    favoriteProjects?: string | null,
    inspections?:  {
      __typename: "ModelInspectionConnection",
      items:  Array< {
        __typename: "Inspection",
        id: string,
        groupId?: string | null,
        clientId?: string | null,
        projectId: string,
        phaseId?: string | null,
        photoSequence?: Array< string | null > | null,
        status: string,
        userId: string,
        inspectionReviewerId?: string | null,
        inspectionDate?: string | null,
        reviewedDate?: string | null,
        deliveredDate?: string | null,
        nextInspectionDate?: string | null,
        arrivalTime?: string | null,
        notes?: string | null,
        coverLetter?: string | null,
        scopeHierarchyItems?: Array< string | null > | null,
        scope?: string | null,
        number?: string | null,
        code?: string | null,
        weather?: string | null,
        milestoneBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        inspectionCheckInContactId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type CreateClientMutationVariables = {
  input: CreateClientInput,
  condition?: ModelClientConditionInput | null,
};

export type CreateClientMutation = {
  createClient?:  {
    __typename: "Client",
    id: string,
    name: string,
    code: string,
    status: string,
    address:  {
      __typename: "Address",
      street: string,
      street2?: string | null,
      city: string,
      state: string,
      zip: string,
    },
    primaryContact?:  {
      __typename: "Contact",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    reportDisclaimer?: string | null,
    contacts?:  {
      __typename: "ModelClientContactConnection",
      items:  Array< {
        __typename: "ClientContact",
        id: string,
        clientId: string,
        contactId: string,
        label?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    projects?:  {
      __typename: "ModelProjectConnection",
      items:  Array< {
        __typename: "Project",
        id: string,
        groupId?: string | null,
        name: string,
        status: string,
        clientId: string,
        reportTemplateId?: string | null,
        reportDisclaimer?: string | null,
        distributionList?: string | null,
        hierarchyItemExclusions?: Array< string | null > | null,
        exclusions?: string | null,
        notes?: string | null,
        code?: string | null,
        milestoneTemplateId?: string | null,
        externalId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        projectEftId?: string | null,
        projectReviewerId?: string | null,
        projectAdminId?: string | null,
        projectPrimaryContactId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    clientPrimaryContactId?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateClientMutationVariables = {
  input: UpdateClientInput,
  condition?: ModelClientConditionInput | null,
};

export type UpdateClientMutation = {
  updateClient?:  {
    __typename: "Client",
    id: string,
    name: string,
    code: string,
    status: string,
    address:  {
      __typename: "Address",
      street: string,
      street2?: string | null,
      city: string,
      state: string,
      zip: string,
    },
    primaryContact?:  {
      __typename: "Contact",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    reportDisclaimer?: string | null,
    contacts?:  {
      __typename: "ModelClientContactConnection",
      items:  Array< {
        __typename: "ClientContact",
        id: string,
        clientId: string,
        contactId: string,
        label?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    projects?:  {
      __typename: "ModelProjectConnection",
      items:  Array< {
        __typename: "Project",
        id: string,
        groupId?: string | null,
        name: string,
        status: string,
        clientId: string,
        reportTemplateId?: string | null,
        reportDisclaimer?: string | null,
        distributionList?: string | null,
        hierarchyItemExclusions?: Array< string | null > | null,
        exclusions?: string | null,
        notes?: string | null,
        code?: string | null,
        milestoneTemplateId?: string | null,
        externalId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        projectEftId?: string | null,
        projectReviewerId?: string | null,
        projectAdminId?: string | null,
        projectPrimaryContactId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    clientPrimaryContactId?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteClientMutationVariables = {
  input: DeleteClientInput,
  condition?: ModelClientConditionInput | null,
};

export type DeleteClientMutation = {
  deleteClient?:  {
    __typename: "Client",
    id: string,
    name: string,
    code: string,
    status: string,
    address:  {
      __typename: "Address",
      street: string,
      street2?: string | null,
      city: string,
      state: string,
      zip: string,
    },
    primaryContact?:  {
      __typename: "Contact",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    reportDisclaimer?: string | null,
    contacts?:  {
      __typename: "ModelClientContactConnection",
      items:  Array< {
        __typename: "ClientContact",
        id: string,
        clientId: string,
        contactId: string,
        label?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    projects?:  {
      __typename: "ModelProjectConnection",
      items:  Array< {
        __typename: "Project",
        id: string,
        groupId?: string | null,
        name: string,
        status: string,
        clientId: string,
        reportTemplateId?: string | null,
        reportDisclaimer?: string | null,
        distributionList?: string | null,
        hierarchyItemExclusions?: Array< string | null > | null,
        exclusions?: string | null,
        notes?: string | null,
        code?: string | null,
        milestoneTemplateId?: string | null,
        externalId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        projectEftId?: string | null,
        projectReviewerId?: string | null,
        projectAdminId?: string | null,
        projectPrimaryContactId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    clientPrimaryContactId?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateClientContactMutationVariables = {
  input: CreateClientContactInput,
  condition?: ModelClientContactConditionInput | null,
};

export type CreateClientContactMutation = {
  createClientContact?:  {
    __typename: "ClientContact",
    id: string,
    clientId: string,
    contactId: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    label?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type UpdateClientContactMutationVariables = {
  input: UpdateClientContactInput,
  condition?: ModelClientContactConditionInput | null,
};

export type UpdateClientContactMutation = {
  updateClientContact?:  {
    __typename: "ClientContact",
    id: string,
    clientId: string,
    contactId: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    label?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type DeleteClientContactMutationVariables = {
  input: DeleteClientContactInput,
  condition?: ModelClientContactConditionInput | null,
};

export type DeleteClientContactMutation = {
  deleteClientContact?:  {
    __typename: "ClientContact",
    id: string,
    clientId: string,
    contactId: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    label?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type CreateProjectMutationVariables = {
  input: CreateProjectInput,
  condition?: ModelProjectConditionInput | null,
};

export type CreateProjectMutation = {
  createProject?:  {
    __typename: "Project",
    id: string,
    groupId?: string | null,
    name: string,
    status: string,
    clientId: string,
    client?:  {
      __typename: "Client",
      id: string,
      name: string,
      code: string,
      status: string,
      address:  {
        __typename: "Address",
        street: string,
        street2?: string | null,
        city: string,
        state: string,
        zip: string,
      },
      primaryContact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reportDisclaimer?: string | null,
      contacts?:  {
        __typename: "ModelClientContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      projects?:  {
        __typename: "ModelProjectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      clientPrimaryContactId?: string | null,
      owner?: string | null,
    } | null,
    eft?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    reviewer?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    admin?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    address:  {
      __typename: "Address",
      street: string,
      street2?: string | null,
      city: string,
      state: string,
      zip: string,
    },
    primaryContact?:  {
      __typename: "Contact",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    reportTemplateId?: string | null,
    reportDisclaimer?: string | null,
    inspections?:  {
      __typename: "ModelInspectionConnection",
      items:  Array< {
        __typename: "Inspection",
        id: string,
        groupId?: string | null,
        clientId?: string | null,
        projectId: string,
        phaseId?: string | null,
        photoSequence?: Array< string | null > | null,
        status: string,
        userId: string,
        inspectionReviewerId?: string | null,
        inspectionDate?: string | null,
        reviewedDate?: string | null,
        deliveredDate?: string | null,
        nextInspectionDate?: string | null,
        arrivalTime?: string | null,
        notes?: string | null,
        coverLetter?: string | null,
        scopeHierarchyItems?: Array< string | null > | null,
        scope?: string | null,
        number?: string | null,
        code?: string | null,
        weather?: string | null,
        milestoneBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        inspectionCheckInContactId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    contacts?:  {
      __typename: "ModelProjectContactConnection",
      items:  Array< {
        __typename: "ProjectContact",
        id: string,
        groupId?: string | null,
        projectId: string,
        contactId: string,
        label?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    distributionList?: string | null,
    externalLinks?:  {
      __typename: "ModelExternalLinkConnection",
      items:  Array< {
        __typename: "ExternalLink",
        id: string,
        groupId?: string | null,
        projectId: string,
        title: string,
        url: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    tags?:  {
      __typename: "ModelTagConnection",
      items:  Array< {
        __typename: "Tag",
        id: string,
        groupId?: string | null,
        type: TagType,
        projectId: string,
        text: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    findings?:  {
      __typename: "ModelFindingConnection",
      items:  Array< {
        __typename: "Finding",
        id: string,
        groupId?: string | null,
        type: FindingType,
        projectId: string,
        phaseId?: string | null,
        inspectionId: string,
        hierarchyItemId: string,
        observationId: string,
        status: FindingStatus,
        date?: string | null,
        closedDate?: string | null,
        code?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        findingClosingObservationId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    phases?:  {
      __typename: "ModelPhaseConnection",
      items:  Array< {
        __typename: "Phase",
        id: string,
        groupId?: string | null,
        projectId: string,
        name: string,
        status: string,
        milestoneTemplateId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    hierarchyItemExclusions?: Array< string | null > | null,
    exclusions?: string | null,
    notes?: string | null,
    code?: string | null,
    milestoneTemplate?:  {
      __typename: "MilestoneTemplate",
      id: string,
      name: string,
      status: string,
      hierarchyBundleIds?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    milestoneTemplateId?: string | null,
    externalId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    projectEftId?: string | null,
    projectReviewerId?: string | null,
    projectAdminId?: string | null,
    projectPrimaryContactId?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateProjectMutationVariables = {
  input: UpdateProjectInput,
  condition?: ModelProjectConditionInput | null,
};

export type UpdateProjectMutation = {
  updateProject?:  {
    __typename: "Project",
    id: string,
    groupId?: string | null,
    name: string,
    status: string,
    clientId: string,
    client?:  {
      __typename: "Client",
      id: string,
      name: string,
      code: string,
      status: string,
      address:  {
        __typename: "Address",
        street: string,
        street2?: string | null,
        city: string,
        state: string,
        zip: string,
      },
      primaryContact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reportDisclaimer?: string | null,
      contacts?:  {
        __typename: "ModelClientContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      projects?:  {
        __typename: "ModelProjectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      clientPrimaryContactId?: string | null,
      owner?: string | null,
    } | null,
    eft?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    reviewer?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    admin?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    address:  {
      __typename: "Address",
      street: string,
      street2?: string | null,
      city: string,
      state: string,
      zip: string,
    },
    primaryContact?:  {
      __typename: "Contact",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    reportTemplateId?: string | null,
    reportDisclaimer?: string | null,
    inspections?:  {
      __typename: "ModelInspectionConnection",
      items:  Array< {
        __typename: "Inspection",
        id: string,
        groupId?: string | null,
        clientId?: string | null,
        projectId: string,
        phaseId?: string | null,
        photoSequence?: Array< string | null > | null,
        status: string,
        userId: string,
        inspectionReviewerId?: string | null,
        inspectionDate?: string | null,
        reviewedDate?: string | null,
        deliveredDate?: string | null,
        nextInspectionDate?: string | null,
        arrivalTime?: string | null,
        notes?: string | null,
        coverLetter?: string | null,
        scopeHierarchyItems?: Array< string | null > | null,
        scope?: string | null,
        number?: string | null,
        code?: string | null,
        weather?: string | null,
        milestoneBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        inspectionCheckInContactId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    contacts?:  {
      __typename: "ModelProjectContactConnection",
      items:  Array< {
        __typename: "ProjectContact",
        id: string,
        groupId?: string | null,
        projectId: string,
        contactId: string,
        label?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    distributionList?: string | null,
    externalLinks?:  {
      __typename: "ModelExternalLinkConnection",
      items:  Array< {
        __typename: "ExternalLink",
        id: string,
        groupId?: string | null,
        projectId: string,
        title: string,
        url: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    tags?:  {
      __typename: "ModelTagConnection",
      items:  Array< {
        __typename: "Tag",
        id: string,
        groupId?: string | null,
        type: TagType,
        projectId: string,
        text: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    findings?:  {
      __typename: "ModelFindingConnection",
      items:  Array< {
        __typename: "Finding",
        id: string,
        groupId?: string | null,
        type: FindingType,
        projectId: string,
        phaseId?: string | null,
        inspectionId: string,
        hierarchyItemId: string,
        observationId: string,
        status: FindingStatus,
        date?: string | null,
        closedDate?: string | null,
        code?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        findingClosingObservationId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    phases?:  {
      __typename: "ModelPhaseConnection",
      items:  Array< {
        __typename: "Phase",
        id: string,
        groupId?: string | null,
        projectId: string,
        name: string,
        status: string,
        milestoneTemplateId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    hierarchyItemExclusions?: Array< string | null > | null,
    exclusions?: string | null,
    notes?: string | null,
    code?: string | null,
    milestoneTemplate?:  {
      __typename: "MilestoneTemplate",
      id: string,
      name: string,
      status: string,
      hierarchyBundleIds?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    milestoneTemplateId?: string | null,
    externalId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    projectEftId?: string | null,
    projectReviewerId?: string | null,
    projectAdminId?: string | null,
    projectPrimaryContactId?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteProjectMutationVariables = {
  input: DeleteProjectInput,
  condition?: ModelProjectConditionInput | null,
};

export type DeleteProjectMutation = {
  deleteProject?:  {
    __typename: "Project",
    id: string,
    groupId?: string | null,
    name: string,
    status: string,
    clientId: string,
    client?:  {
      __typename: "Client",
      id: string,
      name: string,
      code: string,
      status: string,
      address:  {
        __typename: "Address",
        street: string,
        street2?: string | null,
        city: string,
        state: string,
        zip: string,
      },
      primaryContact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reportDisclaimer?: string | null,
      contacts?:  {
        __typename: "ModelClientContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      projects?:  {
        __typename: "ModelProjectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      clientPrimaryContactId?: string | null,
      owner?: string | null,
    } | null,
    eft?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    reviewer?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    admin?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    address:  {
      __typename: "Address",
      street: string,
      street2?: string | null,
      city: string,
      state: string,
      zip: string,
    },
    primaryContact?:  {
      __typename: "Contact",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    reportTemplateId?: string | null,
    reportDisclaimer?: string | null,
    inspections?:  {
      __typename: "ModelInspectionConnection",
      items:  Array< {
        __typename: "Inspection",
        id: string,
        groupId?: string | null,
        clientId?: string | null,
        projectId: string,
        phaseId?: string | null,
        photoSequence?: Array< string | null > | null,
        status: string,
        userId: string,
        inspectionReviewerId?: string | null,
        inspectionDate?: string | null,
        reviewedDate?: string | null,
        deliveredDate?: string | null,
        nextInspectionDate?: string | null,
        arrivalTime?: string | null,
        notes?: string | null,
        coverLetter?: string | null,
        scopeHierarchyItems?: Array< string | null > | null,
        scope?: string | null,
        number?: string | null,
        code?: string | null,
        weather?: string | null,
        milestoneBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        inspectionCheckInContactId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    contacts?:  {
      __typename: "ModelProjectContactConnection",
      items:  Array< {
        __typename: "ProjectContact",
        id: string,
        groupId?: string | null,
        projectId: string,
        contactId: string,
        label?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    distributionList?: string | null,
    externalLinks?:  {
      __typename: "ModelExternalLinkConnection",
      items:  Array< {
        __typename: "ExternalLink",
        id: string,
        groupId?: string | null,
        projectId: string,
        title: string,
        url: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    tags?:  {
      __typename: "ModelTagConnection",
      items:  Array< {
        __typename: "Tag",
        id: string,
        groupId?: string | null,
        type: TagType,
        projectId: string,
        text: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    findings?:  {
      __typename: "ModelFindingConnection",
      items:  Array< {
        __typename: "Finding",
        id: string,
        groupId?: string | null,
        type: FindingType,
        projectId: string,
        phaseId?: string | null,
        inspectionId: string,
        hierarchyItemId: string,
        observationId: string,
        status: FindingStatus,
        date?: string | null,
        closedDate?: string | null,
        code?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        findingClosingObservationId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    phases?:  {
      __typename: "ModelPhaseConnection",
      items:  Array< {
        __typename: "Phase",
        id: string,
        groupId?: string | null,
        projectId: string,
        name: string,
        status: string,
        milestoneTemplateId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    hierarchyItemExclusions?: Array< string | null > | null,
    exclusions?: string | null,
    notes?: string | null,
    code?: string | null,
    milestoneTemplate?:  {
      __typename: "MilestoneTemplate",
      id: string,
      name: string,
      status: string,
      hierarchyBundleIds?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    milestoneTemplateId?: string | null,
    externalId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    projectEftId?: string | null,
    projectReviewerId?: string | null,
    projectAdminId?: string | null,
    projectPrimaryContactId?: string | null,
    owner?: string | null,
  } | null,
};

export type CreatePhaseMutationVariables = {
  input: CreatePhaseInput,
  condition?: ModelPhaseConditionInput | null,
};

export type CreatePhaseMutation = {
  createPhase?:  {
    __typename: "Phase",
    id: string,
    groupId?: string | null,
    projectId: string,
    project:  {
      __typename: "Project",
      id: string,
      groupId?: string | null,
      name: string,
      status: string,
      clientId: string,
      client?:  {
        __typename: "Client",
        id: string,
        name: string,
        code: string,
        status: string,
        reportDisclaimer?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        clientPrimaryContactId?: string | null,
        owner?: string | null,
      } | null,
      eft?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reviewer?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      admin?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      address:  {
        __typename: "Address",
        street: string,
        street2?: string | null,
        city: string,
        state: string,
        zip: string,
      },
      primaryContact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reportTemplateId?: string | null,
      reportDisclaimer?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      contacts?:  {
        __typename: "ModelProjectContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      distributionList?: string | null,
      externalLinks?:  {
        __typename: "ModelExternalLinkConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      tags?:  {
        __typename: "ModelTagConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      findings?:  {
        __typename: "ModelFindingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      phases?:  {
        __typename: "ModelPhaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hierarchyItemExclusions?: Array< string | null > | null,
      exclusions?: string | null,
      notes?: string | null,
      code?: string | null,
      milestoneTemplate?:  {
        __typename: "MilestoneTemplate",
        id: string,
        name: string,
        status: string,
        hierarchyBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      milestoneTemplateId?: string | null,
      externalId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      projectEftId?: string | null,
      projectReviewerId?: string | null,
      projectAdminId?: string | null,
      projectPrimaryContactId?: string | null,
      owner?: string | null,
    },
    name: string,
    status: string,
    units?:  Array< {
      __typename: "Unit",
      id: string,
      name: string,
    } | null > | null,
    milestoneTemplate?:  {
      __typename: "MilestoneTemplate",
      id: string,
      name: string,
      status: string,
      hierarchyBundleIds?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    milestoneTemplateId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type UpdatePhaseMutationVariables = {
  input: UpdatePhaseInput,
  condition?: ModelPhaseConditionInput | null,
};

export type UpdatePhaseMutation = {
  updatePhase?:  {
    __typename: "Phase",
    id: string,
    groupId?: string | null,
    projectId: string,
    project:  {
      __typename: "Project",
      id: string,
      groupId?: string | null,
      name: string,
      status: string,
      clientId: string,
      client?:  {
        __typename: "Client",
        id: string,
        name: string,
        code: string,
        status: string,
        reportDisclaimer?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        clientPrimaryContactId?: string | null,
        owner?: string | null,
      } | null,
      eft?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reviewer?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      admin?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      address:  {
        __typename: "Address",
        street: string,
        street2?: string | null,
        city: string,
        state: string,
        zip: string,
      },
      primaryContact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reportTemplateId?: string | null,
      reportDisclaimer?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      contacts?:  {
        __typename: "ModelProjectContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      distributionList?: string | null,
      externalLinks?:  {
        __typename: "ModelExternalLinkConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      tags?:  {
        __typename: "ModelTagConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      findings?:  {
        __typename: "ModelFindingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      phases?:  {
        __typename: "ModelPhaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hierarchyItemExclusions?: Array< string | null > | null,
      exclusions?: string | null,
      notes?: string | null,
      code?: string | null,
      milestoneTemplate?:  {
        __typename: "MilestoneTemplate",
        id: string,
        name: string,
        status: string,
        hierarchyBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      milestoneTemplateId?: string | null,
      externalId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      projectEftId?: string | null,
      projectReviewerId?: string | null,
      projectAdminId?: string | null,
      projectPrimaryContactId?: string | null,
      owner?: string | null,
    },
    name: string,
    status: string,
    units?:  Array< {
      __typename: "Unit",
      id: string,
      name: string,
    } | null > | null,
    milestoneTemplate?:  {
      __typename: "MilestoneTemplate",
      id: string,
      name: string,
      status: string,
      hierarchyBundleIds?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    milestoneTemplateId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type DeletePhaseMutationVariables = {
  input: DeletePhaseInput,
  condition?: ModelPhaseConditionInput | null,
};

export type DeletePhaseMutation = {
  deletePhase?:  {
    __typename: "Phase",
    id: string,
    groupId?: string | null,
    projectId: string,
    project:  {
      __typename: "Project",
      id: string,
      groupId?: string | null,
      name: string,
      status: string,
      clientId: string,
      client?:  {
        __typename: "Client",
        id: string,
        name: string,
        code: string,
        status: string,
        reportDisclaimer?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        clientPrimaryContactId?: string | null,
        owner?: string | null,
      } | null,
      eft?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reviewer?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      admin?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      address:  {
        __typename: "Address",
        street: string,
        street2?: string | null,
        city: string,
        state: string,
        zip: string,
      },
      primaryContact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reportTemplateId?: string | null,
      reportDisclaimer?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      contacts?:  {
        __typename: "ModelProjectContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      distributionList?: string | null,
      externalLinks?:  {
        __typename: "ModelExternalLinkConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      tags?:  {
        __typename: "ModelTagConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      findings?:  {
        __typename: "ModelFindingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      phases?:  {
        __typename: "ModelPhaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hierarchyItemExclusions?: Array< string | null > | null,
      exclusions?: string | null,
      notes?: string | null,
      code?: string | null,
      milestoneTemplate?:  {
        __typename: "MilestoneTemplate",
        id: string,
        name: string,
        status: string,
        hierarchyBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      milestoneTemplateId?: string | null,
      externalId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      projectEftId?: string | null,
      projectReviewerId?: string | null,
      projectAdminId?: string | null,
      projectPrimaryContactId?: string | null,
      owner?: string | null,
    },
    name: string,
    status: string,
    units?:  Array< {
      __typename: "Unit",
      id: string,
      name: string,
    } | null > | null,
    milestoneTemplate?:  {
      __typename: "MilestoneTemplate",
      id: string,
      name: string,
      status: string,
      hierarchyBundleIds?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    milestoneTemplateId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type CreateProjectContactMutationVariables = {
  input: CreateProjectContactInput,
  condition?: ModelProjectContactConditionInput | null,
};

export type CreateProjectContactMutation = {
  createProjectContact?:  {
    __typename: "ProjectContact",
    id: string,
    groupId?: string | null,
    projectId: string,
    contactId: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    label?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type UpdateProjectContactMutationVariables = {
  input: UpdateProjectContactInput,
  condition?: ModelProjectContactConditionInput | null,
};

export type UpdateProjectContactMutation = {
  updateProjectContact?:  {
    __typename: "ProjectContact",
    id: string,
    groupId?: string | null,
    projectId: string,
    contactId: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    label?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type DeleteProjectContactMutationVariables = {
  input: DeleteProjectContactInput,
  condition?: ModelProjectContactConditionInput | null,
};

export type DeleteProjectContactMutation = {
  deleteProjectContact?:  {
    __typename: "ProjectContact",
    id: string,
    groupId?: string | null,
    projectId: string,
    contactId: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    label?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type CreateFavoriteProjectMutationVariables = {
  input: CreateFavoriteProjectInput,
  condition?: ModelFavoriteProjectConditionInput | null,
};

export type CreateFavoriteProjectMutation = {
  createFavoriteProject?:  {
    __typename: "FavoriteProject",
    id: string,
    projectId: string,
    project:  {
      __typename: "Project",
      id: string,
      groupId?: string | null,
      name: string,
      status: string,
      clientId: string,
      client?:  {
        __typename: "Client",
        id: string,
        name: string,
        code: string,
        status: string,
        reportDisclaimer?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        clientPrimaryContactId?: string | null,
        owner?: string | null,
      } | null,
      eft?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reviewer?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      admin?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      address:  {
        __typename: "Address",
        street: string,
        street2?: string | null,
        city: string,
        state: string,
        zip: string,
      },
      primaryContact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reportTemplateId?: string | null,
      reportDisclaimer?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      contacts?:  {
        __typename: "ModelProjectContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      distributionList?: string | null,
      externalLinks?:  {
        __typename: "ModelExternalLinkConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      tags?:  {
        __typename: "ModelTagConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      findings?:  {
        __typename: "ModelFindingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      phases?:  {
        __typename: "ModelPhaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hierarchyItemExclusions?: Array< string | null > | null,
      exclusions?: string | null,
      notes?: string | null,
      code?: string | null,
      milestoneTemplate?:  {
        __typename: "MilestoneTemplate",
        id: string,
        name: string,
        status: string,
        hierarchyBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      milestoneTemplateId?: string | null,
      externalId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      projectEftId?: string | null,
      projectReviewerId?: string | null,
      projectAdminId?: string | null,
      projectPrimaryContactId?: string | null,
      owner?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type UpdateFavoriteProjectMutationVariables = {
  input: UpdateFavoriteProjectInput,
  condition?: ModelFavoriteProjectConditionInput | null,
};

export type UpdateFavoriteProjectMutation = {
  updateFavoriteProject?:  {
    __typename: "FavoriteProject",
    id: string,
    projectId: string,
    project:  {
      __typename: "Project",
      id: string,
      groupId?: string | null,
      name: string,
      status: string,
      clientId: string,
      client?:  {
        __typename: "Client",
        id: string,
        name: string,
        code: string,
        status: string,
        reportDisclaimer?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        clientPrimaryContactId?: string | null,
        owner?: string | null,
      } | null,
      eft?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reviewer?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      admin?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      address:  {
        __typename: "Address",
        street: string,
        street2?: string | null,
        city: string,
        state: string,
        zip: string,
      },
      primaryContact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reportTemplateId?: string | null,
      reportDisclaimer?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      contacts?:  {
        __typename: "ModelProjectContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      distributionList?: string | null,
      externalLinks?:  {
        __typename: "ModelExternalLinkConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      tags?:  {
        __typename: "ModelTagConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      findings?:  {
        __typename: "ModelFindingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      phases?:  {
        __typename: "ModelPhaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hierarchyItemExclusions?: Array< string | null > | null,
      exclusions?: string | null,
      notes?: string | null,
      code?: string | null,
      milestoneTemplate?:  {
        __typename: "MilestoneTemplate",
        id: string,
        name: string,
        status: string,
        hierarchyBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      milestoneTemplateId?: string | null,
      externalId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      projectEftId?: string | null,
      projectReviewerId?: string | null,
      projectAdminId?: string | null,
      projectPrimaryContactId?: string | null,
      owner?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type DeleteFavoriteProjectMutationVariables = {
  input: DeleteFavoriteProjectInput,
  condition?: ModelFavoriteProjectConditionInput | null,
};

export type DeleteFavoriteProjectMutation = {
  deleteFavoriteProject?:  {
    __typename: "FavoriteProject",
    id: string,
    projectId: string,
    project:  {
      __typename: "Project",
      id: string,
      groupId?: string | null,
      name: string,
      status: string,
      clientId: string,
      client?:  {
        __typename: "Client",
        id: string,
        name: string,
        code: string,
        status: string,
        reportDisclaimer?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        clientPrimaryContactId?: string | null,
        owner?: string | null,
      } | null,
      eft?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reviewer?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      admin?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      address:  {
        __typename: "Address",
        street: string,
        street2?: string | null,
        city: string,
        state: string,
        zip: string,
      },
      primaryContact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reportTemplateId?: string | null,
      reportDisclaimer?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      contacts?:  {
        __typename: "ModelProjectContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      distributionList?: string | null,
      externalLinks?:  {
        __typename: "ModelExternalLinkConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      tags?:  {
        __typename: "ModelTagConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      findings?:  {
        __typename: "ModelFindingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      phases?:  {
        __typename: "ModelPhaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hierarchyItemExclusions?: Array< string | null > | null,
      exclusions?: string | null,
      notes?: string | null,
      code?: string | null,
      milestoneTemplate?:  {
        __typename: "MilestoneTemplate",
        id: string,
        name: string,
        status: string,
        hierarchyBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      milestoneTemplateId?: string | null,
      externalId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      projectEftId?: string | null,
      projectReviewerId?: string | null,
      projectAdminId?: string | null,
      projectPrimaryContactId?: string | null,
      owner?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type CreateInspectionMutationVariables = {
  input: CreateInspectionInput,
  condition?: ModelInspectionConditionInput | null,
};

export type CreateInspectionMutation = {
  createInspection?:  {
    __typename: "Inspection",
    id: string,
    groupId?: string | null,
    clientId?: string | null,
    projectId: string,
    project?:  {
      __typename: "Project",
      id: string,
      groupId?: string | null,
      name: string,
      status: string,
      clientId: string,
      client?:  {
        __typename: "Client",
        id: string,
        name: string,
        code: string,
        status: string,
        reportDisclaimer?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        clientPrimaryContactId?: string | null,
        owner?: string | null,
      } | null,
      eft?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reviewer?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      admin?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      address:  {
        __typename: "Address",
        street: string,
        street2?: string | null,
        city: string,
        state: string,
        zip: string,
      },
      primaryContact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reportTemplateId?: string | null,
      reportDisclaimer?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      contacts?:  {
        __typename: "ModelProjectContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      distributionList?: string | null,
      externalLinks?:  {
        __typename: "ModelExternalLinkConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      tags?:  {
        __typename: "ModelTagConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      findings?:  {
        __typename: "ModelFindingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      phases?:  {
        __typename: "ModelPhaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hierarchyItemExclusions?: Array< string | null > | null,
      exclusions?: string | null,
      notes?: string | null,
      code?: string | null,
      milestoneTemplate?:  {
        __typename: "MilestoneTemplate",
        id: string,
        name: string,
        status: string,
        hierarchyBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      milestoneTemplateId?: string | null,
      externalId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      projectEftId?: string | null,
      projectReviewerId?: string | null,
      projectAdminId?: string | null,
      projectPrimaryContactId?: string | null,
      owner?: string | null,
    } | null,
    phase?:  {
      __typename: "Phase",
      id: string,
      groupId?: string | null,
      projectId: string,
      project:  {
        __typename: "Project",
        id: string,
        groupId?: string | null,
        name: string,
        status: string,
        clientId: string,
        reportTemplateId?: string | null,
        reportDisclaimer?: string | null,
        distributionList?: string | null,
        hierarchyItemExclusions?: Array< string | null > | null,
        exclusions?: string | null,
        notes?: string | null,
        code?: string | null,
        milestoneTemplateId?: string | null,
        externalId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        projectEftId?: string | null,
        projectReviewerId?: string | null,
        projectAdminId?: string | null,
        projectPrimaryContactId?: string | null,
        owner?: string | null,
      },
      name: string,
      status: string,
      units?:  Array< {
        __typename: "Unit",
        id: string,
        name: string,
      } | null > | null,
      milestoneTemplate?:  {
        __typename: "MilestoneTemplate",
        id: string,
        name: string,
        status: string,
        hierarchyBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      milestoneTemplateId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    phaseId?: string | null,
    photoSequence?: Array< string | null > | null,
    status: string,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    reviewer?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    inspectionReviewerId?: string | null,
    inspectionDate?: string | null,
    reviewedDate?: string | null,
    deliveredDate?: string | null,
    nextInspectionDate?: string | null,
    arrivalTime?: string | null,
    checkInContact?:  {
      __typename: "Contact",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    notes?: string | null,
    coverLetter?: string | null,
    comments?:  {
      __typename: "ModelInspectionCommentConnection",
      items:  Array< {
        __typename: "InspectionComment",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        inspectionId: string,
        commentId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    observations?:  {
      __typename: "ModelObservationConnection",
      items:  Array< {
        __typename: "Observation",
        id: string,
        groupId?: string | null,
        type?: ObservationType | null,
        projectId?: string | null,
        inspectionId: string,
        hierarchyItemId: string,
        inspectionPointId: string,
        text?: string | null,
        suggestion?: string | null,
        findingId?: string | null,
        status?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        observationPrimaryLocationId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    findings?:  {
      __typename: "ModelFindingConnection",
      items:  Array< {
        __typename: "Finding",
        id: string,
        groupId?: string | null,
        type: FindingType,
        projectId: string,
        phaseId?: string | null,
        inspectionId: string,
        hierarchyItemId: string,
        observationId: string,
        status: FindingStatus,
        date?: string | null,
        closedDate?: string | null,
        code?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        findingClosingObservationId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    hierarchyItems?:  {
      __typename: "ModelInspectionHierarchyItemConnection",
      items:  Array< {
        __typename: "InspectionHierarchyItem",
        id: string,
        inspectionId: string,
        hierarchyItemId: string,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    photos?:  {
      __typename: "ModelInspectionPhotoConnection",
      items:  Array< {
        __typename: "InspectionPhoto",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        inspectionId: string,
        photoId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    scopeHierarchyItems?: Array< string | null > | null,
    scope?: string | null,
    number?: string | null,
    code?: string | null,
    report?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    weather?: string | null,
    milestoneBundleIds?: string | null,
    milestoneTrackers?:  Array< {
      __typename: "MilestoneTracker",
      milestoneId: string,
      all?: boolean | null,
      units?: Array< string | null > | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    inspectionCheckInContactId?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateInspectionMutationVariables = {
  input: UpdateInspectionInput,
  condition?: ModelInspectionConditionInput | null,
};

export type UpdateInspectionMutation = {
  updateInspection?:  {
    __typename: "Inspection",
    id: string,
    groupId?: string | null,
    clientId?: string | null,
    projectId: string,
    project?:  {
      __typename: "Project",
      id: string,
      groupId?: string | null,
      name: string,
      status: string,
      clientId: string,
      client?:  {
        __typename: "Client",
        id: string,
        name: string,
        code: string,
        status: string,
        reportDisclaimer?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        clientPrimaryContactId?: string | null,
        owner?: string | null,
      } | null,
      eft?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reviewer?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      admin?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      address:  {
        __typename: "Address",
        street: string,
        street2?: string | null,
        city: string,
        state: string,
        zip: string,
      },
      primaryContact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reportTemplateId?: string | null,
      reportDisclaimer?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      contacts?:  {
        __typename: "ModelProjectContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      distributionList?: string | null,
      externalLinks?:  {
        __typename: "ModelExternalLinkConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      tags?:  {
        __typename: "ModelTagConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      findings?:  {
        __typename: "ModelFindingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      phases?:  {
        __typename: "ModelPhaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hierarchyItemExclusions?: Array< string | null > | null,
      exclusions?: string | null,
      notes?: string | null,
      code?: string | null,
      milestoneTemplate?:  {
        __typename: "MilestoneTemplate",
        id: string,
        name: string,
        status: string,
        hierarchyBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      milestoneTemplateId?: string | null,
      externalId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      projectEftId?: string | null,
      projectReviewerId?: string | null,
      projectAdminId?: string | null,
      projectPrimaryContactId?: string | null,
      owner?: string | null,
    } | null,
    phase?:  {
      __typename: "Phase",
      id: string,
      groupId?: string | null,
      projectId: string,
      project:  {
        __typename: "Project",
        id: string,
        groupId?: string | null,
        name: string,
        status: string,
        clientId: string,
        reportTemplateId?: string | null,
        reportDisclaimer?: string | null,
        distributionList?: string | null,
        hierarchyItemExclusions?: Array< string | null > | null,
        exclusions?: string | null,
        notes?: string | null,
        code?: string | null,
        milestoneTemplateId?: string | null,
        externalId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        projectEftId?: string | null,
        projectReviewerId?: string | null,
        projectAdminId?: string | null,
        projectPrimaryContactId?: string | null,
        owner?: string | null,
      },
      name: string,
      status: string,
      units?:  Array< {
        __typename: "Unit",
        id: string,
        name: string,
      } | null > | null,
      milestoneTemplate?:  {
        __typename: "MilestoneTemplate",
        id: string,
        name: string,
        status: string,
        hierarchyBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      milestoneTemplateId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    phaseId?: string | null,
    photoSequence?: Array< string | null > | null,
    status: string,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    reviewer?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    inspectionReviewerId?: string | null,
    inspectionDate?: string | null,
    reviewedDate?: string | null,
    deliveredDate?: string | null,
    nextInspectionDate?: string | null,
    arrivalTime?: string | null,
    checkInContact?:  {
      __typename: "Contact",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    notes?: string | null,
    coverLetter?: string | null,
    comments?:  {
      __typename: "ModelInspectionCommentConnection",
      items:  Array< {
        __typename: "InspectionComment",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        inspectionId: string,
        commentId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    observations?:  {
      __typename: "ModelObservationConnection",
      items:  Array< {
        __typename: "Observation",
        id: string,
        groupId?: string | null,
        type?: ObservationType | null,
        projectId?: string | null,
        inspectionId: string,
        hierarchyItemId: string,
        inspectionPointId: string,
        text?: string | null,
        suggestion?: string | null,
        findingId?: string | null,
        status?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        observationPrimaryLocationId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    findings?:  {
      __typename: "ModelFindingConnection",
      items:  Array< {
        __typename: "Finding",
        id: string,
        groupId?: string | null,
        type: FindingType,
        projectId: string,
        phaseId?: string | null,
        inspectionId: string,
        hierarchyItemId: string,
        observationId: string,
        status: FindingStatus,
        date?: string | null,
        closedDate?: string | null,
        code?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        findingClosingObservationId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    hierarchyItems?:  {
      __typename: "ModelInspectionHierarchyItemConnection",
      items:  Array< {
        __typename: "InspectionHierarchyItem",
        id: string,
        inspectionId: string,
        hierarchyItemId: string,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    photos?:  {
      __typename: "ModelInspectionPhotoConnection",
      items:  Array< {
        __typename: "InspectionPhoto",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        inspectionId: string,
        photoId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    scopeHierarchyItems?: Array< string | null > | null,
    scope?: string | null,
    number?: string | null,
    code?: string | null,
    report?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    weather?: string | null,
    milestoneBundleIds?: string | null,
    milestoneTrackers?:  Array< {
      __typename: "MilestoneTracker",
      milestoneId: string,
      all?: boolean | null,
      units?: Array< string | null > | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    inspectionCheckInContactId?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteInspectionMutationVariables = {
  input: DeleteInspectionInput,
  condition?: ModelInspectionConditionInput | null,
};

export type DeleteInspectionMutation = {
  deleteInspection?:  {
    __typename: "Inspection",
    id: string,
    groupId?: string | null,
    clientId?: string | null,
    projectId: string,
    project?:  {
      __typename: "Project",
      id: string,
      groupId?: string | null,
      name: string,
      status: string,
      clientId: string,
      client?:  {
        __typename: "Client",
        id: string,
        name: string,
        code: string,
        status: string,
        reportDisclaimer?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        clientPrimaryContactId?: string | null,
        owner?: string | null,
      } | null,
      eft?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reviewer?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      admin?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      address:  {
        __typename: "Address",
        street: string,
        street2?: string | null,
        city: string,
        state: string,
        zip: string,
      },
      primaryContact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reportTemplateId?: string | null,
      reportDisclaimer?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      contacts?:  {
        __typename: "ModelProjectContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      distributionList?: string | null,
      externalLinks?:  {
        __typename: "ModelExternalLinkConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      tags?:  {
        __typename: "ModelTagConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      findings?:  {
        __typename: "ModelFindingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      phases?:  {
        __typename: "ModelPhaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hierarchyItemExclusions?: Array< string | null > | null,
      exclusions?: string | null,
      notes?: string | null,
      code?: string | null,
      milestoneTemplate?:  {
        __typename: "MilestoneTemplate",
        id: string,
        name: string,
        status: string,
        hierarchyBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      milestoneTemplateId?: string | null,
      externalId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      projectEftId?: string | null,
      projectReviewerId?: string | null,
      projectAdminId?: string | null,
      projectPrimaryContactId?: string | null,
      owner?: string | null,
    } | null,
    phase?:  {
      __typename: "Phase",
      id: string,
      groupId?: string | null,
      projectId: string,
      project:  {
        __typename: "Project",
        id: string,
        groupId?: string | null,
        name: string,
        status: string,
        clientId: string,
        reportTemplateId?: string | null,
        reportDisclaimer?: string | null,
        distributionList?: string | null,
        hierarchyItemExclusions?: Array< string | null > | null,
        exclusions?: string | null,
        notes?: string | null,
        code?: string | null,
        milestoneTemplateId?: string | null,
        externalId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        projectEftId?: string | null,
        projectReviewerId?: string | null,
        projectAdminId?: string | null,
        projectPrimaryContactId?: string | null,
        owner?: string | null,
      },
      name: string,
      status: string,
      units?:  Array< {
        __typename: "Unit",
        id: string,
        name: string,
      } | null > | null,
      milestoneTemplate?:  {
        __typename: "MilestoneTemplate",
        id: string,
        name: string,
        status: string,
        hierarchyBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      milestoneTemplateId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    phaseId?: string | null,
    photoSequence?: Array< string | null > | null,
    status: string,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    reviewer?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    inspectionReviewerId?: string | null,
    inspectionDate?: string | null,
    reviewedDate?: string | null,
    deliveredDate?: string | null,
    nextInspectionDate?: string | null,
    arrivalTime?: string | null,
    checkInContact?:  {
      __typename: "Contact",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    notes?: string | null,
    coverLetter?: string | null,
    comments?:  {
      __typename: "ModelInspectionCommentConnection",
      items:  Array< {
        __typename: "InspectionComment",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        inspectionId: string,
        commentId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    observations?:  {
      __typename: "ModelObservationConnection",
      items:  Array< {
        __typename: "Observation",
        id: string,
        groupId?: string | null,
        type?: ObservationType | null,
        projectId?: string | null,
        inspectionId: string,
        hierarchyItemId: string,
        inspectionPointId: string,
        text?: string | null,
        suggestion?: string | null,
        findingId?: string | null,
        status?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        observationPrimaryLocationId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    findings?:  {
      __typename: "ModelFindingConnection",
      items:  Array< {
        __typename: "Finding",
        id: string,
        groupId?: string | null,
        type: FindingType,
        projectId: string,
        phaseId?: string | null,
        inspectionId: string,
        hierarchyItemId: string,
        observationId: string,
        status: FindingStatus,
        date?: string | null,
        closedDate?: string | null,
        code?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        findingClosingObservationId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    hierarchyItems?:  {
      __typename: "ModelInspectionHierarchyItemConnection",
      items:  Array< {
        __typename: "InspectionHierarchyItem",
        id: string,
        inspectionId: string,
        hierarchyItemId: string,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    photos?:  {
      __typename: "ModelInspectionPhotoConnection",
      items:  Array< {
        __typename: "InspectionPhoto",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        inspectionId: string,
        photoId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    scopeHierarchyItems?: Array< string | null > | null,
    scope?: string | null,
    number?: string | null,
    code?: string | null,
    report?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    weather?: string | null,
    milestoneBundleIds?: string | null,
    milestoneTrackers?:  Array< {
      __typename: "MilestoneTracker",
      milestoneId: string,
      all?: boolean | null,
      units?: Array< string | null > | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    inspectionCheckInContactId?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateHierarchyMutationVariables = {
  input: CreateHierarchyInput,
  condition?: ModelHierarchyConditionInput | null,
};

export type CreateHierarchyMutation = {
  createHierarchy?:  {
    __typename: "Hierarchy",
    id: string,
    name: string,
    items?:  {
      __typename: "ModelHierarchyItemConnection",
      items:  Array< {
        __typename: "HierarchyItem",
        id: string,
        name: string,
        code?: string | null,
        hierarchyId: string,
        parentId?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type UpdateHierarchyMutationVariables = {
  input: UpdateHierarchyInput,
  condition?: ModelHierarchyConditionInput | null,
};

export type UpdateHierarchyMutation = {
  updateHierarchy?:  {
    __typename: "Hierarchy",
    id: string,
    name: string,
    items?:  {
      __typename: "ModelHierarchyItemConnection",
      items:  Array< {
        __typename: "HierarchyItem",
        id: string,
        name: string,
        code?: string | null,
        hierarchyId: string,
        parentId?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type DeleteHierarchyMutationVariables = {
  input: DeleteHierarchyInput,
  condition?: ModelHierarchyConditionInput | null,
};

export type DeleteHierarchyMutation = {
  deleteHierarchy?:  {
    __typename: "Hierarchy",
    id: string,
    name: string,
    items?:  {
      __typename: "ModelHierarchyItemConnection",
      items:  Array< {
        __typename: "HierarchyItem",
        id: string,
        name: string,
        code?: string | null,
        hierarchyId: string,
        parentId?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type CreateHierarchyItemMutationVariables = {
  input: CreateHierarchyItemInput,
  condition?: ModelHierarchyItemConditionInput | null,
};

export type CreateHierarchyItemMutation = {
  createHierarchyItem?:  {
    __typename: "HierarchyItem",
    id: string,
    name: string,
    code?: string | null,
    hierarchyId: string,
    parentId?: string | null,
    hidden?: boolean | null,
    inspectionPoints?:  {
      __typename: "ModelInspectionPointConnection",
      items:  Array< {
        __typename: "InspectionPoint",
        id: string,
        groupId?: string | null,
        hierarchyItemId: string,
        type: InspectionPointType,
        projectId?: string | null,
        text: string,
        code?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type UpdateHierarchyItemMutationVariables = {
  input: UpdateHierarchyItemInput,
  condition?: ModelHierarchyItemConditionInput | null,
};

export type UpdateHierarchyItemMutation = {
  updateHierarchyItem?:  {
    __typename: "HierarchyItem",
    id: string,
    name: string,
    code?: string | null,
    hierarchyId: string,
    parentId?: string | null,
    hidden?: boolean | null,
    inspectionPoints?:  {
      __typename: "ModelInspectionPointConnection",
      items:  Array< {
        __typename: "InspectionPoint",
        id: string,
        groupId?: string | null,
        hierarchyItemId: string,
        type: InspectionPointType,
        projectId?: string | null,
        text: string,
        code?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type DeleteHierarchyItemMutationVariables = {
  input: DeleteHierarchyItemInput,
  condition?: ModelHierarchyItemConditionInput | null,
};

export type DeleteHierarchyItemMutation = {
  deleteHierarchyItem?:  {
    __typename: "HierarchyItem",
    id: string,
    name: string,
    code?: string | null,
    hierarchyId: string,
    parentId?: string | null,
    hidden?: boolean | null,
    inspectionPoints?:  {
      __typename: "ModelInspectionPointConnection",
      items:  Array< {
        __typename: "InspectionPoint",
        id: string,
        groupId?: string | null,
        hierarchyItemId: string,
        type: InspectionPointType,
        projectId?: string | null,
        text: string,
        code?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type CreateInspectionHierarchyItemMutationVariables = {
  input: CreateInspectionHierarchyItemInput,
  condition?: ModelInspectionHierarchyItemConditionInput | null,
};

export type CreateInspectionHierarchyItemMutation = {
  createInspectionHierarchyItem?:  {
    __typename: "InspectionHierarchyItem",
    id: string,
    inspectionId: string,
    hierarchyItemId: string,
    hierarchyItem?:  {
      __typename: "HierarchyItem",
      id: string,
      name: string,
      code?: string | null,
      hierarchyId: string,
      parentId?: string | null,
      hidden?: boolean | null,
      inspectionPoints?:  {
        __typename: "ModelInspectionPointConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    notes?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type UpdateInspectionHierarchyItemMutationVariables = {
  input: UpdateInspectionHierarchyItemInput,
  condition?: ModelInspectionHierarchyItemConditionInput | null,
};

export type UpdateInspectionHierarchyItemMutation = {
  updateInspectionHierarchyItem?:  {
    __typename: "InspectionHierarchyItem",
    id: string,
    inspectionId: string,
    hierarchyItemId: string,
    hierarchyItem?:  {
      __typename: "HierarchyItem",
      id: string,
      name: string,
      code?: string | null,
      hierarchyId: string,
      parentId?: string | null,
      hidden?: boolean | null,
      inspectionPoints?:  {
        __typename: "ModelInspectionPointConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    notes?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type DeleteInspectionHierarchyItemMutationVariables = {
  input: DeleteInspectionHierarchyItemInput,
  condition?: ModelInspectionHierarchyItemConditionInput | null,
};

export type DeleteInspectionHierarchyItemMutation = {
  deleteInspectionHierarchyItem?:  {
    __typename: "InspectionHierarchyItem",
    id: string,
    inspectionId: string,
    hierarchyItemId: string,
    hierarchyItem?:  {
      __typename: "HierarchyItem",
      id: string,
      name: string,
      code?: string | null,
      hierarchyId: string,
      parentId?: string | null,
      hidden?: boolean | null,
      inspectionPoints?:  {
        __typename: "ModelInspectionPointConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    notes?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type CreateInspectionPointMutationVariables = {
  input: CreateInspectionPointInput,
  condition?: ModelInspectionPointConditionInput | null,
};

export type CreateInspectionPointMutation = {
  createInspectionPoint?:  {
    __typename: "InspectionPoint",
    id: string,
    groupId?: string | null,
    hierarchyItemId: string,
    type: InspectionPointType,
    projectId?: string | null,
    text: string,
    code?: string | null,
    hidden?: boolean | null,
    observations?:  {
      __typename: "ModelObservationConnection",
      items:  Array< {
        __typename: "Observation",
        id: string,
        groupId?: string | null,
        type?: ObservationType | null,
        projectId?: string | null,
        inspectionId: string,
        hierarchyItemId: string,
        inspectionPointId: string,
        text?: string | null,
        suggestion?: string | null,
        findingId?: string | null,
        status?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        observationPrimaryLocationId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type UpdateInspectionPointMutationVariables = {
  input: UpdateInspectionPointInput,
  condition?: ModelInspectionPointConditionInput | null,
};

export type UpdateInspectionPointMutation = {
  updateInspectionPoint?:  {
    __typename: "InspectionPoint",
    id: string,
    groupId?: string | null,
    hierarchyItemId: string,
    type: InspectionPointType,
    projectId?: string | null,
    text: string,
    code?: string | null,
    hidden?: boolean | null,
    observations?:  {
      __typename: "ModelObservationConnection",
      items:  Array< {
        __typename: "Observation",
        id: string,
        groupId?: string | null,
        type?: ObservationType | null,
        projectId?: string | null,
        inspectionId: string,
        hierarchyItemId: string,
        inspectionPointId: string,
        text?: string | null,
        suggestion?: string | null,
        findingId?: string | null,
        status?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        observationPrimaryLocationId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type DeleteInspectionPointMutationVariables = {
  input: DeleteInspectionPointInput,
  condition?: ModelInspectionPointConditionInput | null,
};

export type DeleteInspectionPointMutation = {
  deleteInspectionPoint?:  {
    __typename: "InspectionPoint",
    id: string,
    groupId?: string | null,
    hierarchyItemId: string,
    type: InspectionPointType,
    projectId?: string | null,
    text: string,
    code?: string | null,
    hidden?: boolean | null,
    observations?:  {
      __typename: "ModelObservationConnection",
      items:  Array< {
        __typename: "Observation",
        id: string,
        groupId?: string | null,
        type?: ObservationType | null,
        projectId?: string | null,
        inspectionId: string,
        hierarchyItemId: string,
        inspectionPointId: string,
        text?: string | null,
        suggestion?: string | null,
        findingId?: string | null,
        status?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        observationPrimaryLocationId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type CreateCommentMutationVariables = {
  input: CreateCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type CreateCommentMutation = {
  createComment?:  {
    __typename: "Comment",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    date: string,
    text: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type UpdateCommentMutationVariables = {
  input: UpdateCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type UpdateCommentMutation = {
  updateComment?:  {
    __typename: "Comment",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    date: string,
    text: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type DeleteCommentMutationVariables = {
  input: DeleteCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type DeleteCommentMutation = {
  deleteComment?:  {
    __typename: "Comment",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    date: string,
    text: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type CreateInspectionCommentMutationVariables = {
  input: CreateInspectionCommentInput,
  condition?: ModelInspectionCommentConditionInput | null,
};

export type CreateInspectionCommentMutation = {
  createInspectionComment?:  {
    __typename: "InspectionComment",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    inspectionId: string,
    commentId: string,
    comment?:  {
      __typename: "Comment",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      date: string,
      text: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type UpdateInspectionCommentMutationVariables = {
  input: UpdateInspectionCommentInput,
  condition?: ModelInspectionCommentConditionInput | null,
};

export type UpdateInspectionCommentMutation = {
  updateInspectionComment?:  {
    __typename: "InspectionComment",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    inspectionId: string,
    commentId: string,
    comment?:  {
      __typename: "Comment",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      date: string,
      text: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type DeleteInspectionCommentMutationVariables = {
  input: DeleteInspectionCommentInput,
  condition?: ModelInspectionCommentConditionInput | null,
};

export type DeleteInspectionCommentMutation = {
  deleteInspectionComment?:  {
    __typename: "InspectionComment",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    inspectionId: string,
    commentId: string,
    comment?:  {
      __typename: "Comment",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      date: string,
      text: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type CreateObservationMutationVariables = {
  input: CreateObservationInput,
  condition?: ModelObservationConditionInput | null,
};

export type CreateObservationMutation = {
  createObservation?:  {
    __typename: "Observation",
    id: string,
    groupId?: string | null,
    type?: ObservationType | null,
    projectId?: string | null,
    inspectionId: string,
    hierarchyItemId: string,
    hierarchyItem?:  {
      __typename: "HierarchyItem",
      id: string,
      name: string,
      code?: string | null,
      hierarchyId: string,
      parentId?: string | null,
      hidden?: boolean | null,
      inspectionPoints?:  {
        __typename: "ModelInspectionPointConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    inspectionPointId: string,
    inspectionPoint?:  {
      __typename: "InspectionPoint",
      id: string,
      groupId?: string | null,
      hierarchyItemId: string,
      type: InspectionPointType,
      projectId?: string | null,
      text: string,
      code?: string | null,
      hidden?: boolean | null,
      observations?:  {
        __typename: "ModelObservationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    text?: string | null,
    suggestion?: string | null,
    findingId?: string | null,
    primaryLocation?:  {
      __typename: "Location",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      observationId: string,
      primaryPhoto?:  {
        __typename: "Photo",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        inspectionId: string,
        json?: string | null,
        text?: string | null,
        reportable?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      photoSequence?: Array< string | null > | null,
      tags?:  {
        __typename: "ModelLocationTagConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      photos?:  {
        __typename: "ModelLocationPhotoConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      locationPrimaryPhotoId?: string | null,
      owner?: string | null,
    } | null,
    locations?:  {
      __typename: "ModelLocationConnection",
      items:  Array< {
        __typename: "Location",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        observationId: string,
        photoSequence?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        locationPrimaryPhotoId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    status?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    observationPrimaryLocationId?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateObservationMutationVariables = {
  input: UpdateObservationInput,
  condition?: ModelObservationConditionInput | null,
};

export type UpdateObservationMutation = {
  updateObservation?:  {
    __typename: "Observation",
    id: string,
    groupId?: string | null,
    type?: ObservationType | null,
    projectId?: string | null,
    inspectionId: string,
    hierarchyItemId: string,
    hierarchyItem?:  {
      __typename: "HierarchyItem",
      id: string,
      name: string,
      code?: string | null,
      hierarchyId: string,
      parentId?: string | null,
      hidden?: boolean | null,
      inspectionPoints?:  {
        __typename: "ModelInspectionPointConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    inspectionPointId: string,
    inspectionPoint?:  {
      __typename: "InspectionPoint",
      id: string,
      groupId?: string | null,
      hierarchyItemId: string,
      type: InspectionPointType,
      projectId?: string | null,
      text: string,
      code?: string | null,
      hidden?: boolean | null,
      observations?:  {
        __typename: "ModelObservationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    text?: string | null,
    suggestion?: string | null,
    findingId?: string | null,
    primaryLocation?:  {
      __typename: "Location",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      observationId: string,
      primaryPhoto?:  {
        __typename: "Photo",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        inspectionId: string,
        json?: string | null,
        text?: string | null,
        reportable?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      photoSequence?: Array< string | null > | null,
      tags?:  {
        __typename: "ModelLocationTagConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      photos?:  {
        __typename: "ModelLocationPhotoConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      locationPrimaryPhotoId?: string | null,
      owner?: string | null,
    } | null,
    locations?:  {
      __typename: "ModelLocationConnection",
      items:  Array< {
        __typename: "Location",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        observationId: string,
        photoSequence?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        locationPrimaryPhotoId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    status?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    observationPrimaryLocationId?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteObservationMutationVariables = {
  input: DeleteObservationInput,
  condition?: ModelObservationConditionInput | null,
};

export type DeleteObservationMutation = {
  deleteObservation?:  {
    __typename: "Observation",
    id: string,
    groupId?: string | null,
    type?: ObservationType | null,
    projectId?: string | null,
    inspectionId: string,
    hierarchyItemId: string,
    hierarchyItem?:  {
      __typename: "HierarchyItem",
      id: string,
      name: string,
      code?: string | null,
      hierarchyId: string,
      parentId?: string | null,
      hidden?: boolean | null,
      inspectionPoints?:  {
        __typename: "ModelInspectionPointConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    inspectionPointId: string,
    inspectionPoint?:  {
      __typename: "InspectionPoint",
      id: string,
      groupId?: string | null,
      hierarchyItemId: string,
      type: InspectionPointType,
      projectId?: string | null,
      text: string,
      code?: string | null,
      hidden?: boolean | null,
      observations?:  {
        __typename: "ModelObservationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    text?: string | null,
    suggestion?: string | null,
    findingId?: string | null,
    primaryLocation?:  {
      __typename: "Location",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      observationId: string,
      primaryPhoto?:  {
        __typename: "Photo",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        inspectionId: string,
        json?: string | null,
        text?: string | null,
        reportable?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      photoSequence?: Array< string | null > | null,
      tags?:  {
        __typename: "ModelLocationTagConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      photos?:  {
        __typename: "ModelLocationPhotoConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      locationPrimaryPhotoId?: string | null,
      owner?: string | null,
    } | null,
    locations?:  {
      __typename: "ModelLocationConnection",
      items:  Array< {
        __typename: "Location",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        observationId: string,
        photoSequence?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        locationPrimaryPhotoId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    status?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    observationPrimaryLocationId?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateLocationMutationVariables = {
  input: CreateLocationInput,
  condition?: ModelLocationConditionInput | null,
};

export type CreateLocationMutation = {
  createLocation?:  {
    __typename: "Location",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    observationId: string,
    primaryPhoto?:  {
      __typename: "Photo",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      inspectionId: string,
      json?: string | null,
      text?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      reportable?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    photoSequence?: Array< string | null > | null,
    tags?:  {
      __typename: "ModelLocationTagConnection",
      items:  Array< {
        __typename: "LocationTag",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        locationId: string,
        tagId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    photos?:  {
      __typename: "ModelLocationPhotoConnection",
      items:  Array< {
        __typename: "LocationPhoto",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        locationId: string,
        photoId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    locationPrimaryPhotoId?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateLocationMutationVariables = {
  input: UpdateLocationInput,
  condition?: ModelLocationConditionInput | null,
};

export type UpdateLocationMutation = {
  updateLocation?:  {
    __typename: "Location",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    observationId: string,
    primaryPhoto?:  {
      __typename: "Photo",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      inspectionId: string,
      json?: string | null,
      text?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      reportable?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    photoSequence?: Array< string | null > | null,
    tags?:  {
      __typename: "ModelLocationTagConnection",
      items:  Array< {
        __typename: "LocationTag",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        locationId: string,
        tagId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    photos?:  {
      __typename: "ModelLocationPhotoConnection",
      items:  Array< {
        __typename: "LocationPhoto",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        locationId: string,
        photoId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    locationPrimaryPhotoId?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteLocationMutationVariables = {
  input: DeleteLocationInput,
  condition?: ModelLocationConditionInput | null,
};

export type DeleteLocationMutation = {
  deleteLocation?:  {
    __typename: "Location",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    observationId: string,
    primaryPhoto?:  {
      __typename: "Photo",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      inspectionId: string,
      json?: string | null,
      text?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      reportable?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    photoSequence?: Array< string | null > | null,
    tags?:  {
      __typename: "ModelLocationTagConnection",
      items:  Array< {
        __typename: "LocationTag",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        locationId: string,
        tagId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    photos?:  {
      __typename: "ModelLocationPhotoConnection",
      items:  Array< {
        __typename: "LocationPhoto",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        locationId: string,
        photoId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    locationPrimaryPhotoId?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateTagMutationVariables = {
  input: CreateTagInput,
  condition?: ModelTagConditionInput | null,
};

export type CreateTagMutation = {
  createTag?:  {
    __typename: "Tag",
    id: string,
    groupId?: string | null,
    type: TagType,
    projectId: string,
    text: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type UpdateTagMutationVariables = {
  input: UpdateTagInput,
  condition?: ModelTagConditionInput | null,
};

export type UpdateTagMutation = {
  updateTag?:  {
    __typename: "Tag",
    id: string,
    groupId?: string | null,
    type: TagType,
    projectId: string,
    text: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type DeleteTagMutationVariables = {
  input: DeleteTagInput,
  condition?: ModelTagConditionInput | null,
};

export type DeleteTagMutation = {
  deleteTag?:  {
    __typename: "Tag",
    id: string,
    groupId?: string | null,
    type: TagType,
    projectId: string,
    text: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type CreateLocationTagMutationVariables = {
  input: CreateLocationTagInput,
  condition?: ModelLocationTagConditionInput | null,
};

export type CreateLocationTagMutation = {
  createLocationTag?:  {
    __typename: "LocationTag",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    locationId: string,
    tagId: string,
    tag?:  {
      __typename: "Tag",
      id: string,
      groupId?: string | null,
      type: TagType,
      projectId: string,
      text: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type UpdateLocationTagMutationVariables = {
  input: UpdateLocationTagInput,
  condition?: ModelLocationTagConditionInput | null,
};

export type UpdateLocationTagMutation = {
  updateLocationTag?:  {
    __typename: "LocationTag",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    locationId: string,
    tagId: string,
    tag?:  {
      __typename: "Tag",
      id: string,
      groupId?: string | null,
      type: TagType,
      projectId: string,
      text: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type DeleteLocationTagMutationVariables = {
  input: DeleteLocationTagInput,
  condition?: ModelLocationTagConditionInput | null,
};

export type DeleteLocationTagMutation = {
  deleteLocationTag?:  {
    __typename: "LocationTag",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    locationId: string,
    tagId: string,
    tag?:  {
      __typename: "Tag",
      id: string,
      groupId?: string | null,
      type: TagType,
      projectId: string,
      text: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type CreatePhotoMutationVariables = {
  input: CreatePhotoInput,
  condition?: ModelPhotoConditionInput | null,
};

export type CreatePhotoMutation = {
  createPhoto?:  {
    __typename: "Photo",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    inspectionId: string,
    json?: string | null,
    text?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    reportable?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type UpdatePhotoMutationVariables = {
  input: UpdatePhotoInput,
  condition?: ModelPhotoConditionInput | null,
};

export type UpdatePhotoMutation = {
  updatePhoto?:  {
    __typename: "Photo",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    inspectionId: string,
    json?: string | null,
    text?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    reportable?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type DeletePhotoMutationVariables = {
  input: DeletePhotoInput,
  condition?: ModelPhotoConditionInput | null,
};

export type DeletePhotoMutation = {
  deletePhoto?:  {
    __typename: "Photo",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    inspectionId: string,
    json?: string | null,
    text?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    reportable?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type CreateInspectionPhotoMutationVariables = {
  input: CreateInspectionPhotoInput,
  condition?: ModelInspectionPhotoConditionInput | null,
};

export type CreateInspectionPhotoMutation = {
  createInspectionPhoto?:  {
    __typename: "InspectionPhoto",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    inspectionId: string,
    photoId: string,
    photo?:  {
      __typename: "Photo",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      inspectionId: string,
      json?: string | null,
      text?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      reportable?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type UpdateInspectionPhotoMutationVariables = {
  input: UpdateInspectionPhotoInput,
  condition?: ModelInspectionPhotoConditionInput | null,
};

export type UpdateInspectionPhotoMutation = {
  updateInspectionPhoto?:  {
    __typename: "InspectionPhoto",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    inspectionId: string,
    photoId: string,
    photo?:  {
      __typename: "Photo",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      inspectionId: string,
      json?: string | null,
      text?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      reportable?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type DeleteInspectionPhotoMutationVariables = {
  input: DeleteInspectionPhotoInput,
  condition?: ModelInspectionPhotoConditionInput | null,
};

export type DeleteInspectionPhotoMutation = {
  deleteInspectionPhoto?:  {
    __typename: "InspectionPhoto",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    inspectionId: string,
    photoId: string,
    photo?:  {
      __typename: "Photo",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      inspectionId: string,
      json?: string | null,
      text?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      reportable?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type CreateLocationPhotoMutationVariables = {
  input: CreateLocationPhotoInput,
  condition?: ModelLocationPhotoConditionInput | null,
};

export type CreateLocationPhotoMutation = {
  createLocationPhoto?:  {
    __typename: "LocationPhoto",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    locationId: string,
    photoId: string,
    photo?:  {
      __typename: "Photo",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      inspectionId: string,
      json?: string | null,
      text?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      reportable?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type UpdateLocationPhotoMutationVariables = {
  input: UpdateLocationPhotoInput,
  condition?: ModelLocationPhotoConditionInput | null,
};

export type UpdateLocationPhotoMutation = {
  updateLocationPhoto?:  {
    __typename: "LocationPhoto",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    locationId: string,
    photoId: string,
    photo?:  {
      __typename: "Photo",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      inspectionId: string,
      json?: string | null,
      text?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      reportable?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type DeleteLocationPhotoMutationVariables = {
  input: DeleteLocationPhotoInput,
  condition?: ModelLocationPhotoConditionInput | null,
};

export type DeleteLocationPhotoMutation = {
  deleteLocationPhoto?:  {
    __typename: "LocationPhoto",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    locationId: string,
    photoId: string,
    photo?:  {
      __typename: "Photo",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      inspectionId: string,
      json?: string | null,
      text?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      reportable?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type CreateFindingMutationVariables = {
  input: CreateFindingInput,
  condition?: ModelFindingConditionInput | null,
};

export type CreateFindingMutation = {
  createFinding?:  {
    __typename: "Finding",
    id: string,
    groupId?: string | null,
    type: FindingType,
    projectId: string,
    phaseId?: string | null,
    inspectionId: string,
    hierarchyItemId: string,
    hierarchyItem?:  {
      __typename: "HierarchyItem",
      id: string,
      name: string,
      code?: string | null,
      hierarchyId: string,
      parentId?: string | null,
      hidden?: boolean | null,
      inspectionPoints?:  {
        __typename: "ModelInspectionPointConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    observationId: string,
    observation?:  {
      __typename: "Observation",
      id: string,
      groupId?: string | null,
      type?: ObservationType | null,
      projectId?: string | null,
      inspectionId: string,
      hierarchyItemId: string,
      hierarchyItem?:  {
        __typename: "HierarchyItem",
        id: string,
        name: string,
        code?: string | null,
        hierarchyId: string,
        parentId?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      inspectionPointId: string,
      inspectionPoint?:  {
        __typename: "InspectionPoint",
        id: string,
        groupId?: string | null,
        hierarchyItemId: string,
        type: InspectionPointType,
        projectId?: string | null,
        text: string,
        code?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      text?: string | null,
      suggestion?: string | null,
      findingId?: string | null,
      primaryLocation?:  {
        __typename: "Location",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        observationId: string,
        photoSequence?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        locationPrimaryPhotoId?: string | null,
        owner?: string | null,
      } | null,
      locations?:  {
        __typename: "ModelLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      observationPrimaryLocationId?: string | null,
      owner?: string | null,
    } | null,
    closingObservation?:  {
      __typename: "Observation",
      id: string,
      groupId?: string | null,
      type?: ObservationType | null,
      projectId?: string | null,
      inspectionId: string,
      hierarchyItemId: string,
      hierarchyItem?:  {
        __typename: "HierarchyItem",
        id: string,
        name: string,
        code?: string | null,
        hierarchyId: string,
        parentId?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      inspectionPointId: string,
      inspectionPoint?:  {
        __typename: "InspectionPoint",
        id: string,
        groupId?: string | null,
        hierarchyItemId: string,
        type: InspectionPointType,
        projectId?: string | null,
        text: string,
        code?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      text?: string | null,
      suggestion?: string | null,
      findingId?: string | null,
      primaryLocation?:  {
        __typename: "Location",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        observationId: string,
        photoSequence?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        locationPrimaryPhotoId?: string | null,
        owner?: string | null,
      } | null,
      locations?:  {
        __typename: "ModelLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      observationPrimaryLocationId?: string | null,
      owner?: string | null,
    } | null,
    status: FindingStatus,
    date?: string | null,
    closedDate?: string | null,
    code?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    findingClosingObservationId?: string | null,
  } | null,
};

export type UpdateFindingMutationVariables = {
  input: UpdateFindingInput,
  condition?: ModelFindingConditionInput | null,
};

export type UpdateFindingMutation = {
  updateFinding?:  {
    __typename: "Finding",
    id: string,
    groupId?: string | null,
    type: FindingType,
    projectId: string,
    phaseId?: string | null,
    inspectionId: string,
    hierarchyItemId: string,
    hierarchyItem?:  {
      __typename: "HierarchyItem",
      id: string,
      name: string,
      code?: string | null,
      hierarchyId: string,
      parentId?: string | null,
      hidden?: boolean | null,
      inspectionPoints?:  {
        __typename: "ModelInspectionPointConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    observationId: string,
    observation?:  {
      __typename: "Observation",
      id: string,
      groupId?: string | null,
      type?: ObservationType | null,
      projectId?: string | null,
      inspectionId: string,
      hierarchyItemId: string,
      hierarchyItem?:  {
        __typename: "HierarchyItem",
        id: string,
        name: string,
        code?: string | null,
        hierarchyId: string,
        parentId?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      inspectionPointId: string,
      inspectionPoint?:  {
        __typename: "InspectionPoint",
        id: string,
        groupId?: string | null,
        hierarchyItemId: string,
        type: InspectionPointType,
        projectId?: string | null,
        text: string,
        code?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      text?: string | null,
      suggestion?: string | null,
      findingId?: string | null,
      primaryLocation?:  {
        __typename: "Location",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        observationId: string,
        photoSequence?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        locationPrimaryPhotoId?: string | null,
        owner?: string | null,
      } | null,
      locations?:  {
        __typename: "ModelLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      observationPrimaryLocationId?: string | null,
      owner?: string | null,
    } | null,
    closingObservation?:  {
      __typename: "Observation",
      id: string,
      groupId?: string | null,
      type?: ObservationType | null,
      projectId?: string | null,
      inspectionId: string,
      hierarchyItemId: string,
      hierarchyItem?:  {
        __typename: "HierarchyItem",
        id: string,
        name: string,
        code?: string | null,
        hierarchyId: string,
        parentId?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      inspectionPointId: string,
      inspectionPoint?:  {
        __typename: "InspectionPoint",
        id: string,
        groupId?: string | null,
        hierarchyItemId: string,
        type: InspectionPointType,
        projectId?: string | null,
        text: string,
        code?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      text?: string | null,
      suggestion?: string | null,
      findingId?: string | null,
      primaryLocation?:  {
        __typename: "Location",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        observationId: string,
        photoSequence?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        locationPrimaryPhotoId?: string | null,
        owner?: string | null,
      } | null,
      locations?:  {
        __typename: "ModelLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      observationPrimaryLocationId?: string | null,
      owner?: string | null,
    } | null,
    status: FindingStatus,
    date?: string | null,
    closedDate?: string | null,
    code?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    findingClosingObservationId?: string | null,
  } | null,
};

export type DeleteFindingMutationVariables = {
  input: DeleteFindingInput,
  condition?: ModelFindingConditionInput | null,
};

export type DeleteFindingMutation = {
  deleteFinding?:  {
    __typename: "Finding",
    id: string,
    groupId?: string | null,
    type: FindingType,
    projectId: string,
    phaseId?: string | null,
    inspectionId: string,
    hierarchyItemId: string,
    hierarchyItem?:  {
      __typename: "HierarchyItem",
      id: string,
      name: string,
      code?: string | null,
      hierarchyId: string,
      parentId?: string | null,
      hidden?: boolean | null,
      inspectionPoints?:  {
        __typename: "ModelInspectionPointConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    observationId: string,
    observation?:  {
      __typename: "Observation",
      id: string,
      groupId?: string | null,
      type?: ObservationType | null,
      projectId?: string | null,
      inspectionId: string,
      hierarchyItemId: string,
      hierarchyItem?:  {
        __typename: "HierarchyItem",
        id: string,
        name: string,
        code?: string | null,
        hierarchyId: string,
        parentId?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      inspectionPointId: string,
      inspectionPoint?:  {
        __typename: "InspectionPoint",
        id: string,
        groupId?: string | null,
        hierarchyItemId: string,
        type: InspectionPointType,
        projectId?: string | null,
        text: string,
        code?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      text?: string | null,
      suggestion?: string | null,
      findingId?: string | null,
      primaryLocation?:  {
        __typename: "Location",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        observationId: string,
        photoSequence?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        locationPrimaryPhotoId?: string | null,
        owner?: string | null,
      } | null,
      locations?:  {
        __typename: "ModelLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      observationPrimaryLocationId?: string | null,
      owner?: string | null,
    } | null,
    closingObservation?:  {
      __typename: "Observation",
      id: string,
      groupId?: string | null,
      type?: ObservationType | null,
      projectId?: string | null,
      inspectionId: string,
      hierarchyItemId: string,
      hierarchyItem?:  {
        __typename: "HierarchyItem",
        id: string,
        name: string,
        code?: string | null,
        hierarchyId: string,
        parentId?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      inspectionPointId: string,
      inspectionPoint?:  {
        __typename: "InspectionPoint",
        id: string,
        groupId?: string | null,
        hierarchyItemId: string,
        type: InspectionPointType,
        projectId?: string | null,
        text: string,
        code?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      text?: string | null,
      suggestion?: string | null,
      findingId?: string | null,
      primaryLocation?:  {
        __typename: "Location",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        observationId: string,
        photoSequence?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        locationPrimaryPhotoId?: string | null,
        owner?: string | null,
      } | null,
      locations?:  {
        __typename: "ModelLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      observationPrimaryLocationId?: string | null,
      owner?: string | null,
    } | null,
    status: FindingStatus,
    date?: string | null,
    closedDate?: string | null,
    code?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    findingClosingObservationId?: string | null,
  } | null,
};

export type CreateHierarchyBundleMutationVariables = {
  input: CreateHierarchyBundleInput,
  condition?: ModelHierarchyBundleConditionInput | null,
};

export type CreateHierarchyBundleMutation = {
  createHierarchyBundle?:  {
    __typename: "HierarchyBundle",
    id: string,
    name: string,
    type: string,
    status: string,
    hierarchyItemIds?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type UpdateHierarchyBundleMutationVariables = {
  input: UpdateHierarchyBundleInput,
  condition?: ModelHierarchyBundleConditionInput | null,
};

export type UpdateHierarchyBundleMutation = {
  updateHierarchyBundle?:  {
    __typename: "HierarchyBundle",
    id: string,
    name: string,
    type: string,
    status: string,
    hierarchyItemIds?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type DeleteHierarchyBundleMutationVariables = {
  input: DeleteHierarchyBundleInput,
  condition?: ModelHierarchyBundleConditionInput | null,
};

export type DeleteHierarchyBundleMutation = {
  deleteHierarchyBundle?:  {
    __typename: "HierarchyBundle",
    id: string,
    name: string,
    type: string,
    status: string,
    hierarchyItemIds?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type CreateMilestoneTemplateMutationVariables = {
  input: CreateMilestoneTemplateInput,
  condition?: ModelMilestoneTemplateConditionInput | null,
};

export type CreateMilestoneTemplateMutation = {
  createMilestoneTemplate?:  {
    __typename: "MilestoneTemplate",
    id: string,
    name: string,
    status: string,
    hierarchyBundleIds?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type UpdateMilestoneTemplateMutationVariables = {
  input: UpdateMilestoneTemplateInput,
  condition?: ModelMilestoneTemplateConditionInput | null,
};

export type UpdateMilestoneTemplateMutation = {
  updateMilestoneTemplate?:  {
    __typename: "MilestoneTemplate",
    id: string,
    name: string,
    status: string,
    hierarchyBundleIds?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type DeleteMilestoneTemplateMutationVariables = {
  input: DeleteMilestoneTemplateInput,
  condition?: ModelMilestoneTemplateConditionInput | null,
};

export type DeleteMilestoneTemplateMutation = {
  deleteMilestoneTemplate?:  {
    __typename: "MilestoneTemplate",
    id: string,
    name: string,
    status: string,
    hierarchyBundleIds?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type CreateActivityStreamMutationVariables = {
  input: CreateActivityStreamInput,
  condition?: ModelActivityStreamConditionInput | null,
};

export type CreateActivityStreamMutation = {
  createActivityStream?:  {
    __typename: "ActivityStream",
    id: string,
    modelType: string,
    instanceId: string,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    date: string,
    type: string,
    text?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type UpdateActivityStreamMutationVariables = {
  input: UpdateActivityStreamInput,
  condition?: ModelActivityStreamConditionInput | null,
};

export type UpdateActivityStreamMutation = {
  updateActivityStream?:  {
    __typename: "ActivityStream",
    id: string,
    modelType: string,
    instanceId: string,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    date: string,
    type: string,
    text?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type DeleteActivityStreamMutationVariables = {
  input: DeleteActivityStreamInput,
  condition?: ModelActivityStreamConditionInput | null,
};

export type DeleteActivityStreamMutation = {
  deleteActivityStream?:  {
    __typename: "ActivityStream",
    id: string,
    modelType: string,
    instanceId: string,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    date: string,
    type: string,
    text?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type GetExternalLinkQueryVariables = {
  id: string,
};

export type GetExternalLinkQuery = {
  getExternalLink?:  {
    __typename: "ExternalLink",
    id: string,
    groupId?: string | null,
    projectId: string,
    title: string,
    url: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type ListExternalLinksQueryVariables = {
  filter?: ModelExternalLinkFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListExternalLinksQuery = {
  listExternalLinks?:  {
    __typename: "ModelExternalLinkConnection",
    items:  Array< {
      __typename: "ExternalLink",
      id: string,
      groupId?: string | null,
      projectId: string,
      title: string,
      url: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncExternalLinksQueryVariables = {
  filter?: ModelExternalLinkFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncExternalLinksQuery = {
  syncExternalLinks?:  {
    __typename: "ModelExternalLinkConnection",
    items:  Array< {
      __typename: "ExternalLink",
      id: string,
      groupId?: string | null,
      projectId: string,
      title: string,
      url: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ExternalLinksByProjectIdQueryVariables = {
  projectId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelExternalLinkFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ExternalLinksByProjectIdQuery = {
  externalLinksByProjectId?:  {
    __typename: "ModelExternalLinkConnection",
    items:  Array< {
      __typename: "ExternalLink",
      id: string,
      groupId?: string | null,
      projectId: string,
      title: string,
      url: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetContactQueryVariables = {
  id: string,
};

export type GetContactQuery = {
  getContact?:  {
    __typename: "Contact",
    id: string,
    type: string,
    firstName: string,
    lastName: string,
    companyName?: string | null,
    jobTitle?: string | null,
    phone?: string | null,
    email?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type ListContactsQueryVariables = {
  id?: string | null,
  filter?: ModelContactFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListContactsQuery = {
  listContacts?:  {
    __typename: "ModelContactConnection",
    items:  Array< {
      __typename: "Contact",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncContactsQueryVariables = {
  filter?: ModelContactFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncContactsQuery = {
  syncContacts?:  {
    __typename: "ModelContactConnection",
    items:  Array< {
      __typename: "Contact",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SearchContactsQueryVariables = {
  filter?: SearchableContactFilterInput | null,
  sort?: Array< SearchableContactSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableContactAggregationInput | null > | null,
};

export type SearchContactsQuery = {
  searchContacts?:  {
    __typename: "SearchableContactConnection",
    items:  Array< {
      __typename: "Contact",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    status: string,
    type: string,
    firstName: string,
    lastName: string,
    companyName?: string | null,
    jobTitle?: string | null,
    phone?: string | null,
    email?: string | null,
    metadata?: string | null,
    projects?: Array< string | null > | null,
    favoriteProjects?: string | null,
    inspections?:  {
      __typename: "ModelInspectionConnection",
      items:  Array< {
        __typename: "Inspection",
        id: string,
        groupId?: string | null,
        clientId?: string | null,
        projectId: string,
        phaseId?: string | null,
        photoSequence?: Array< string | null > | null,
        status: string,
        userId: string,
        inspectionReviewerId?: string | null,
        inspectionDate?: string | null,
        reviewedDate?: string | null,
        deliveredDate?: string | null,
        nextInspectionDate?: string | null,
        arrivalTime?: string | null,
        notes?: string | null,
        coverLetter?: string | null,
        scopeHierarchyItems?: Array< string | null > | null,
        scope?: string | null,
        number?: string | null,
        code?: string | null,
        weather?: string | null,
        milestoneBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        inspectionCheckInContactId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type ListUsersQueryVariables = {
  id?: string | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUsersQuery = {
  syncUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type UsersByTypeQueryVariables = {
  type: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsersByTypeQuery = {
  usersByType?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SearchUsersQueryVariables = {
  filter?: SearchableUserFilterInput | null,
  sort?: Array< SearchableUserSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableUserAggregationInput | null > | null,
};

export type SearchUsersQuery = {
  searchUsers?:  {
    __typename: "SearchableUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetClientQueryVariables = {
  id: string,
};

export type GetClientQuery = {
  getClient?:  {
    __typename: "Client",
    id: string,
    name: string,
    code: string,
    status: string,
    address:  {
      __typename: "Address",
      street: string,
      street2?: string | null,
      city: string,
      state: string,
      zip: string,
    },
    primaryContact?:  {
      __typename: "Contact",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    reportDisclaimer?: string | null,
    contacts?:  {
      __typename: "ModelClientContactConnection",
      items:  Array< {
        __typename: "ClientContact",
        id: string,
        clientId: string,
        contactId: string,
        label?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    projects?:  {
      __typename: "ModelProjectConnection",
      items:  Array< {
        __typename: "Project",
        id: string,
        groupId?: string | null,
        name: string,
        status: string,
        clientId: string,
        reportTemplateId?: string | null,
        reportDisclaimer?: string | null,
        distributionList?: string | null,
        hierarchyItemExclusions?: Array< string | null > | null,
        exclusions?: string | null,
        notes?: string | null,
        code?: string | null,
        milestoneTemplateId?: string | null,
        externalId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        projectEftId?: string | null,
        projectReviewerId?: string | null,
        projectAdminId?: string | null,
        projectPrimaryContactId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    clientPrimaryContactId?: string | null,
    owner?: string | null,
  } | null,
};

export type ListClientsQueryVariables = {
  id?: string | null,
  filter?: ModelClientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListClientsQuery = {
  listClients?:  {
    __typename: "ModelClientConnection",
    items:  Array< {
      __typename: "Client",
      id: string,
      name: string,
      code: string,
      status: string,
      address:  {
        __typename: "Address",
        street: string,
        street2?: string | null,
        city: string,
        state: string,
        zip: string,
      },
      primaryContact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reportDisclaimer?: string | null,
      contacts?:  {
        __typename: "ModelClientContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      projects?:  {
        __typename: "ModelProjectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      clientPrimaryContactId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncClientsQueryVariables = {
  filter?: ModelClientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncClientsQuery = {
  syncClients?:  {
    __typename: "ModelClientConnection",
    items:  Array< {
      __typename: "Client",
      id: string,
      name: string,
      code: string,
      status: string,
      address:  {
        __typename: "Address",
        street: string,
        street2?: string | null,
        city: string,
        state: string,
        zip: string,
      },
      primaryContact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reportDisclaimer?: string | null,
      contacts?:  {
        __typename: "ModelClientContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      projects?:  {
        __typename: "ModelProjectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      clientPrimaryContactId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SearchClientsQueryVariables = {
  filter?: SearchableClientFilterInput | null,
  sort?: Array< SearchableClientSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableClientAggregationInput | null > | null,
};

export type SearchClientsQuery = {
  searchClients?:  {
    __typename: "SearchableClientConnection",
    items:  Array< {
      __typename: "Client",
      id: string,
      name: string,
      code: string,
      status: string,
      address:  {
        __typename: "Address",
        street: string,
        street2?: string | null,
        city: string,
        state: string,
        zip: string,
      },
      primaryContact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reportDisclaimer?: string | null,
      contacts?:  {
        __typename: "ModelClientContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      projects?:  {
        __typename: "ModelProjectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      clientPrimaryContactId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetClientContactQueryVariables = {
  id: string,
};

export type GetClientContactQuery = {
  getClientContact?:  {
    __typename: "ClientContact",
    id: string,
    clientId: string,
    contactId: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    label?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type ListClientContactsQueryVariables = {
  filter?: ModelClientContactFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClientContactsQuery = {
  listClientContacts?:  {
    __typename: "ModelClientContactConnection",
    items:  Array< {
      __typename: "ClientContact",
      id: string,
      clientId: string,
      contactId: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      label?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncClientContactsQueryVariables = {
  filter?: ModelClientContactFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncClientContactsQuery = {
  syncClientContacts?:  {
    __typename: "ModelClientContactConnection",
    items:  Array< {
      __typename: "ClientContact",
      id: string,
      clientId: string,
      contactId: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      label?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ClientContactsByClientIdQueryVariables = {
  clientId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClientContactFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ClientContactsByClientIdQuery = {
  clientContactsByClientId?:  {
    __typename: "ModelClientContactConnection",
    items:  Array< {
      __typename: "ClientContact",
      id: string,
      clientId: string,
      contactId: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      label?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetProjectQueryVariables = {
  id: string,
};

export type GetProjectQuery = {
  getProject?:  {
    __typename: "Project",
    id: string,
    groupId?: string | null,
    name: string,
    status: string,
    clientId: string,
    client?:  {
      __typename: "Client",
      id: string,
      name: string,
      code: string,
      status: string,
      address:  {
        __typename: "Address",
        street: string,
        street2?: string | null,
        city: string,
        state: string,
        zip: string,
      },
      primaryContact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reportDisclaimer?: string | null,
      contacts?:  {
        __typename: "ModelClientContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      projects?:  {
        __typename: "ModelProjectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      clientPrimaryContactId?: string | null,
      owner?: string | null,
    } | null,
    eft?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    reviewer?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    admin?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    address:  {
      __typename: "Address",
      street: string,
      street2?: string | null,
      city: string,
      state: string,
      zip: string,
    },
    primaryContact?:  {
      __typename: "Contact",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    reportTemplateId?: string | null,
    reportDisclaimer?: string | null,
    inspections?:  {
      __typename: "ModelInspectionConnection",
      items:  Array< {
        __typename: "Inspection",
        id: string,
        groupId?: string | null,
        clientId?: string | null,
        projectId: string,
        phaseId?: string | null,
        photoSequence?: Array< string | null > | null,
        status: string,
        userId: string,
        inspectionReviewerId?: string | null,
        inspectionDate?: string | null,
        reviewedDate?: string | null,
        deliveredDate?: string | null,
        nextInspectionDate?: string | null,
        arrivalTime?: string | null,
        notes?: string | null,
        coverLetter?: string | null,
        scopeHierarchyItems?: Array< string | null > | null,
        scope?: string | null,
        number?: string | null,
        code?: string | null,
        weather?: string | null,
        milestoneBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        inspectionCheckInContactId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    contacts?:  {
      __typename: "ModelProjectContactConnection",
      items:  Array< {
        __typename: "ProjectContact",
        id: string,
        groupId?: string | null,
        projectId: string,
        contactId: string,
        label?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    distributionList?: string | null,
    externalLinks?:  {
      __typename: "ModelExternalLinkConnection",
      items:  Array< {
        __typename: "ExternalLink",
        id: string,
        groupId?: string | null,
        projectId: string,
        title: string,
        url: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    tags?:  {
      __typename: "ModelTagConnection",
      items:  Array< {
        __typename: "Tag",
        id: string,
        groupId?: string | null,
        type: TagType,
        projectId: string,
        text: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    findings?:  {
      __typename: "ModelFindingConnection",
      items:  Array< {
        __typename: "Finding",
        id: string,
        groupId?: string | null,
        type: FindingType,
        projectId: string,
        phaseId?: string | null,
        inspectionId: string,
        hierarchyItemId: string,
        observationId: string,
        status: FindingStatus,
        date?: string | null,
        closedDate?: string | null,
        code?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        findingClosingObservationId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    phases?:  {
      __typename: "ModelPhaseConnection",
      items:  Array< {
        __typename: "Phase",
        id: string,
        groupId?: string | null,
        projectId: string,
        name: string,
        status: string,
        milestoneTemplateId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    hierarchyItemExclusions?: Array< string | null > | null,
    exclusions?: string | null,
    notes?: string | null,
    code?: string | null,
    milestoneTemplate?:  {
      __typename: "MilestoneTemplate",
      id: string,
      name: string,
      status: string,
      hierarchyBundleIds?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    milestoneTemplateId?: string | null,
    externalId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    projectEftId?: string | null,
    projectReviewerId?: string | null,
    projectAdminId?: string | null,
    projectPrimaryContactId?: string | null,
    owner?: string | null,
  } | null,
};

export type ListProjectsQueryVariables = {
  id?: string | null,
  filter?: ModelProjectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListProjectsQuery = {
  listProjects?:  {
    __typename: "ModelProjectConnection",
    items:  Array< {
      __typename: "Project",
      id: string,
      groupId?: string | null,
      name: string,
      status: string,
      clientId: string,
      client?:  {
        __typename: "Client",
        id: string,
        name: string,
        code: string,
        status: string,
        reportDisclaimer?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        clientPrimaryContactId?: string | null,
        owner?: string | null,
      } | null,
      eft?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reviewer?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      admin?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      address:  {
        __typename: "Address",
        street: string,
        street2?: string | null,
        city: string,
        state: string,
        zip: string,
      },
      primaryContact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reportTemplateId?: string | null,
      reportDisclaimer?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      contacts?:  {
        __typename: "ModelProjectContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      distributionList?: string | null,
      externalLinks?:  {
        __typename: "ModelExternalLinkConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      tags?:  {
        __typename: "ModelTagConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      findings?:  {
        __typename: "ModelFindingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      phases?:  {
        __typename: "ModelPhaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hierarchyItemExclusions?: Array< string | null > | null,
      exclusions?: string | null,
      notes?: string | null,
      code?: string | null,
      milestoneTemplate?:  {
        __typename: "MilestoneTemplate",
        id: string,
        name: string,
        status: string,
        hierarchyBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      milestoneTemplateId?: string | null,
      externalId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      projectEftId?: string | null,
      projectReviewerId?: string | null,
      projectAdminId?: string | null,
      projectPrimaryContactId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncProjectsQueryVariables = {
  filter?: ModelProjectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncProjectsQuery = {
  syncProjects?:  {
    __typename: "ModelProjectConnection",
    items:  Array< {
      __typename: "Project",
      id: string,
      groupId?: string | null,
      name: string,
      status: string,
      clientId: string,
      client?:  {
        __typename: "Client",
        id: string,
        name: string,
        code: string,
        status: string,
        reportDisclaimer?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        clientPrimaryContactId?: string | null,
        owner?: string | null,
      } | null,
      eft?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reviewer?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      admin?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      address:  {
        __typename: "Address",
        street: string,
        street2?: string | null,
        city: string,
        state: string,
        zip: string,
      },
      primaryContact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reportTemplateId?: string | null,
      reportDisclaimer?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      contacts?:  {
        __typename: "ModelProjectContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      distributionList?: string | null,
      externalLinks?:  {
        __typename: "ModelExternalLinkConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      tags?:  {
        __typename: "ModelTagConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      findings?:  {
        __typename: "ModelFindingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      phases?:  {
        __typename: "ModelPhaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hierarchyItemExclusions?: Array< string | null > | null,
      exclusions?: string | null,
      notes?: string | null,
      code?: string | null,
      milestoneTemplate?:  {
        __typename: "MilestoneTemplate",
        id: string,
        name: string,
        status: string,
        hierarchyBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      milestoneTemplateId?: string | null,
      externalId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      projectEftId?: string | null,
      projectReviewerId?: string | null,
      projectAdminId?: string | null,
      projectPrimaryContactId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ProjectsByClientIdQueryVariables = {
  clientId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProjectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProjectsByClientIdQuery = {
  projectsByClientId?:  {
    __typename: "ModelProjectConnection",
    items:  Array< {
      __typename: "Project",
      id: string,
      groupId?: string | null,
      name: string,
      status: string,
      clientId: string,
      client?:  {
        __typename: "Client",
        id: string,
        name: string,
        code: string,
        status: string,
        reportDisclaimer?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        clientPrimaryContactId?: string | null,
        owner?: string | null,
      } | null,
      eft?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reviewer?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      admin?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      address:  {
        __typename: "Address",
        street: string,
        street2?: string | null,
        city: string,
        state: string,
        zip: string,
      },
      primaryContact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reportTemplateId?: string | null,
      reportDisclaimer?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      contacts?:  {
        __typename: "ModelProjectContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      distributionList?: string | null,
      externalLinks?:  {
        __typename: "ModelExternalLinkConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      tags?:  {
        __typename: "ModelTagConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      findings?:  {
        __typename: "ModelFindingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      phases?:  {
        __typename: "ModelPhaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hierarchyItemExclusions?: Array< string | null > | null,
      exclusions?: string | null,
      notes?: string | null,
      code?: string | null,
      milestoneTemplate?:  {
        __typename: "MilestoneTemplate",
        id: string,
        name: string,
        status: string,
        hierarchyBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      milestoneTemplateId?: string | null,
      externalId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      projectEftId?: string | null,
      projectReviewerId?: string | null,
      projectAdminId?: string | null,
      projectPrimaryContactId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SearchProjectsQueryVariables = {
  filter?: SearchableProjectFilterInput | null,
  sort?: Array< SearchableProjectSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableProjectAggregationInput | null > | null,
};

export type SearchProjectsQuery = {
  searchProjects?:  {
    __typename: "SearchableProjectConnection",
    items:  Array< {
      __typename: "Project",
      id: string,
      groupId?: string | null,
      name: string,
      status: string,
      clientId: string,
      client?:  {
        __typename: "Client",
        id: string,
        name: string,
        code: string,
        status: string,
        reportDisclaimer?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        clientPrimaryContactId?: string | null,
        owner?: string | null,
      } | null,
      eft?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reviewer?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      admin?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      address:  {
        __typename: "Address",
        street: string,
        street2?: string | null,
        city: string,
        state: string,
        zip: string,
      },
      primaryContact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reportTemplateId?: string | null,
      reportDisclaimer?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      contacts?:  {
        __typename: "ModelProjectContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      distributionList?: string | null,
      externalLinks?:  {
        __typename: "ModelExternalLinkConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      tags?:  {
        __typename: "ModelTagConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      findings?:  {
        __typename: "ModelFindingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      phases?:  {
        __typename: "ModelPhaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hierarchyItemExclusions?: Array< string | null > | null,
      exclusions?: string | null,
      notes?: string | null,
      code?: string | null,
      milestoneTemplate?:  {
        __typename: "MilestoneTemplate",
        id: string,
        name: string,
        status: string,
        hierarchyBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      milestoneTemplateId?: string | null,
      externalId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      projectEftId?: string | null,
      projectReviewerId?: string | null,
      projectAdminId?: string | null,
      projectPrimaryContactId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetPhaseQueryVariables = {
  id: string,
};

export type GetPhaseQuery = {
  getPhase?:  {
    __typename: "Phase",
    id: string,
    groupId?: string | null,
    projectId: string,
    project:  {
      __typename: "Project",
      id: string,
      groupId?: string | null,
      name: string,
      status: string,
      clientId: string,
      client?:  {
        __typename: "Client",
        id: string,
        name: string,
        code: string,
        status: string,
        reportDisclaimer?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        clientPrimaryContactId?: string | null,
        owner?: string | null,
      } | null,
      eft?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reviewer?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      admin?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      address:  {
        __typename: "Address",
        street: string,
        street2?: string | null,
        city: string,
        state: string,
        zip: string,
      },
      primaryContact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reportTemplateId?: string | null,
      reportDisclaimer?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      contacts?:  {
        __typename: "ModelProjectContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      distributionList?: string | null,
      externalLinks?:  {
        __typename: "ModelExternalLinkConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      tags?:  {
        __typename: "ModelTagConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      findings?:  {
        __typename: "ModelFindingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      phases?:  {
        __typename: "ModelPhaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hierarchyItemExclusions?: Array< string | null > | null,
      exclusions?: string | null,
      notes?: string | null,
      code?: string | null,
      milestoneTemplate?:  {
        __typename: "MilestoneTemplate",
        id: string,
        name: string,
        status: string,
        hierarchyBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      milestoneTemplateId?: string | null,
      externalId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      projectEftId?: string | null,
      projectReviewerId?: string | null,
      projectAdminId?: string | null,
      projectPrimaryContactId?: string | null,
      owner?: string | null,
    },
    name: string,
    status: string,
    units?:  Array< {
      __typename: "Unit",
      id: string,
      name: string,
    } | null > | null,
    milestoneTemplate?:  {
      __typename: "MilestoneTemplate",
      id: string,
      name: string,
      status: string,
      hierarchyBundleIds?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    milestoneTemplateId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type ListPhasesQueryVariables = {
  filter?: ModelPhaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPhasesQuery = {
  listPhases?:  {
    __typename: "ModelPhaseConnection",
    items:  Array< {
      __typename: "Phase",
      id: string,
      groupId?: string | null,
      projectId: string,
      project:  {
        __typename: "Project",
        id: string,
        groupId?: string | null,
        name: string,
        status: string,
        clientId: string,
        reportTemplateId?: string | null,
        reportDisclaimer?: string | null,
        distributionList?: string | null,
        hierarchyItemExclusions?: Array< string | null > | null,
        exclusions?: string | null,
        notes?: string | null,
        code?: string | null,
        milestoneTemplateId?: string | null,
        externalId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        projectEftId?: string | null,
        projectReviewerId?: string | null,
        projectAdminId?: string | null,
        projectPrimaryContactId?: string | null,
        owner?: string | null,
      },
      name: string,
      status: string,
      units?:  Array< {
        __typename: "Unit",
        id: string,
        name: string,
      } | null > | null,
      milestoneTemplate?:  {
        __typename: "MilestoneTemplate",
        id: string,
        name: string,
        status: string,
        hierarchyBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      milestoneTemplateId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncPhasesQueryVariables = {
  filter?: ModelPhaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncPhasesQuery = {
  syncPhases?:  {
    __typename: "ModelPhaseConnection",
    items:  Array< {
      __typename: "Phase",
      id: string,
      groupId?: string | null,
      projectId: string,
      project:  {
        __typename: "Project",
        id: string,
        groupId?: string | null,
        name: string,
        status: string,
        clientId: string,
        reportTemplateId?: string | null,
        reportDisclaimer?: string | null,
        distributionList?: string | null,
        hierarchyItemExclusions?: Array< string | null > | null,
        exclusions?: string | null,
        notes?: string | null,
        code?: string | null,
        milestoneTemplateId?: string | null,
        externalId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        projectEftId?: string | null,
        projectReviewerId?: string | null,
        projectAdminId?: string | null,
        projectPrimaryContactId?: string | null,
        owner?: string | null,
      },
      name: string,
      status: string,
      units?:  Array< {
        __typename: "Unit",
        id: string,
        name: string,
      } | null > | null,
      milestoneTemplate?:  {
        __typename: "MilestoneTemplate",
        id: string,
        name: string,
        status: string,
        hierarchyBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      milestoneTemplateId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type PhasesByProjectIdQueryVariables = {
  projectId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPhaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PhasesByProjectIdQuery = {
  phasesByProjectId?:  {
    __typename: "ModelPhaseConnection",
    items:  Array< {
      __typename: "Phase",
      id: string,
      groupId?: string | null,
      projectId: string,
      project:  {
        __typename: "Project",
        id: string,
        groupId?: string | null,
        name: string,
        status: string,
        clientId: string,
        reportTemplateId?: string | null,
        reportDisclaimer?: string | null,
        distributionList?: string | null,
        hierarchyItemExclusions?: Array< string | null > | null,
        exclusions?: string | null,
        notes?: string | null,
        code?: string | null,
        milestoneTemplateId?: string | null,
        externalId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        projectEftId?: string | null,
        projectReviewerId?: string | null,
        projectAdminId?: string | null,
        projectPrimaryContactId?: string | null,
        owner?: string | null,
      },
      name: string,
      status: string,
      units?:  Array< {
        __typename: "Unit",
        id: string,
        name: string,
      } | null > | null,
      milestoneTemplate?:  {
        __typename: "MilestoneTemplate",
        id: string,
        name: string,
        status: string,
        hierarchyBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      milestoneTemplateId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SearchPhasesQueryVariables = {
  filter?: SearchablePhaseFilterInput | null,
  sort?: Array< SearchablePhaseSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchablePhaseAggregationInput | null > | null,
};

export type SearchPhasesQuery = {
  searchPhases?:  {
    __typename: "SearchablePhaseConnection",
    items:  Array< {
      __typename: "Phase",
      id: string,
      groupId?: string | null,
      projectId: string,
      project:  {
        __typename: "Project",
        id: string,
        groupId?: string | null,
        name: string,
        status: string,
        clientId: string,
        reportTemplateId?: string | null,
        reportDisclaimer?: string | null,
        distributionList?: string | null,
        hierarchyItemExclusions?: Array< string | null > | null,
        exclusions?: string | null,
        notes?: string | null,
        code?: string | null,
        milestoneTemplateId?: string | null,
        externalId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        projectEftId?: string | null,
        projectReviewerId?: string | null,
        projectAdminId?: string | null,
        projectPrimaryContactId?: string | null,
        owner?: string | null,
      },
      name: string,
      status: string,
      units?:  Array< {
        __typename: "Unit",
        id: string,
        name: string,
      } | null > | null,
      milestoneTemplate?:  {
        __typename: "MilestoneTemplate",
        id: string,
        name: string,
        status: string,
        hierarchyBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      milestoneTemplateId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetProjectContactQueryVariables = {
  id: string,
};

export type GetProjectContactQuery = {
  getProjectContact?:  {
    __typename: "ProjectContact",
    id: string,
    groupId?: string | null,
    projectId: string,
    contactId: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    label?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type ListProjectContactsQueryVariables = {
  filter?: ModelProjectContactFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProjectContactsQuery = {
  listProjectContacts?:  {
    __typename: "ModelProjectContactConnection",
    items:  Array< {
      __typename: "ProjectContact",
      id: string,
      groupId?: string | null,
      projectId: string,
      contactId: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      label?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncProjectContactsQueryVariables = {
  filter?: ModelProjectContactFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncProjectContactsQuery = {
  syncProjectContacts?:  {
    __typename: "ModelProjectContactConnection",
    items:  Array< {
      __typename: "ProjectContact",
      id: string,
      groupId?: string | null,
      projectId: string,
      contactId: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      label?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ProjectContactsByProjectIdQueryVariables = {
  projectId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProjectContactFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProjectContactsByProjectIdQuery = {
  projectContactsByProjectId?:  {
    __typename: "ModelProjectContactConnection",
    items:  Array< {
      __typename: "ProjectContact",
      id: string,
      groupId?: string | null,
      projectId: string,
      contactId: string,
      contact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      label?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetFavoriteProjectQueryVariables = {
  id: string,
};

export type GetFavoriteProjectQuery = {
  getFavoriteProject?:  {
    __typename: "FavoriteProject",
    id: string,
    projectId: string,
    project:  {
      __typename: "Project",
      id: string,
      groupId?: string | null,
      name: string,
      status: string,
      clientId: string,
      client?:  {
        __typename: "Client",
        id: string,
        name: string,
        code: string,
        status: string,
        reportDisclaimer?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        clientPrimaryContactId?: string | null,
        owner?: string | null,
      } | null,
      eft?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reviewer?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      admin?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      address:  {
        __typename: "Address",
        street: string,
        street2?: string | null,
        city: string,
        state: string,
        zip: string,
      },
      primaryContact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reportTemplateId?: string | null,
      reportDisclaimer?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      contacts?:  {
        __typename: "ModelProjectContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      distributionList?: string | null,
      externalLinks?:  {
        __typename: "ModelExternalLinkConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      tags?:  {
        __typename: "ModelTagConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      findings?:  {
        __typename: "ModelFindingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      phases?:  {
        __typename: "ModelPhaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hierarchyItemExclusions?: Array< string | null > | null,
      exclusions?: string | null,
      notes?: string | null,
      code?: string | null,
      milestoneTemplate?:  {
        __typename: "MilestoneTemplate",
        id: string,
        name: string,
        status: string,
        hierarchyBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      milestoneTemplateId?: string | null,
      externalId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      projectEftId?: string | null,
      projectReviewerId?: string | null,
      projectAdminId?: string | null,
      projectPrimaryContactId?: string | null,
      owner?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type ListFavoriteProjectsQueryVariables = {
  filter?: ModelFavoriteProjectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFavoriteProjectsQuery = {
  listFavoriteProjects?:  {
    __typename: "ModelFavoriteProjectConnection",
    items:  Array< {
      __typename: "FavoriteProject",
      id: string,
      projectId: string,
      project:  {
        __typename: "Project",
        id: string,
        groupId?: string | null,
        name: string,
        status: string,
        clientId: string,
        reportTemplateId?: string | null,
        reportDisclaimer?: string | null,
        distributionList?: string | null,
        hierarchyItemExclusions?: Array< string | null > | null,
        exclusions?: string | null,
        notes?: string | null,
        code?: string | null,
        milestoneTemplateId?: string | null,
        externalId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        projectEftId?: string | null,
        projectReviewerId?: string | null,
        projectAdminId?: string | null,
        projectPrimaryContactId?: string | null,
        owner?: string | null,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncFavoriteProjectsQueryVariables = {
  filter?: ModelFavoriteProjectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncFavoriteProjectsQuery = {
  syncFavoriteProjects?:  {
    __typename: "ModelFavoriteProjectConnection",
    items:  Array< {
      __typename: "FavoriteProject",
      id: string,
      projectId: string,
      project:  {
        __typename: "Project",
        id: string,
        groupId?: string | null,
        name: string,
        status: string,
        clientId: string,
        reportTemplateId?: string | null,
        reportDisclaimer?: string | null,
        distributionList?: string | null,
        hierarchyItemExclusions?: Array< string | null > | null,
        exclusions?: string | null,
        notes?: string | null,
        code?: string | null,
        milestoneTemplateId?: string | null,
        externalId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        projectEftId?: string | null,
        projectReviewerId?: string | null,
        projectAdminId?: string | null,
        projectPrimaryContactId?: string | null,
        owner?: string | null,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetInspectionQueryVariables = {
  id: string,
};

export type GetInspectionQuery = {
  getInspection?:  {
    __typename: "Inspection",
    id: string,
    groupId?: string | null,
    clientId?: string | null,
    projectId: string,
    project?:  {
      __typename: "Project",
      id: string,
      groupId?: string | null,
      name: string,
      status: string,
      clientId: string,
      client?:  {
        __typename: "Client",
        id: string,
        name: string,
        code: string,
        status: string,
        reportDisclaimer?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        clientPrimaryContactId?: string | null,
        owner?: string | null,
      } | null,
      eft?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reviewer?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      admin?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      address:  {
        __typename: "Address",
        street: string,
        street2?: string | null,
        city: string,
        state: string,
        zip: string,
      },
      primaryContact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reportTemplateId?: string | null,
      reportDisclaimer?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      contacts?:  {
        __typename: "ModelProjectContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      distributionList?: string | null,
      externalLinks?:  {
        __typename: "ModelExternalLinkConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      tags?:  {
        __typename: "ModelTagConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      findings?:  {
        __typename: "ModelFindingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      phases?:  {
        __typename: "ModelPhaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hierarchyItemExclusions?: Array< string | null > | null,
      exclusions?: string | null,
      notes?: string | null,
      code?: string | null,
      milestoneTemplate?:  {
        __typename: "MilestoneTemplate",
        id: string,
        name: string,
        status: string,
        hierarchyBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      milestoneTemplateId?: string | null,
      externalId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      projectEftId?: string | null,
      projectReviewerId?: string | null,
      projectAdminId?: string | null,
      projectPrimaryContactId?: string | null,
      owner?: string | null,
    } | null,
    phase?:  {
      __typename: "Phase",
      id: string,
      groupId?: string | null,
      projectId: string,
      project:  {
        __typename: "Project",
        id: string,
        groupId?: string | null,
        name: string,
        status: string,
        clientId: string,
        reportTemplateId?: string | null,
        reportDisclaimer?: string | null,
        distributionList?: string | null,
        hierarchyItemExclusions?: Array< string | null > | null,
        exclusions?: string | null,
        notes?: string | null,
        code?: string | null,
        milestoneTemplateId?: string | null,
        externalId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        projectEftId?: string | null,
        projectReviewerId?: string | null,
        projectAdminId?: string | null,
        projectPrimaryContactId?: string | null,
        owner?: string | null,
      },
      name: string,
      status: string,
      units?:  Array< {
        __typename: "Unit",
        id: string,
        name: string,
      } | null > | null,
      milestoneTemplate?:  {
        __typename: "MilestoneTemplate",
        id: string,
        name: string,
        status: string,
        hierarchyBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      milestoneTemplateId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    phaseId?: string | null,
    photoSequence?: Array< string | null > | null,
    status: string,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    reviewer?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    inspectionReviewerId?: string | null,
    inspectionDate?: string | null,
    reviewedDate?: string | null,
    deliveredDate?: string | null,
    nextInspectionDate?: string | null,
    arrivalTime?: string | null,
    checkInContact?:  {
      __typename: "Contact",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    notes?: string | null,
    coverLetter?: string | null,
    comments?:  {
      __typename: "ModelInspectionCommentConnection",
      items:  Array< {
        __typename: "InspectionComment",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        inspectionId: string,
        commentId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    observations?:  {
      __typename: "ModelObservationConnection",
      items:  Array< {
        __typename: "Observation",
        id: string,
        groupId?: string | null,
        type?: ObservationType | null,
        projectId?: string | null,
        inspectionId: string,
        hierarchyItemId: string,
        inspectionPointId: string,
        text?: string | null,
        suggestion?: string | null,
        findingId?: string | null,
        status?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        observationPrimaryLocationId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    findings?:  {
      __typename: "ModelFindingConnection",
      items:  Array< {
        __typename: "Finding",
        id: string,
        groupId?: string | null,
        type: FindingType,
        projectId: string,
        phaseId?: string | null,
        inspectionId: string,
        hierarchyItemId: string,
        observationId: string,
        status: FindingStatus,
        date?: string | null,
        closedDate?: string | null,
        code?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        findingClosingObservationId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    hierarchyItems?:  {
      __typename: "ModelInspectionHierarchyItemConnection",
      items:  Array< {
        __typename: "InspectionHierarchyItem",
        id: string,
        inspectionId: string,
        hierarchyItemId: string,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    photos?:  {
      __typename: "ModelInspectionPhotoConnection",
      items:  Array< {
        __typename: "InspectionPhoto",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        inspectionId: string,
        photoId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    scopeHierarchyItems?: Array< string | null > | null,
    scope?: string | null,
    number?: string | null,
    code?: string | null,
    report?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    weather?: string | null,
    milestoneBundleIds?: string | null,
    milestoneTrackers?:  Array< {
      __typename: "MilestoneTracker",
      milestoneId: string,
      all?: boolean | null,
      units?: Array< string | null > | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    inspectionCheckInContactId?: string | null,
    owner?: string | null,
  } | null,
};

export type ListInspectionsQueryVariables = {
  id?: string | null,
  filter?: ModelInspectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListInspectionsQuery = {
  listInspections?:  {
    __typename: "ModelInspectionConnection",
    items:  Array< {
      __typename: "Inspection",
      id: string,
      groupId?: string | null,
      clientId?: string | null,
      projectId: string,
      project?:  {
        __typename: "Project",
        id: string,
        groupId?: string | null,
        name: string,
        status: string,
        clientId: string,
        reportTemplateId?: string | null,
        reportDisclaimer?: string | null,
        distributionList?: string | null,
        hierarchyItemExclusions?: Array< string | null > | null,
        exclusions?: string | null,
        notes?: string | null,
        code?: string | null,
        milestoneTemplateId?: string | null,
        externalId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        projectEftId?: string | null,
        projectReviewerId?: string | null,
        projectAdminId?: string | null,
        projectPrimaryContactId?: string | null,
        owner?: string | null,
      } | null,
      phase?:  {
        __typename: "Phase",
        id: string,
        groupId?: string | null,
        projectId: string,
        name: string,
        status: string,
        milestoneTemplateId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      phaseId?: string | null,
      photoSequence?: Array< string | null > | null,
      status: string,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reviewer?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      inspectionReviewerId?: string | null,
      inspectionDate?: string | null,
      reviewedDate?: string | null,
      deliveredDate?: string | null,
      nextInspectionDate?: string | null,
      arrivalTime?: string | null,
      checkInContact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      notes?: string | null,
      coverLetter?: string | null,
      comments?:  {
        __typename: "ModelInspectionCommentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      observations?:  {
        __typename: "ModelObservationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      findings?:  {
        __typename: "ModelFindingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hierarchyItems?:  {
        __typename: "ModelInspectionHierarchyItemConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      photos?:  {
        __typename: "ModelInspectionPhotoConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      scopeHierarchyItems?: Array< string | null > | null,
      scope?: string | null,
      number?: string | null,
      code?: string | null,
      report?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      weather?: string | null,
      milestoneBundleIds?: string | null,
      milestoneTrackers?:  Array< {
        __typename: "MilestoneTracker",
        milestoneId: string,
        all?: boolean | null,
        units?: Array< string | null > | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      inspectionCheckInContactId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncInspectionsQueryVariables = {
  filter?: ModelInspectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncInspectionsQuery = {
  syncInspections?:  {
    __typename: "ModelInspectionConnection",
    items:  Array< {
      __typename: "Inspection",
      id: string,
      groupId?: string | null,
      clientId?: string | null,
      projectId: string,
      project?:  {
        __typename: "Project",
        id: string,
        groupId?: string | null,
        name: string,
        status: string,
        clientId: string,
        reportTemplateId?: string | null,
        reportDisclaimer?: string | null,
        distributionList?: string | null,
        hierarchyItemExclusions?: Array< string | null > | null,
        exclusions?: string | null,
        notes?: string | null,
        code?: string | null,
        milestoneTemplateId?: string | null,
        externalId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        projectEftId?: string | null,
        projectReviewerId?: string | null,
        projectAdminId?: string | null,
        projectPrimaryContactId?: string | null,
        owner?: string | null,
      } | null,
      phase?:  {
        __typename: "Phase",
        id: string,
        groupId?: string | null,
        projectId: string,
        name: string,
        status: string,
        milestoneTemplateId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      phaseId?: string | null,
      photoSequence?: Array< string | null > | null,
      status: string,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reviewer?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      inspectionReviewerId?: string | null,
      inspectionDate?: string | null,
      reviewedDate?: string | null,
      deliveredDate?: string | null,
      nextInspectionDate?: string | null,
      arrivalTime?: string | null,
      checkInContact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      notes?: string | null,
      coverLetter?: string | null,
      comments?:  {
        __typename: "ModelInspectionCommentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      observations?:  {
        __typename: "ModelObservationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      findings?:  {
        __typename: "ModelFindingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hierarchyItems?:  {
        __typename: "ModelInspectionHierarchyItemConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      photos?:  {
        __typename: "ModelInspectionPhotoConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      scopeHierarchyItems?: Array< string | null > | null,
      scope?: string | null,
      number?: string | null,
      code?: string | null,
      report?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      weather?: string | null,
      milestoneBundleIds?: string | null,
      milestoneTrackers?:  Array< {
        __typename: "MilestoneTracker",
        milestoneId: string,
        all?: boolean | null,
        units?: Array< string | null > | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      inspectionCheckInContactId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type InspectionsByProjectIdQueryVariables = {
  projectId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInspectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type InspectionsByProjectIdQuery = {
  inspectionsByProjectId?:  {
    __typename: "ModelInspectionConnection",
    items:  Array< {
      __typename: "Inspection",
      id: string,
      groupId?: string | null,
      clientId?: string | null,
      projectId: string,
      project?:  {
        __typename: "Project",
        id: string,
        groupId?: string | null,
        name: string,
        status: string,
        clientId: string,
        reportTemplateId?: string | null,
        reportDisclaimer?: string | null,
        distributionList?: string | null,
        hierarchyItemExclusions?: Array< string | null > | null,
        exclusions?: string | null,
        notes?: string | null,
        code?: string | null,
        milestoneTemplateId?: string | null,
        externalId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        projectEftId?: string | null,
        projectReviewerId?: string | null,
        projectAdminId?: string | null,
        projectPrimaryContactId?: string | null,
        owner?: string | null,
      } | null,
      phase?:  {
        __typename: "Phase",
        id: string,
        groupId?: string | null,
        projectId: string,
        name: string,
        status: string,
        milestoneTemplateId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      phaseId?: string | null,
      photoSequence?: Array< string | null > | null,
      status: string,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reviewer?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      inspectionReviewerId?: string | null,
      inspectionDate?: string | null,
      reviewedDate?: string | null,
      deliveredDate?: string | null,
      nextInspectionDate?: string | null,
      arrivalTime?: string | null,
      checkInContact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      notes?: string | null,
      coverLetter?: string | null,
      comments?:  {
        __typename: "ModelInspectionCommentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      observations?:  {
        __typename: "ModelObservationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      findings?:  {
        __typename: "ModelFindingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hierarchyItems?:  {
        __typename: "ModelInspectionHierarchyItemConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      photos?:  {
        __typename: "ModelInspectionPhotoConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      scopeHierarchyItems?: Array< string | null > | null,
      scope?: string | null,
      number?: string | null,
      code?: string | null,
      report?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      weather?: string | null,
      milestoneBundleIds?: string | null,
      milestoneTrackers?:  Array< {
        __typename: "MilestoneTracker",
        milestoneId: string,
        all?: boolean | null,
        units?: Array< string | null > | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      inspectionCheckInContactId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type InspectionsByUserIdQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInspectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type InspectionsByUserIdQuery = {
  inspectionsByUserId?:  {
    __typename: "ModelInspectionConnection",
    items:  Array< {
      __typename: "Inspection",
      id: string,
      groupId?: string | null,
      clientId?: string | null,
      projectId: string,
      project?:  {
        __typename: "Project",
        id: string,
        groupId?: string | null,
        name: string,
        status: string,
        clientId: string,
        reportTemplateId?: string | null,
        reportDisclaimer?: string | null,
        distributionList?: string | null,
        hierarchyItemExclusions?: Array< string | null > | null,
        exclusions?: string | null,
        notes?: string | null,
        code?: string | null,
        milestoneTemplateId?: string | null,
        externalId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        projectEftId?: string | null,
        projectReviewerId?: string | null,
        projectAdminId?: string | null,
        projectPrimaryContactId?: string | null,
        owner?: string | null,
      } | null,
      phase?:  {
        __typename: "Phase",
        id: string,
        groupId?: string | null,
        projectId: string,
        name: string,
        status: string,
        milestoneTemplateId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      phaseId?: string | null,
      photoSequence?: Array< string | null > | null,
      status: string,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reviewer?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      inspectionReviewerId?: string | null,
      inspectionDate?: string | null,
      reviewedDate?: string | null,
      deliveredDate?: string | null,
      nextInspectionDate?: string | null,
      arrivalTime?: string | null,
      checkInContact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      notes?: string | null,
      coverLetter?: string | null,
      comments?:  {
        __typename: "ModelInspectionCommentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      observations?:  {
        __typename: "ModelObservationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      findings?:  {
        __typename: "ModelFindingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hierarchyItems?:  {
        __typename: "ModelInspectionHierarchyItemConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      photos?:  {
        __typename: "ModelInspectionPhotoConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      scopeHierarchyItems?: Array< string | null > | null,
      scope?: string | null,
      number?: string | null,
      code?: string | null,
      report?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      weather?: string | null,
      milestoneBundleIds?: string | null,
      milestoneTrackers?:  Array< {
        __typename: "MilestoneTracker",
        milestoneId: string,
        all?: boolean | null,
        units?: Array< string | null > | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      inspectionCheckInContactId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SearchInspectionsQueryVariables = {
  filter?: SearchableInspectionFilterInput | null,
  sort?: Array< SearchableInspectionSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableInspectionAggregationInput | null > | null,
};

export type SearchInspectionsQuery = {
  searchInspections?:  {
    __typename: "SearchableInspectionConnection",
    items:  Array< {
      __typename: "Inspection",
      id: string,
      groupId?: string | null,
      clientId?: string | null,
      projectId: string,
      project?:  {
        __typename: "Project",
        id: string,
        groupId?: string | null,
        name: string,
        status: string,
        clientId: string,
        reportTemplateId?: string | null,
        reportDisclaimer?: string | null,
        distributionList?: string | null,
        hierarchyItemExclusions?: Array< string | null > | null,
        exclusions?: string | null,
        notes?: string | null,
        code?: string | null,
        milestoneTemplateId?: string | null,
        externalId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        projectEftId?: string | null,
        projectReviewerId?: string | null,
        projectAdminId?: string | null,
        projectPrimaryContactId?: string | null,
        owner?: string | null,
      } | null,
      phase?:  {
        __typename: "Phase",
        id: string,
        groupId?: string | null,
        projectId: string,
        name: string,
        status: string,
        milestoneTemplateId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      phaseId?: string | null,
      photoSequence?: Array< string | null > | null,
      status: string,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reviewer?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      inspectionReviewerId?: string | null,
      inspectionDate?: string | null,
      reviewedDate?: string | null,
      deliveredDate?: string | null,
      nextInspectionDate?: string | null,
      arrivalTime?: string | null,
      checkInContact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      notes?: string | null,
      coverLetter?: string | null,
      comments?:  {
        __typename: "ModelInspectionCommentConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      observations?:  {
        __typename: "ModelObservationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      findings?:  {
        __typename: "ModelFindingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hierarchyItems?:  {
        __typename: "ModelInspectionHierarchyItemConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      photos?:  {
        __typename: "ModelInspectionPhotoConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      scopeHierarchyItems?: Array< string | null > | null,
      scope?: string | null,
      number?: string | null,
      code?: string | null,
      report?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      weather?: string | null,
      milestoneBundleIds?: string | null,
      milestoneTrackers?:  Array< {
        __typename: "MilestoneTracker",
        milestoneId: string,
        all?: boolean | null,
        units?: Array< string | null > | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      inspectionCheckInContactId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetHierarchyQueryVariables = {
  id: string,
};

export type GetHierarchyQuery = {
  getHierarchy?:  {
    __typename: "Hierarchy",
    id: string,
    name: string,
    items?:  {
      __typename: "ModelHierarchyItemConnection",
      items:  Array< {
        __typename: "HierarchyItem",
        id: string,
        name: string,
        code?: string | null,
        hierarchyId: string,
        parentId?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type ListHierarchiesQueryVariables = {
  filter?: ModelHierarchyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListHierarchiesQuery = {
  listHierarchies?:  {
    __typename: "ModelHierarchyConnection",
    items:  Array< {
      __typename: "Hierarchy",
      id: string,
      name: string,
      items?:  {
        __typename: "ModelHierarchyItemConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncHierarchiesQueryVariables = {
  filter?: ModelHierarchyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncHierarchiesQuery = {
  syncHierarchies?:  {
    __typename: "ModelHierarchyConnection",
    items:  Array< {
      __typename: "Hierarchy",
      id: string,
      name: string,
      items?:  {
        __typename: "ModelHierarchyItemConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetHierarchyItemQueryVariables = {
  id: string,
};

export type GetHierarchyItemQuery = {
  getHierarchyItem?:  {
    __typename: "HierarchyItem",
    id: string,
    name: string,
    code?: string | null,
    hierarchyId: string,
    parentId?: string | null,
    hidden?: boolean | null,
    inspectionPoints?:  {
      __typename: "ModelInspectionPointConnection",
      items:  Array< {
        __typename: "InspectionPoint",
        id: string,
        groupId?: string | null,
        hierarchyItemId: string,
        type: InspectionPointType,
        projectId?: string | null,
        text: string,
        code?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type ListHierarchyItemsQueryVariables = {
  filter?: ModelHierarchyItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListHierarchyItemsQuery = {
  listHierarchyItems?:  {
    __typename: "ModelHierarchyItemConnection",
    items:  Array< {
      __typename: "HierarchyItem",
      id: string,
      name: string,
      code?: string | null,
      hierarchyId: string,
      parentId?: string | null,
      hidden?: boolean | null,
      inspectionPoints?:  {
        __typename: "ModelInspectionPointConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncHierarchyItemsQueryVariables = {
  filter?: ModelHierarchyItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncHierarchyItemsQuery = {
  syncHierarchyItems?:  {
    __typename: "ModelHierarchyItemConnection",
    items:  Array< {
      __typename: "HierarchyItem",
      id: string,
      name: string,
      code?: string | null,
      hierarchyId: string,
      parentId?: string | null,
      hidden?: boolean | null,
      inspectionPoints?:  {
        __typename: "ModelInspectionPointConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type HierarchyItemsByHierarchyIdQueryVariables = {
  hierarchyId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelHierarchyItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type HierarchyItemsByHierarchyIdQuery = {
  hierarchyItemsByHierarchyId?:  {
    __typename: "ModelHierarchyItemConnection",
    items:  Array< {
      __typename: "HierarchyItem",
      id: string,
      name: string,
      code?: string | null,
      hierarchyId: string,
      parentId?: string | null,
      hidden?: boolean | null,
      inspectionPoints?:  {
        __typename: "ModelInspectionPointConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type HierarchyItemsByParentIdQueryVariables = {
  parentId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelHierarchyItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type HierarchyItemsByParentIdQuery = {
  hierarchyItemsByParentId?:  {
    __typename: "ModelHierarchyItemConnection",
    items:  Array< {
      __typename: "HierarchyItem",
      id: string,
      name: string,
      code?: string | null,
      hierarchyId: string,
      parentId?: string | null,
      hidden?: boolean | null,
      inspectionPoints?:  {
        __typename: "ModelInspectionPointConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetInspectionHierarchyItemQueryVariables = {
  id: string,
};

export type GetInspectionHierarchyItemQuery = {
  getInspectionHierarchyItem?:  {
    __typename: "InspectionHierarchyItem",
    id: string,
    inspectionId: string,
    hierarchyItemId: string,
    hierarchyItem?:  {
      __typename: "HierarchyItem",
      id: string,
      name: string,
      code?: string | null,
      hierarchyId: string,
      parentId?: string | null,
      hidden?: boolean | null,
      inspectionPoints?:  {
        __typename: "ModelInspectionPointConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    notes?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type ListInspectionHierarchyItemsQueryVariables = {
  filter?: ModelInspectionHierarchyItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInspectionHierarchyItemsQuery = {
  listInspectionHierarchyItems?:  {
    __typename: "ModelInspectionHierarchyItemConnection",
    items:  Array< {
      __typename: "InspectionHierarchyItem",
      id: string,
      inspectionId: string,
      hierarchyItemId: string,
      hierarchyItem?:  {
        __typename: "HierarchyItem",
        id: string,
        name: string,
        code?: string | null,
        hierarchyId: string,
        parentId?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncInspectionHierarchyItemsQueryVariables = {
  filter?: ModelInspectionHierarchyItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncInspectionHierarchyItemsQuery = {
  syncInspectionHierarchyItems?:  {
    __typename: "ModelInspectionHierarchyItemConnection",
    items:  Array< {
      __typename: "InspectionHierarchyItem",
      id: string,
      inspectionId: string,
      hierarchyItemId: string,
      hierarchyItem?:  {
        __typename: "HierarchyItem",
        id: string,
        name: string,
        code?: string | null,
        hierarchyId: string,
        parentId?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type InspectionHierarchyItemsByInspectionIdQueryVariables = {
  inspectionId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInspectionHierarchyItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type InspectionHierarchyItemsByInspectionIdQuery = {
  inspectionHierarchyItemsByInspectionId?:  {
    __typename: "ModelInspectionHierarchyItemConnection",
    items:  Array< {
      __typename: "InspectionHierarchyItem",
      id: string,
      inspectionId: string,
      hierarchyItemId: string,
      hierarchyItem?:  {
        __typename: "HierarchyItem",
        id: string,
        name: string,
        code?: string | null,
        hierarchyId: string,
        parentId?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetInspectionPointQueryVariables = {
  id: string,
};

export type GetInspectionPointQuery = {
  getInspectionPoint?:  {
    __typename: "InspectionPoint",
    id: string,
    groupId?: string | null,
    hierarchyItemId: string,
    type: InspectionPointType,
    projectId?: string | null,
    text: string,
    code?: string | null,
    hidden?: boolean | null,
    observations?:  {
      __typename: "ModelObservationConnection",
      items:  Array< {
        __typename: "Observation",
        id: string,
        groupId?: string | null,
        type?: ObservationType | null,
        projectId?: string | null,
        inspectionId: string,
        hierarchyItemId: string,
        inspectionPointId: string,
        text?: string | null,
        suggestion?: string | null,
        findingId?: string | null,
        status?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        observationPrimaryLocationId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type ListInspectionPointsQueryVariables = {
  id?: string | null,
  filter?: ModelInspectionPointFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListInspectionPointsQuery = {
  listInspectionPoints?:  {
    __typename: "ModelInspectionPointConnection",
    items:  Array< {
      __typename: "InspectionPoint",
      id: string,
      groupId?: string | null,
      hierarchyItemId: string,
      type: InspectionPointType,
      projectId?: string | null,
      text: string,
      code?: string | null,
      hidden?: boolean | null,
      observations?:  {
        __typename: "ModelObservationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncInspectionPointsQueryVariables = {
  filter?: ModelInspectionPointFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncInspectionPointsQuery = {
  syncInspectionPoints?:  {
    __typename: "ModelInspectionPointConnection",
    items:  Array< {
      __typename: "InspectionPoint",
      id: string,
      groupId?: string | null,
      hierarchyItemId: string,
      type: InspectionPointType,
      projectId?: string | null,
      text: string,
      code?: string | null,
      hidden?: boolean | null,
      observations?:  {
        __typename: "ModelObservationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type InspectionPointsByHierarchyItemIdQueryVariables = {
  hierarchyItemId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInspectionPointFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type InspectionPointsByHierarchyItemIdQuery = {
  inspectionPointsByHierarchyItemId?:  {
    __typename: "ModelInspectionPointConnection",
    items:  Array< {
      __typename: "InspectionPoint",
      id: string,
      groupId?: string | null,
      hierarchyItemId: string,
      type: InspectionPointType,
      projectId?: string | null,
      text: string,
      code?: string | null,
      hidden?: boolean | null,
      observations?:  {
        __typename: "ModelObservationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SearchInspectionPointsQueryVariables = {
  filter?: SearchableInspectionPointFilterInput | null,
  sort?: Array< SearchableInspectionPointSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableInspectionPointAggregationInput | null > | null,
};

export type SearchInspectionPointsQuery = {
  searchInspectionPoints?:  {
    __typename: "SearchableInspectionPointConnection",
    items:  Array< {
      __typename: "InspectionPoint",
      id: string,
      groupId?: string | null,
      hierarchyItemId: string,
      type: InspectionPointType,
      projectId?: string | null,
      text: string,
      code?: string | null,
      hidden?: boolean | null,
      observations?:  {
        __typename: "ModelObservationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetCommentQueryVariables = {
  id: string,
};

export type GetCommentQuery = {
  getComment?:  {
    __typename: "Comment",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    date: string,
    text: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type ListCommentsQueryVariables = {
  filter?: ModelCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommentsQuery = {
  listComments?:  {
    __typename: "ModelCommentConnection",
    items:  Array< {
      __typename: "Comment",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      date: string,
      text: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCommentsQueryVariables = {
  filter?: ModelCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCommentsQuery = {
  syncComments?:  {
    __typename: "ModelCommentConnection",
    items:  Array< {
      __typename: "Comment",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      date: string,
      text: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetInspectionCommentQueryVariables = {
  id: string,
};

export type GetInspectionCommentQuery = {
  getInspectionComment?:  {
    __typename: "InspectionComment",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    inspectionId: string,
    commentId: string,
    comment?:  {
      __typename: "Comment",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      date: string,
      text: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type ListInspectionCommentsQueryVariables = {
  filter?: ModelInspectionCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInspectionCommentsQuery = {
  listInspectionComments?:  {
    __typename: "ModelInspectionCommentConnection",
    items:  Array< {
      __typename: "InspectionComment",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      inspectionId: string,
      commentId: string,
      comment?:  {
        __typename: "Comment",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        userId: string,
        date: string,
        text: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncInspectionCommentsQueryVariables = {
  filter?: ModelInspectionCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncInspectionCommentsQuery = {
  syncInspectionComments?:  {
    __typename: "ModelInspectionCommentConnection",
    items:  Array< {
      __typename: "InspectionComment",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      inspectionId: string,
      commentId: string,
      comment?:  {
        __typename: "Comment",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        userId: string,
        date: string,
        text: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CommentsByInspectionIdQueryVariables = {
  inspectionId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInspectionCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CommentsByInspectionIdQuery = {
  commentsByInspectionId?:  {
    __typename: "ModelInspectionCommentConnection",
    items:  Array< {
      __typename: "InspectionComment",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      inspectionId: string,
      commentId: string,
      comment?:  {
        __typename: "Comment",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        userId: string,
        date: string,
        text: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetObservationQueryVariables = {
  id: string,
};

export type GetObservationQuery = {
  getObservation?:  {
    __typename: "Observation",
    id: string,
    groupId?: string | null,
    type?: ObservationType | null,
    projectId?: string | null,
    inspectionId: string,
    hierarchyItemId: string,
    hierarchyItem?:  {
      __typename: "HierarchyItem",
      id: string,
      name: string,
      code?: string | null,
      hierarchyId: string,
      parentId?: string | null,
      hidden?: boolean | null,
      inspectionPoints?:  {
        __typename: "ModelInspectionPointConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    inspectionPointId: string,
    inspectionPoint?:  {
      __typename: "InspectionPoint",
      id: string,
      groupId?: string | null,
      hierarchyItemId: string,
      type: InspectionPointType,
      projectId?: string | null,
      text: string,
      code?: string | null,
      hidden?: boolean | null,
      observations?:  {
        __typename: "ModelObservationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    text?: string | null,
    suggestion?: string | null,
    findingId?: string | null,
    primaryLocation?:  {
      __typename: "Location",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      observationId: string,
      primaryPhoto?:  {
        __typename: "Photo",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        inspectionId: string,
        json?: string | null,
        text?: string | null,
        reportable?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      photoSequence?: Array< string | null > | null,
      tags?:  {
        __typename: "ModelLocationTagConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      photos?:  {
        __typename: "ModelLocationPhotoConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      locationPrimaryPhotoId?: string | null,
      owner?: string | null,
    } | null,
    locations?:  {
      __typename: "ModelLocationConnection",
      items:  Array< {
        __typename: "Location",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        observationId: string,
        photoSequence?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        locationPrimaryPhotoId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    status?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    observationPrimaryLocationId?: string | null,
    owner?: string | null,
  } | null,
};

export type ListObservationsQueryVariables = {
  filter?: ModelObservationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListObservationsQuery = {
  listObservations?:  {
    __typename: "ModelObservationConnection",
    items:  Array< {
      __typename: "Observation",
      id: string,
      groupId?: string | null,
      type?: ObservationType | null,
      projectId?: string | null,
      inspectionId: string,
      hierarchyItemId: string,
      hierarchyItem?:  {
        __typename: "HierarchyItem",
        id: string,
        name: string,
        code?: string | null,
        hierarchyId: string,
        parentId?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      inspectionPointId: string,
      inspectionPoint?:  {
        __typename: "InspectionPoint",
        id: string,
        groupId?: string | null,
        hierarchyItemId: string,
        type: InspectionPointType,
        projectId?: string | null,
        text: string,
        code?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      text?: string | null,
      suggestion?: string | null,
      findingId?: string | null,
      primaryLocation?:  {
        __typename: "Location",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        observationId: string,
        photoSequence?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        locationPrimaryPhotoId?: string | null,
        owner?: string | null,
      } | null,
      locations?:  {
        __typename: "ModelLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      observationPrimaryLocationId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncObservationsQueryVariables = {
  filter?: ModelObservationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncObservationsQuery = {
  syncObservations?:  {
    __typename: "ModelObservationConnection",
    items:  Array< {
      __typename: "Observation",
      id: string,
      groupId?: string | null,
      type?: ObservationType | null,
      projectId?: string | null,
      inspectionId: string,
      hierarchyItemId: string,
      hierarchyItem?:  {
        __typename: "HierarchyItem",
        id: string,
        name: string,
        code?: string | null,
        hierarchyId: string,
        parentId?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      inspectionPointId: string,
      inspectionPoint?:  {
        __typename: "InspectionPoint",
        id: string,
        groupId?: string | null,
        hierarchyItemId: string,
        type: InspectionPointType,
        projectId?: string | null,
        text: string,
        code?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      text?: string | null,
      suggestion?: string | null,
      findingId?: string | null,
      primaryLocation?:  {
        __typename: "Location",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        observationId: string,
        photoSequence?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        locationPrimaryPhotoId?: string | null,
        owner?: string | null,
      } | null,
      locations?:  {
        __typename: "ModelLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      observationPrimaryLocationId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ObservationsByInspectionIdQueryVariables = {
  inspectionId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelObservationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ObservationsByInspectionIdQuery = {
  observationsByInspectionId?:  {
    __typename: "ModelObservationConnection",
    items:  Array< {
      __typename: "Observation",
      id: string,
      groupId?: string | null,
      type?: ObservationType | null,
      projectId?: string | null,
      inspectionId: string,
      hierarchyItemId: string,
      hierarchyItem?:  {
        __typename: "HierarchyItem",
        id: string,
        name: string,
        code?: string | null,
        hierarchyId: string,
        parentId?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      inspectionPointId: string,
      inspectionPoint?:  {
        __typename: "InspectionPoint",
        id: string,
        groupId?: string | null,
        hierarchyItemId: string,
        type: InspectionPointType,
        projectId?: string | null,
        text: string,
        code?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      text?: string | null,
      suggestion?: string | null,
      findingId?: string | null,
      primaryLocation?:  {
        __typename: "Location",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        observationId: string,
        photoSequence?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        locationPrimaryPhotoId?: string | null,
        owner?: string | null,
      } | null,
      locations?:  {
        __typename: "ModelLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      observationPrimaryLocationId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ObservationsByInspectionIdAndStatusQueryVariables = {
  inspectionId: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelObservationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ObservationsByInspectionIdAndStatusQuery = {
  observationsByInspectionIdAndStatus?:  {
    __typename: "ModelObservationConnection",
    items:  Array< {
      __typename: "Observation",
      id: string,
      groupId?: string | null,
      type?: ObservationType | null,
      projectId?: string | null,
      inspectionId: string,
      hierarchyItemId: string,
      hierarchyItem?:  {
        __typename: "HierarchyItem",
        id: string,
        name: string,
        code?: string | null,
        hierarchyId: string,
        parentId?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      inspectionPointId: string,
      inspectionPoint?:  {
        __typename: "InspectionPoint",
        id: string,
        groupId?: string | null,
        hierarchyItemId: string,
        type: InspectionPointType,
        projectId?: string | null,
        text: string,
        code?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      text?: string | null,
      suggestion?: string | null,
      findingId?: string | null,
      primaryLocation?:  {
        __typename: "Location",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        observationId: string,
        photoSequence?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        locationPrimaryPhotoId?: string | null,
        owner?: string | null,
      } | null,
      locations?:  {
        __typename: "ModelLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      observationPrimaryLocationId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ObservationsByInspectionPointIdQueryVariables = {
  inspectionPointId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelObservationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ObservationsByInspectionPointIdQuery = {
  observationsByInspectionPointId?:  {
    __typename: "ModelObservationConnection",
    items:  Array< {
      __typename: "Observation",
      id: string,
      groupId?: string | null,
      type?: ObservationType | null,
      projectId?: string | null,
      inspectionId: string,
      hierarchyItemId: string,
      hierarchyItem?:  {
        __typename: "HierarchyItem",
        id: string,
        name: string,
        code?: string | null,
        hierarchyId: string,
        parentId?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      inspectionPointId: string,
      inspectionPoint?:  {
        __typename: "InspectionPoint",
        id: string,
        groupId?: string | null,
        hierarchyItemId: string,
        type: InspectionPointType,
        projectId?: string | null,
        text: string,
        code?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      text?: string | null,
      suggestion?: string | null,
      findingId?: string | null,
      primaryLocation?:  {
        __typename: "Location",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        observationId: string,
        photoSequence?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        locationPrimaryPhotoId?: string | null,
        owner?: string | null,
      } | null,
      locations?:  {
        __typename: "ModelLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      observationPrimaryLocationId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetLocationQueryVariables = {
  id: string,
};

export type GetLocationQuery = {
  getLocation?:  {
    __typename: "Location",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    observationId: string,
    primaryPhoto?:  {
      __typename: "Photo",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      inspectionId: string,
      json?: string | null,
      text?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      reportable?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    photoSequence?: Array< string | null > | null,
    tags?:  {
      __typename: "ModelLocationTagConnection",
      items:  Array< {
        __typename: "LocationTag",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        locationId: string,
        tagId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    photos?:  {
      __typename: "ModelLocationPhotoConnection",
      items:  Array< {
        __typename: "LocationPhoto",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        locationId: string,
        photoId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    locationPrimaryPhotoId?: string | null,
    owner?: string | null,
  } | null,
};

export type ListLocationsQueryVariables = {
  filter?: ModelLocationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLocationsQuery = {
  listLocations?:  {
    __typename: "ModelLocationConnection",
    items:  Array< {
      __typename: "Location",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      observationId: string,
      primaryPhoto?:  {
        __typename: "Photo",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        inspectionId: string,
        json?: string | null,
        text?: string | null,
        reportable?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      photoSequence?: Array< string | null > | null,
      tags?:  {
        __typename: "ModelLocationTagConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      photos?:  {
        __typename: "ModelLocationPhotoConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      locationPrimaryPhotoId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncLocationsQueryVariables = {
  filter?: ModelLocationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncLocationsQuery = {
  syncLocations?:  {
    __typename: "ModelLocationConnection",
    items:  Array< {
      __typename: "Location",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      observationId: string,
      primaryPhoto?:  {
        __typename: "Photo",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        inspectionId: string,
        json?: string | null,
        text?: string | null,
        reportable?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      photoSequence?: Array< string | null > | null,
      tags?:  {
        __typename: "ModelLocationTagConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      photos?:  {
        __typename: "ModelLocationPhotoConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      locationPrimaryPhotoId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type LocationsByObservationIdQueryVariables = {
  observationId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLocationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LocationsByObservationIdQuery = {
  locationsByObservationId?:  {
    __typename: "ModelLocationConnection",
    items:  Array< {
      __typename: "Location",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      observationId: string,
      primaryPhoto?:  {
        __typename: "Photo",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        inspectionId: string,
        json?: string | null,
        text?: string | null,
        reportable?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      photoSequence?: Array< string | null > | null,
      tags?:  {
        __typename: "ModelLocationTagConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      photos?:  {
        __typename: "ModelLocationPhotoConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      locationPrimaryPhotoId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTagQueryVariables = {
  id: string,
};

export type GetTagQuery = {
  getTag?:  {
    __typename: "Tag",
    id: string,
    groupId?: string | null,
    type: TagType,
    projectId: string,
    text: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type ListTagsQueryVariables = {
  filter?: ModelTagFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTagsQuery = {
  listTags?:  {
    __typename: "ModelTagConnection",
    items:  Array< {
      __typename: "Tag",
      id: string,
      groupId?: string | null,
      type: TagType,
      projectId: string,
      text: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTagsQueryVariables = {
  filter?: ModelTagFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTagsQuery = {
  syncTags?:  {
    __typename: "ModelTagConnection",
    items:  Array< {
      __typename: "Tag",
      id: string,
      groupId?: string | null,
      type: TagType,
      projectId: string,
      text: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type TagsByProjectIdQueryVariables = {
  projectId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTagFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TagsByProjectIdQuery = {
  tagsByProjectId?:  {
    __typename: "ModelTagConnection",
    items:  Array< {
      __typename: "Tag",
      id: string,
      groupId?: string | null,
      type: TagType,
      projectId: string,
      text: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetLocationTagQueryVariables = {
  id: string,
};

export type GetLocationTagQuery = {
  getLocationTag?:  {
    __typename: "LocationTag",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    locationId: string,
    tagId: string,
    tag?:  {
      __typename: "Tag",
      id: string,
      groupId?: string | null,
      type: TagType,
      projectId: string,
      text: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type ListLocationTagsQueryVariables = {
  filter?: ModelLocationTagFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLocationTagsQuery = {
  listLocationTags?:  {
    __typename: "ModelLocationTagConnection",
    items:  Array< {
      __typename: "LocationTag",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      locationId: string,
      tagId: string,
      tag?:  {
        __typename: "Tag",
        id: string,
        groupId?: string | null,
        type: TagType,
        projectId: string,
        text: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncLocationTagsQueryVariables = {
  filter?: ModelLocationTagFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncLocationTagsQuery = {
  syncLocationTags?:  {
    __typename: "ModelLocationTagConnection",
    items:  Array< {
      __typename: "LocationTag",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      locationId: string,
      tagId: string,
      tag?:  {
        __typename: "Tag",
        id: string,
        groupId?: string | null,
        type: TagType,
        projectId: string,
        text: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type LocationTagsByLocationIdQueryVariables = {
  locationId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLocationTagFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LocationTagsByLocationIdQuery = {
  locationTagsByLocationId?:  {
    __typename: "ModelLocationTagConnection",
    items:  Array< {
      __typename: "LocationTag",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      locationId: string,
      tagId: string,
      tag?:  {
        __typename: "Tag",
        id: string,
        groupId?: string | null,
        type: TagType,
        projectId: string,
        text: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetPhotoQueryVariables = {
  id: string,
};

export type GetPhotoQuery = {
  getPhoto?:  {
    __typename: "Photo",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    inspectionId: string,
    json?: string | null,
    text?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    reportable?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type ListPhotosQueryVariables = {
  filter?: ModelPhotoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPhotosQuery = {
  listPhotos?:  {
    __typename: "ModelPhotoConnection",
    items:  Array< {
      __typename: "Photo",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      inspectionId: string,
      json?: string | null,
      text?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      reportable?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncPhotosQueryVariables = {
  filter?: ModelPhotoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncPhotosQuery = {
  syncPhotos?:  {
    __typename: "ModelPhotoConnection",
    items:  Array< {
      __typename: "Photo",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      inspectionId: string,
      json?: string | null,
      text?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      reportable?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetInspectionPhotoQueryVariables = {
  id: string,
};

export type GetInspectionPhotoQuery = {
  getInspectionPhoto?:  {
    __typename: "InspectionPhoto",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    inspectionId: string,
    photoId: string,
    photo?:  {
      __typename: "Photo",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      inspectionId: string,
      json?: string | null,
      text?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      reportable?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type ListInspectionPhotosQueryVariables = {
  filter?: ModelInspectionPhotoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInspectionPhotosQuery = {
  listInspectionPhotos?:  {
    __typename: "ModelInspectionPhotoConnection",
    items:  Array< {
      __typename: "InspectionPhoto",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      inspectionId: string,
      photoId: string,
      photo?:  {
        __typename: "Photo",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        inspectionId: string,
        json?: string | null,
        text?: string | null,
        reportable?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncInspectionPhotosQueryVariables = {
  filter?: ModelInspectionPhotoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncInspectionPhotosQuery = {
  syncInspectionPhotos?:  {
    __typename: "ModelInspectionPhotoConnection",
    items:  Array< {
      __typename: "InspectionPhoto",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      inspectionId: string,
      photoId: string,
      photo?:  {
        __typename: "Photo",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        inspectionId: string,
        json?: string | null,
        text?: string | null,
        reportable?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type PhotosByInspectionIdQueryVariables = {
  inspectionId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInspectionPhotoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PhotosByInspectionIdQuery = {
  photosByInspectionId?:  {
    __typename: "ModelInspectionPhotoConnection",
    items:  Array< {
      __typename: "InspectionPhoto",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      inspectionId: string,
      photoId: string,
      photo?:  {
        __typename: "Photo",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        inspectionId: string,
        json?: string | null,
        text?: string | null,
        reportable?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetLocationPhotoQueryVariables = {
  id: string,
};

export type GetLocationPhotoQuery = {
  getLocationPhoto?:  {
    __typename: "LocationPhoto",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    locationId: string,
    photoId: string,
    photo?:  {
      __typename: "Photo",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      inspectionId: string,
      json?: string | null,
      text?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      reportable?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type ListLocationPhotosQueryVariables = {
  filter?: ModelLocationPhotoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLocationPhotosQuery = {
  listLocationPhotos?:  {
    __typename: "ModelLocationPhotoConnection",
    items:  Array< {
      __typename: "LocationPhoto",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      locationId: string,
      photoId: string,
      photo?:  {
        __typename: "Photo",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        inspectionId: string,
        json?: string | null,
        text?: string | null,
        reportable?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncLocationPhotosQueryVariables = {
  filter?: ModelLocationPhotoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncLocationPhotosQuery = {
  syncLocationPhotos?:  {
    __typename: "ModelLocationPhotoConnection",
    items:  Array< {
      __typename: "LocationPhoto",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      locationId: string,
      photoId: string,
      photo?:  {
        __typename: "Photo",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        inspectionId: string,
        json?: string | null,
        text?: string | null,
        reportable?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type PhotosByLocationIdQueryVariables = {
  locationId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLocationPhotoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PhotosByLocationIdQuery = {
  photosByLocationId?:  {
    __typename: "ModelLocationPhotoConnection",
    items:  Array< {
      __typename: "LocationPhoto",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      locationId: string,
      photoId: string,
      photo?:  {
        __typename: "Photo",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        inspectionId: string,
        json?: string | null,
        text?: string | null,
        reportable?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetFindingQueryVariables = {
  id: string,
};

export type GetFindingQuery = {
  getFinding?:  {
    __typename: "Finding",
    id: string,
    groupId?: string | null,
    type: FindingType,
    projectId: string,
    phaseId?: string | null,
    inspectionId: string,
    hierarchyItemId: string,
    hierarchyItem?:  {
      __typename: "HierarchyItem",
      id: string,
      name: string,
      code?: string | null,
      hierarchyId: string,
      parentId?: string | null,
      hidden?: boolean | null,
      inspectionPoints?:  {
        __typename: "ModelInspectionPointConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    observationId: string,
    observation?:  {
      __typename: "Observation",
      id: string,
      groupId?: string | null,
      type?: ObservationType | null,
      projectId?: string | null,
      inspectionId: string,
      hierarchyItemId: string,
      hierarchyItem?:  {
        __typename: "HierarchyItem",
        id: string,
        name: string,
        code?: string | null,
        hierarchyId: string,
        parentId?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      inspectionPointId: string,
      inspectionPoint?:  {
        __typename: "InspectionPoint",
        id: string,
        groupId?: string | null,
        hierarchyItemId: string,
        type: InspectionPointType,
        projectId?: string | null,
        text: string,
        code?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      text?: string | null,
      suggestion?: string | null,
      findingId?: string | null,
      primaryLocation?:  {
        __typename: "Location",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        observationId: string,
        photoSequence?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        locationPrimaryPhotoId?: string | null,
        owner?: string | null,
      } | null,
      locations?:  {
        __typename: "ModelLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      observationPrimaryLocationId?: string | null,
      owner?: string | null,
    } | null,
    closingObservation?:  {
      __typename: "Observation",
      id: string,
      groupId?: string | null,
      type?: ObservationType | null,
      projectId?: string | null,
      inspectionId: string,
      hierarchyItemId: string,
      hierarchyItem?:  {
        __typename: "HierarchyItem",
        id: string,
        name: string,
        code?: string | null,
        hierarchyId: string,
        parentId?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      inspectionPointId: string,
      inspectionPoint?:  {
        __typename: "InspectionPoint",
        id: string,
        groupId?: string | null,
        hierarchyItemId: string,
        type: InspectionPointType,
        projectId?: string | null,
        text: string,
        code?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      text?: string | null,
      suggestion?: string | null,
      findingId?: string | null,
      primaryLocation?:  {
        __typename: "Location",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        observationId: string,
        photoSequence?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        locationPrimaryPhotoId?: string | null,
        owner?: string | null,
      } | null,
      locations?:  {
        __typename: "ModelLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      observationPrimaryLocationId?: string | null,
      owner?: string | null,
    } | null,
    status: FindingStatus,
    date?: string | null,
    closedDate?: string | null,
    code?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    findingClosingObservationId?: string | null,
  } | null,
};

export type ListFindingsQueryVariables = {
  filter?: ModelFindingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFindingsQuery = {
  listFindings?:  {
    __typename: "ModelFindingConnection",
    items:  Array< {
      __typename: "Finding",
      id: string,
      groupId?: string | null,
      type: FindingType,
      projectId: string,
      phaseId?: string | null,
      inspectionId: string,
      hierarchyItemId: string,
      hierarchyItem?:  {
        __typename: "HierarchyItem",
        id: string,
        name: string,
        code?: string | null,
        hierarchyId: string,
        parentId?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      observationId: string,
      observation?:  {
        __typename: "Observation",
        id: string,
        groupId?: string | null,
        type?: ObservationType | null,
        projectId?: string | null,
        inspectionId: string,
        hierarchyItemId: string,
        inspectionPointId: string,
        text?: string | null,
        suggestion?: string | null,
        findingId?: string | null,
        status?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        observationPrimaryLocationId?: string | null,
        owner?: string | null,
      } | null,
      closingObservation?:  {
        __typename: "Observation",
        id: string,
        groupId?: string | null,
        type?: ObservationType | null,
        projectId?: string | null,
        inspectionId: string,
        hierarchyItemId: string,
        inspectionPointId: string,
        text?: string | null,
        suggestion?: string | null,
        findingId?: string | null,
        status?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        observationPrimaryLocationId?: string | null,
        owner?: string | null,
      } | null,
      status: FindingStatus,
      date?: string | null,
      closedDate?: string | null,
      code?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      findingClosingObservationId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncFindingsQueryVariables = {
  filter?: ModelFindingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncFindingsQuery = {
  syncFindings?:  {
    __typename: "ModelFindingConnection",
    items:  Array< {
      __typename: "Finding",
      id: string,
      groupId?: string | null,
      type: FindingType,
      projectId: string,
      phaseId?: string | null,
      inspectionId: string,
      hierarchyItemId: string,
      hierarchyItem?:  {
        __typename: "HierarchyItem",
        id: string,
        name: string,
        code?: string | null,
        hierarchyId: string,
        parentId?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      observationId: string,
      observation?:  {
        __typename: "Observation",
        id: string,
        groupId?: string | null,
        type?: ObservationType | null,
        projectId?: string | null,
        inspectionId: string,
        hierarchyItemId: string,
        inspectionPointId: string,
        text?: string | null,
        suggestion?: string | null,
        findingId?: string | null,
        status?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        observationPrimaryLocationId?: string | null,
        owner?: string | null,
      } | null,
      closingObservation?:  {
        __typename: "Observation",
        id: string,
        groupId?: string | null,
        type?: ObservationType | null,
        projectId?: string | null,
        inspectionId: string,
        hierarchyItemId: string,
        inspectionPointId: string,
        text?: string | null,
        suggestion?: string | null,
        findingId?: string | null,
        status?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        observationPrimaryLocationId?: string | null,
        owner?: string | null,
      } | null,
      status: FindingStatus,
      date?: string | null,
      closedDate?: string | null,
      code?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      findingClosingObservationId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type FindingsByProjectIdQueryVariables = {
  projectId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFindingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindingsByProjectIdQuery = {
  findingsByProjectId?:  {
    __typename: "ModelFindingConnection",
    items:  Array< {
      __typename: "Finding",
      id: string,
      groupId?: string | null,
      type: FindingType,
      projectId: string,
      phaseId?: string | null,
      inspectionId: string,
      hierarchyItemId: string,
      hierarchyItem?:  {
        __typename: "HierarchyItem",
        id: string,
        name: string,
        code?: string | null,
        hierarchyId: string,
        parentId?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      observationId: string,
      observation?:  {
        __typename: "Observation",
        id: string,
        groupId?: string | null,
        type?: ObservationType | null,
        projectId?: string | null,
        inspectionId: string,
        hierarchyItemId: string,
        inspectionPointId: string,
        text?: string | null,
        suggestion?: string | null,
        findingId?: string | null,
        status?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        observationPrimaryLocationId?: string | null,
        owner?: string | null,
      } | null,
      closingObservation?:  {
        __typename: "Observation",
        id: string,
        groupId?: string | null,
        type?: ObservationType | null,
        projectId?: string | null,
        inspectionId: string,
        hierarchyItemId: string,
        inspectionPointId: string,
        text?: string | null,
        suggestion?: string | null,
        findingId?: string | null,
        status?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        observationPrimaryLocationId?: string | null,
        owner?: string | null,
      } | null,
      status: FindingStatus,
      date?: string | null,
      closedDate?: string | null,
      code?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      findingClosingObservationId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type FindingsByProjectIdAndStatusQueryVariables = {
  projectId: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFindingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindingsByProjectIdAndStatusQuery = {
  findingsByProjectIdAndStatus?:  {
    __typename: "ModelFindingConnection",
    items:  Array< {
      __typename: "Finding",
      id: string,
      groupId?: string | null,
      type: FindingType,
      projectId: string,
      phaseId?: string | null,
      inspectionId: string,
      hierarchyItemId: string,
      hierarchyItem?:  {
        __typename: "HierarchyItem",
        id: string,
        name: string,
        code?: string | null,
        hierarchyId: string,
        parentId?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      observationId: string,
      observation?:  {
        __typename: "Observation",
        id: string,
        groupId?: string | null,
        type?: ObservationType | null,
        projectId?: string | null,
        inspectionId: string,
        hierarchyItemId: string,
        inspectionPointId: string,
        text?: string | null,
        suggestion?: string | null,
        findingId?: string | null,
        status?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        observationPrimaryLocationId?: string | null,
        owner?: string | null,
      } | null,
      closingObservation?:  {
        __typename: "Observation",
        id: string,
        groupId?: string | null,
        type?: ObservationType | null,
        projectId?: string | null,
        inspectionId: string,
        hierarchyItemId: string,
        inspectionPointId: string,
        text?: string | null,
        suggestion?: string | null,
        findingId?: string | null,
        status?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        observationPrimaryLocationId?: string | null,
        owner?: string | null,
      } | null,
      status: FindingStatus,
      date?: string | null,
      closedDate?: string | null,
      code?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      findingClosingObservationId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type FindingsByInspectionIdQueryVariables = {
  inspectionId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFindingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FindingsByInspectionIdQuery = {
  findingsByInspectionId?:  {
    __typename: "ModelFindingConnection",
    items:  Array< {
      __typename: "Finding",
      id: string,
      groupId?: string | null,
      type: FindingType,
      projectId: string,
      phaseId?: string | null,
      inspectionId: string,
      hierarchyItemId: string,
      hierarchyItem?:  {
        __typename: "HierarchyItem",
        id: string,
        name: string,
        code?: string | null,
        hierarchyId: string,
        parentId?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      observationId: string,
      observation?:  {
        __typename: "Observation",
        id: string,
        groupId?: string | null,
        type?: ObservationType | null,
        projectId?: string | null,
        inspectionId: string,
        hierarchyItemId: string,
        inspectionPointId: string,
        text?: string | null,
        suggestion?: string | null,
        findingId?: string | null,
        status?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        observationPrimaryLocationId?: string | null,
        owner?: string | null,
      } | null,
      closingObservation?:  {
        __typename: "Observation",
        id: string,
        groupId?: string | null,
        type?: ObservationType | null,
        projectId?: string | null,
        inspectionId: string,
        hierarchyItemId: string,
        inspectionPointId: string,
        text?: string | null,
        suggestion?: string | null,
        findingId?: string | null,
        status?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        observationPrimaryLocationId?: string | null,
        owner?: string | null,
      } | null,
      status: FindingStatus,
      date?: string | null,
      closedDate?: string | null,
      code?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      findingClosingObservationId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetHierarchyBundleQueryVariables = {
  id: string,
};

export type GetHierarchyBundleQuery = {
  getHierarchyBundle?:  {
    __typename: "HierarchyBundle",
    id: string,
    name: string,
    type: string,
    status: string,
    hierarchyItemIds?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type ListHierarchyBundlesQueryVariables = {
  id?: string | null,
  filter?: ModelHierarchyBundleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListHierarchyBundlesQuery = {
  listHierarchyBundles?:  {
    __typename: "ModelHierarchyBundleConnection",
    items:  Array< {
      __typename: "HierarchyBundle",
      id: string,
      name: string,
      type: string,
      status: string,
      hierarchyItemIds?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncHierarchyBundlesQueryVariables = {
  filter?: ModelHierarchyBundleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncHierarchyBundlesQuery = {
  syncHierarchyBundles?:  {
    __typename: "ModelHierarchyBundleConnection",
    items:  Array< {
      __typename: "HierarchyBundle",
      id: string,
      name: string,
      type: string,
      status: string,
      hierarchyItemIds?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type HierarchyBundlesByTypeQueryVariables = {
  type: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelHierarchyBundleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type HierarchyBundlesByTypeQuery = {
  hierarchyBundlesByType?:  {
    __typename: "ModelHierarchyBundleConnection",
    items:  Array< {
      __typename: "HierarchyBundle",
      id: string,
      name: string,
      type: string,
      status: string,
      hierarchyItemIds?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type HierarchyBundlesByTypeAndStatusQueryVariables = {
  type: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelHierarchyBundleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type HierarchyBundlesByTypeAndStatusQuery = {
  hierarchyBundlesByTypeAndStatus?:  {
    __typename: "ModelHierarchyBundleConnection",
    items:  Array< {
      __typename: "HierarchyBundle",
      id: string,
      name: string,
      type: string,
      status: string,
      hierarchyItemIds?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SearchHierarchyBundlesQueryVariables = {
  filter?: SearchableHierarchyBundleFilterInput | null,
  sort?: Array< SearchableHierarchyBundleSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableHierarchyBundleAggregationInput | null > | null,
};

export type SearchHierarchyBundlesQuery = {
  searchHierarchyBundles?:  {
    __typename: "SearchableHierarchyBundleConnection",
    items:  Array< {
      __typename: "HierarchyBundle",
      id: string,
      name: string,
      type: string,
      status: string,
      hierarchyItemIds?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetMilestoneTemplateQueryVariables = {
  id: string,
};

export type GetMilestoneTemplateQuery = {
  getMilestoneTemplate?:  {
    __typename: "MilestoneTemplate",
    id: string,
    name: string,
    status: string,
    hierarchyBundleIds?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type ListMilestoneTemplatesQueryVariables = {
  id?: string | null,
  filter?: ModelMilestoneTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListMilestoneTemplatesQuery = {
  listMilestoneTemplates?:  {
    __typename: "ModelMilestoneTemplateConnection",
    items:  Array< {
      __typename: "MilestoneTemplate",
      id: string,
      name: string,
      status: string,
      hierarchyBundleIds?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncMilestoneTemplatesQueryVariables = {
  filter?: ModelMilestoneTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncMilestoneTemplatesQuery = {
  syncMilestoneTemplates?:  {
    __typename: "ModelMilestoneTemplateConnection",
    items:  Array< {
      __typename: "MilestoneTemplate",
      id: string,
      name: string,
      status: string,
      hierarchyBundleIds?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SearchMilestoneTemplatesQueryVariables = {
  filter?: SearchableMilestoneTemplateFilterInput | null,
  sort?: Array< SearchableMilestoneTemplateSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableMilestoneTemplateAggregationInput | null > | null,
};

export type SearchMilestoneTemplatesQuery = {
  searchMilestoneTemplates?:  {
    __typename: "SearchableMilestoneTemplateConnection",
    items:  Array< {
      __typename: "MilestoneTemplate",
      id: string,
      name: string,
      status: string,
      hierarchyBundleIds?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type GetActivityStreamQueryVariables = {
  id: string,
};

export type GetActivityStreamQuery = {
  getActivityStream?:  {
    __typename: "ActivityStream",
    id: string,
    modelType: string,
    instanceId: string,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    date: string,
    type: string,
    text?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type ListActivityStreamsQueryVariables = {
  id?: string | null,
  filter?: ModelActivityStreamFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListActivityStreamsQuery = {
  listActivityStreams?:  {
    __typename: "ModelActivityStreamConnection",
    items:  Array< {
      __typename: "ActivityStream",
      id: string,
      modelType: string,
      instanceId: string,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      date: string,
      type: string,
      text?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncActivityStreamsQueryVariables = {
  filter?: ModelActivityStreamFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncActivityStreamsQuery = {
  syncActivityStreams?:  {
    __typename: "ModelActivityStreamConnection",
    items:  Array< {
      __typename: "ActivityStream",
      id: string,
      modelType: string,
      instanceId: string,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      date: string,
      type: string,
      text?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SearchActivityStreamsQueryVariables = {
  filter?: SearchableActivityStreamFilterInput | null,
  sort?: Array< SearchableActivityStreamSortInput | null > | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
  aggregates?: Array< SearchableActivityStreamAggregationInput | null > | null,
};

export type SearchActivityStreamsQuery = {
  searchActivityStreams?:  {
    __typename: "SearchableActivityStreamConnection",
    items:  Array< {
      __typename: "ActivityStream",
      id: string,
      modelType: string,
      instanceId: string,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      date: string,
      type: string,
      text?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
    aggregateItems:  Array< {
      __typename: "SearchableAggregateResult",
      name: string,
      result: ( {
          __typename: "SearchableAggregateScalarResult",
          value: number,
        } | {
          __typename: "SearchableAggregateBucketResult",
          buckets?:  Array< {
            __typename: string,
            key: string,
            doc_count: number,
          } | null > | null,
        }
      ) | null,
    } | null >,
  } | null,
};

export type OnCreateExternalLinkSubscriptionVariables = {
  filter?: ModelSubscriptionExternalLinkFilterInput | null,
};

export type OnCreateExternalLinkSubscription = {
  onCreateExternalLink?:  {
    __typename: "ExternalLink",
    id: string,
    groupId?: string | null,
    projectId: string,
    title: string,
    url: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnUpdateExternalLinkSubscriptionVariables = {
  filter?: ModelSubscriptionExternalLinkFilterInput | null,
};

export type OnUpdateExternalLinkSubscription = {
  onUpdateExternalLink?:  {
    __typename: "ExternalLink",
    id: string,
    groupId?: string | null,
    projectId: string,
    title: string,
    url: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnDeleteExternalLinkSubscriptionVariables = {
  filter?: ModelSubscriptionExternalLinkFilterInput | null,
};

export type OnDeleteExternalLinkSubscription = {
  onDeleteExternalLink?:  {
    __typename: "ExternalLink",
    id: string,
    groupId?: string | null,
    projectId: string,
    title: string,
    url: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnCreatePhaseSubscriptionVariables = {
  filter?: ModelSubscriptionPhaseFilterInput | null,
};

export type OnCreatePhaseSubscription = {
  onCreatePhase?:  {
    __typename: "Phase",
    id: string,
    groupId?: string | null,
    projectId: string,
    project:  {
      __typename: "Project",
      id: string,
      groupId?: string | null,
      name: string,
      status: string,
      clientId: string,
      client?:  {
        __typename: "Client",
        id: string,
        name: string,
        code: string,
        status: string,
        reportDisclaimer?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        clientPrimaryContactId?: string | null,
        owner?: string | null,
      } | null,
      eft?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reviewer?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      admin?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      address:  {
        __typename: "Address",
        street: string,
        street2?: string | null,
        city: string,
        state: string,
        zip: string,
      },
      primaryContact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reportTemplateId?: string | null,
      reportDisclaimer?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      contacts?:  {
        __typename: "ModelProjectContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      distributionList?: string | null,
      externalLinks?:  {
        __typename: "ModelExternalLinkConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      tags?:  {
        __typename: "ModelTagConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      findings?:  {
        __typename: "ModelFindingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      phases?:  {
        __typename: "ModelPhaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hierarchyItemExclusions?: Array< string | null > | null,
      exclusions?: string | null,
      notes?: string | null,
      code?: string | null,
      milestoneTemplate?:  {
        __typename: "MilestoneTemplate",
        id: string,
        name: string,
        status: string,
        hierarchyBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      milestoneTemplateId?: string | null,
      externalId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      projectEftId?: string | null,
      projectReviewerId?: string | null,
      projectAdminId?: string | null,
      projectPrimaryContactId?: string | null,
      owner?: string | null,
    },
    name: string,
    status: string,
    units?:  Array< {
      __typename: "Unit",
      id: string,
      name: string,
    } | null > | null,
    milestoneTemplate?:  {
      __typename: "MilestoneTemplate",
      id: string,
      name: string,
      status: string,
      hierarchyBundleIds?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    milestoneTemplateId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnUpdatePhaseSubscriptionVariables = {
  filter?: ModelSubscriptionPhaseFilterInput | null,
};

export type OnUpdatePhaseSubscription = {
  onUpdatePhase?:  {
    __typename: "Phase",
    id: string,
    groupId?: string | null,
    projectId: string,
    project:  {
      __typename: "Project",
      id: string,
      groupId?: string | null,
      name: string,
      status: string,
      clientId: string,
      client?:  {
        __typename: "Client",
        id: string,
        name: string,
        code: string,
        status: string,
        reportDisclaimer?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        clientPrimaryContactId?: string | null,
        owner?: string | null,
      } | null,
      eft?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reviewer?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      admin?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      address:  {
        __typename: "Address",
        street: string,
        street2?: string | null,
        city: string,
        state: string,
        zip: string,
      },
      primaryContact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reportTemplateId?: string | null,
      reportDisclaimer?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      contacts?:  {
        __typename: "ModelProjectContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      distributionList?: string | null,
      externalLinks?:  {
        __typename: "ModelExternalLinkConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      tags?:  {
        __typename: "ModelTagConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      findings?:  {
        __typename: "ModelFindingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      phases?:  {
        __typename: "ModelPhaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hierarchyItemExclusions?: Array< string | null > | null,
      exclusions?: string | null,
      notes?: string | null,
      code?: string | null,
      milestoneTemplate?:  {
        __typename: "MilestoneTemplate",
        id: string,
        name: string,
        status: string,
        hierarchyBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      milestoneTemplateId?: string | null,
      externalId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      projectEftId?: string | null,
      projectReviewerId?: string | null,
      projectAdminId?: string | null,
      projectPrimaryContactId?: string | null,
      owner?: string | null,
    },
    name: string,
    status: string,
    units?:  Array< {
      __typename: "Unit",
      id: string,
      name: string,
    } | null > | null,
    milestoneTemplate?:  {
      __typename: "MilestoneTemplate",
      id: string,
      name: string,
      status: string,
      hierarchyBundleIds?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    milestoneTemplateId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnDeletePhaseSubscriptionVariables = {
  filter?: ModelSubscriptionPhaseFilterInput | null,
};

export type OnDeletePhaseSubscription = {
  onDeletePhase?:  {
    __typename: "Phase",
    id: string,
    groupId?: string | null,
    projectId: string,
    project:  {
      __typename: "Project",
      id: string,
      groupId?: string | null,
      name: string,
      status: string,
      clientId: string,
      client?:  {
        __typename: "Client",
        id: string,
        name: string,
        code: string,
        status: string,
        reportDisclaimer?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        clientPrimaryContactId?: string | null,
        owner?: string | null,
      } | null,
      eft?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reviewer?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      admin?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      address:  {
        __typename: "Address",
        street: string,
        street2?: string | null,
        city: string,
        state: string,
        zip: string,
      },
      primaryContact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reportTemplateId?: string | null,
      reportDisclaimer?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      contacts?:  {
        __typename: "ModelProjectContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      distributionList?: string | null,
      externalLinks?:  {
        __typename: "ModelExternalLinkConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      tags?:  {
        __typename: "ModelTagConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      findings?:  {
        __typename: "ModelFindingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      phases?:  {
        __typename: "ModelPhaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hierarchyItemExclusions?: Array< string | null > | null,
      exclusions?: string | null,
      notes?: string | null,
      code?: string | null,
      milestoneTemplate?:  {
        __typename: "MilestoneTemplate",
        id: string,
        name: string,
        status: string,
        hierarchyBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      milestoneTemplateId?: string | null,
      externalId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      projectEftId?: string | null,
      projectReviewerId?: string | null,
      projectAdminId?: string | null,
      projectPrimaryContactId?: string | null,
      owner?: string | null,
    },
    name: string,
    status: string,
    units?:  Array< {
      __typename: "Unit",
      id: string,
      name: string,
    } | null > | null,
    milestoneTemplate?:  {
      __typename: "MilestoneTemplate",
      id: string,
      name: string,
      status: string,
      hierarchyBundleIds?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    milestoneTemplateId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnCreateProjectContactSubscriptionVariables = {
  filter?: ModelSubscriptionProjectContactFilterInput | null,
};

export type OnCreateProjectContactSubscription = {
  onCreateProjectContact?:  {
    __typename: "ProjectContact",
    id: string,
    groupId?: string | null,
    projectId: string,
    contactId: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    label?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnUpdateProjectContactSubscriptionVariables = {
  filter?: ModelSubscriptionProjectContactFilterInput | null,
};

export type OnUpdateProjectContactSubscription = {
  onUpdateProjectContact?:  {
    __typename: "ProjectContact",
    id: string,
    groupId?: string | null,
    projectId: string,
    contactId: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    label?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnDeleteProjectContactSubscriptionVariables = {
  filter?: ModelSubscriptionProjectContactFilterInput | null,
};

export type OnDeleteProjectContactSubscription = {
  onDeleteProjectContact?:  {
    __typename: "ProjectContact",
    id: string,
    groupId?: string | null,
    projectId: string,
    contactId: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    label?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnCreateInspectionSubscriptionVariables = {
  filter?: ModelSubscriptionInspectionFilterInput | null,
};

export type OnCreateInspectionSubscription = {
  onCreateInspection?:  {
    __typename: "Inspection",
    id: string,
    groupId?: string | null,
    clientId?: string | null,
    projectId: string,
    project?:  {
      __typename: "Project",
      id: string,
      groupId?: string | null,
      name: string,
      status: string,
      clientId: string,
      client?:  {
        __typename: "Client",
        id: string,
        name: string,
        code: string,
        status: string,
        reportDisclaimer?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        clientPrimaryContactId?: string | null,
        owner?: string | null,
      } | null,
      eft?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reviewer?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      admin?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      address:  {
        __typename: "Address",
        street: string,
        street2?: string | null,
        city: string,
        state: string,
        zip: string,
      },
      primaryContact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reportTemplateId?: string | null,
      reportDisclaimer?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      contacts?:  {
        __typename: "ModelProjectContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      distributionList?: string | null,
      externalLinks?:  {
        __typename: "ModelExternalLinkConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      tags?:  {
        __typename: "ModelTagConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      findings?:  {
        __typename: "ModelFindingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      phases?:  {
        __typename: "ModelPhaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hierarchyItemExclusions?: Array< string | null > | null,
      exclusions?: string | null,
      notes?: string | null,
      code?: string | null,
      milestoneTemplate?:  {
        __typename: "MilestoneTemplate",
        id: string,
        name: string,
        status: string,
        hierarchyBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      milestoneTemplateId?: string | null,
      externalId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      projectEftId?: string | null,
      projectReviewerId?: string | null,
      projectAdminId?: string | null,
      projectPrimaryContactId?: string | null,
      owner?: string | null,
    } | null,
    phase?:  {
      __typename: "Phase",
      id: string,
      groupId?: string | null,
      projectId: string,
      project:  {
        __typename: "Project",
        id: string,
        groupId?: string | null,
        name: string,
        status: string,
        clientId: string,
        reportTemplateId?: string | null,
        reportDisclaimer?: string | null,
        distributionList?: string | null,
        hierarchyItemExclusions?: Array< string | null > | null,
        exclusions?: string | null,
        notes?: string | null,
        code?: string | null,
        milestoneTemplateId?: string | null,
        externalId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        projectEftId?: string | null,
        projectReviewerId?: string | null,
        projectAdminId?: string | null,
        projectPrimaryContactId?: string | null,
        owner?: string | null,
      },
      name: string,
      status: string,
      units?:  Array< {
        __typename: "Unit",
        id: string,
        name: string,
      } | null > | null,
      milestoneTemplate?:  {
        __typename: "MilestoneTemplate",
        id: string,
        name: string,
        status: string,
        hierarchyBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      milestoneTemplateId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    phaseId?: string | null,
    photoSequence?: Array< string | null > | null,
    status: string,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    reviewer?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    inspectionReviewerId?: string | null,
    inspectionDate?: string | null,
    reviewedDate?: string | null,
    deliveredDate?: string | null,
    nextInspectionDate?: string | null,
    arrivalTime?: string | null,
    checkInContact?:  {
      __typename: "Contact",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    notes?: string | null,
    coverLetter?: string | null,
    comments?:  {
      __typename: "ModelInspectionCommentConnection",
      items:  Array< {
        __typename: "InspectionComment",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        inspectionId: string,
        commentId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    observations?:  {
      __typename: "ModelObservationConnection",
      items:  Array< {
        __typename: "Observation",
        id: string,
        groupId?: string | null,
        type?: ObservationType | null,
        projectId?: string | null,
        inspectionId: string,
        hierarchyItemId: string,
        inspectionPointId: string,
        text?: string | null,
        suggestion?: string | null,
        findingId?: string | null,
        status?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        observationPrimaryLocationId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    findings?:  {
      __typename: "ModelFindingConnection",
      items:  Array< {
        __typename: "Finding",
        id: string,
        groupId?: string | null,
        type: FindingType,
        projectId: string,
        phaseId?: string | null,
        inspectionId: string,
        hierarchyItemId: string,
        observationId: string,
        status: FindingStatus,
        date?: string | null,
        closedDate?: string | null,
        code?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        findingClosingObservationId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    hierarchyItems?:  {
      __typename: "ModelInspectionHierarchyItemConnection",
      items:  Array< {
        __typename: "InspectionHierarchyItem",
        id: string,
        inspectionId: string,
        hierarchyItemId: string,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    photos?:  {
      __typename: "ModelInspectionPhotoConnection",
      items:  Array< {
        __typename: "InspectionPhoto",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        inspectionId: string,
        photoId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    scopeHierarchyItems?: Array< string | null > | null,
    scope?: string | null,
    number?: string | null,
    code?: string | null,
    report?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    weather?: string | null,
    milestoneBundleIds?: string | null,
    milestoneTrackers?:  Array< {
      __typename: "MilestoneTracker",
      milestoneId: string,
      all?: boolean | null,
      units?: Array< string | null > | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    inspectionCheckInContactId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateInspectionSubscriptionVariables = {
  filter?: ModelSubscriptionInspectionFilterInput | null,
};

export type OnUpdateInspectionSubscription = {
  onUpdateInspection?:  {
    __typename: "Inspection",
    id: string,
    groupId?: string | null,
    clientId?: string | null,
    projectId: string,
    project?:  {
      __typename: "Project",
      id: string,
      groupId?: string | null,
      name: string,
      status: string,
      clientId: string,
      client?:  {
        __typename: "Client",
        id: string,
        name: string,
        code: string,
        status: string,
        reportDisclaimer?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        clientPrimaryContactId?: string | null,
        owner?: string | null,
      } | null,
      eft?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reviewer?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      admin?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      address:  {
        __typename: "Address",
        street: string,
        street2?: string | null,
        city: string,
        state: string,
        zip: string,
      },
      primaryContact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reportTemplateId?: string | null,
      reportDisclaimer?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      contacts?:  {
        __typename: "ModelProjectContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      distributionList?: string | null,
      externalLinks?:  {
        __typename: "ModelExternalLinkConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      tags?:  {
        __typename: "ModelTagConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      findings?:  {
        __typename: "ModelFindingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      phases?:  {
        __typename: "ModelPhaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hierarchyItemExclusions?: Array< string | null > | null,
      exclusions?: string | null,
      notes?: string | null,
      code?: string | null,
      milestoneTemplate?:  {
        __typename: "MilestoneTemplate",
        id: string,
        name: string,
        status: string,
        hierarchyBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      milestoneTemplateId?: string | null,
      externalId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      projectEftId?: string | null,
      projectReviewerId?: string | null,
      projectAdminId?: string | null,
      projectPrimaryContactId?: string | null,
      owner?: string | null,
    } | null,
    phase?:  {
      __typename: "Phase",
      id: string,
      groupId?: string | null,
      projectId: string,
      project:  {
        __typename: "Project",
        id: string,
        groupId?: string | null,
        name: string,
        status: string,
        clientId: string,
        reportTemplateId?: string | null,
        reportDisclaimer?: string | null,
        distributionList?: string | null,
        hierarchyItemExclusions?: Array< string | null > | null,
        exclusions?: string | null,
        notes?: string | null,
        code?: string | null,
        milestoneTemplateId?: string | null,
        externalId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        projectEftId?: string | null,
        projectReviewerId?: string | null,
        projectAdminId?: string | null,
        projectPrimaryContactId?: string | null,
        owner?: string | null,
      },
      name: string,
      status: string,
      units?:  Array< {
        __typename: "Unit",
        id: string,
        name: string,
      } | null > | null,
      milestoneTemplate?:  {
        __typename: "MilestoneTemplate",
        id: string,
        name: string,
        status: string,
        hierarchyBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      milestoneTemplateId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    phaseId?: string | null,
    photoSequence?: Array< string | null > | null,
    status: string,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    reviewer?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    inspectionReviewerId?: string | null,
    inspectionDate?: string | null,
    reviewedDate?: string | null,
    deliveredDate?: string | null,
    nextInspectionDate?: string | null,
    arrivalTime?: string | null,
    checkInContact?:  {
      __typename: "Contact",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    notes?: string | null,
    coverLetter?: string | null,
    comments?:  {
      __typename: "ModelInspectionCommentConnection",
      items:  Array< {
        __typename: "InspectionComment",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        inspectionId: string,
        commentId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    observations?:  {
      __typename: "ModelObservationConnection",
      items:  Array< {
        __typename: "Observation",
        id: string,
        groupId?: string | null,
        type?: ObservationType | null,
        projectId?: string | null,
        inspectionId: string,
        hierarchyItemId: string,
        inspectionPointId: string,
        text?: string | null,
        suggestion?: string | null,
        findingId?: string | null,
        status?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        observationPrimaryLocationId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    findings?:  {
      __typename: "ModelFindingConnection",
      items:  Array< {
        __typename: "Finding",
        id: string,
        groupId?: string | null,
        type: FindingType,
        projectId: string,
        phaseId?: string | null,
        inspectionId: string,
        hierarchyItemId: string,
        observationId: string,
        status: FindingStatus,
        date?: string | null,
        closedDate?: string | null,
        code?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        findingClosingObservationId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    hierarchyItems?:  {
      __typename: "ModelInspectionHierarchyItemConnection",
      items:  Array< {
        __typename: "InspectionHierarchyItem",
        id: string,
        inspectionId: string,
        hierarchyItemId: string,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    photos?:  {
      __typename: "ModelInspectionPhotoConnection",
      items:  Array< {
        __typename: "InspectionPhoto",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        inspectionId: string,
        photoId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    scopeHierarchyItems?: Array< string | null > | null,
    scope?: string | null,
    number?: string | null,
    code?: string | null,
    report?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    weather?: string | null,
    milestoneBundleIds?: string | null,
    milestoneTrackers?:  Array< {
      __typename: "MilestoneTracker",
      milestoneId: string,
      all?: boolean | null,
      units?: Array< string | null > | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    inspectionCheckInContactId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteInspectionSubscriptionVariables = {
  filter?: ModelSubscriptionInspectionFilterInput | null,
};

export type OnDeleteInspectionSubscription = {
  onDeleteInspection?:  {
    __typename: "Inspection",
    id: string,
    groupId?: string | null,
    clientId?: string | null,
    projectId: string,
    project?:  {
      __typename: "Project",
      id: string,
      groupId?: string | null,
      name: string,
      status: string,
      clientId: string,
      client?:  {
        __typename: "Client",
        id: string,
        name: string,
        code: string,
        status: string,
        reportDisclaimer?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        clientPrimaryContactId?: string | null,
        owner?: string | null,
      } | null,
      eft?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reviewer?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      admin?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      address:  {
        __typename: "Address",
        street: string,
        street2?: string | null,
        city: string,
        state: string,
        zip: string,
      },
      primaryContact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reportTemplateId?: string | null,
      reportDisclaimer?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      contacts?:  {
        __typename: "ModelProjectContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      distributionList?: string | null,
      externalLinks?:  {
        __typename: "ModelExternalLinkConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      tags?:  {
        __typename: "ModelTagConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      findings?:  {
        __typename: "ModelFindingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      phases?:  {
        __typename: "ModelPhaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hierarchyItemExclusions?: Array< string | null > | null,
      exclusions?: string | null,
      notes?: string | null,
      code?: string | null,
      milestoneTemplate?:  {
        __typename: "MilestoneTemplate",
        id: string,
        name: string,
        status: string,
        hierarchyBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      milestoneTemplateId?: string | null,
      externalId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      projectEftId?: string | null,
      projectReviewerId?: string | null,
      projectAdminId?: string | null,
      projectPrimaryContactId?: string | null,
      owner?: string | null,
    } | null,
    phase?:  {
      __typename: "Phase",
      id: string,
      groupId?: string | null,
      projectId: string,
      project:  {
        __typename: "Project",
        id: string,
        groupId?: string | null,
        name: string,
        status: string,
        clientId: string,
        reportTemplateId?: string | null,
        reportDisclaimer?: string | null,
        distributionList?: string | null,
        hierarchyItemExclusions?: Array< string | null > | null,
        exclusions?: string | null,
        notes?: string | null,
        code?: string | null,
        milestoneTemplateId?: string | null,
        externalId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        projectEftId?: string | null,
        projectReviewerId?: string | null,
        projectAdminId?: string | null,
        projectPrimaryContactId?: string | null,
        owner?: string | null,
      },
      name: string,
      status: string,
      units?:  Array< {
        __typename: "Unit",
        id: string,
        name: string,
      } | null > | null,
      milestoneTemplate?:  {
        __typename: "MilestoneTemplate",
        id: string,
        name: string,
        status: string,
        hierarchyBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      milestoneTemplateId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    phaseId?: string | null,
    photoSequence?: Array< string | null > | null,
    status: string,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    reviewer?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    inspectionReviewerId?: string | null,
    inspectionDate?: string | null,
    reviewedDate?: string | null,
    deliveredDate?: string | null,
    nextInspectionDate?: string | null,
    arrivalTime?: string | null,
    checkInContact?:  {
      __typename: "Contact",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    notes?: string | null,
    coverLetter?: string | null,
    comments?:  {
      __typename: "ModelInspectionCommentConnection",
      items:  Array< {
        __typename: "InspectionComment",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        inspectionId: string,
        commentId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    observations?:  {
      __typename: "ModelObservationConnection",
      items:  Array< {
        __typename: "Observation",
        id: string,
        groupId?: string | null,
        type?: ObservationType | null,
        projectId?: string | null,
        inspectionId: string,
        hierarchyItemId: string,
        inspectionPointId: string,
        text?: string | null,
        suggestion?: string | null,
        findingId?: string | null,
        status?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        observationPrimaryLocationId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    findings?:  {
      __typename: "ModelFindingConnection",
      items:  Array< {
        __typename: "Finding",
        id: string,
        groupId?: string | null,
        type: FindingType,
        projectId: string,
        phaseId?: string | null,
        inspectionId: string,
        hierarchyItemId: string,
        observationId: string,
        status: FindingStatus,
        date?: string | null,
        closedDate?: string | null,
        code?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        findingClosingObservationId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    hierarchyItems?:  {
      __typename: "ModelInspectionHierarchyItemConnection",
      items:  Array< {
        __typename: "InspectionHierarchyItem",
        id: string,
        inspectionId: string,
        hierarchyItemId: string,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    photos?:  {
      __typename: "ModelInspectionPhotoConnection",
      items:  Array< {
        __typename: "InspectionPhoto",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        inspectionId: string,
        photoId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    scopeHierarchyItems?: Array< string | null > | null,
    scope?: string | null,
    number?: string | null,
    code?: string | null,
    report?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    weather?: string | null,
    milestoneBundleIds?: string | null,
    milestoneTrackers?:  Array< {
      __typename: "MilestoneTracker",
      milestoneId: string,
      all?: boolean | null,
      units?: Array< string | null > | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    inspectionCheckInContactId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateCommentSubscriptionVariables = {
  filter?: ModelSubscriptionCommentFilterInput | null,
};

export type OnCreateCommentSubscription = {
  onCreateComment?:  {
    __typename: "Comment",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    date: string,
    text: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnUpdateCommentSubscriptionVariables = {
  filter?: ModelSubscriptionCommentFilterInput | null,
};

export type OnUpdateCommentSubscription = {
  onUpdateComment?:  {
    __typename: "Comment",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    date: string,
    text: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnDeleteCommentSubscriptionVariables = {
  filter?: ModelSubscriptionCommentFilterInput | null,
};

export type OnDeleteCommentSubscription = {
  onDeleteComment?:  {
    __typename: "Comment",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    date: string,
    text: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnCreateInspectionCommentSubscriptionVariables = {
  filter?: ModelSubscriptionInspectionCommentFilterInput | null,
};

export type OnCreateInspectionCommentSubscription = {
  onCreateInspectionComment?:  {
    __typename: "InspectionComment",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    inspectionId: string,
    commentId: string,
    comment?:  {
      __typename: "Comment",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      date: string,
      text: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnUpdateInspectionCommentSubscriptionVariables = {
  filter?: ModelSubscriptionInspectionCommentFilterInput | null,
};

export type OnUpdateInspectionCommentSubscription = {
  onUpdateInspectionComment?:  {
    __typename: "InspectionComment",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    inspectionId: string,
    commentId: string,
    comment?:  {
      __typename: "Comment",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      date: string,
      text: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnDeleteInspectionCommentSubscriptionVariables = {
  filter?: ModelSubscriptionInspectionCommentFilterInput | null,
};

export type OnDeleteInspectionCommentSubscription = {
  onDeleteInspectionComment?:  {
    __typename: "InspectionComment",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    inspectionId: string,
    commentId: string,
    comment?:  {
      __typename: "Comment",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      userId: string,
      user?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      date: string,
      text: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnCreateObservationSubscriptionVariables = {
  filter?: ModelSubscriptionObservationFilterInput | null,
};

export type OnCreateObservationSubscription = {
  onCreateObservation?:  {
    __typename: "Observation",
    id: string,
    groupId?: string | null,
    type?: ObservationType | null,
    projectId?: string | null,
    inspectionId: string,
    hierarchyItemId: string,
    hierarchyItem?:  {
      __typename: "HierarchyItem",
      id: string,
      name: string,
      code?: string | null,
      hierarchyId: string,
      parentId?: string | null,
      hidden?: boolean | null,
      inspectionPoints?:  {
        __typename: "ModelInspectionPointConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    inspectionPointId: string,
    inspectionPoint?:  {
      __typename: "InspectionPoint",
      id: string,
      groupId?: string | null,
      hierarchyItemId: string,
      type: InspectionPointType,
      projectId?: string | null,
      text: string,
      code?: string | null,
      hidden?: boolean | null,
      observations?:  {
        __typename: "ModelObservationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    text?: string | null,
    suggestion?: string | null,
    findingId?: string | null,
    primaryLocation?:  {
      __typename: "Location",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      observationId: string,
      primaryPhoto?:  {
        __typename: "Photo",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        inspectionId: string,
        json?: string | null,
        text?: string | null,
        reportable?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      photoSequence?: Array< string | null > | null,
      tags?:  {
        __typename: "ModelLocationTagConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      photos?:  {
        __typename: "ModelLocationPhotoConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      locationPrimaryPhotoId?: string | null,
      owner?: string | null,
    } | null,
    locations?:  {
      __typename: "ModelLocationConnection",
      items:  Array< {
        __typename: "Location",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        observationId: string,
        photoSequence?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        locationPrimaryPhotoId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    status?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    observationPrimaryLocationId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateObservationSubscriptionVariables = {
  filter?: ModelSubscriptionObservationFilterInput | null,
};

export type OnUpdateObservationSubscription = {
  onUpdateObservation?:  {
    __typename: "Observation",
    id: string,
    groupId?: string | null,
    type?: ObservationType | null,
    projectId?: string | null,
    inspectionId: string,
    hierarchyItemId: string,
    hierarchyItem?:  {
      __typename: "HierarchyItem",
      id: string,
      name: string,
      code?: string | null,
      hierarchyId: string,
      parentId?: string | null,
      hidden?: boolean | null,
      inspectionPoints?:  {
        __typename: "ModelInspectionPointConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    inspectionPointId: string,
    inspectionPoint?:  {
      __typename: "InspectionPoint",
      id: string,
      groupId?: string | null,
      hierarchyItemId: string,
      type: InspectionPointType,
      projectId?: string | null,
      text: string,
      code?: string | null,
      hidden?: boolean | null,
      observations?:  {
        __typename: "ModelObservationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    text?: string | null,
    suggestion?: string | null,
    findingId?: string | null,
    primaryLocation?:  {
      __typename: "Location",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      observationId: string,
      primaryPhoto?:  {
        __typename: "Photo",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        inspectionId: string,
        json?: string | null,
        text?: string | null,
        reportable?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      photoSequence?: Array< string | null > | null,
      tags?:  {
        __typename: "ModelLocationTagConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      photos?:  {
        __typename: "ModelLocationPhotoConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      locationPrimaryPhotoId?: string | null,
      owner?: string | null,
    } | null,
    locations?:  {
      __typename: "ModelLocationConnection",
      items:  Array< {
        __typename: "Location",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        observationId: string,
        photoSequence?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        locationPrimaryPhotoId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    status?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    observationPrimaryLocationId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteObservationSubscriptionVariables = {
  filter?: ModelSubscriptionObservationFilterInput | null,
};

export type OnDeleteObservationSubscription = {
  onDeleteObservation?:  {
    __typename: "Observation",
    id: string,
    groupId?: string | null,
    type?: ObservationType | null,
    projectId?: string | null,
    inspectionId: string,
    hierarchyItemId: string,
    hierarchyItem?:  {
      __typename: "HierarchyItem",
      id: string,
      name: string,
      code?: string | null,
      hierarchyId: string,
      parentId?: string | null,
      hidden?: boolean | null,
      inspectionPoints?:  {
        __typename: "ModelInspectionPointConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    inspectionPointId: string,
    inspectionPoint?:  {
      __typename: "InspectionPoint",
      id: string,
      groupId?: string | null,
      hierarchyItemId: string,
      type: InspectionPointType,
      projectId?: string | null,
      text: string,
      code?: string | null,
      hidden?: boolean | null,
      observations?:  {
        __typename: "ModelObservationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    text?: string | null,
    suggestion?: string | null,
    findingId?: string | null,
    primaryLocation?:  {
      __typename: "Location",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      observationId: string,
      primaryPhoto?:  {
        __typename: "Photo",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        inspectionId: string,
        json?: string | null,
        text?: string | null,
        reportable?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      photoSequence?: Array< string | null > | null,
      tags?:  {
        __typename: "ModelLocationTagConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      photos?:  {
        __typename: "ModelLocationPhotoConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      locationPrimaryPhotoId?: string | null,
      owner?: string | null,
    } | null,
    locations?:  {
      __typename: "ModelLocationConnection",
      items:  Array< {
        __typename: "Location",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        observationId: string,
        photoSequence?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        locationPrimaryPhotoId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    status?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    observationPrimaryLocationId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateLocationSubscriptionVariables = {
  filter?: ModelSubscriptionLocationFilterInput | null,
};

export type OnCreateLocationSubscription = {
  onCreateLocation?:  {
    __typename: "Location",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    observationId: string,
    primaryPhoto?:  {
      __typename: "Photo",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      inspectionId: string,
      json?: string | null,
      text?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      reportable?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    photoSequence?: Array< string | null > | null,
    tags?:  {
      __typename: "ModelLocationTagConnection",
      items:  Array< {
        __typename: "LocationTag",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        locationId: string,
        tagId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    photos?:  {
      __typename: "ModelLocationPhotoConnection",
      items:  Array< {
        __typename: "LocationPhoto",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        locationId: string,
        photoId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    locationPrimaryPhotoId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateLocationSubscriptionVariables = {
  filter?: ModelSubscriptionLocationFilterInput | null,
};

export type OnUpdateLocationSubscription = {
  onUpdateLocation?:  {
    __typename: "Location",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    observationId: string,
    primaryPhoto?:  {
      __typename: "Photo",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      inspectionId: string,
      json?: string | null,
      text?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      reportable?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    photoSequence?: Array< string | null > | null,
    tags?:  {
      __typename: "ModelLocationTagConnection",
      items:  Array< {
        __typename: "LocationTag",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        locationId: string,
        tagId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    photos?:  {
      __typename: "ModelLocationPhotoConnection",
      items:  Array< {
        __typename: "LocationPhoto",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        locationId: string,
        photoId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    locationPrimaryPhotoId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteLocationSubscriptionVariables = {
  filter?: ModelSubscriptionLocationFilterInput | null,
};

export type OnDeleteLocationSubscription = {
  onDeleteLocation?:  {
    __typename: "Location",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    observationId: string,
    primaryPhoto?:  {
      __typename: "Photo",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      inspectionId: string,
      json?: string | null,
      text?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      reportable?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    photoSequence?: Array< string | null > | null,
    tags?:  {
      __typename: "ModelLocationTagConnection",
      items:  Array< {
        __typename: "LocationTag",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        locationId: string,
        tagId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    photos?:  {
      __typename: "ModelLocationPhotoConnection",
      items:  Array< {
        __typename: "LocationPhoto",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        locationId: string,
        photoId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    locationPrimaryPhotoId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateTagSubscriptionVariables = {
  filter?: ModelSubscriptionTagFilterInput | null,
};

export type OnCreateTagSubscription = {
  onCreateTag?:  {
    __typename: "Tag",
    id: string,
    groupId?: string | null,
    type: TagType,
    projectId: string,
    text: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnUpdateTagSubscriptionVariables = {
  filter?: ModelSubscriptionTagFilterInput | null,
};

export type OnUpdateTagSubscription = {
  onUpdateTag?:  {
    __typename: "Tag",
    id: string,
    groupId?: string | null,
    type: TagType,
    projectId: string,
    text: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnDeleteTagSubscriptionVariables = {
  filter?: ModelSubscriptionTagFilterInput | null,
};

export type OnDeleteTagSubscription = {
  onDeleteTag?:  {
    __typename: "Tag",
    id: string,
    groupId?: string | null,
    type: TagType,
    projectId: string,
    text: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnCreateLocationTagSubscriptionVariables = {
  filter?: ModelSubscriptionLocationTagFilterInput | null,
};

export type OnCreateLocationTagSubscription = {
  onCreateLocationTag?:  {
    __typename: "LocationTag",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    locationId: string,
    tagId: string,
    tag?:  {
      __typename: "Tag",
      id: string,
      groupId?: string | null,
      type: TagType,
      projectId: string,
      text: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnUpdateLocationTagSubscriptionVariables = {
  filter?: ModelSubscriptionLocationTagFilterInput | null,
};

export type OnUpdateLocationTagSubscription = {
  onUpdateLocationTag?:  {
    __typename: "LocationTag",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    locationId: string,
    tagId: string,
    tag?:  {
      __typename: "Tag",
      id: string,
      groupId?: string | null,
      type: TagType,
      projectId: string,
      text: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnDeleteLocationTagSubscriptionVariables = {
  filter?: ModelSubscriptionLocationTagFilterInput | null,
};

export type OnDeleteLocationTagSubscription = {
  onDeleteLocationTag?:  {
    __typename: "LocationTag",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    locationId: string,
    tagId: string,
    tag?:  {
      __typename: "Tag",
      id: string,
      groupId?: string | null,
      type: TagType,
      projectId: string,
      text: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnCreatePhotoSubscriptionVariables = {
  filter?: ModelSubscriptionPhotoFilterInput | null,
};

export type OnCreatePhotoSubscription = {
  onCreatePhoto?:  {
    __typename: "Photo",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    inspectionId: string,
    json?: string | null,
    text?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    reportable?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnUpdatePhotoSubscriptionVariables = {
  filter?: ModelSubscriptionPhotoFilterInput | null,
};

export type OnUpdatePhotoSubscription = {
  onUpdatePhoto?:  {
    __typename: "Photo",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    inspectionId: string,
    json?: string | null,
    text?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    reportable?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnDeletePhotoSubscriptionVariables = {
  filter?: ModelSubscriptionPhotoFilterInput | null,
};

export type OnDeletePhotoSubscription = {
  onDeletePhoto?:  {
    __typename: "Photo",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    inspectionId: string,
    json?: string | null,
    text?: string | null,
    file?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    reportable?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnCreateInspectionPhotoSubscriptionVariables = {
  filter?: ModelSubscriptionInspectionPhotoFilterInput | null,
};

export type OnCreateInspectionPhotoSubscription = {
  onCreateInspectionPhoto?:  {
    __typename: "InspectionPhoto",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    inspectionId: string,
    photoId: string,
    photo?:  {
      __typename: "Photo",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      inspectionId: string,
      json?: string | null,
      text?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      reportable?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnUpdateInspectionPhotoSubscriptionVariables = {
  filter?: ModelSubscriptionInspectionPhotoFilterInput | null,
};

export type OnUpdateInspectionPhotoSubscription = {
  onUpdateInspectionPhoto?:  {
    __typename: "InspectionPhoto",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    inspectionId: string,
    photoId: string,
    photo?:  {
      __typename: "Photo",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      inspectionId: string,
      json?: string | null,
      text?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      reportable?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnDeleteInspectionPhotoSubscriptionVariables = {
  filter?: ModelSubscriptionInspectionPhotoFilterInput | null,
};

export type OnDeleteInspectionPhotoSubscription = {
  onDeleteInspectionPhoto?:  {
    __typename: "InspectionPhoto",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    inspectionId: string,
    photoId: string,
    photo?:  {
      __typename: "Photo",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      inspectionId: string,
      json?: string | null,
      text?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      reportable?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnCreateLocationPhotoSubscriptionVariables = {
  filter?: ModelSubscriptionLocationPhotoFilterInput | null,
};

export type OnCreateLocationPhotoSubscription = {
  onCreateLocationPhoto?:  {
    __typename: "LocationPhoto",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    locationId: string,
    photoId: string,
    photo?:  {
      __typename: "Photo",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      inspectionId: string,
      json?: string | null,
      text?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      reportable?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnUpdateLocationPhotoSubscriptionVariables = {
  filter?: ModelSubscriptionLocationPhotoFilterInput | null,
};

export type OnUpdateLocationPhotoSubscription = {
  onUpdateLocationPhoto?:  {
    __typename: "LocationPhoto",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    locationId: string,
    photoId: string,
    photo?:  {
      __typename: "Photo",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      inspectionId: string,
      json?: string | null,
      text?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      reportable?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnDeleteLocationPhotoSubscriptionVariables = {
  filter?: ModelSubscriptionLocationPhotoFilterInput | null,
};

export type OnDeleteLocationPhotoSubscription = {
  onDeleteLocationPhoto?:  {
    __typename: "LocationPhoto",
    id: string,
    groupId?: string | null,
    projectId?: string | null,
    locationId: string,
    photoId: string,
    photo?:  {
      __typename: "Photo",
      id: string,
      groupId?: string | null,
      projectId?: string | null,
      inspectionId: string,
      json?: string | null,
      text?: string | null,
      file?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      reportable?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnCreateFindingSubscriptionVariables = {
  filter?: ModelSubscriptionFindingFilterInput | null,
};

export type OnCreateFindingSubscription = {
  onCreateFinding?:  {
    __typename: "Finding",
    id: string,
    groupId?: string | null,
    type: FindingType,
    projectId: string,
    phaseId?: string | null,
    inspectionId: string,
    hierarchyItemId: string,
    hierarchyItem?:  {
      __typename: "HierarchyItem",
      id: string,
      name: string,
      code?: string | null,
      hierarchyId: string,
      parentId?: string | null,
      hidden?: boolean | null,
      inspectionPoints?:  {
        __typename: "ModelInspectionPointConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    observationId: string,
    observation?:  {
      __typename: "Observation",
      id: string,
      groupId?: string | null,
      type?: ObservationType | null,
      projectId?: string | null,
      inspectionId: string,
      hierarchyItemId: string,
      hierarchyItem?:  {
        __typename: "HierarchyItem",
        id: string,
        name: string,
        code?: string | null,
        hierarchyId: string,
        parentId?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      inspectionPointId: string,
      inspectionPoint?:  {
        __typename: "InspectionPoint",
        id: string,
        groupId?: string | null,
        hierarchyItemId: string,
        type: InspectionPointType,
        projectId?: string | null,
        text: string,
        code?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      text?: string | null,
      suggestion?: string | null,
      findingId?: string | null,
      primaryLocation?:  {
        __typename: "Location",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        observationId: string,
        photoSequence?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        locationPrimaryPhotoId?: string | null,
        owner?: string | null,
      } | null,
      locations?:  {
        __typename: "ModelLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      observationPrimaryLocationId?: string | null,
      owner?: string | null,
    } | null,
    closingObservation?:  {
      __typename: "Observation",
      id: string,
      groupId?: string | null,
      type?: ObservationType | null,
      projectId?: string | null,
      inspectionId: string,
      hierarchyItemId: string,
      hierarchyItem?:  {
        __typename: "HierarchyItem",
        id: string,
        name: string,
        code?: string | null,
        hierarchyId: string,
        parentId?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      inspectionPointId: string,
      inspectionPoint?:  {
        __typename: "InspectionPoint",
        id: string,
        groupId?: string | null,
        hierarchyItemId: string,
        type: InspectionPointType,
        projectId?: string | null,
        text: string,
        code?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      text?: string | null,
      suggestion?: string | null,
      findingId?: string | null,
      primaryLocation?:  {
        __typename: "Location",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        observationId: string,
        photoSequence?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        locationPrimaryPhotoId?: string | null,
        owner?: string | null,
      } | null,
      locations?:  {
        __typename: "ModelLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      observationPrimaryLocationId?: string | null,
      owner?: string | null,
    } | null,
    status: FindingStatus,
    date?: string | null,
    closedDate?: string | null,
    code?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    findingClosingObservationId?: string | null,
  } | null,
};

export type OnUpdateFindingSubscriptionVariables = {
  filter?: ModelSubscriptionFindingFilterInput | null,
};

export type OnUpdateFindingSubscription = {
  onUpdateFinding?:  {
    __typename: "Finding",
    id: string,
    groupId?: string | null,
    type: FindingType,
    projectId: string,
    phaseId?: string | null,
    inspectionId: string,
    hierarchyItemId: string,
    hierarchyItem?:  {
      __typename: "HierarchyItem",
      id: string,
      name: string,
      code?: string | null,
      hierarchyId: string,
      parentId?: string | null,
      hidden?: boolean | null,
      inspectionPoints?:  {
        __typename: "ModelInspectionPointConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    observationId: string,
    observation?:  {
      __typename: "Observation",
      id: string,
      groupId?: string | null,
      type?: ObservationType | null,
      projectId?: string | null,
      inspectionId: string,
      hierarchyItemId: string,
      hierarchyItem?:  {
        __typename: "HierarchyItem",
        id: string,
        name: string,
        code?: string | null,
        hierarchyId: string,
        parentId?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      inspectionPointId: string,
      inspectionPoint?:  {
        __typename: "InspectionPoint",
        id: string,
        groupId?: string | null,
        hierarchyItemId: string,
        type: InspectionPointType,
        projectId?: string | null,
        text: string,
        code?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      text?: string | null,
      suggestion?: string | null,
      findingId?: string | null,
      primaryLocation?:  {
        __typename: "Location",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        observationId: string,
        photoSequence?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        locationPrimaryPhotoId?: string | null,
        owner?: string | null,
      } | null,
      locations?:  {
        __typename: "ModelLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      observationPrimaryLocationId?: string | null,
      owner?: string | null,
    } | null,
    closingObservation?:  {
      __typename: "Observation",
      id: string,
      groupId?: string | null,
      type?: ObservationType | null,
      projectId?: string | null,
      inspectionId: string,
      hierarchyItemId: string,
      hierarchyItem?:  {
        __typename: "HierarchyItem",
        id: string,
        name: string,
        code?: string | null,
        hierarchyId: string,
        parentId?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      inspectionPointId: string,
      inspectionPoint?:  {
        __typename: "InspectionPoint",
        id: string,
        groupId?: string | null,
        hierarchyItemId: string,
        type: InspectionPointType,
        projectId?: string | null,
        text: string,
        code?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      text?: string | null,
      suggestion?: string | null,
      findingId?: string | null,
      primaryLocation?:  {
        __typename: "Location",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        observationId: string,
        photoSequence?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        locationPrimaryPhotoId?: string | null,
        owner?: string | null,
      } | null,
      locations?:  {
        __typename: "ModelLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      observationPrimaryLocationId?: string | null,
      owner?: string | null,
    } | null,
    status: FindingStatus,
    date?: string | null,
    closedDate?: string | null,
    code?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    findingClosingObservationId?: string | null,
  } | null,
};

export type OnDeleteFindingSubscriptionVariables = {
  filter?: ModelSubscriptionFindingFilterInput | null,
};

export type OnDeleteFindingSubscription = {
  onDeleteFinding?:  {
    __typename: "Finding",
    id: string,
    groupId?: string | null,
    type: FindingType,
    projectId: string,
    phaseId?: string | null,
    inspectionId: string,
    hierarchyItemId: string,
    hierarchyItem?:  {
      __typename: "HierarchyItem",
      id: string,
      name: string,
      code?: string | null,
      hierarchyId: string,
      parentId?: string | null,
      hidden?: boolean | null,
      inspectionPoints?:  {
        __typename: "ModelInspectionPointConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    observationId: string,
    observation?:  {
      __typename: "Observation",
      id: string,
      groupId?: string | null,
      type?: ObservationType | null,
      projectId?: string | null,
      inspectionId: string,
      hierarchyItemId: string,
      hierarchyItem?:  {
        __typename: "HierarchyItem",
        id: string,
        name: string,
        code?: string | null,
        hierarchyId: string,
        parentId?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      inspectionPointId: string,
      inspectionPoint?:  {
        __typename: "InspectionPoint",
        id: string,
        groupId?: string | null,
        hierarchyItemId: string,
        type: InspectionPointType,
        projectId?: string | null,
        text: string,
        code?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      text?: string | null,
      suggestion?: string | null,
      findingId?: string | null,
      primaryLocation?:  {
        __typename: "Location",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        observationId: string,
        photoSequence?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        locationPrimaryPhotoId?: string | null,
        owner?: string | null,
      } | null,
      locations?:  {
        __typename: "ModelLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      observationPrimaryLocationId?: string | null,
      owner?: string | null,
    } | null,
    closingObservation?:  {
      __typename: "Observation",
      id: string,
      groupId?: string | null,
      type?: ObservationType | null,
      projectId?: string | null,
      inspectionId: string,
      hierarchyItemId: string,
      hierarchyItem?:  {
        __typename: "HierarchyItem",
        id: string,
        name: string,
        code?: string | null,
        hierarchyId: string,
        parentId?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      inspectionPointId: string,
      inspectionPoint?:  {
        __typename: "InspectionPoint",
        id: string,
        groupId?: string | null,
        hierarchyItemId: string,
        type: InspectionPointType,
        projectId?: string | null,
        text: string,
        code?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      text?: string | null,
      suggestion?: string | null,
      findingId?: string | null,
      primaryLocation?:  {
        __typename: "Location",
        id: string,
        groupId?: string | null,
        projectId?: string | null,
        observationId: string,
        photoSequence?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        locationPrimaryPhotoId?: string | null,
        owner?: string | null,
      } | null,
      locations?:  {
        __typename: "ModelLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      status?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      observationPrimaryLocationId?: string | null,
      owner?: string | null,
    } | null,
    status: FindingStatus,
    date?: string | null,
    closedDate?: string | null,
    code?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    findingClosingObservationId?: string | null,
  } | null,
};

export type OnCreateContactSubscriptionVariables = {
  filter?: ModelSubscriptionContactFilterInput | null,
};

export type OnCreateContactSubscription = {
  onCreateContact?:  {
    __typename: "Contact",
    id: string,
    type: string,
    firstName: string,
    lastName: string,
    companyName?: string | null,
    jobTitle?: string | null,
    phone?: string | null,
    email?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnUpdateContactSubscriptionVariables = {
  filter?: ModelSubscriptionContactFilterInput | null,
};

export type OnUpdateContactSubscription = {
  onUpdateContact?:  {
    __typename: "Contact",
    id: string,
    type: string,
    firstName: string,
    lastName: string,
    companyName?: string | null,
    jobTitle?: string | null,
    phone?: string | null,
    email?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnDeleteContactSubscriptionVariables = {
  filter?: ModelSubscriptionContactFilterInput | null,
};

export type OnDeleteContactSubscription = {
  onDeleteContact?:  {
    __typename: "Contact",
    id: string,
    type: string,
    firstName: string,
    lastName: string,
    companyName?: string | null,
    jobTitle?: string | null,
    phone?: string | null,
    email?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    status: string,
    type: string,
    firstName: string,
    lastName: string,
    companyName?: string | null,
    jobTitle?: string | null,
    phone?: string | null,
    email?: string | null,
    metadata?: string | null,
    projects?: Array< string | null > | null,
    favoriteProjects?: string | null,
    inspections?:  {
      __typename: "ModelInspectionConnection",
      items:  Array< {
        __typename: "Inspection",
        id: string,
        groupId?: string | null,
        clientId?: string | null,
        projectId: string,
        phaseId?: string | null,
        photoSequence?: Array< string | null > | null,
        status: string,
        userId: string,
        inspectionReviewerId?: string | null,
        inspectionDate?: string | null,
        reviewedDate?: string | null,
        deliveredDate?: string | null,
        nextInspectionDate?: string | null,
        arrivalTime?: string | null,
        notes?: string | null,
        coverLetter?: string | null,
        scopeHierarchyItems?: Array< string | null > | null,
        scope?: string | null,
        number?: string | null,
        code?: string | null,
        weather?: string | null,
        milestoneBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        inspectionCheckInContactId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    status: string,
    type: string,
    firstName: string,
    lastName: string,
    companyName?: string | null,
    jobTitle?: string | null,
    phone?: string | null,
    email?: string | null,
    metadata?: string | null,
    projects?: Array< string | null > | null,
    favoriteProjects?: string | null,
    inspections?:  {
      __typename: "ModelInspectionConnection",
      items:  Array< {
        __typename: "Inspection",
        id: string,
        groupId?: string | null,
        clientId?: string | null,
        projectId: string,
        phaseId?: string | null,
        photoSequence?: Array< string | null > | null,
        status: string,
        userId: string,
        inspectionReviewerId?: string | null,
        inspectionDate?: string | null,
        reviewedDate?: string | null,
        deliveredDate?: string | null,
        nextInspectionDate?: string | null,
        arrivalTime?: string | null,
        notes?: string | null,
        coverLetter?: string | null,
        scopeHierarchyItems?: Array< string | null > | null,
        scope?: string | null,
        number?: string | null,
        code?: string | null,
        weather?: string | null,
        milestoneBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        inspectionCheckInContactId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    status: string,
    type: string,
    firstName: string,
    lastName: string,
    companyName?: string | null,
    jobTitle?: string | null,
    phone?: string | null,
    email?: string | null,
    metadata?: string | null,
    projects?: Array< string | null > | null,
    favoriteProjects?: string | null,
    inspections?:  {
      __typename: "ModelInspectionConnection",
      items:  Array< {
        __typename: "Inspection",
        id: string,
        groupId?: string | null,
        clientId?: string | null,
        projectId: string,
        phaseId?: string | null,
        photoSequence?: Array< string | null > | null,
        status: string,
        userId: string,
        inspectionReviewerId?: string | null,
        inspectionDate?: string | null,
        reviewedDate?: string | null,
        deliveredDate?: string | null,
        nextInspectionDate?: string | null,
        arrivalTime?: string | null,
        notes?: string | null,
        coverLetter?: string | null,
        scopeHierarchyItems?: Array< string | null > | null,
        scope?: string | null,
        number?: string | null,
        code?: string | null,
        weather?: string | null,
        milestoneBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        inspectionCheckInContactId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnCreateClientSubscriptionVariables = {
  filter?: ModelSubscriptionClientFilterInput | null,
};

export type OnCreateClientSubscription = {
  onCreateClient?:  {
    __typename: "Client",
    id: string,
    name: string,
    code: string,
    status: string,
    address:  {
      __typename: "Address",
      street: string,
      street2?: string | null,
      city: string,
      state: string,
      zip: string,
    },
    primaryContact?:  {
      __typename: "Contact",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    reportDisclaimer?: string | null,
    contacts?:  {
      __typename: "ModelClientContactConnection",
      items:  Array< {
        __typename: "ClientContact",
        id: string,
        clientId: string,
        contactId: string,
        label?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    projects?:  {
      __typename: "ModelProjectConnection",
      items:  Array< {
        __typename: "Project",
        id: string,
        groupId?: string | null,
        name: string,
        status: string,
        clientId: string,
        reportTemplateId?: string | null,
        reportDisclaimer?: string | null,
        distributionList?: string | null,
        hierarchyItemExclusions?: Array< string | null > | null,
        exclusions?: string | null,
        notes?: string | null,
        code?: string | null,
        milestoneTemplateId?: string | null,
        externalId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        projectEftId?: string | null,
        projectReviewerId?: string | null,
        projectAdminId?: string | null,
        projectPrimaryContactId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    clientPrimaryContactId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateClientSubscriptionVariables = {
  filter?: ModelSubscriptionClientFilterInput | null,
};

export type OnUpdateClientSubscription = {
  onUpdateClient?:  {
    __typename: "Client",
    id: string,
    name: string,
    code: string,
    status: string,
    address:  {
      __typename: "Address",
      street: string,
      street2?: string | null,
      city: string,
      state: string,
      zip: string,
    },
    primaryContact?:  {
      __typename: "Contact",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    reportDisclaimer?: string | null,
    contacts?:  {
      __typename: "ModelClientContactConnection",
      items:  Array< {
        __typename: "ClientContact",
        id: string,
        clientId: string,
        contactId: string,
        label?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    projects?:  {
      __typename: "ModelProjectConnection",
      items:  Array< {
        __typename: "Project",
        id: string,
        groupId?: string | null,
        name: string,
        status: string,
        clientId: string,
        reportTemplateId?: string | null,
        reportDisclaimer?: string | null,
        distributionList?: string | null,
        hierarchyItemExclusions?: Array< string | null > | null,
        exclusions?: string | null,
        notes?: string | null,
        code?: string | null,
        milestoneTemplateId?: string | null,
        externalId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        projectEftId?: string | null,
        projectReviewerId?: string | null,
        projectAdminId?: string | null,
        projectPrimaryContactId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    clientPrimaryContactId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteClientSubscriptionVariables = {
  filter?: ModelSubscriptionClientFilterInput | null,
};

export type OnDeleteClientSubscription = {
  onDeleteClient?:  {
    __typename: "Client",
    id: string,
    name: string,
    code: string,
    status: string,
    address:  {
      __typename: "Address",
      street: string,
      street2?: string | null,
      city: string,
      state: string,
      zip: string,
    },
    primaryContact?:  {
      __typename: "Contact",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    reportDisclaimer?: string | null,
    contacts?:  {
      __typename: "ModelClientContactConnection",
      items:  Array< {
        __typename: "ClientContact",
        id: string,
        clientId: string,
        contactId: string,
        label?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    projects?:  {
      __typename: "ModelProjectConnection",
      items:  Array< {
        __typename: "Project",
        id: string,
        groupId?: string | null,
        name: string,
        status: string,
        clientId: string,
        reportTemplateId?: string | null,
        reportDisclaimer?: string | null,
        distributionList?: string | null,
        hierarchyItemExclusions?: Array< string | null > | null,
        exclusions?: string | null,
        notes?: string | null,
        code?: string | null,
        milestoneTemplateId?: string | null,
        externalId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        projectEftId?: string | null,
        projectReviewerId?: string | null,
        projectAdminId?: string | null,
        projectPrimaryContactId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    clientPrimaryContactId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateClientContactSubscriptionVariables = {
  filter?: ModelSubscriptionClientContactFilterInput | null,
};

export type OnCreateClientContactSubscription = {
  onCreateClientContact?:  {
    __typename: "ClientContact",
    id: string,
    clientId: string,
    contactId: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    label?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnUpdateClientContactSubscriptionVariables = {
  filter?: ModelSubscriptionClientContactFilterInput | null,
};

export type OnUpdateClientContactSubscription = {
  onUpdateClientContact?:  {
    __typename: "ClientContact",
    id: string,
    clientId: string,
    contactId: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    label?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnDeleteClientContactSubscriptionVariables = {
  filter?: ModelSubscriptionClientContactFilterInput | null,
};

export type OnDeleteClientContactSubscription = {
  onDeleteClientContact?:  {
    __typename: "ClientContact",
    id: string,
    clientId: string,
    contactId: string,
    contact?:  {
      __typename: "Contact",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    label?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnCreateProjectSubscriptionVariables = {
  filter?: ModelSubscriptionProjectFilterInput | null,
};

export type OnCreateProjectSubscription = {
  onCreateProject?:  {
    __typename: "Project",
    id: string,
    groupId?: string | null,
    name: string,
    status: string,
    clientId: string,
    client?:  {
      __typename: "Client",
      id: string,
      name: string,
      code: string,
      status: string,
      address:  {
        __typename: "Address",
        street: string,
        street2?: string | null,
        city: string,
        state: string,
        zip: string,
      },
      primaryContact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reportDisclaimer?: string | null,
      contacts?:  {
        __typename: "ModelClientContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      projects?:  {
        __typename: "ModelProjectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      clientPrimaryContactId?: string | null,
      owner?: string | null,
    } | null,
    eft?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    reviewer?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    admin?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    address:  {
      __typename: "Address",
      street: string,
      street2?: string | null,
      city: string,
      state: string,
      zip: string,
    },
    primaryContact?:  {
      __typename: "Contact",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    reportTemplateId?: string | null,
    reportDisclaimer?: string | null,
    inspections?:  {
      __typename: "ModelInspectionConnection",
      items:  Array< {
        __typename: "Inspection",
        id: string,
        groupId?: string | null,
        clientId?: string | null,
        projectId: string,
        phaseId?: string | null,
        photoSequence?: Array< string | null > | null,
        status: string,
        userId: string,
        inspectionReviewerId?: string | null,
        inspectionDate?: string | null,
        reviewedDate?: string | null,
        deliveredDate?: string | null,
        nextInspectionDate?: string | null,
        arrivalTime?: string | null,
        notes?: string | null,
        coverLetter?: string | null,
        scopeHierarchyItems?: Array< string | null > | null,
        scope?: string | null,
        number?: string | null,
        code?: string | null,
        weather?: string | null,
        milestoneBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        inspectionCheckInContactId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    contacts?:  {
      __typename: "ModelProjectContactConnection",
      items:  Array< {
        __typename: "ProjectContact",
        id: string,
        groupId?: string | null,
        projectId: string,
        contactId: string,
        label?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    distributionList?: string | null,
    externalLinks?:  {
      __typename: "ModelExternalLinkConnection",
      items:  Array< {
        __typename: "ExternalLink",
        id: string,
        groupId?: string | null,
        projectId: string,
        title: string,
        url: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    tags?:  {
      __typename: "ModelTagConnection",
      items:  Array< {
        __typename: "Tag",
        id: string,
        groupId?: string | null,
        type: TagType,
        projectId: string,
        text: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    findings?:  {
      __typename: "ModelFindingConnection",
      items:  Array< {
        __typename: "Finding",
        id: string,
        groupId?: string | null,
        type: FindingType,
        projectId: string,
        phaseId?: string | null,
        inspectionId: string,
        hierarchyItemId: string,
        observationId: string,
        status: FindingStatus,
        date?: string | null,
        closedDate?: string | null,
        code?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        findingClosingObservationId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    phases?:  {
      __typename: "ModelPhaseConnection",
      items:  Array< {
        __typename: "Phase",
        id: string,
        groupId?: string | null,
        projectId: string,
        name: string,
        status: string,
        milestoneTemplateId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    hierarchyItemExclusions?: Array< string | null > | null,
    exclusions?: string | null,
    notes?: string | null,
    code?: string | null,
    milestoneTemplate?:  {
      __typename: "MilestoneTemplate",
      id: string,
      name: string,
      status: string,
      hierarchyBundleIds?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    milestoneTemplateId?: string | null,
    externalId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    projectEftId?: string | null,
    projectReviewerId?: string | null,
    projectAdminId?: string | null,
    projectPrimaryContactId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateProjectSubscriptionVariables = {
  filter?: ModelSubscriptionProjectFilterInput | null,
};

export type OnUpdateProjectSubscription = {
  onUpdateProject?:  {
    __typename: "Project",
    id: string,
    groupId?: string | null,
    name: string,
    status: string,
    clientId: string,
    client?:  {
      __typename: "Client",
      id: string,
      name: string,
      code: string,
      status: string,
      address:  {
        __typename: "Address",
        street: string,
        street2?: string | null,
        city: string,
        state: string,
        zip: string,
      },
      primaryContact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reportDisclaimer?: string | null,
      contacts?:  {
        __typename: "ModelClientContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      projects?:  {
        __typename: "ModelProjectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      clientPrimaryContactId?: string | null,
      owner?: string | null,
    } | null,
    eft?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    reviewer?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    admin?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    address:  {
      __typename: "Address",
      street: string,
      street2?: string | null,
      city: string,
      state: string,
      zip: string,
    },
    primaryContact?:  {
      __typename: "Contact",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    reportTemplateId?: string | null,
    reportDisclaimer?: string | null,
    inspections?:  {
      __typename: "ModelInspectionConnection",
      items:  Array< {
        __typename: "Inspection",
        id: string,
        groupId?: string | null,
        clientId?: string | null,
        projectId: string,
        phaseId?: string | null,
        photoSequence?: Array< string | null > | null,
        status: string,
        userId: string,
        inspectionReviewerId?: string | null,
        inspectionDate?: string | null,
        reviewedDate?: string | null,
        deliveredDate?: string | null,
        nextInspectionDate?: string | null,
        arrivalTime?: string | null,
        notes?: string | null,
        coverLetter?: string | null,
        scopeHierarchyItems?: Array< string | null > | null,
        scope?: string | null,
        number?: string | null,
        code?: string | null,
        weather?: string | null,
        milestoneBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        inspectionCheckInContactId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    contacts?:  {
      __typename: "ModelProjectContactConnection",
      items:  Array< {
        __typename: "ProjectContact",
        id: string,
        groupId?: string | null,
        projectId: string,
        contactId: string,
        label?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    distributionList?: string | null,
    externalLinks?:  {
      __typename: "ModelExternalLinkConnection",
      items:  Array< {
        __typename: "ExternalLink",
        id: string,
        groupId?: string | null,
        projectId: string,
        title: string,
        url: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    tags?:  {
      __typename: "ModelTagConnection",
      items:  Array< {
        __typename: "Tag",
        id: string,
        groupId?: string | null,
        type: TagType,
        projectId: string,
        text: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    findings?:  {
      __typename: "ModelFindingConnection",
      items:  Array< {
        __typename: "Finding",
        id: string,
        groupId?: string | null,
        type: FindingType,
        projectId: string,
        phaseId?: string | null,
        inspectionId: string,
        hierarchyItemId: string,
        observationId: string,
        status: FindingStatus,
        date?: string | null,
        closedDate?: string | null,
        code?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        findingClosingObservationId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    phases?:  {
      __typename: "ModelPhaseConnection",
      items:  Array< {
        __typename: "Phase",
        id: string,
        groupId?: string | null,
        projectId: string,
        name: string,
        status: string,
        milestoneTemplateId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    hierarchyItemExclusions?: Array< string | null > | null,
    exclusions?: string | null,
    notes?: string | null,
    code?: string | null,
    milestoneTemplate?:  {
      __typename: "MilestoneTemplate",
      id: string,
      name: string,
      status: string,
      hierarchyBundleIds?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    milestoneTemplateId?: string | null,
    externalId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    projectEftId?: string | null,
    projectReviewerId?: string | null,
    projectAdminId?: string | null,
    projectPrimaryContactId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteProjectSubscriptionVariables = {
  filter?: ModelSubscriptionProjectFilterInput | null,
};

export type OnDeleteProjectSubscription = {
  onDeleteProject?:  {
    __typename: "Project",
    id: string,
    groupId?: string | null,
    name: string,
    status: string,
    clientId: string,
    client?:  {
      __typename: "Client",
      id: string,
      name: string,
      code: string,
      status: string,
      address:  {
        __typename: "Address",
        street: string,
        street2?: string | null,
        city: string,
        state: string,
        zip: string,
      },
      primaryContact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reportDisclaimer?: string | null,
      contacts?:  {
        __typename: "ModelClientContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      projects?:  {
        __typename: "ModelProjectConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      clientPrimaryContactId?: string | null,
      owner?: string | null,
    } | null,
    eft?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    reviewer?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    admin?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    address:  {
      __typename: "Address",
      street: string,
      street2?: string | null,
      city: string,
      state: string,
      zip: string,
    },
    primaryContact?:  {
      __typename: "Contact",
      id: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    reportTemplateId?: string | null,
    reportDisclaimer?: string | null,
    inspections?:  {
      __typename: "ModelInspectionConnection",
      items:  Array< {
        __typename: "Inspection",
        id: string,
        groupId?: string | null,
        clientId?: string | null,
        projectId: string,
        phaseId?: string | null,
        photoSequence?: Array< string | null > | null,
        status: string,
        userId: string,
        inspectionReviewerId?: string | null,
        inspectionDate?: string | null,
        reviewedDate?: string | null,
        deliveredDate?: string | null,
        nextInspectionDate?: string | null,
        arrivalTime?: string | null,
        notes?: string | null,
        coverLetter?: string | null,
        scopeHierarchyItems?: Array< string | null > | null,
        scope?: string | null,
        number?: string | null,
        code?: string | null,
        weather?: string | null,
        milestoneBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        inspectionCheckInContactId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    contacts?:  {
      __typename: "ModelProjectContactConnection",
      items:  Array< {
        __typename: "ProjectContact",
        id: string,
        groupId?: string | null,
        projectId: string,
        contactId: string,
        label?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    distributionList?: string | null,
    externalLinks?:  {
      __typename: "ModelExternalLinkConnection",
      items:  Array< {
        __typename: "ExternalLink",
        id: string,
        groupId?: string | null,
        projectId: string,
        title: string,
        url: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    tags?:  {
      __typename: "ModelTagConnection",
      items:  Array< {
        __typename: "Tag",
        id: string,
        groupId?: string | null,
        type: TagType,
        projectId: string,
        text: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    findings?:  {
      __typename: "ModelFindingConnection",
      items:  Array< {
        __typename: "Finding",
        id: string,
        groupId?: string | null,
        type: FindingType,
        projectId: string,
        phaseId?: string | null,
        inspectionId: string,
        hierarchyItemId: string,
        observationId: string,
        status: FindingStatus,
        date?: string | null,
        closedDate?: string | null,
        code?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        findingClosingObservationId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    phases?:  {
      __typename: "ModelPhaseConnection",
      items:  Array< {
        __typename: "Phase",
        id: string,
        groupId?: string | null,
        projectId: string,
        name: string,
        status: string,
        milestoneTemplateId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    hierarchyItemExclusions?: Array< string | null > | null,
    exclusions?: string | null,
    notes?: string | null,
    code?: string | null,
    milestoneTemplate?:  {
      __typename: "MilestoneTemplate",
      id: string,
      name: string,
      status: string,
      hierarchyBundleIds?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    milestoneTemplateId?: string | null,
    externalId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    projectEftId?: string | null,
    projectReviewerId?: string | null,
    projectAdminId?: string | null,
    projectPrimaryContactId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateFavoriteProjectSubscriptionVariables = {
  filter?: ModelSubscriptionFavoriteProjectFilterInput | null,
  owner?: string | null,
};

export type OnCreateFavoriteProjectSubscription = {
  onCreateFavoriteProject?:  {
    __typename: "FavoriteProject",
    id: string,
    projectId: string,
    project:  {
      __typename: "Project",
      id: string,
      groupId?: string | null,
      name: string,
      status: string,
      clientId: string,
      client?:  {
        __typename: "Client",
        id: string,
        name: string,
        code: string,
        status: string,
        reportDisclaimer?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        clientPrimaryContactId?: string | null,
        owner?: string | null,
      } | null,
      eft?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reviewer?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      admin?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      address:  {
        __typename: "Address",
        street: string,
        street2?: string | null,
        city: string,
        state: string,
        zip: string,
      },
      primaryContact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reportTemplateId?: string | null,
      reportDisclaimer?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      contacts?:  {
        __typename: "ModelProjectContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      distributionList?: string | null,
      externalLinks?:  {
        __typename: "ModelExternalLinkConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      tags?:  {
        __typename: "ModelTagConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      findings?:  {
        __typename: "ModelFindingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      phases?:  {
        __typename: "ModelPhaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hierarchyItemExclusions?: Array< string | null > | null,
      exclusions?: string | null,
      notes?: string | null,
      code?: string | null,
      milestoneTemplate?:  {
        __typename: "MilestoneTemplate",
        id: string,
        name: string,
        status: string,
        hierarchyBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      milestoneTemplateId?: string | null,
      externalId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      projectEftId?: string | null,
      projectReviewerId?: string | null,
      projectAdminId?: string | null,
      projectPrimaryContactId?: string | null,
      owner?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnUpdateFavoriteProjectSubscriptionVariables = {
  filter?: ModelSubscriptionFavoriteProjectFilterInput | null,
  owner?: string | null,
};

export type OnUpdateFavoriteProjectSubscription = {
  onUpdateFavoriteProject?:  {
    __typename: "FavoriteProject",
    id: string,
    projectId: string,
    project:  {
      __typename: "Project",
      id: string,
      groupId?: string | null,
      name: string,
      status: string,
      clientId: string,
      client?:  {
        __typename: "Client",
        id: string,
        name: string,
        code: string,
        status: string,
        reportDisclaimer?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        clientPrimaryContactId?: string | null,
        owner?: string | null,
      } | null,
      eft?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reviewer?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      admin?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      address:  {
        __typename: "Address",
        street: string,
        street2?: string | null,
        city: string,
        state: string,
        zip: string,
      },
      primaryContact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reportTemplateId?: string | null,
      reportDisclaimer?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      contacts?:  {
        __typename: "ModelProjectContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      distributionList?: string | null,
      externalLinks?:  {
        __typename: "ModelExternalLinkConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      tags?:  {
        __typename: "ModelTagConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      findings?:  {
        __typename: "ModelFindingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      phases?:  {
        __typename: "ModelPhaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hierarchyItemExclusions?: Array< string | null > | null,
      exclusions?: string | null,
      notes?: string | null,
      code?: string | null,
      milestoneTemplate?:  {
        __typename: "MilestoneTemplate",
        id: string,
        name: string,
        status: string,
        hierarchyBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      milestoneTemplateId?: string | null,
      externalId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      projectEftId?: string | null,
      projectReviewerId?: string | null,
      projectAdminId?: string | null,
      projectPrimaryContactId?: string | null,
      owner?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnDeleteFavoriteProjectSubscriptionVariables = {
  filter?: ModelSubscriptionFavoriteProjectFilterInput | null,
  owner?: string | null,
};

export type OnDeleteFavoriteProjectSubscription = {
  onDeleteFavoriteProject?:  {
    __typename: "FavoriteProject",
    id: string,
    projectId: string,
    project:  {
      __typename: "Project",
      id: string,
      groupId?: string | null,
      name: string,
      status: string,
      clientId: string,
      client?:  {
        __typename: "Client",
        id: string,
        name: string,
        code: string,
        status: string,
        reportDisclaimer?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        clientPrimaryContactId?: string | null,
        owner?: string | null,
      } | null,
      eft?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reviewer?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      admin?:  {
        __typename: "User",
        id: string,
        status: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        metadata?: string | null,
        projects?: Array< string | null > | null,
        favoriteProjects?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      address:  {
        __typename: "Address",
        street: string,
        street2?: string | null,
        city: string,
        state: string,
        zip: string,
      },
      primaryContact?:  {
        __typename: "Contact",
        id: string,
        type: string,
        firstName: string,
        lastName: string,
        companyName?: string | null,
        jobTitle?: string | null,
        phone?: string | null,
        email?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      reportTemplateId?: string | null,
      reportDisclaimer?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      contacts?:  {
        __typename: "ModelProjectContactConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      distributionList?: string | null,
      externalLinks?:  {
        __typename: "ModelExternalLinkConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      tags?:  {
        __typename: "ModelTagConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      findings?:  {
        __typename: "ModelFindingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      phases?:  {
        __typename: "ModelPhaseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hierarchyItemExclusions?: Array< string | null > | null,
      exclusions?: string | null,
      notes?: string | null,
      code?: string | null,
      milestoneTemplate?:  {
        __typename: "MilestoneTemplate",
        id: string,
        name: string,
        status: string,
        hierarchyBundleIds?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null,
      milestoneTemplateId?: string | null,
      externalId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      projectEftId?: string | null,
      projectReviewerId?: string | null,
      projectAdminId?: string | null,
      projectPrimaryContactId?: string | null,
      owner?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnCreateHierarchySubscriptionVariables = {
  filter?: ModelSubscriptionHierarchyFilterInput | null,
};

export type OnCreateHierarchySubscription = {
  onCreateHierarchy?:  {
    __typename: "Hierarchy",
    id: string,
    name: string,
    items?:  {
      __typename: "ModelHierarchyItemConnection",
      items:  Array< {
        __typename: "HierarchyItem",
        id: string,
        name: string,
        code?: string | null,
        hierarchyId: string,
        parentId?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnUpdateHierarchySubscriptionVariables = {
  filter?: ModelSubscriptionHierarchyFilterInput | null,
};

export type OnUpdateHierarchySubscription = {
  onUpdateHierarchy?:  {
    __typename: "Hierarchy",
    id: string,
    name: string,
    items?:  {
      __typename: "ModelHierarchyItemConnection",
      items:  Array< {
        __typename: "HierarchyItem",
        id: string,
        name: string,
        code?: string | null,
        hierarchyId: string,
        parentId?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnDeleteHierarchySubscriptionVariables = {
  filter?: ModelSubscriptionHierarchyFilterInput | null,
};

export type OnDeleteHierarchySubscription = {
  onDeleteHierarchy?:  {
    __typename: "Hierarchy",
    id: string,
    name: string,
    items?:  {
      __typename: "ModelHierarchyItemConnection",
      items:  Array< {
        __typename: "HierarchyItem",
        id: string,
        name: string,
        code?: string | null,
        hierarchyId: string,
        parentId?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnCreateHierarchyItemSubscriptionVariables = {
  filter?: ModelSubscriptionHierarchyItemFilterInput | null,
};

export type OnCreateHierarchyItemSubscription = {
  onCreateHierarchyItem?:  {
    __typename: "HierarchyItem",
    id: string,
    name: string,
    code?: string | null,
    hierarchyId: string,
    parentId?: string | null,
    hidden?: boolean | null,
    inspectionPoints?:  {
      __typename: "ModelInspectionPointConnection",
      items:  Array< {
        __typename: "InspectionPoint",
        id: string,
        groupId?: string | null,
        hierarchyItemId: string,
        type: InspectionPointType,
        projectId?: string | null,
        text: string,
        code?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnUpdateHierarchyItemSubscriptionVariables = {
  filter?: ModelSubscriptionHierarchyItemFilterInput | null,
};

export type OnUpdateHierarchyItemSubscription = {
  onUpdateHierarchyItem?:  {
    __typename: "HierarchyItem",
    id: string,
    name: string,
    code?: string | null,
    hierarchyId: string,
    parentId?: string | null,
    hidden?: boolean | null,
    inspectionPoints?:  {
      __typename: "ModelInspectionPointConnection",
      items:  Array< {
        __typename: "InspectionPoint",
        id: string,
        groupId?: string | null,
        hierarchyItemId: string,
        type: InspectionPointType,
        projectId?: string | null,
        text: string,
        code?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnDeleteHierarchyItemSubscriptionVariables = {
  filter?: ModelSubscriptionHierarchyItemFilterInput | null,
};

export type OnDeleteHierarchyItemSubscription = {
  onDeleteHierarchyItem?:  {
    __typename: "HierarchyItem",
    id: string,
    name: string,
    code?: string | null,
    hierarchyId: string,
    parentId?: string | null,
    hidden?: boolean | null,
    inspectionPoints?:  {
      __typename: "ModelInspectionPointConnection",
      items:  Array< {
        __typename: "InspectionPoint",
        id: string,
        groupId?: string | null,
        hierarchyItemId: string,
        type: InspectionPointType,
        projectId?: string | null,
        text: string,
        code?: string | null,
        hidden?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnCreateInspectionHierarchyItemSubscriptionVariables = {
  filter?: ModelSubscriptionInspectionHierarchyItemFilterInput | null,
};

export type OnCreateInspectionHierarchyItemSubscription = {
  onCreateInspectionHierarchyItem?:  {
    __typename: "InspectionHierarchyItem",
    id: string,
    inspectionId: string,
    hierarchyItemId: string,
    hierarchyItem?:  {
      __typename: "HierarchyItem",
      id: string,
      name: string,
      code?: string | null,
      hierarchyId: string,
      parentId?: string | null,
      hidden?: boolean | null,
      inspectionPoints?:  {
        __typename: "ModelInspectionPointConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    notes?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnUpdateInspectionHierarchyItemSubscriptionVariables = {
  filter?: ModelSubscriptionInspectionHierarchyItemFilterInput | null,
};

export type OnUpdateInspectionHierarchyItemSubscription = {
  onUpdateInspectionHierarchyItem?:  {
    __typename: "InspectionHierarchyItem",
    id: string,
    inspectionId: string,
    hierarchyItemId: string,
    hierarchyItem?:  {
      __typename: "HierarchyItem",
      id: string,
      name: string,
      code?: string | null,
      hierarchyId: string,
      parentId?: string | null,
      hidden?: boolean | null,
      inspectionPoints?:  {
        __typename: "ModelInspectionPointConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    notes?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnDeleteInspectionHierarchyItemSubscriptionVariables = {
  filter?: ModelSubscriptionInspectionHierarchyItemFilterInput | null,
};

export type OnDeleteInspectionHierarchyItemSubscription = {
  onDeleteInspectionHierarchyItem?:  {
    __typename: "InspectionHierarchyItem",
    id: string,
    inspectionId: string,
    hierarchyItemId: string,
    hierarchyItem?:  {
      __typename: "HierarchyItem",
      id: string,
      name: string,
      code?: string | null,
      hierarchyId: string,
      parentId?: string | null,
      hidden?: boolean | null,
      inspectionPoints?:  {
        __typename: "ModelInspectionPointConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    notes?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnCreateInspectionPointSubscriptionVariables = {
  filter?: ModelSubscriptionInspectionPointFilterInput | null,
};

export type OnCreateInspectionPointSubscription = {
  onCreateInspectionPoint?:  {
    __typename: "InspectionPoint",
    id: string,
    groupId?: string | null,
    hierarchyItemId: string,
    type: InspectionPointType,
    projectId?: string | null,
    text: string,
    code?: string | null,
    hidden?: boolean | null,
    observations?:  {
      __typename: "ModelObservationConnection",
      items:  Array< {
        __typename: "Observation",
        id: string,
        groupId?: string | null,
        type?: ObservationType | null,
        projectId?: string | null,
        inspectionId: string,
        hierarchyItemId: string,
        inspectionPointId: string,
        text?: string | null,
        suggestion?: string | null,
        findingId?: string | null,
        status?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        observationPrimaryLocationId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnUpdateInspectionPointSubscriptionVariables = {
  filter?: ModelSubscriptionInspectionPointFilterInput | null,
};

export type OnUpdateInspectionPointSubscription = {
  onUpdateInspectionPoint?:  {
    __typename: "InspectionPoint",
    id: string,
    groupId?: string | null,
    hierarchyItemId: string,
    type: InspectionPointType,
    projectId?: string | null,
    text: string,
    code?: string | null,
    hidden?: boolean | null,
    observations?:  {
      __typename: "ModelObservationConnection",
      items:  Array< {
        __typename: "Observation",
        id: string,
        groupId?: string | null,
        type?: ObservationType | null,
        projectId?: string | null,
        inspectionId: string,
        hierarchyItemId: string,
        inspectionPointId: string,
        text?: string | null,
        suggestion?: string | null,
        findingId?: string | null,
        status?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        observationPrimaryLocationId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnDeleteInspectionPointSubscriptionVariables = {
  filter?: ModelSubscriptionInspectionPointFilterInput | null,
};

export type OnDeleteInspectionPointSubscription = {
  onDeleteInspectionPoint?:  {
    __typename: "InspectionPoint",
    id: string,
    groupId?: string | null,
    hierarchyItemId: string,
    type: InspectionPointType,
    projectId?: string | null,
    text: string,
    code?: string | null,
    hidden?: boolean | null,
    observations?:  {
      __typename: "ModelObservationConnection",
      items:  Array< {
        __typename: "Observation",
        id: string,
        groupId?: string | null,
        type?: ObservationType | null,
        projectId?: string | null,
        inspectionId: string,
        hierarchyItemId: string,
        inspectionPointId: string,
        text?: string | null,
        suggestion?: string | null,
        findingId?: string | null,
        status?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        observationPrimaryLocationId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnCreateHierarchyBundleSubscriptionVariables = {
  filter?: ModelSubscriptionHierarchyBundleFilterInput | null,
};

export type OnCreateHierarchyBundleSubscription = {
  onCreateHierarchyBundle?:  {
    __typename: "HierarchyBundle",
    id: string,
    name: string,
    type: string,
    status: string,
    hierarchyItemIds?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnUpdateHierarchyBundleSubscriptionVariables = {
  filter?: ModelSubscriptionHierarchyBundleFilterInput | null,
};

export type OnUpdateHierarchyBundleSubscription = {
  onUpdateHierarchyBundle?:  {
    __typename: "HierarchyBundle",
    id: string,
    name: string,
    type: string,
    status: string,
    hierarchyItemIds?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnDeleteHierarchyBundleSubscriptionVariables = {
  filter?: ModelSubscriptionHierarchyBundleFilterInput | null,
};

export type OnDeleteHierarchyBundleSubscription = {
  onDeleteHierarchyBundle?:  {
    __typename: "HierarchyBundle",
    id: string,
    name: string,
    type: string,
    status: string,
    hierarchyItemIds?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnCreateMilestoneTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionMilestoneTemplateFilterInput | null,
};

export type OnCreateMilestoneTemplateSubscription = {
  onCreateMilestoneTemplate?:  {
    __typename: "MilestoneTemplate",
    id: string,
    name: string,
    status: string,
    hierarchyBundleIds?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnUpdateMilestoneTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionMilestoneTemplateFilterInput | null,
};

export type OnUpdateMilestoneTemplateSubscription = {
  onUpdateMilestoneTemplate?:  {
    __typename: "MilestoneTemplate",
    id: string,
    name: string,
    status: string,
    hierarchyBundleIds?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnDeleteMilestoneTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionMilestoneTemplateFilterInput | null,
};

export type OnDeleteMilestoneTemplateSubscription = {
  onDeleteMilestoneTemplate?:  {
    __typename: "MilestoneTemplate",
    id: string,
    name: string,
    status: string,
    hierarchyBundleIds?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnCreateActivityStreamSubscriptionVariables = {
  filter?: ModelSubscriptionActivityStreamFilterInput | null,
};

export type OnCreateActivityStreamSubscription = {
  onCreateActivityStream?:  {
    __typename: "ActivityStream",
    id: string,
    modelType: string,
    instanceId: string,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    date: string,
    type: string,
    text?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnUpdateActivityStreamSubscriptionVariables = {
  filter?: ModelSubscriptionActivityStreamFilterInput | null,
};

export type OnUpdateActivityStreamSubscription = {
  onUpdateActivityStream?:  {
    __typename: "ActivityStream",
    id: string,
    modelType: string,
    instanceId: string,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    date: string,
    type: string,
    text?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type OnDeleteActivityStreamSubscriptionVariables = {
  filter?: ModelSubscriptionActivityStreamFilterInput | null,
};

export type OnDeleteActivityStreamSubscription = {
  onDeleteActivityStream?:  {
    __typename: "ActivityStream",
    id: string,
    modelType: string,
    instanceId: string,
    userId: string,
    user?:  {
      __typename: "User",
      id: string,
      status: string,
      type: string,
      firstName: string,
      lastName: string,
      companyName?: string | null,
      jobTitle?: string | null,
      phone?: string | null,
      email?: string | null,
      metadata?: string | null,
      projects?: Array< string | null > | null,
      favoriteProjects?: string | null,
      inspections?:  {
        __typename: "ModelInspectionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null,
    date: string,
    type: string,
    text?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};
