export const getLocation = /* GraphQL */ `
  query CustomGetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      projectId
      groupId
      observationId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner
      photoSequence
      tags {
        items {
          id
          projectId
          locationId
          tagId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
      primaryPhoto {
        id
        projectId
        inspectionId
        json
        text
        file {
          bucket
          region
          key
        }
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner
      }
      photos {
        items {
          id
          projectId
          locationId
          photo {
            _version
            id
            reportable
            file {
              bucket
              region
              key
            }
          }
          photoId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          owner
        }
        nextToken
        startedAt
      }
    }
  }
`;

export const searchContacts = /* GraphQL */ `
  query CustomSearchContacts(
    $filter: SearchableContactFilterInput
    $sort: [SearchableContactSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchContacts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        type
        firstName
        lastName
        companyName
        jobTitle
        phone
        email
        _version
        _deleted
        createdAt
        updatedAt
        owner
      }
      nextToken
      total
    }
  }
`;

export const searchInspectionPoints = /* GraphQL */ `
  query CustomSearchInspectionPoints(
    $filter: SearchableInspectionPointFilterInput
    $sort: [SearchableInspectionPointSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchInspectionPoints(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        hierarchyItemId
        type
        projectId
        text
        code
        _version
        _deleted
        createdAt
        updatedAt
        owner
      }
      nextToken
      total
    }
  }
`;

export const findingsByProjectId = /* GraphQL */ `
query CustomFindingsByProjectId(
  $projectId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelFindingFilterInput
  $limit: Int
  $nextToken: String
) {
  findingsByProjectId(
    projectId: $projectId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      projectId
      groupId
      phaseId
      inspectionId
      hierarchyItemId
      hierarchyItem {
        id
        name
        code
        hierarchyId
        parentId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      observationId
      observation {
        id
        type
        projectId
        groupId
        inspectionId
        hierarchyItemId
        hierarchyItem {
          id
          name
          code
          hierarchyId
          parentId
          inspectionPoints {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        inspectionPointId
        inspectionPoint {
          id
          hierarchyItemId
          type
          projectId
          groupId
          text
          code
          hidden
          observations {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        text
        suggestion
        findingId
        primaryLocation {
          id
          projectId
          observationId
          primaryPhoto {
            id
            projectId
            groupId
            inspectionId
            json
            text
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
          }
          photoSequence
          tags {
            nextToken
            startedAt
          }
          photos {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          primaryPhoto {
            id
          }
          owner
        }
        locations {
          items {
            id
            projectId
            groupId
            observationId
            photoSequence
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            primaryPhoto {
              id
            }
            owner
          }
          nextToken
          startedAt
        }
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        primaryLocation {
          id
        }
        owner
      }
      closingObservation {
        id
        type
        projectId
        groupId
        inspectionId
        hierarchyItemId
        hierarchyItem {
          id
          name
          code
          hierarchyId
          parentId
          inspectionPoints {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        inspectionPointId
        inspectionPoint {
          id
          hierarchyItemId
          type
          projectId
          groupId
          text
          code
          hidden
          observations {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        text
        suggestion
        findingId
        primaryLocation {
          id
          projectId
          groupId
          observationId
          primaryPhoto {
            id
            projectId
            groupId
            inspectionId
            json
            text
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
          }
          photoSequence
          tags {
            nextToken
            startedAt
          }
          photos {
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          primaryPhoto {
            id
          }
          owner
        }
        locations {
          items {
            id
            projectId
            groupId
            observationId
            photoSequence
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            primaryPhoto {
              id
            }
            owner
          }
          nextToken
          startedAt
        }
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        primaryLocation {
          id
        }
        owner
      }
      status
      date
      closedDate
      code
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      closingObservation { 
        id
      }
    }
    nextToken
    startedAt
  }
}
`;

export const getDashboardData = /* GraphQL */ `
query getDashboardData {
  searchInspections(filter: {
        or: [{
          status: {eq: "SUBMITTED"}}, 
          {status: {eq: "ACTIVE"}}, 
          {status: {eq: "SUBMITTING"}}, 
          {status: {eq: "DECLINED"}},
          {status: {eq: "REVIEWING"}}
        ]
  }, limit: 500) {
    items {
      id
      project {
        id
        name
        client {
          name
        }
        reviewer {
          id
        }
      }
      inspectionDate
      comments {
        items {
          comment {
            text
            createdAt
            user {
              firstName
              lastName
            }
          }
        }
      }
      phase {
        name
      }
      reviewer {
        id
      }
      status
    }
  }
}
`
export const locationsByObservationId = /* GraphQL */ `
query locationsByObservationId ($observationId: ID!) {
  locationsByObservationId(observationId: $observationId) {
    items {
      photos {
        items {
          photo {
            id
            json
            _version
          }
        }
      }
    }
  }
}
`;
