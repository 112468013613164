type AgFilter = {
    filter: string,
    filterType: string,
    type: string
}

const filterTransforms = {
    equals: (field: string, filter: AgFilter) => { 
      return { eq: filter.filter }
    },
    notEqual: (field: string, filter: AgFilter) => { 
      return { ne: filter.filter }
    },
    contains: (field: string, filter: AgFilter) => { 
      return { wildcard: `*${filter.filter}*` }
    },
    notContains: (field: string, filter: AgFilter) => { 
      return { not: { [field]: { wildcard: `*${filter.filter}*` }}}
    },
    startsWith: (field: string, filter: AgFilter) => { 
      return { wildcard: `${filter.filter}*` }
    },
    endsWith: (field: string, filter: AgFilter) => { 
      return { wildcard: `*${filter.filter}` }
    },
    lessThan: (field: string, filter: AgFilter) => { 
      return { lt: filter.filter }
    },
    lessThanOrEqual: (field: string, filter: AgFilter) => { 
      return { lte: filter.filter }
    },
    greaterThan: (field: string, filter: AgFilter) => { 
      return { gt: filter.filter }
    },
    greaterThanOrEqual: (field: string, filter: AgFilter) => { 
      return { gte: filter.filter }
    },
    inRange: (field: string, filter: AgFilter) => { 
      return { range: filter.filter }
    }
  } as any

export function AgGridToElasticsearch(sortModel: any, filterModels: any): { sort?: any, filter?: any} {
    const searchParams: { sort?: any, filter?: any} = {}

      if (sortModel.length) {
        searchParams.sort = {
          field: sortModel[0].colId,
          direction: sortModel[0].sort
        }
      }

      const filterInput: any = {}
      Object.keys(filterModels).forEach(key => {
        const filterModel = filterModels[key]
        filterModel.filter = filterModel.filter.toLowerCase();

        const filter = filterTransforms[filterModel.type](key, filterModel as AgFilter)

        if (filter.not) {
          if (!filterInput.not) {
            filterInput.not = []
          }
          filterInput.not.push(filter.not)
        }
        else {
          filterInput[key] = filter
        }
      })

      if (Object.keys(filterInput).length !== 0) {
        searchParams.filter = filterInput
      }

      return searchParams;
}